<template>
    <div class="flex justify-between items-center m-1.5 p-1.5 rounded-lg bg-gray-200">
        <div class="flex items-center min-w-0 m-0 mr-3 gap-1.5">
            <icon class="shrink-0" :name="icon" />

            <span class="shrink-0">{{ message }}</span>
            <template v-if="value">
                :
                <em class="truncate">{{ value }}</em>
            </template>
        </div>

        <button class="text-gray-600 | hover:text-gray-700" @click="$emit('cancel')">
            <icon name="bold/remove-circle" />
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            icon: {
                type: String,
                default: '',
            },
            message: {
                type: String,
                default: '',
            },
            value: {
                type: String,
                default: '',
            },
        },
    };
</script>
