import { cloneDeep, has, isPlainObject } from 'lodash-es';
import { apolloClient } from '@/plugins/vue-apollo.js';
import { gql } from 'apollo-boost';
import axios from '@/plugins/axios.js';
import Vue from 'vue';
import caching from '@/plugins/caching.js';
import ActivixDate from '@/value-objects/ActivixDate.js';
import Moment from '@/value-objects/Moment.js';
import Lead from '@/entities/Lead.js';

export default {
    updatePagination(key, value) {
        const updatedPagination = cloneDeep(this.pagination);

        updatedPagination[key] = value;

        this.pagination = updatedPagination;
    },

    async fetchDashboardViewsWithCaching() {
        try {
            const dashboardViews = await Vue.caching.getCache(caching.DASHBOARD_VIEWS_CACHE_KEY);
            const sharedDashboardViews = await Vue.caching.getCache(caching.SHARED_DASHBOARD_VIEWS_CACHE_KEY);
            if (dashboardViews || sharedDashboardViews) {
                if (dashboardViews) {
                    this.setDashboardViewsStore(dashboardViews, 'dashboardViews');
                }

                if (sharedDashboardViews) {
                    this.setDashboardViewsStore(sharedDashboardViews, 'sharedDashboardViews');
                }

                setTimeout(async () => {
                    await this.fetchDashboardViews();
                }, 2000);
            } else {
                await this.fetchDashboardViews();
            }
        } catch (e) {
            await this.fetchDashboardViews();
        }
    },

    async fetchDashboardViews() {
        let dashboardViewsResponse;

        if (Vue.feature.isEnabled('remove_graphql_dashboard_views')) {
            dashboardViewsResponse = (await Vue.api.authUser.getDashboardViews());
        } else {
            const authUserDashboardViewsResponse = apolloClient.query({
                query: gql`query authUser_dashboardViews {
                    authUser {
                        id
                        dashboard_views {
                            id
                            created_at
                            updated_at
                            user_id
                            type
                            name
                            description
                            dashboard
                            hot_swap_date
                            start_date
                            end_date
                            dates
                            preset_dates
                            filter
                            order
                            option
                            expand
                            column
                            subscription
                            system_generated
                            accessible_through_link_only

                            shared_to {
                                id
                            }
                        }

                        shared_dashboard_views {
                            id
                            user_id
                            user {
                                fullname
                            }
                            name
                            dashboard
                            hot_swap_date
                            filter
                            order
                            option
                            expand
                            column
                            start_date
                            end_date
                            dates
                            preset_dates
                            subscription
                            description
                            system_generated
                            accessible_through_link_only
                            subscribed
                            is_shared
                        }
                    }
                }`,
            });

            dashboardViewsResponse = (await authUserDashboardViewsResponse).data.authUser;
        }

        this.setDashboardViewsStore(dashboardViewsResponse.dashboard_views, 'dashboardViews');
        this.setDashboardViewsStore(dashboardViewsResponse.shared_dashboard_views, 'sharedDashboardViews');

        Vue.caching.setCache(caching.DASHBOARD_VIEWS_CACHE_KEY, this.dashboardViews);
        Vue.caching.setCache(caching.SHARED_DASHBOARD_VIEWS_CACHE_KEY, this.sharedDashboardViews);
    },

    setDashboardViewsStore(dashboardViews, storeName = 'dashboardViews') {
        const views = {
            allLead: [],
            commercial: [],
            event: [],
            leadXpress: [],
            loyalty: [],
            phoneUp: [],
            renewal: [],
            saleTable: [],
            service: [],
            walkIn: [],
            webBoost: [],
            webOrder: [],
        };

        if (isPlainObject(dashboardViews)) {
            this[storeName] = { ...views, ...dashboardViews };
        } else {
            (dashboardViews || []).forEach(view => {
                if (views[view.dashboard]) {
                    views[view.dashboard].push(view);
                }
            });

            this[storeName] = views;
        }
    },

    async updateDashboardView(payload, id) {
        const response = await axios.patch(`v1/dashboard-views/${id}`, payload);

        // Update current dashboard view
        this.selectedDashboardView = response.data.data;

        const viewData = this.getDashboardView(this.dashboardView.id);
        viewData.name = response.data.data.name;

        viewData.shared_to = response.data.data.shared_to;

        if (viewData.dashboard) {
            const views = cloneDeep(this.dashboardViews);
            let viewDashboard = views[viewData.dashboard];
            viewDashboard = viewDashboard.filter(view => {
                return view.id != viewData.id;
            });
            viewDashboard.push(viewData);

            views[viewData.dashboard] = viewDashboard;
            this.dashboardViews = views;
        }
    },

    async addDashboardView(payload) {
        const response = await axios.post('v1/dashboard-views', payload);

        this.selectedDashboardView = response.data.data;
        this.viewId = response.data.data.id;

        if (response.data.data.dashboard) {
            const dashboardViews = cloneDeep(this.dashboardViews);
            dashboardViews[response.data.data.dashboard].push(response.data.data);
            this.dashboardViews = dashboardViews;
        }
    },

    async deleteDashboardView(viewId) {
        await axios.delete(`v1/dashboard-views/${viewId}`);

        for (const [dashboardType, views] of Object.entries(this.dashboardViews)) {
            const viewIndex = views.findIndex(v => v.id == viewId);

            if (viewIndex !== -1) {
                const dashboardViews = cloneDeep(this.dashboardViews);
                dashboardViews[dashboardType].splice(viewIndex, 1);
                this.dashboardViews = dashboardViews;
                return;
            }
        }
    },

    async deleteUserFromSharedDashboardView(viewId) {
        await axios.delete(`v1/dashboard-views/${viewId}/shared-users`);

        for (const [dashboardType, views] of Object.entries(this.sharedDashboardViews)) {
            const viewIndex = views.findIndex(v => v.id == viewId);

            if (viewIndex !== -1) {
                const sharedDashboardViews = cloneDeep(this.sharedDashboardViews);
                sharedDashboardViews[dashboardType].splice(viewIndex, 1);
                this.sharedDashboardViews = sharedDashboardViews;
                return;
            }
        }
    },

    async updateSubscribedDashboardView(payload, viewId, userId) {
        await axios.patch(`v1/dashboard-views/${viewId}/shared-users/${userId}`, payload);
    },

    setDashboardConfigs(configs, overwrite = false) {
        const newConfigs = {};

        Object.keys(configs).forEach(key => {
            if (has(configs, `${key}.__ob__`)) {
                configs[key] = JSON.parse(JSON.stringify(configs[key]));
            }

            if (key === 'columnOrder') {
                const mandatoryColumns = ['bulk_select', 'fullname', 'contact_method'];

                mandatoryColumns.forEach((column, mandatoryIndex) => {
                    if (configs[key].indexOf(column) === -1) {
                        configs[key].splice(mandatoryIndex, 0, column);
                    } else if (configs[key].indexOf(column) !== mandatoryIndex) {
                        configs[key].splice(mandatoryIndex, 0, configs[key].splice(configs[key].indexOf(column), 1)[0]);
                    }
                });
            }

            if (overwrite || ['filters', 'columnVisibilities', 'columnOrder', 'hotSwapDate', 'dates'].includes(key)) {
                newConfigs[key] = configs[key];
            } else {
                newConfigs[key] = { ...this.configs[key], ...configs[key] };
            }
        });

        this.configs = { ...this.configs, ...newConfigs };
    },
    setAllLeadsInTableSelected(allLeadsInTableSelected) {
        if (!allLeadsInTableSelected) {
            this.bulkSelect = [];
            this.blackListedLeadIds = [];
        }

        this.allLeadsInTableSelected = allLeadsInTableSelected;
    },
    setBulkSelect(bulkSelect) {
        if (!bulkSelect.length) {
            this.dashboardSelect = false;
        }

        this.bulkSelect = Object.freeze(bulkSelect);
    },
    setDashboardTableLeads(leads) {
        this.tableLeads = leads.map(lead => new Lead(lead));
    },
    setStartDate(startDate) {
        this.startDate = Moment.asLocale(startDate, 'startDate').toDateString();
    },
    setEndDate(endDate) {
        this.endDate = Moment.asLocale(endDate, 'endDate').toDateString();
    },
    setStartContractDateStore(startContractDate) {
        this.startContractDate = Moment.init(startContractDate, 'date').toString();
    },
    appendRenewalAlertDateInFilters(notificationDate) {
        if (!notificationDate) {
            return;
        }
        this.configs.filters.renewal_alert_date = new ActivixDate(notificationDate).format(ActivixDate.DATE_HUMAN_SHORT_FORMAT);
    },
};
