import Vue from 'vue';
import { useContextStore } from '@/store/modules/context/store.js';
import PreQualification from '@/entities/PreQualification.ts';
import useFeature from '@/plugins/optimizely.js';
import axios from '../../../plugins/axios.js';
import { merge, sort } from '../../../utils/index.js';
import { useGlobalStore } from '../../store.js';

export default {
    addTaskEvent(addedTaskEvent) {
        if (addedTaskEvent.lead_id !== useContextStore().leadId) {
            return;
        }

        const taskAlreadyExists = this.taskEvents.some(taskEvent => taskEvent.id === addedTaskEvent.id);

        if (taskAlreadyExists) {
            return;
        }

        this.taskEvents.push(addedTaskEvent);
    },

    deleteTaskEvent(deletedTaskEvent) {
        if (deletedTaskEvent.lead_id !== useContextStore().leadId) {
            return;
        }

        const taskIndex = this.taskEvents.findIndex(taskEvent => taskEvent.id === deletedTaskEvent.id);

        if (taskIndex === -1) {
            return;
        }

        this.taskEvents.splice(taskIndex, 1);
    },

    async fetchTaskEvents(leadId) {
        let taskEvents;
        if (useFeature.isEnabled('remove_graphql_taskevents')) {
            taskEvents = await Vue.api.leadTaskEvents.index(leadId);
        } else {
            taskEvents = await Vue.api.taskEvents.index([leadId]);
        }

        this.setTaskEvents(taskEvents);
    },

    setTaskEvents(taskEvents) {
        const contextLeadId = useContextStore().leadId;

        this.taskEvents = taskEvents.filter(taskEvent => taskEvent.lead_id === contextLeadId);
    },

    updateTaskEvent(updatedTaskEvent) {
        if (updatedTaskEvent.lead_id !== useContextStore().leadId) {
            return;
        }

        const taskEvent = this.taskEvents.find(taskEvent => taskEvent.id === updatedTaskEvent.id);

        if (!taskEvent) {
            return;
        }

        merge(taskEvent, updatedTaskEvent);
    },

    async fetchCommunicationAutomationJobs(leadIds) {
        const response = await axios.get('v1/automation-jobs/communications', {
            params: {
                leadIds,
            },
        });

        this.setCommunicationAutomationJobs(response.data.data);
    },

    setCommunicationAutomationJobs(automationJobs) {
        this.communicationAutomationJobs = automationJobs;
    },

    updateCommunicationAutomationJob(updatedAutomationJob) {
        if (updatedAutomationJob.lead_id !== useContextStore().leadId) {
            return;
        }

        const communicationAutomationJob = this.communicationAutomationJobs.find(communicationAutomationJob => communicationAutomationJob.id === updatedAutomationJob.id);

        if (!communicationAutomationJob) {
            return;
        }

        merge(communicationAutomationJob, updatedAutomationJob);
    },

    addCommunication(addedCommunication) {
        if (addedCommunication.lead_id !== useContextStore().leadId) {
            return;
        }

        const communicationAlreadyExists = this.communications.some(communication => communication.id === addedCommunication.id);

        if (communicationAlreadyExists) {
            return;
        }

        this.communications.push(addedCommunication);
    },

    deleteCommunication(deletedCommunication) {
        if (deletedCommunication.lead_id !== useContextStore().leadId) {
            return;
        }

        const communicationIndex = this.communications.findIndex(communication => communication.id === deletedCommunication.id);

        if (communicationIndex === -1) {
            return;
        }

        this.communications.splice(communicationIndex, 1);
    },

    async fetchCommunications() {
        const leadId = useContextStore().leadId;

        if (!leadId) {
            return;
        }

        let communications;
        if (useFeature.isEnabled('remove_graphql_communications')) {
            communications = await Vue.api.leadCommunications.index(leadId);
        } else {
            communications = await Vue.api.communications.index([leadId]);
        }

        this.setCommunications(communications);
    },

    setCommunications(communications) {
        const leadId = useContextStore().leadId;

        this.communications = communications.filter(communication => communication.lead_id === leadId);
    },

    updateCommunication(updatedCommunication) {
        if (updatedCommunication.lead_id !== useContextStore().leadId) {
            return;
        }

        const communication = this.communications.find(communication => communication.id === updatedCommunication.id);

        if (!communication) {
            return;
        }

        merge(communication, updatedCommunication);
    },

    async setVehicles() {
        if (this.vehicles.length) {
            return;
        }

        try {
            // @TODO Only fetch power sports
            const response = await axios.get('v1/vehicles', {
                params: {
                    powerSportsOnly: true,
                },
            });

            const vehicles = Object.keys(response.data.data).map(make => {
                // We only need power sports
                const models = response.data.data[make].filter(model => {
                    return model.type !== 'vehicle';
                });

                return {
                    make,
                    models: sort(models, 'model'),
                };
            });

            this.vehicles = Object.freeze(sort(vehicles, 'make'));
        } catch (error) {
            useGlobalStore().appendNewError({
                code: '0039',
                display: true,
                error,
            });

            throw error;
        }
    },

    async setVehicleMakes() {
        if (this.vehicleMakes.length) {
            return;
        }

        const vehicleMakes = await useGlobalStore().fetchVehicleMakes();

        this.vehicleMakes = Object.freeze(vehicleMakes);
    },

    async fetchPreQualification() {
        const response = await Vue.api.preQualification.show(useContextStore().leadId);

        this.preQualification = Object.freeze(new PreQualification(response));

        return this.preQualification;
    },

    async fetchAndSyncEti() {
        try {
            await Vue.api.eti.index(useContextStore().leadId);
        } catch (error) {
            // do something ?
        }
    },
};
