export default {
    dateTooltip: 'Date of the event: {eventDate}<br/>Date of the attribution: {attributionDate}',
    matchingLeadEvents: 'Events matching the criteria',
    viewMatchingLeadEvents: 'View events matching the criteria',

    replacePrimaryEvent: {
        confirmation: 'This attribution will replace some information in the destination lead.<br/>@:general.areYouSure',

        currentInformation: 'Current information',
        afterAttribution: 'After attribution',
    },
};
