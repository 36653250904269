<template>
    <div
        class="relative list-none rounded border-2 bg-gray-100 md:w-1/4 overflow-y-auto max-h-100"
        :class="{'w-1/3': forModalInventoryUnit }"
    >
        <div class="pt-4 px-4 text-sm text-gray-800 bg-gray-100 sticky top-0">
            <activix-label :label="$t('inventory.leadsWithThisVehicle')" />
        </div>
        <div class="px-4 pb-4" v-if="concurrentOpportunities.length">
            <concurrent-opportunity
                :concurrent-opportunity="concurentOpportunity"
                :key="concurentOpportunity.id"
                v-for="concurentOpportunity in concurrentOpportunities"
            />
        </div>
        <div v-else>
            <empty-state
                class="pb-6"
                svg="illustrations/search-doc-text"
                :show-clear="false"
                :description="$t('inventory.noConcurrentOpportunity')"
                key="empty-state"
            />
        </div>
    </div>
</template>

<script>
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import ActivixLabel from '@/components/elements/ActivixLabel.vue';
    import ConcurrentOpportunity from '@/components/inventory/ConcurrentOpportunity.vue';
    import EmptyState from '@/components/EmptyState.vue';

    export default {
        components: { EmptyState, ConcurrentOpportunity, ActivixLabel },
        props: {
            concurrentOpportunities: {
                type: Array,
                required: true,
            },
            forModalInventoryUnit: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            leadFullName() {
                return `${this.concurrentOpportunity.first_name} ${this.concurrentOpportunity.last_name}`;
            },

            concurrentOpportunityDate() {
                const diffInDays = new ActivixDate('now').diffInDays(new ActivixDate(this.concurrentOpportunity.created_at));
                return `${this.$t('inventory.concurrentOpportunityDate', [diffInDays])}`;
            },

            statusIconColor() {
                return 'fill-green-500 text-green-500';
            },
        },
    };
</script>
