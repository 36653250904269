import { getOnce } from '@/plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async index(leadId: string) {
        if (!leadId) {
            throw new InvalidPayload('Lead ID not provided.');
        }

        const response = await getOnce(`v1/leads/${leadId}/task-events`);
        if (response === null) {
            return null;
        }

        return response.data;
    },
};
