export default {
    dateTooltip: "Date de l'événement: {eventDate}<br/>Date de l'attribution: {attributionDate}",
    matchingLeadEvents: 'Événements correspondants aux critères',
    viewMatchingLeadEvents: 'Afficher les événements correspondants aux critères',

    replacePrimaryEvent: {
        confirmation: 'Cette attribution va remplacer certaines informations dans le lead de destination.<br/>@:general.areYouSure',

        currentInformation: 'Information actuelle',
        afterAttribution: "Après l'attribution",
    },
};
