<template>
    <div class="flex grow items-center justify-center">
        <div class="sticky" :style="positionStyle">
            <div
                class="flex grow items-center justify-center"
                :class="{
                    'flex-row pb-12': horizontal,
                    'flex-col': !horizontal,
                }"
            >
                <activix-svg
                    class="pointer-events-none"
                    :class="{
                        'w-48 px-0 ml-0 | md:px-2 md:ml-6': horizontal,
                        'w-56 px-6': !horizontal
                    }"
                    :name="svg"
                />
                <div class="flex flex-col gap-6" :class="!horizontal && 'text-center'">
                    <div>
                        <div class="text-gray-800 text-2xl font-medium" v-text="title" v-if="title" />
                        <div class="mt-1 text-gray-600 text-lg" v-text="description" v-if="description" />
                    </div>

                    <div v-if="showAdd">
                        <activix-tooltip :content="buttonTooltip">
                            <div>
                                <activix-button :disabled="verifiedSale" @click="$emit('add-item')">
                                    <div class="text-gray-600" v-text="$t('general.add')" />
                                </activix-button>
                            </div>
                        </activix-tooltip>
                    </div>

                    <div v-else-if="showClear">
                        <activix-button @click="$emit('clear-filters')">
                            <div class="text-gray-600" v-text="$t('filters.clearAllFilters')" />
                        </activix-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useLayoutStore } from '@/store/modules/layout/store.js';
    import { useGlobalStore } from '@/store/store.js';
    import { ActivixButton } from '@autosync/atx-activix-ui';
    import ActivixSvg from '@/components/elements/ActivixSvg.vue';
    import { useContextStore } from '@/store/modules/context/store.js';

    export default {
        components: {
            ActivixSvg,
            ActivixButton,
        },

        props: {
            addTooltip: {
                type: String,
                default: '',
            },
            description: {
                type: String,
                default: '',
            },
            horizontal: {
                type: Boolean,
                default: false,
            },
            showAdd: {
                type: Boolean,
                default: false,
            },
            showClear: {
                type: Boolean,
                default: true,
            },
            svg: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                default: '',
            },
        },

        computed: {
            ...mapState(useLayoutStore, ['bodySpacingTop', 'headerHeight']),
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
            }),

            verifiedSale() {
                return !!this.contextLead.disabledBySaleVerified(this.authUser);
            },
            verifiedSaleReason() {
                return this.verifiedSale ? this.$t('clientCard.verifiedSaleUneditable') : '';
            },
            buttonTooltip() {
                if (this.verifiedSale) {
                    return this.verifiedSaleReason;
                }
                return this.addTooltip;
            },
            positionStyle() {
                const top = this.headerHeight + this.bodySpacingTop;

                return {
                    top: `${top}px`,
                    bottom: '1.5rem',
                };
            },
        },
    };
</script>
