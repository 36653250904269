const defaultForm = {
    id: null,
    active: true,
    first_name: '',
    last_name: '',
    email: '',
    sex: 'M',
    timezone: 'America/Montreal',
    locale: 'fr',

    post: null,
    role: {},
    role_id: null,
    team: null,
    supplier: null,
    divisions: [],
    profilePicturePath: '',
    profile_picture: '',
    user_phones: [],
    suppliers: [],
    director_inactive: null,
    director_inactive_children: {},
    inbound_cc_users: [],
    inbound_cc_users_children: {},
    groups: [],
    reminder_preferences: [],
    default_dashboard: null,

    password: '',
    password_confirmation: '',
    block_lead_info: true,
    hide_in_user_select: false,
    no_reassignation_notification: false,
    next_step: true,
    next_step_mandatory: false,
    mass_mailing: true,
    campaign: false,
    niotext: false,
    mail_inbox: false,
    restrict_interdealer_leads: false,
    allow_email_template_edition: false,

    appt_report: false,
    appt_sms: false,
    appt_email: false,

    sale_report: false,
    sale_sms: false,
    sale_email: false,

    freeze_financial_data: false,
    hide_fi_profits: false,
    allow_import_access: false,
    allow_export_access: false,
    allow_event_creation: false,
    end_contract_required: false,
    in_turn_edit: false,
    bell_event_option: false,
    can_create_child: false,
    credit: true,
    trade_report: false,
    automation_access: false,
    automation_advanced_access: false,
    director_access_renewal: false,
    has_analytics_access: false,
    analytics_module_access: false,

    email_config: {
        access_token: '',
        active: false,
        signature: '',
    },

    module_access: {
        leadxpress: true,
        web_boost: true,
        walk_in: true,
        phone_up: true,
        loyalty: false,
        service: false,
        event: false,
        renewal: false,
        sale_table: false,
        commercial: false,
        activity_report: true,
        web_order: false,
    },

    custom_permissions: {
        delete_lead: false,
        merge_lead: false,
        limit_audio_access: false,
        limit_calendar_task_access: true,
    },

    automation_options: {},

    options: {
        newLeadLoyalty: {
            screen: false,
            email: false,
            push: false,
            sms: false,
            availability: 'user_schedule',
            divisions: [1, 2, 3],
        },
        newLeadLoyaltyDuplicate: {
            screen: false,
            email: false,
            push: false,
            sms: false,
            availability: 'user_schedule',
            divisions: [1, 2, 3],
        },
        assignLeadDemand: {
            screen: false,
            email: true,
            push: false,
            sms: false,
            divisions: [1, 2, 3],
        },
        assignUser: {
            screen: true,
            email: false,
            push: false,
            sms: false,
            divisions: [1, 2, 3],
        },
        taskEventUnassigned: {
            screen: false,
            email: false,
            push: false,
            sms: false,
            divisions: [1, 2, 3],
        },
        taskDeleted: {
            screen: false,
            email: false,
            push: false,
            sms: false,
            divisions: [1, 2, 3],
        },
        alertCommentReply: {
            screen: true,
            email: false,
            push: false,
            sms: false,
            divisions: [1, 2, 3],
        },
        commentFollow: {
            screen: false,
            email: false,
            push: false,
            sms: false,
            divisions: [1, 2, 3],
        },
        clientResponse: {
            screen: true,
            email: false,
            push: false,
            sms: false,
            divisions: [1, 2, 3],
        },
        lostAlert: {
            screen: false,
            email: false,
            push: false,
            sms: false,
            divisions: [1, 2, 3],
        },
        invalidAlert: {
            screen: false,
            email: false,
            push: false,
            sms: false,
            divisions: [1, 2, 3],
        },
        declinedLeadDemand: {
            screen: true,
            email: false,
            push: false,
            sms: false,
            divisions: [1, 2, 3],
        },
        leadWaitingInRoom: {
            email: false,
            push: true,
            sms: false,
        },
        missedCall: {
            screen: false,
            sms: false,
            push: false,
            email: false,
            availability: 'anytime',
            divisions: [1, 2, 3],
        },
        leadAuto: {
            screen: true,
            sms: false,
            push: false,
            email: false,
            availability: 'anytime',
            divisions: [1, 2, 3],
        },
        noDivision: {
            screen: true,
            sms: false,
            push: false,
            email: false,
            availability: 'anytime',
            divisions: [1, 2, 3],
        },
        takeOver: {
            sms: false,
            email: false,
            push: false,
            availability: 'anytime',
            divisions: [1, 2, 3],
        },
        noPhoneAlert: {
            screen: true,
            sms: false,
            email: false,
            push: false,
            availability: 'anytime',
            divisions: [1, 2, 3],
        },
        nextInTurn: {
            screen: true,
            sms: false,
            push: false,
            availability: 'anytime',
        },
        noUserInTurn: {
            screen: true,
            sms: false,
            push: false,
            availability: 'anytime',
            divisions: [1, 2, 3],
        },
        eventLeadToReach: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            availability: 'anytime',
        },
        eventNewAppointment: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            availability: 'anytime',
        },
        eventNewPhoneAppointment: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            availability: 'anytime',
        },
        eventConfirmedAppointment: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            availability: 'anytime',
        },
        eventCanceledAppointment: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            availability: 'anytime',
        },
        eventBadService: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            availability: 'anytime',
        },
        serviceMyClientsAppointments: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            availability: 'anytime',
            divisions: [1, 2, 3],
        },
        serviceMyClientsVehiclePickUp: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            availability: 'anytime',
            divisions: [1, 2, 3],
        },
        serviceVehiclePickUp: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            availability: 'anytime',
            divisions: [1, 2, 3],
        },
        nioTextIncoming: {
            screen: true,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        renewalAlertEquity: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        renewalAlertEndLocation: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        renewalAlertEndWarranty: {
            screen: false,
            sms: false,
            email: false,
            push: false,
        },
        renewalAlertExcessMileage: {
            screen: false,
            sms: false,
            email: false,
            push: false,
        },
        inTurnRemove: {
            screen: false,
            sms: false,
            push: false,
            availability: 'anytime',
        },
        stateChanged: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        appointment: {
            screen: false,
            sms: false,
            email: false,
            push: false,
        },
        sale: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        deposit: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        approved: {
            screen: true,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        prepared: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        inspected: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        gas: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        delivered: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        deliveryDate: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        availableDate: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        paperworkDate: {
            screen: false,
            sms: false,
            email: false,
            push: false,
            divisions: [1, 2, 3],
        },
        untreatedLead: {
            screen: false,
            email: false,
            sms: false,
            push: false,
            availability: 'user_schedule',
            divisions: [1, 2, 3],
        },
        untreatedLeadDuplicate: {
            screen: false,
            email: false,
            sms: false,
            push: false,
            availability: 'user_schedule',
            divisions: [1, 2, 3],
        },
        assignLeadFollow: {
            screen: false,
            email: false,
            sms: false,
            divisions: [1, 2, 3],
        },
        newLead: {
            screen: true,
            email: false,
            sms: false,
            push: false,
            availability: 'user_schedule',
            divisions: [1, 2, 3],
        },
        newLeadDuplicate: {
            screen: false,
            email: false,
            sms: false,
            push: false,
            availability: 'user_schedule',
            divisions: [1, 2, 3],
        },
        emailRead: {
            screen: true,
            email: false,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
        newNoteOnAssignedLead: {
            screen: false,
            email: false,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
        assignedLeadUpdated: {
            screen: true,
            email: true,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
        assignedLeadVehicleCreated: {
            screen: false,
            email: false,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
        assignedLeadVehicleCreatedManager: {
            screen: false,
            email: false,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
        assignedLeadVehicleUpdated: {
            screen: false,
            email: false,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
        assignedLeadVehicleUpdatedManager: {
            screen: false,
            email: false,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
        taskEventDatesUpdated: {
            screen: false,
            email: false,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
        taskEventStatusUpdated: {
            screen: false,
            email: false,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
        mentions: {
            screen: true,
            email: true,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
        returnToMarket: {
            screen: false,
            email: false,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
        feedbackCalculator: {
            screen: false,
            email: false,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
        leadAddedToSalesJourney: {
            screen: true,
            email: false,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
        leadSeparatedFromSalesJourney: {
            screen: true,
            email: false,
            sms: false,
            push: false,
            divisions: [1, 2, 3],
        },
    },

    default_account_id: null,

    confirmation_timeframe: {
        when: '24_hours_before',
        time: null,
    },
    older_than: null,
    newer_than: null,

    account: {},
    child_accounts: [],
    children: [],
    parent: {},
    parent_user_id: null,
    calculator_value_visible: false,
    bdc_super_access: false,
    service_super_access: false,
    flows: [],
    access_token: '',
    personal_tokens: [],
    last_mobile_heartbeat_at: null,
    abilities: [],
};

export default {
    currentForm: defaultForm,
    defaultForm,
    mfaEnrolled: null,
};
