<template>
    <portal :to="portal" v-if="exists || !destroyable">
        <div
            class="modal fade | shadow-md"
            tabindex="-1"
            :class="[classes, { scrollable: scrollable }]"
            :data-backdrop="closable || 'static'"
            :data-keyboard="closable ? 'true' : 'false'"
            data-testid="activix-modal"
            ref="modal"
        >
            <div class="modal-dialog" :class="[`modal-${size}`, { 'w-full m-0': mdLayout }]">
                <div class="modal-content | flex flex-col border-0 shadow-md | md:rounded">
                    <div class="relative">
                        <div
                            class="absolute z-50 mt-0 top-0.5 right-0 mr-2 | xl:mt-3 xl:top-1.5 xl:mr-6"
                            :class="cancelClassName || 'h-full'"
                            v-if="isCancelable"
                        >
                            <button type="button" class="link-grey-light | p-4 | xl:p-0" @click="cancelModal">
                                <icon
                                    class="text-xl xl:text-lg"
                                    name="regular/remove"
                                    data-intercom-target="modal.close"
                                />
                            </button>
                        </div>
                    </div>
                    <div class="relative" :class="{ 'modal-header | py-4 px-6 border-grey-200 | md:rounded-t': $slots.header }">
                        <slot name="header" />
                    </div>
                    <div>
                        <slot name="widget" v-if="!loading" />
                    </div>
                    <div class="modal-body" :class="[{ 'px-6': mdLayout && !noPadding, 'p-0': noPadding }, bodyColor]">
                        <activix-spinner :size="50" v-if="loading && !loadingOverlay" />
                        <slot name="body" v-else />

                        <transition name="fade" :duration="loadingDuration">
                            <div
                                class="absolute flex h-full items-center justify-center left-0 top-0 w-full bg-white z-10"
                                :class="{ 'opacity-50': loadingOverlayOpacity }"
                                v-if="loading && loadingOverlay"
                            >
                                <activix-spinner :size="50" v-if="loadingSpinner" />
                            </div>
                        </transition>
                    </div>
                    <div
                        class="modal-footer | py-4 px-6 bg-white border-grey-200 text-center | md:rounded-b"
                        :class="{ 'sticky bottom-0 z-10': stickyFooter }"
                        v-if="$slots.footer"
                    >
                        <slot name="footer" />
                    </div>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
    import { debounce, isFunction } from 'lodash-es';

    export const colors = ['white', 'gray'];

    export default {
        name: 'ActivixModal',

        props: {
            name: {
                type: String,
                default: '',
            },
            opened: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: 'md',
            },
            closable: {
                type: Boolean,
                default: true,
            },
            cancelable: {
                type: Boolean,
                default: false,
            },
            portal: {
                type: String,
                default: 'modal-1',
            },
            loading: {
                type: Boolean,
                default: false,
            },
            loadingOverlay: {
                type: Boolean,
                default: true,
            },
            loadingOverlayOpacity: {
                type: Boolean,
                default: false,
            },
            loadingAnimateEnter: {
                type: Boolean,
                default: false,
            },
            loadingAnimateLeave: {
                type: Boolean,
                default: false,
            },
            loadingSpinner: {
                type: Boolean,
                default: true,
            },
            destroyable: {
                type: Boolean,
                default: true,
            },
            openable: {
                type: Function,
                default: null,
            },
            alwaysOnTop: {
                type: Boolean,
                default: false,
            },
            scrollable: {
                type: Boolean,
                default: false,
            },
            classes: {
                type: String,
                default: '',
            },
            color: {
                default: 'white',
                validator: (value) => colors.includes(value),
            },

            cancelClassName: {
                type: String,
                default: '',
            },
            stickyFooter: {
                type: Boolean,
                default: false,
            },
            noPadding: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                exists: false,
                hidding: false,
                showing: false,
            };
        },

        computed: {
            bodyColor() {
                switch (this.color) {
                    case 'gray': return 'bg-gray-100';
                    default: return 'bg-white';
                }
            },

            loadingDuration() {
                const duration = {};

                if (!this.loadingAnimateEnter) {
                    duration.enter = 0;
                }

                if (!this.loadingAnimateLeave && this.loadingSpinner) {
                    duration.leave = 0;
                }

                return duration;
            },

            isCancelable() {
                return this.closable || this.cancelable;
            },
        },

        watch: {
            '$route.name'() {
                this.hide();
            },

            opened(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.toggle(newValue);
                }
            },

            closable(newValue, oldValue) {
                const $modal = $(this.$refs.modal);

                if (oldValue && !newValue) {
                    $modal.off('keyup.dismiss.bs.modal');
                    $modal.data('bs.modal').options.backdrop = 'static';
                    $modal.find("[data-dismiss='modal']").hide();
                } else if (!oldValue && newValue) {
                    $modal.data('bs.modal').escape();
                    $modal.data('bs.modal').options.backdrop = true;
                    $modal.find("[data-dismiss='modal']").show();
                }
            },
        },

        methods: {
            toggle(opened, params) {
                if (opened) {
                    if (isFunction(this.openable) && !this.openable()) {
                        return;
                    }

                    this.exists = true;
                }

                this.$nextTick(() => {
                    if (opened) {
                        this.show(params);
                    } else {
                        this.hide(params);
                    }
                });
            },

            init() {
                $(this.$refs.modal)
                    .on('show.bs.modal', () => {
                        if (this.showing) {
                            return;
                        }

                        this.showing = true;
                        const modalIndex = 50 + 1 * $('.modal:visible').length;

                        $(this.$refs.modal).css('z-index', this.alwaysOnTop ? 99999 : modalIndex);

                        this.$nextTick(() => {
                            $('.modal-backdrop:not(.modal-stack)')
                                .css('z-index', modalIndex - 1)
                                .addClass('modal-stack');
                        });

                        this.$emit('open');
                    })
                    .on('shown.bs.modal', () => {
                        $(document.body).addClass('modal-open');

                        this.showing = false;
                        this.$emit('opened');
                    })
                    .on('hide.bs.modal', () => {
                        if (this.hidding) {
                            return;
                        }

                        this.hidding = true;
                        this.$emit('close');
                    })
                    .on('hidden.bs.modal', () => {
                        this.hidding = false;

                        if ($('.modal:visible').length) {
                            $(document.body).addClass('modal-open');
                        }

                        this.$nextTick(() => {
                            this.$emit('closed');
                            this.destroyModal();
                        });
                    });

                if (process.env.NODE_ENV !== 'production') {
                    window.addEventListener('message', this.hideModalOnHotReload);
                }
            },

            destroyModal() {
                this.exists = false;

                $(this.$refs.modal)
                    .off('show.bs.modal')
                    .off('shown.bs.modal')
                    .off('hide.bs.modal')
                    .off('hidden.bs.modal')
                    .off('loaded.bs.modal');

                if (process.env.NODE_ENV !== 'production') {
                    window.removeEventListener('message', this.hideModalOnHotReload);
                }
            },

            show(params) {
                this.init();

                this.$emit('before-open', params);

                this.$nextTick(() => {
                    $(this.$refs.modal).modal('show');
                });
            },

            hide(params, nextTick = true) {
                this.$emit('before-close', params);

                if (nextTick) {
                    this.$nextTick(() => {
                        $(this.$refs.modal).modal('hide');
                    });
                } else {
                    $(this.$refs.modal).modal('hide');
                }
            },

            cancelModal() {
                if (!this.isCancelable) {
                    return;
                }

                this.$emit('canceled');
                this.hide();
            },

            handleUpdate() {
                if (this.$refs.modal) {
                    $(this.$refs.modal).modal('handleUpdate');
                }
            },

            scrollToBottom() {
                if (!this.scrollable) {
                    return;
                }

                setTimeout(() => {
                    const body = this.$refs.modal.querySelector('.modal-body');

                    body.scrollTop = body.scrollHeight;
                }, 0);
            },

            onToggle(name, state, params) {
                if (this.name && this.name === name) {
                    const nextState = typeof state === 'undefined' ? !this.opened : state;

                    this.toggle(nextState, params);
                }
            },

            hideModalOnHotReload(event) {
                if (event.data && event.data.type === 'webpackInvalid') {
                    this.hide();
                }
            },
        },

        created() {
            this.handleUpdate = debounce(this.handleUpdate, 100);
            this.$modal.subscription.$on('toggle', this.onToggle);
        },

        updated() {
            this.handleUpdate();
        },

        beforeDestroy() {
            this.hide(null, false);
            this.destroyModal();
            this.handleUpdate = null;
            this.$modal.subscription.$off('toggle', this.onToggle);
        },
    };
</script>
