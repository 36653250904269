import ActivixModal from '../components/elements/ActivixModal.vue';

const Plugin = {
    install(Vue) {
        if (this.installed) {
            return;
        }

        this.installed = true;

        const eventBus = new Vue();
        const Modal = {
            get subscription() {
                return eventBus;
            },
            show(modal, params) {
                eventBus.$emit('toggle', modal, true, params);
            },
            hide(name, params) {
                eventBus.$emit('toggle', name, false, params);
            },
            toggle(name, params) {
                eventBus.$emit('toggle', name, undefined, params);
            },
            closeAllModals() {
                $('.modal').modal('hide');
                $('.modal-backdrop').remove();
            },
        };

        Vue.modal = Modal;
        Vue.prototype.$modal = Modal;

        Vue.component(ActivixModal.name, ActivixModal);
    },
};

export default Plugin;
