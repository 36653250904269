<template>
    <div class="flex w-full text-lg">
        <div class="text-left">
            <div>
                {{ $tc('inventory.statistics.averagePrice') }}
            </div>
            <div>
                {{ $tc('inventory.statistics.lowestPrice') }}
            </div>
            <div>
                {{ $tc('inventory.statistics.highestPrice') }}
            </div>
            <div>
                {{ $tc('inventory.statistics.totalValue') }}
            </div>
        </div>
        <div class="text-right ml-3">
            <div>
                <span class="font-bold">{{ cards.averagePrice.number.count }}</span>
            </div>
            <div>
                <span class="font-bold">{{ cards.averagePrice.lowestPrice.price }}</span>
            </div>
            <div>
                <span class="font-bold">{{ cards.averagePrice.highestPrice.price }}</span>
            </div>
            <div>
                <span class="font-bold">{{ cards.averagePrice.totalValue.price }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            cards: {
                type: Object,
                default: () => {},
            },
        },
    };
</script>
