import Vue from 'vue';
import optimizely from '@optimizely/optimizely-sdk';
import { get } from 'lodash-es';
import { contextStore, globalStore } from '@/pinia/storeHelper.js';
import ActivixDate from '@/value-objects/ActivixDate.js';
import Role from '../entities/Role.js';

optimizely.setLogLevel('warn');

let optimizelyInstance;

export const setupOptimizely = (datafile) => {
    if (datafile) {
        optimizelyInstance = optimizely.createInstance({
            sdkKey: process.env.VUE_APP_OPTIMIZELY_KEY,
            eventBatchSize: 100,
            eventFlushInterval: 1000,
            datafile,
            datafileOptions: {
                autoUpdate: process.env.VUE_APP_ENV !== 'local',
                urlTemplate: 'https://features.crm.activix.ca/datafiles/%s.json',
            },
        });
    }
};

const useFeature = {
    isInitialized: false,
    user: null,

    getAuthUser() {
        return Vue.auth.user();
    },

    getOptimizelyUserId(userId) {
        return `user_${userId}`;
    },

    validate(id) {
        return !!id && !!optimizelyInstance;
    },

    isEnabled(feature, defaultValue = true) {
        if (this.hasUrlOverride(feature)) {
            return this.isForced(feature);
        }

        if (this.featureExist(feature)) {
            return this.isEnabledForUser(this.getAuthUser(), feature);
        }

        return defaultValue && this.getFeaturesList().length !== 0;
    },

    isEnabledForAccount(accountId, feature) {
        if (!this.validate(accountId)) {
            return false;
        }

        const optimizelyDecision = optimizelyInstance.createUserContext(`account_${accountId}`, {
            account_id: accountId,
            environment: process.env.VUE_APP_ENV,
        }).decide(feature);

        return optimizelyDecision.enabled;
    },

    isEnabledForUser(user, feature) {
        if (!this.validate(user.id)) {
            return false;
        }

        if (this.isFeaturePreview(feature)) {
            return this.isFeaturePreviewEnabledForUser(user, feature);
        }

        const optimizelyDecision = this.getOptimizelyUser().decide(feature);

        return optimizelyDecision.enabled;
    },

    isFeaturePreviewEnabledForUser(user, feature) {
        const featurePreviewUserData = Vue.auth.featurePreviews().find(featurePreview => featurePreview.key === feature);

        if (featurePreviewUserData?.activated_at) {
            return true;
        }

        const accountId = contextStore().accountId || user.account_id;

        const userOptOutLimitDate = new ActivixDate(featurePreviewUserData?.accounts_opt_out_limit_date?.[accountId]);

        return userOptOutLimitDate.isPast();
    },

    isFeaturePreviewEnforcedForUser(feature) {
        const user = this.getAuthUser();
        const accountId = contextStore().accountId || user.account_id;

        const featurePreviewUserData = Vue.auth.featurePreviews().find(featurePreview => featurePreview.key === feature);
        const userOptOutLimitDate = new ActivixDate(featurePreviewUserData?.accounts_opt_out_limit_date?.[accountId]);

        if (userOptOutLimitDate.isPast()) {
            return true;
        }

        if (!featurePreviewUserData?.activated_at) {
            return false;
        }

        const featurePreviewData = globalStore().featurePreviews.find(featurePreview => featurePreview.key === feature);
        const newDealersEnforcementOn = new ActivixDate(featurePreviewData?.new_dealers_enforced ? featurePreviewData?.new_dealers_auto_activation_on : null);
        const newUsersEnforcementOn = new ActivixDate(featurePreviewData?.new_users_enforced ? featurePreviewData?.new_users_auto_activation_on : null);

        const userCreationDate = new ActivixDate(user.created_at);
        const accountCreationDate = new ActivixDate(user.account.created_at);

        return newDealersEnforcementOn.isBefore(accountCreationDate) || newUsersEnforcementOn.isBefore(userCreationDate);
    },

    isFeaturePreview(feature) {
        return globalStore().featurePreviews.some(featurePreview => featurePreview.key === feature);
    },

    hasUrlOverride(feature) {
        return !['production'].includes(process.env.VUE_APP_ENV) && get(Vue.router, `app.$route.query.${feature}`);
    },

    isForced(feature) {
        return get(Vue.router, `app.$route.query.${feature}`) === 'true';
    },

    featureExist(feature) {
        return this.getFeaturesList().some((featureFlag) => {
            return featureFlag.key === feature;
        });
    },

    getFeaturesList() {
        const authUserId = this.getAuthUser().id;

        if (!this.validate(authUserId)) {
            return [];
        }

        return get(window, 'optimizelyDatafile.featureFlags', []);
    },

    getEnabled() {
        const authUserId = this.getAuthUser().id;
        if (!this.validate(authUserId)) {
            return [];
        }

        return optimizelyInstance.getEnabledFeatures(this.getOptimizelyUserId(authUserId));
    },

    getOptimizelyUser() {
        const authUser = this.getAuthUser();
        const userType = Role.hasAdminRights(authUser.role_id) ? 'admin' : 'user';
        const userKey = `${userType}_${authUser.id}`;

        return optimizelyInstance.createUserContext(userKey, {
            id: authUser.id,
            account_id: authUser.account_id,
            role: Role.getKey(authUser.role_id),
            environment: process.env.VUE_APP_ENV,
            context_account_id: contextStore().accountId,
        });
    },
};

export default useFeature;
