export default {
    call: 'Appel',
    email: 'Courriel',
    appointment: 'Rendez-vous',
    delivery: '@.capitalize:lexicon.delivery.singular',
    other: 'Autre',
    csi: 'CSI',
    sms: 'SMS',
    phone_appointment: 'Rendez-vous téléphonique',
    virtual_appointment: 'Rendez-vous virtuel',
    test_drive: 'Essai routier',

    asAction: {
        call: "tâche d'appel",
        email: 'tâche de courriel',
        appointment: 'rendez-vous',
        delivery: '@:lexicon.delivery.singular',
        other: 'événement',
        csi: 'tâche CSI',
        sms: 'tâche SMS',
        phone_appointment: 'rendez-vous téléphonique',
        virtual_appointment: 'rendez-vous virtuel',
        test_drive: 'essai routier',
    },
};
