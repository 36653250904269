<template>
    <div />
</template>

<script>
    import { cloneDeep, debounce, upperFirst, merge } from 'lodash-es';
    import 'fullcalendar';
    import 'fullcalendar/dist/locale/fr.js';

    import { mapState } from 'pinia';
    import Moment from '../../value-objects/Moment.js';
    import ActivixDate from '../../value-objects/ActivixDate.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'ActivixCalendar',

        props: {
            config: {
                type: Object,
                default: () => ({}),
            },

            height: {
                type: String,
                default: 'parent',
            },

            events: {
                type: Function,
                required: true,
            },

            eventRender: {
                type: Function,
                default: (event, element) => element,
            },

            eventClick: {
                type: Function,
                default: () => ({}),
            },

            eventDrop: {
                type: Function,
                default: () => ({}),
            },

            eventAfterRender: {
                type: Function,
                default: () => ({}),
            },

            eventAfterAllRender: {
                type: Function,
                default: () => ({}),
            },

            eventDestroy: {
                type: Function,
                default: () => ({}),
            },

            eventOverlap: {
                type: Function,
                default: () => ({}),
            },

            viewRender: {
                type: Function,
                default: () => ({}),
            },
            buttonTextOverride: {
                type: Object,
                default: () => ({}),
            },
            defaultDate: {
                type: String,
                default: null,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),

            buttonText() {
                const buttons = {
                    month: upperFirst(this.$tc('delays.month')),
                    agendaWeek: upperFirst(this.$tc('calendar.agenda')),
                    week: upperFirst(this.$tc('delays.week')),
                    threeDay: upperFirst(this.$tc('delays.threeDay')),
                    day: upperFirst(this.$tc('delays.day')),
                    toDo: upperFirst(this.$tc('delays.toDo')),
                    today: upperFirst(this.$tc('delays.today')),
                };

                if (this.mdLayout) {
                    buttons.today = new ActivixDate('now').format('dd');
                }

                return {
                    ...buttons,
                    ...this.buttonTextOverride,
                };
            },

            timeFormat() {
                return Moment.timeLocalizedFormat();
            },
        },

        watch: {
            '$i18n.locale'() {
                this.resetCalendar();
            },

            'authUser.timezone'(newTimezone) {
                $(this.$el).fullCalendar('option', 'timezone', newTimezone);
            },

            timeFormat(newTimeFormat) {
                $(this.$el).fullCalendar('option', 'timeFormat', newTimeFormat);
            },

            contextLead() {
                if (this.$route.name != 'calendar') {
                    this.resetCalendar();
                }
            },
        },

        methods: {
            fireMethod(...options) {
                return $(this.$el).fullCalendar(...options);
            },

            setHeight() {
                if (this.height != 'parent') {
                    return;
                }

                $(this.$el).fullCalendar('option', 'height', 350);

                setTimeout(() => {
                    $(this.$el).fullCalendar('option', 'height', 'parent');
                }, 0);
            },

            debounceSetHeight() {
                this.setHeight();
            },

            initCalendar() {
                const defaultConfig = {
                    defaultDate: this.defaultDate,
                    themeSystem: 'bootstrap3',
                    defaultView: 'basicWeek',
                    forceEventDuration: true,
                    defaultTimedEventDuration: '00:30',
                    showNonCurrentDates: false,
                    fixedWeekCount: false,
                    handleWindowResize: false,
                    header: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'month,basicWeek,basicDay',
                    },
                    buttonText: this.buttonText,
                    locale: this.$i18n.locale,
                    timeFormat: this.timeFormat,
                    timezone: this.authUser.timezone,
                    titleRangeSeparator: '-',

                    events: this.events,
                    eventRender: this.eventRender,
                    eventOverlap: this.eventOverlap,
                    eventLimitText: this.eventLimitText,

                    dayClick: (...args) => {
                        this.$emit('day-click', ...args);
                    },

                    drop: (...args) => {
                        this.$emit('drop', ...args);
                    },

                    eventAfterRender: (...args) => {
                        this.$emit('event-after-render', ...args);
                    },

                    eventClick: (...args) => {
                        this.$emit('event-click', ...args);
                    },

                    eventDestroy: (...args) => {
                        this.$emit('event-destroy', ...args);
                    },

                    eventDrop: (...args) => {
                        this.$emit('event-drop', ...args);
                    },

                    eventResize: (...args) => {
                        this.$emit('event-resize', ...args);
                    },

                    select: (start, end, jsEvent, view, resource) => {
                        this.$emit('event-created', {
                            start,
                            end,
                            allDay: !start.hasTime() && !end.hasTime(),
                            view,
                            resource,
                        });
                    },

                    viewRender: (...args) => {
                        this.$emit('view-render', ...args);
                    },

                    viewDestroy: (...args) => {
                        this.$emit('view-destroy', ...args);
                    },
                };

                const normalizedConfig = merge(defaultConfig, cloneDeep(this.config), { height: this.height });

                $(this.$el)
                    .fullCalendar(normalizedConfig)
                    .on(
                        'click',
                        '.fc-month-button, .fc-agendaWeek-button, .fc-basicWeek-button, .fc-threeDay-button, .fc-agendaDay-button, .fc-toDo-button',
                        this._onCalendarClick,
                    );
            },

            _onCalendarClick() {
                this.$emit('view-button-click');
            },

            resetCalendar() {
                this.fireMethod('destroy');

                this.initCalendar();
            },

            eventLimitText() {
                return this.$t('calendar.showMore');
            },
        },

        created() {
            this.debounceSetHeight = debounce(this.debounceSetHeight, 100);

            this.$eventBus.$on('reset-calendar', this.resetCalendar);

            window.addEventListener('resize', this.debounceSetHeight);
        },

        mounted() {
            this.initCalendar();

            this.setHeight();
        },

        beforeDestroy() {
            $(this.$el).off(
                'click',
                '.fc-month-button, .fc-agendaWeek-button, .fc-basicWeek-button, .fc-threeDay-button, .fc-agendaDay-button, .fc-toDo-button',
                this._onCalendarClick,
            );
            $(this.$el).fullCalendar('destroy');

            this.$eventBus.$off('reset-calendar', this.resetCalendar);

            window.removeEventListener('resize', this.debounceSetHeight);
        },
    };
</script>

<style>
    @media (max-width: 767px) {
        .fc {
            position: relative;
        }
        .fc-addEvent-button {
            position: absolute !important;
            bottom: 5px;
            right: 5px;
            z-index: 10;
        }
        .fc-month-button,
        .fc-agendaWeek-button {
            display: none !important;
        }

        .fc-left .btn-default {
            height: 40px;
            width: 40px;
        }

        .fc-right .btn-default {
            height: 40px;
        }

        .fc-center {
            position: absolute;
            margin-top: 55px;
            margin-left: -6px;
        }

        .fc-toolbar {
            padding-bottom: 25px;
        }

        .fc-toolbar h2 {
            font-size: 1rem !important;
        }
    }
</style>
