<template>
    <div class="w-full h-full flex flex-col justify-between relative">
        <div
            :class="[
                'overscroll-contain px-6 pb-6',
                collapsed ? 'overflow-y-hidden' : 'overflow-y-auto',
            ]"
        >
            <slide-over-header
                :reset-tooltip="resetSearchTooltip"
                :reset-visible="!state.addingAssociatedLead"
                :collapsed="collapsed"
                @close="onClose(false)"
                @reset="resetSearch"
                @collapse="$emit('update:collapsed', !collapsed)"
            >
                {{ addLeadTitle }}
            </slide-over-header>

            <inventory-overview-vehicle
                :account="contextAccount"
                :inventory-unit="inventoryUnit"
                @reset-inventory-vehicle-object="resetInventoryUnit"
            />

            <add-lead-form
                class="mt-8"
                :account="contextAccount"
                :adding-associated-lead="state.addingAssociatedLead"
                :account-options="accountOptions"
                :related-lead-id.sync="relatedLeadId"
                :state="state"
                :previously-selected-account-id="previouslySelectedAccountId"
                :visible="visible"
                @reset-account-options="resetAccountOptions"
                @toggle-additional-fields="state.showAdditionalFields = !state.showAdditionalFields"
                @search="searchLead"
                @update="newLead = $event"
                @update-state="updateState"
            />

            <add-lead-result-list
                :account="contextAccount"
                :leads="leads"
                :new-lead="newLead"
                :searched-parameters="searchedParameters"
                :state="state"
                :inventory-unit="inventoryUnit"
                @add-to-sales-journey="addEventToSalesJourney"
                @search-lead="searchLead"
                @navigate-to-lead="navigateToLead($event, false)"
                @select-lead-for-completion="selectLeadForCompletion"
                @select-lead-for-association="selectLeadForAssociation"
                @back-to-search-results="onBackToSearchResults"
                v-if="showLeadSearchResults && visible"
            />
            <div class="mb-4 space-y-4" v-if="state.addingAssociatedLead && visible">
                <activix-checkbox v-model="state.copyClientsInformations">
                    <span>{{ $t('addLead.copyClientsInformations') }}</span>
                </activix-checkbox>
                <activix-checkbox v-model="state.copyWantedVehicle" v-if="isRenewal">
                    <span>{{ $t('addLead.copyWantedToExchangeVehicle') }}</span>
                </activix-checkbox>
            </div>
        </div>
        <div class="mx-6" v-if="visible && (!state.associateToResultItemId || !showLeadSearchResults)">
            <activix-button
                type="primary"
                class="w-full mb-6"
                @click="state.showAdditionalFields = true"
                v-if="showNextStepButton"
            >
                {{ $t('addLead.nextStep') }}
            </activix-button>
            <activix-tooltip :content="createLeadDisabledTooltip" v-if="showCreateLeadButton">
                <div>
                    <activix-button
                        class="w-full mb-6"
                        :type="createButtonType"
                        :loading="creating"
                        :disabled="createLeadDisabled"
                        @click="confirmLeadCreation"
                    >
                        {{
                            state.addingAssociatedLead
                                ? $t('addLead.createAssociatedLead')
                                : $t('addLead.createNewLead')
                        }}
                    </activix-button>
                </div>
            </activix-tooltip>
        </div>

        <!-- Create Lead Modal -->
        <activix-confirm-modal
            type="warning"
            :content="
                isService
                    ? $t('addLead.confirmLeadCreationOverAddingVisit')
                    : $t('addLead.confirmLeadCreationOverSelectingExisting')
            "
            :opened.sync="modals.createLeadConfirmationOpened"
            @approve="includeOriginalEmail"
            @closed="modals.createLeadConfirmationOpened = false"
        />

        <!-- Complete Lead Modal -->
        <activix-confirm-modal
            type="warning"
            :opened.sync="modals.completeLeadConfirmation.opened"
            :title="$t('general.wishToProceed')"
            @approve="completeLead"
            @closed="modals.completeLeadConfirmation.opened = false"
        >
            <div slot="content">
                {{ $t('addLead.completableFieldsTooltip') }}
                <div class="flex justify-center mt-4">
                    <div class="text-right font-semibold">
                        <div :key="item.key" v-for="item in modals.completeLeadConfirmation.content">
                            {{ item.key }}
                        </div>
                    </div>
                    <div class="ml-2">
                        <div class="flex items-center" :key="item.key" v-for="item in modals.completeLeadConfirmation.content">
                            <icon class="mr-2 text-base" name="regular/house-chimney-2" v-if="item.icon === 'home'" />
                            <icon class="mr-2 text-base" name="regular/briefcase" v-if="item.icon === 'work'" />
                            <icon class="mr-2 text-base" name="regular/mobile-phone" v-if="item.icon === 'cell'" />
                            {{ item.value }}
                        </div>
                    </div>
                </div>
            </div>
        </activix-confirm-modal>

        <!-- Lead Not Active Modal -->
        <activix-confirm-modal
            type="warning"
            :show-deny="false"
            :approve-text="$t('addLead.backToSearchResults')"
            :content="leadStatusTextSelectModal"
            :opened.sync="modals.selectedLeadNotActiveOpened"
            @closed="modals.selectedLeadNotActiveOpened = false"
        />

        <!-- Lead Not Active Modal Warning for Association -->
        <activix-confirm-modal
            type="info"
            :show-deny="true"
            :deny-text="$t('addLead.continue')"
            :approve-text="$t('addLead.backToSearchResults')"
            :content="leadStatusTextAssociateModal"
            :opened.sync="modals.associateToNotActiveLeadOpened"
            @deny="associate"
            @approve="selectedId = null"
            @closed="modals.associateToNotActiveLeadOpened = false"
        />

        <!-- Modal for original email -->
        <update-original-email-modal
            :opened="modals.originalEmailOpened"
            :original-email.sync="originalEmail"
            :disabled="empty(originalEmail)"
            @close="closeOriginalEmail"
        />
    </div>
</template>

<script>
    import { useDashboardStore } from '@/store/modules/dashboard/store.js';
    import { useGlobalStore } from '@/store/store.js';
    import { useContextStore } from '@/store/modules/context/store.js';
    import { mapActions, mapState } from 'pinia';

    import { camelCase, orderBy, unionBy } from 'lodash-es';

    import AddLeadForm from '@/components/add_lead/AddLeadForm.vue';
    import AddLeadMixin from '@/mixins/AddLead.js';
    import AddLeadResultList from '@/components/add_lead/AddLeadResultList.vue';
    import InventoryOverviewVehicle from '@/components/inventory/InventoryOverviewVehicle.vue';
    import SlideOverHeader from '@/components/SlideOverHeader.vue';
    import UpdateOriginalEmailModal from '@/components/modals/UpdateOriginalEmailModal.vue';

    import ActivixDate from '@/value-objects/ActivixDate.js';
    import ContactType from '@/entities/ContactType.js';
    import Division from '@/entities/Division.js';
    import Lead from '@/entities/Lead.js';
    import LeadEmailType from '@/entities/LeadEmailType.js';
    import LeadPhone from '@/entities/LeadPhone.js';
    import LeadPhoneType from '@/entities/LeadPhoneType.js';
    import LeadType from '@/entities/LeadType.js';
    import LeadVehicleType from '@/entities/LeadVehicleType.js';

    export default {
        components: {
            AddLeadForm,
            AddLeadResultList,
            InventoryOverviewVehicle,
            SlideOverHeader,
            UpdateOriginalEmailModal,
        },

        mixins: [AddLeadMixin],

        props: {
            visible: {
                type: Boolean,
                required: true,
            },
            collapsed: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                state: {
                    addingAssociatedLead: false,
                    noResults: false,
                    searching: false,
                    showResults: false,
                    associateToResultItemId: null,
                    searchParametersChanged: false,
                    showAdditionalFields: false,
                    selectedForViewingId: null,
                    copyClientsInformations: false,
                    copyWantedVehicle: false,
                },
                modals: {
                    createLeadConfirmationOpened: false,
                    completeLeadConfirmation: {
                        opened: false,
                        content: {},
                    },
                    selectedLeadNotActiveOpened: false,
                    associateToNotActiveLeadOpened: false,
                    originalEmailOpened: false,
                },
                newLead: {},
                inventoryUnit: {},
                accountOptions: [],
                originalEmail: '',
                leads: [],
                selectedLeadId: null,
                selectedLead: null,
                selectedLeadCompletableValues: null,
                relatedLeadId: null,
                replaceRouterHistory: false,
                creating: false,
                searchedParameters: {},
                previouslySelectedAccountId: null,
            };
        },

        provide() {
            return {
                $association: () => {
                    return {
                        disabled: this.associationDisabled,
                        tooltip: this.associationDisabledTooltip,
                        creating: this.creating,
                        leadId: this.state.associateToResultItemId,
                    };
                },
                $attribution: () => {
                    return {
                        creating: this.creating,
                        disabled: this.attributionDisabled,
                        tooltip: this.attributionDisabledTooltip,
                    };
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextLead: 'contextLead',
            }),
            ...mapState(useDashboardStore, ['dashboardType']),

            showCreateLeadButton() {
                return (
                    this.state.addingAssociatedLead ||
                    (this.state.showAdditionalFields &&
                        (!this.state.associateToResultItemId || !this.showNextStepButton))
                );
            },

            showNextStepButton() {
                const validPhone = this.newLead.leadType == LeadType.PHONE && this.isValidPhone;
                const validEmail =
                    [LeadType.EMAIL, LeadType.WEB_ORDER].includes(this.newLead.leadType) && this.isValidEmail;
                const validPhoneOrEmail =
                    ![LeadType.PHONE, LeadType.EMAIL, LeadType.WEB_ORDER].includes(this.newLead.leadType) &&
                    (this.isValidPhone || this.isValidEmail);

                if (
                    !this.state.showAdditionalFields &&
                    this.state.showResults &&
                    this.state.noResults &&
                    this.isValidClientName &&
                    this.isValidLeadType &&
                    (validPhone || validEmail || validPhoneOrEmail)
                ) {
                    return true;
                }

                return false;
            },

            showLeadSearchResults() {
                return (
                    this.state.showResults &&
                    !this.state.addingAssociatedLead &&
                    !(this.state.showAdditionalFields && this.state.noResults)
                );
            },

            createButtonType() {
                if (this.state.noResults || this.state.addingAssociatedLead) {
                    return 'primary';
                }

                if (this.showLeadSearchResults) {
                    return 'default';
                }

                return 'grey';
            },

            completableValues() {
                return this.selectedLeadCompletableValues || {};
            },

            searchDisabled() {
                return !this.validSearchParameters;
            },

            validSearchParameters() {
                return (this.isValidClientName || this.isValidBusinessName || this.isValidEmail || this.isValidPhone) && !!this.selectedAccount;
            },

            newLeadIsEmail() {
                return this.newLead.leadType == LeadType.EMAIL;
            },

            nameIsBlank() {
                return !this.newLead.name;
            },

            createLeadDisabled() {
                if (this.sameEmailDuplicateFound) {
                    return true;
                }

                if (this.state.searching) {
                    return true;
                }

                // Empty
                if (empty(this.newLead.leadType)) {
                    return true;
                }

                if (!this.newLead.source && this.mandatorySource) {
                    return true;
                }

                if (this.newLead.leadType == LeadType.PHONE) {
                    if (!this.isValidPhone || !this.dateIsValid(this.newLead.callDate)) {
                        return true;
                    }
                }

                if (this.newLead.leadType == LeadType.SMS && !this.isValidPhone) {
                    return true;
                }

                let disabled = false;

                // Walk-in
                if (this.isWalkIn) {
                    if (
                        !this.newLead.presentedDate ||
                        (this.sources.length && !this.newLead.source && this.newLead.division != Division.SERVICE)
                    ) {
                        return true;
                    }
                    disabled = !this.dateIsValid(this.newLead.presentedDate);
                }

                // Renewal
                if (this.newLead.leadType == LeadType.RENEWAL) {
                    disabled = !this.dateIsValid(this.newLead.endContractDate, true);
                }

                // Client name
                if (this.newLead.contactType === ContactType.MAIN &&
                    (empty(this.newLead.name) || this.newLead.name.length < 3)
                ) {
                    return true;
                }

                // Business name
                if (this.newLead.contactType === ContactType.BUSINESS &&
                    (empty(this.newLead.businessName) || this.newLead.businessName.length < 3)
                ) {
                    return true;
                }

                // Always required
                if (empty(this.newLead.division)) {
                    return true;
                }

                // Child accounts
                if (this.accountOptions.length && !this.newLead.childAccountId && !this.state.addingAssociatedLead) {
                    return true;
                }

                if (this.account.mandatory_coordinate && !this.isValidEmail && !this.isValidPhone) {
                    return true;
                }

                return disabled;
            },

            associationDisabled() {
                return this.createLeadDisabled && !!this.state.associateToResultItemId;
            },

            attributionDisabled() {
                return this.createLeadDisabled;
            },

            selectedLeadHasCompletableValues() {
                const values = this.selectedLeadCompletableValues;
                return Object.keys(values).some(key => !!values[key]);
            },

            leadStatusTextSelectModal() {
                if (!this.selectedLead) {
                    return this.$t('addLead.error');
                }

                if (!this.selectedLead.status) {
                    return '';
                }

                const statusText = `
                    <span class="font-bold">${this.$t(`clientCard.${this.selectedLead.status}`)}</span>.
                `;

                return this.$t('addLead.selectLeadNotActiveModalText', [statusText]);
            },

            leadStatusTextAssociateModal() {
                if (!this.selectedLead) {
                    return this.$t('addLead.error');
                }

                if (!this.selectedLead.status) {
                    return '';
                }

                const statusText = `
                    <span class="font-bold">${this.$t(`clientCard.${this.selectedLead.status}`)}</span>.
                `;

                return this.$t('addLead.associateLeadNotActiveModalText', [statusText]);
            },

            addLeadTitle() {
                if (this.state.addingAssociatedLead) {
                    return this.$t('addLead.leadAssociation');
                }

                return this.$t('addLead.leadCreation');
            },

            sameEmailDuplicateFound() {
                if (!this.contextAccount?.disable_manual_duplicate || !this.searchedParameters.email || !this.leads.length) {
                    return false;
                }

                return this.leads.some(lead => {
                    return lead?.emails.some(email => email?.address === this.searchedParameters.email);
                });
            },

            createLeadDisabledTooltip() {
                if (this.sameEmailDuplicateFound) {
                    return this.$t('addLead.disableManualDuplicate');
                }

                if (!this.createLeadDisabled || this.state.searching) {
                    return '';
                }

                const tooltip = [];

                if (!this.newLead.leadType) {
                    tooltip.push(this.$t('addLead.leadTypeRequried'));
                }

                // Always required
                if (this.newLead.contactType === ContactType.MAIN && this.nameIsBlank) {
                    tooltip.push(this.$t('addLead.clientNameRequired'));
                }

                if (this.newLead.contactType === ContactType.MAIN && !!this.newLead.name && this.newLead.name.length < 3) {
                    tooltip.push(this.$t('addLead.clientNameLength'));
                }

                if (this.newLead.contactType === ContactType.BUSINESS && !this.newLead.businessName) {
                    tooltip.push(this.$t('addLead.businessNameRequired'));
                }

                if (this.newLead.contactType === ContactType.BUSINESS && !!this.newLead.businessName && this.newLead.businessName.length < 3) {
                    tooltip.push(this.$t('addLead.businessNameLength'));
                }

                if (!this.newLead.source && this.mandatorySource) {
                    tooltip.push(this.$t('addLead.originRequired'));
                }

                if (this.newLead.leadType == LeadType.PHONE) {
                    if (!this.isValidPhone) {
                        tooltip.push(this.$t('addLead.phoneRequired'));
                    }

                    if (!this.dateIsValid(this.newLead.callDate)) {
                        tooltip.push(this.$t('addLead.validCallDateRequired'));
                    }
                } else if (this.newLead.leadType == LeadType.SMS && !this.isValidPhone) {
                    tooltip.push(this.$t('addLead.phoneRequired'));
                } else if (this.account.mandatory_coordinate && !this.isValidEmail && !this.isValidPhone) {
                    tooltip.push(this.$t('addLead.emailOrPhoneRequired'));
                }

                if (this.isWalkIn) {
                    if (!this.dateIsValid(this.newLead.presentedDate)) {
                        tooltip.push(this.$t('addLead.validPresentedDateRequired'));
                    }
                }

                // Renewal
                if (
                    this.newLead.leadType == LeadType.RENEWAL &&
                    !this.dateIsValid(this.newLead.endContractDate, true)
                ) {
                    tooltip.push(this.$t('addLead.validEndContractDateRequired'));
                }

                if (empty(this.newLead.division)) {
                    tooltip.push(this.$t('addLead.divisionRequired'));
                }

                // Child accounts
                if (this.accountOptions.length && !this.newLead.childAccountId && !this.state.addingAssociatedLead) {
                    tooltip.push(this.$t('addLead.mustSelectAccount'));
                }

                return tooltip.join('<br>');
            },

            associationDisabledTooltip() {
                if (this.state.associateToResultItemId && this.createLeadDisabled) {
                    return this.createLeadDisabledTooltip;
                }

                return '';
            },

            attributionDisabledTooltip() {
                if (this.attributionDisabled) {
                    return this.createLeadDisabledTooltip;
                }

                return '';
            },

            resetSearchTooltip() {
                if (this.state.addingAssociatedLead) {
                    return this.$t('addLead.resetSearchDisabled');
                }

                return this.$t('addLead.resetSearch');
            },

            showDateModal() {
                return (
                    !!this.selectedLead &&
                    (!this.selectedLead.presented_date || !this.selectedLead.be_back_date) &&
                    !this.selectedLead.delivered_date &&
                    (!this.selectedLead.sale_date || this.selectedLead.sale_by_phone)
                );
            },

            leadUpdateDataObject() {
                const leadData = {};
                const vehicleData = {};
                let phoneData = {};
                let emailData = {};

                Object.keys(this.completableValues).forEach(key => {
                    switch (key) {
                        case 'fullName':
                        case 'last_name':
                            leadData.last_name = this.completableValues[key];
                            break;

                        case 'civility':
                            leadData.civility = this.completableValues[key];
                            break;

                        case 'business_name':
                            leadData.business_name = this.completableValues[key];
                            break;

                        case 'division':
                            leadData.division_id = this.completableValues[key];
                            break;

                        case 'source':
                            leadData.source_id = this.completableValues[key];
                            break;

                        case 'advisor':
                            leadData.user_id = this.completableValues[key];
                            break;

                        case 'bdc':
                            leadData.bdc_user_id = this.completableValues[key];
                            break;

                        case 'serviceAgent':
                            leadData.service_agent_id = this.completableValues[key];
                            break;

                        case 'serviceAdvisor':
                            leadData.service_advisor_id = this.completableValues[key];
                            break;

                        case 'endContractDate':
                            vehicleData.end_contract_date = this.completableValues[key];
                            break;

                        case 'phone':
                            phoneData = {
                                type: this.completableValues[key].type,
                                lead_id: this.selectedLeadId,
                                phone: this.completableValues[key].number,
                            };
                            break;

                        case 'email':
                            emailData = {
                                type: this.completableValues[key].type,
                                lead_id: this.selectedLeadId,
                                email: this.completableValues[key].email,
                            };
                            break;
                    }
                });

                if (this.inventoryUnit) {
                    vehicleData.inventory_unit_id = this.inventoryUnit.id;
                    vehicleData.type = 'wanted';
                }

                return { leadData, vehicleData, phoneData, emailData };
            },

            leadCreationPayload() {
                const payload = {
                    account: this.selectedAccount.id,
                    name: this.newLead.name,
                    business_name: this.newLead.businessName,
                    business: this.newLead.contactType === ContactType.BUSINESS,
                    user: this.newLead.advisor,
                    division: this.newLead.division,
                    civility: this.newLead.civility,
                    lead_type: this.newLead.leadType,
                    created_by_user: true,
                    pending: false,
                    related_lead_id: this.relatedLeadId,
                    copy_wanted_vehicle: this.state.copyWantedVehicle,
                };

                if (this.dateIsValid(this.newLead.endContractDate, true) && this.newLead.leadType == LeadType.RENEWAL) {
                    payload.exchange_end_contract_date = new ActivixDate(this.newLead.endContractDate)
                        .setSeconds(0)
                        .toString();
                }

                if (this.isWalkIn) {
                    payload.presented_date = new ActivixDate(this.newLead.presentedDate).setSeconds(0).toString();
                }

                if (this.newLead.leadType == LeadType.PHONE) {
                    payload.call_date = new ActivixDate(this.newLead.callDate).setSeconds(0).toString();
                }

                if (this.newLead.phone) {
                    payload.phone_home = this.newLead.phone;
                }

                if (this.newLead.email) {
                    payload.email_home = this.newLead.email;
                }

                if (this.newLead.bdc && this.account.bdc_advisor && this.showBdcAgent) {
                    payload.bdc_user = this.newLead.bdc;
                }

                if (this.newLead.serviceAdvisor) {
                    payload.service_advisor_user = this.newLead.serviceAdvisor;
                }

                if (this.newLead.serviceAgent) {
                    payload.service_agent_user = this.newLead.serviceAgent;
                }

                if (this.newLead.source && this.showSource) {
                    payload.origin = this.newLead.source;
                }

                if (this.state.addingAssociatedLead && this.state.copyClientsInformations) {
                    payload.locale = this.contextLead.locale;
                    payload.sex = this.contextLead.sex;
                    payload.birth_date = this.contextLead.birth_date;
                    payload.address_street = this.contextLead.address1;
                    payload.address_city = this.contextLead.city;
                    payload.address_country = this.contextLead.country;
                    payload.address_province = this.contextLead.province;
                    payload.address_postal_code = this.contextLead.postal_code;
                }

                if (this.inventoryUnit) {
                    payload.wanted_vehicle_inventory_unit_id = this.inventoryUnit.id;
                }

                payload.original_email = this.originalEmail;

                return payload;
            },

            leadEventPayload() {
                const payload = {
                    eventData: {
                        account: this.selectedAccount.id,
                        last_name: this.newLead.name,
                        business_name: this.newLead.businessName,
                        business: this.newLead.contactType === ContactType.BUSINESS,
                        civility: this.newLead.civility,
                        lead_type_id: this.newLead.leadType,
                        division_id: this.newLead.division,
                        user_id: this.newLead.advisor,
                    },
                    eventRelations: {
                        vehicles: [],
                        phones: [],
                        emails: [],
                    },
                };

                if (this.newLead.bdc && this.account.bdc_advisor && this.showBdcAgent) {
                    payload.eventData.bdc_user_id = this.newLead.bdc;
                }

                if (this.dateIsValid(this.newLead.endContractDate, true) && this.newLead.leadType === LeadType.RENEWAL) {
                    payload.eventRelations.vehicles.push({
                        end_contract_date: new ActivixDate(this.newLead.endContractDate).setSeconds(0).toString(),
                        type: LeadVehicleType.EXCHANGE,
                    });
                }

                if (this.inventoryUnit?.id) {
                    payload.eventRelations.vehicles.push({
                        inventory_unit_id: this.inventoryUnit.id,
                        type: LeadVehicleType.WANTED,
                    });
                }

                if (this.isWalkIn) {
                    payload.eventData.presented_date = new ActivixDate(this.newLead.presentedDate).setSeconds(0).toString();
                }

                if (this.newLead.leadType === LeadType.PHONE) {
                    payload.eventData.call_date = new ActivixDate(this.newLead.callDate).setSeconds(0).toString();
                }

                if (this.newLead.phone) {
                    payload.eventRelations.phones.push({
                        phone: this.newLead.phone,
                        type: LeadPhoneType.HOME,
                    });
                }

                if (this.newLead.email) {
                    payload.eventRelations.emails.push({
                        email: this.newLead.email,
                        type: LeadEmailType.HOME,
                    });
                }

                if (this.newLead.source && this.showSource) {
                    payload.eventData.source_id = this.newLead.source;
                }

                return payload;
            },
        },

        watch: {
            '$route.path'() {
                if (this.state.addingAssociatedLead && !this.$wait.is('associating.lead')) {
                    this.onClose();
                }
            },

            '$route.name'() {
                if (this.$route.name !== 'leads.update') {
                    this.resetAccount();
                }
            },

            'state.addingAssociatedLead'(newValue) {
                if (!newValue) {
                    this.resetSearch();
                }
            },

            'contextAccount.id'() {
                if (this.$route.name !== 'leads.update') {
                    this.resetSearch();
                }
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextLeadIdAction']),
            ...mapActions(useGlobalStore, [
                'syncAssociatedLeadsCustomer',
                'updateLeadAction',
                'addLeadPhoneAction',
                'addLeadEmailAction',
                'addLeadVehicleAction',
                'updateLeadVehicleAction',
                'appendNewError',
            ]),

            updateState(values) {
                this.state = { ...this.state, ...values };
            },

            closeOriginalEmail() {
                this.modals.originalEmailOpened = false;
                this.createLead();
            },

            handleRouterHistory(route, forcePush = false) {
                if (this.replaceRouterHistory && !forcePush) {
                    this.$router.replace(route);
                } else {
                    this.replaceRouterHistory = true;
                    this.$router.push(route);
                }
            },

            formatRouteObject(leadId, includeAdditionalParams = true) {
                let route = {
                    name: 'leads.update',
                    params: { id: leadId },
                };

                if (this.isWalkIn && !this.state.addingAssociatedLead && includeAdditionalParams) {
                    const date = new ActivixDate(this.newLead.presentedDate).setSeconds(0).toString();

                    route = {
                        ...route,
                        query: { date },
                    };
                }

                return route;
            },

            async navigateToLead(
                leadId,
                includeAdditionalParams = true,
                resetSearch = false,
                hideRightSidebar = false,
            ) {
                if (this.xsLayout && !hideRightSidebar) {
                    this.state.selectedForViewingId = leadId;
                    return;
                }

                const route = this.formatRouteObject(leadId, includeAdditionalParams);
                this.handleRouterHistory(route, hideRightSidebar);

                await this.$nextTick();

                if (resetSearch) {
                    this.resetSearch();
                }

                if (hideRightSidebar) {
                    this.onClose(resetSearch);
                }
            },

            async selectLeadForCompletion(lead, completableValues) {
                this.selectedLeadId = lead.id;
                this.selectedLead = lead;
                this.selectedLeadCompletableValues = completableValues;

                if (lead.status) {
                    this.modals.selectedLeadNotActiveOpened = true;
                    return;
                }

                if (this.selectedLeadHasCompletableValues) {
                    this.modals.completeLeadConfirmation.content = await this.buildCompleteLeadContent();
                    this.modals.completeLeadConfirmation.opened = true;
                    return;
                }

                this.navigateToLead(this.selectedLeadId, this.showDateModal, true, true);
            },

            completeLead() {
                const { leadData, vehicleData, phoneData, emailData } = this.leadUpdateDataObject;

                if (vehicleData.end_contract_date) {
                    const exchangeVehicleIds = this.selectedLead.vehicles
                        .filter(vehicle => vehicle.type === 'exchange')
                        .sort((a, b) => {
                            return a.id - b.id;
                        })
                        .map(vehicle => {
                            return vehicle.id;
                        });

                    if (exchangeVehicleIds.length) {
                        this.updateLeadVehicleAction(
                            { end_contract_date: vehicleData.end_contract_date },
                            exchangeVehicleIds[0],
                        );
                    }
                }

                if (phoneData) {
                    this.addLeadPhoneAction(phoneData, this.selectedLeadId);
                }

                if (emailData) {
                    this.addLeadEmailAction(emailData, this.selectedLeadId);
                }

                if (vehicleData.inventory_unit_id) {
                    this.addLeadVehicleAction(vehicleData, this.selectedLeadId);
                }

                this.updateLeadAction(this.selectedLeadId, leadData);
                this.navigateToLead(this.selectedLeadId, this.showDateModal, true, true);
            },

            selectLeadForAssociation(lead) {
                this.selectedLeadId = lead.id;
                this.selectedLead = lead;

                if (!this.selectedForViewing) {
                    this.navigateToLead(lead.id, false);
                }

                if (this.createLeadDisabled) {
                    this.selectLeadForAssociationConfirmed();
                    return;
                }

                if (this.selectedLead.status) {
                    this.modals.associateToNotActiveLeadOpened = true;
                    return;
                }

                this.associate();
            },

            selectLeadForAssociationConfirmed() {
                this.state.associateToResultItemId = this.selectedLeadId;
                this.state.showAdditionalFields = true;
            },

            associate() {
                this.state.associateToResultItemId = this.selectedLeadId;

                if (this.newLeadIsEmail) {
                    this.includeOriginalEmail();
                    return;
                }

                this.createLead();
            },

            async addEventToSalesJourney(lead) {
                this.creating = true;

                const payload = this.leadEventPayload;

                try {
                    await this.$api.leads.addEventToSalesJourney(lead.id, payload);

                    if (this.$route.name == 'leads.update' && lead.id == this.$route.params.id) {
                        await this.setContextLeadIdAction(null);
                        await this.setContextLeadIdAction(lead.id, true);
                    }

                    this.navigateToLead(lead.id, false, true, true);
                    this.resetSearch();
                } catch (error) {
                    this.$notify.error(this.$t('addLead.errorDuringAddEventToSalesJourney'));

                    throw error;
                } finally {
                    this.creating = false;
                }
            },

            async createLead() {
                this.creating = true;

                const payload = this.leadCreationPayload;

                try {
                    const response = await this.$axios.post('v1/legacy/leads/store', payload);
                    const leadId = response.data.data.id;

                    if (!!this.state.associateToResultItemId || !!this.relatedLeadId) {
                        await this.associateLead(leadId);
                        this.syncAssociatedLeadsCustomer(await this.$api.leads.show(leadId));
                    }

                    this.setContextLeadIdAction(leadId);
                    this.navigateToLead(leadId, false, true, true);
                    this.resetSearch();
                } catch (error) {
                    if (error.response && error.response.status === 403) {
                        return;
                    }

                    this.appendNewError({
                        code: '0066',
                        display: true,
                        error,
                        payload,
                    });
                } finally {
                    this.creating = false;
                }
            },

            async associateLead(leadId) {
                try {
                    this.$wait.start('associating.lead');

                    await this.$api.leads.associate(
                        { id: this.state.associateToResultItemId || this.relatedLeadId },
                        {
                            lang: this.$i18n.locale,
                            associatedIds: leadId,
                            eventLeadAssociation: this.contextLead.lead_type_id === LeadType.EVENT,
                            dissociation: false,
                        },
                    );
                } catch (error) {
                    this.$notify.error(this.$t('addLead.errorDuringAssociation'));

                    throw error;
                } finally {
                    this.$wait.end('associating.lead');
                }
            },

            confirmLeadCreation() {
                if (this.inventoryUnit) {
                    this.$behavior.track('inventory', { action: 'Click', location: 'CreateLeadWithInventoryData' });
                }

                if (!this.state.showAdditionalFields && !this.state.addingAssociatedLead) {
                    this.state.showResults = false;
                    this.state.showAdditionalFields = true;
                    return;
                }

                if (this.state.addingAssociatedLead) {
                    this.associate();
                    return;
                }

                if (this.state.showResults && !this.state.noResults && (this.isWalkIn || this.isRenewal)) {
                    this.modals.createLeadConfirmationOpened = true;
                    return;
                }

                if (this.newLeadIsEmail) {
                    this.includeOriginalEmail();
                    return;
                }

                this.createLead();
            },

            includeOriginalEmail() {
                if (this.newLeadIsEmail) {
                    this.modals.originalEmailOpened = true;
                    return;
                }

                this.createLead();
            },

            resetSearchedParameters() {
                this.searchedParameters = {
                    name: null,
                    phone: null,
                    email: null,
                    businessName: null,
                };
            },

            resetSearch() {
                this.resetAccountOptions();
                this.$eventBus.$emit('reset-new-lead-object');
                this.inventoryUnit = null;
                this.leads = [];
                this.state.associateToResultItemId = null;
                this.state.selectedForViewingId = null;
                this.relatedLeadId = null;
                this.state.showAdditionalFields = false;
                this.state.showResults = false;
                this.originalEmail = '';
            },

            resetInventoryUnit() {
                this.inventoryUnit = null;
                this.$emit('update:collapsed', !this.collapsed);
            },

            onShow(activePanel, inventoryUnit = null) {
                if (activePanel === 'addLead') {
                    this.resetWalkinPresentedDate();
                    this.inventoryUnit = inventoryUnit;
                }
            },

            resetWalkinPresentedDate() {
                if (this.newLead.leadType == LeadType.WALK_IN) {
                    this.$eventBus.$emit('reset-walk-in-presented-date');
                }
            },

            resetAccountOptions() {
                this.resetAccount();

                if (!this.accountOptions.some(account => account.id == this.account.id)) {
                    this.accountOptions = orderBy(this.account.children, 'name') || [];
                }

                this.accountOptions = this.accountOptions.filter(
                    account => this.authUser.isAdmin() || this.authUser.childAccountIds.includes(account.id),
                );

                if (
                    !!this.previouslySelectedAccountId &&
                    !this.accountOptions.some(account => account.id == this.previouslySelectedAccountId)
                ) {
                    this.previouslySelectedAccountId = null;
                }
            },

            resetAccount() {
                this.previouslySelectedAccountId = this.newLead.childAccountId;
                this.account = this.contextAccount;
            },

            async buildCompleteLeadContent() {
                return Promise.all(Object.entries(this.completableValues).map(async ([key, rawValue]) => {
                    let value = '';
                    let icon = '';

                    switch (key) {
                        case 'civility':
                            value = this.$t(`civilities.${rawValue}`);
                            break;

                        case 'phone':
                            icon = rawValue.type;
                            value = LeadPhone.format(rawValue.number);
                            break;

                        case 'email':
                            icon = rawValue.type;
                            value = rawValue.email;
                            break;

                        case 'division':
                            value = this.getDivisionName(rawValue);
                            break;

                        case 'advisor':
                        case 'bdc':
                        case 'serviceAdvisor':
                        case 'serviceAgent': {
                            const user = await this.account.getUser(rawValue);
                            value = user?.fullName;
                            break;
                        }

                        case 'source':
                            value = this.newLead.sourceName;
                            break;

                        case 'endContractDate':
                            value = new ActivixDate(rawValue).toHumanShort(false);
                            key = 'endContractDateExchange';
                            break;

                        default:
                            value = rawValue;
                            break;
                    }

                    return {
                        key: this.$t(`clientCard.${camelCase(key)}`),
                        value,
                        icon,
                    };
                }));
            },

            buildSearchPayload() {
                const searchData = {};
                const searchIndexes = ['name', 'phone', 'email', 'businessName'];

                searchIndexes.forEach(index => {
                    if (this.newLead[index]) {
                        searchData[index] = this.newLead[index];

                        if (index === 'name' || index === 'businessName') {
                            searchData[index] = `%${searchData[index].trim()}%`;
                        }

                        this.searchedParameters[index] = searchData[index];
                    }
                });

                const params = {
                    accountId: this.selectedAccount.id,
                    filter: {
                        account: this.selectedAccount.id,
                    },
                    include: ['advisor', 'bdc', 'service_advisor', 'service_agent', 'emails', 'vehicles', 'phones'],
                    per_page: 25,
                    page: 1,
                };

                return [searchData, params];
            },

            buildAPISearchRequests(searchData, params) {
                const duplicatesCalls = [];

                Object.entries(searchData).forEach(([key, value]) => {
                    duplicatesCalls.push(
                        this.$api.leads.index({
                            ...params,
                            filter: {
                                ...params.filter,
                                [key]: value,
                            },
                        }),
                    );
                });

                return duplicatesCalls;
            },

            handleSearchResults(leads) {
                if (!leads.length) {
                    this.state.noResults = true;
                    return;
                }

                this.state.showResults = true;
                this.state.noResults = false;

                leads.reverse().forEach(lead => {
                    if (this.leads.some(l => l.id == lead.id)) {
                        return;
                    }

                    this.leads.push(new Lead(lead));
                });
            },

            async searchLead() {
                if (this.state.associateToResultItemId) {
                    this.state.searchParametersChanged = true;
                    return;
                }

                if (this.searchDisabled || (this.state.addingAssociatedLead && !this.contextAccount?.disable_manual_duplicate)) {
                    this.state.showResults = false;
                    return;
                }

                this.state.showResults = true;
                this.state.searching = true;
                this.leads = [];

                this.resetSearchedParameters();

                const [searchData, params] = this.buildSearchPayload();
                const duplicatesCalls = this.buildAPISearchRequests(searchData, params);

                try {
                    const [response1, response2, response3] = await Promise.all(duplicatesCalls);

                    const response = unionBy(response1, response2, response3, 'id');

                    this.handleSearchResults(response);
                } catch (error) {
                    this.$notify.error(this.$t('addLead.errorDuringSearch'));

                    throw error;
                } finally {
                    this.state.searching = false;
                }
            },

            dateIsValid(date, after = false) {
                if (!locale_dt(date).isValid()) {
                    return false;
                }

                return after ? locale_dt(date).isFuture() : !locale_dt(date).isFuture();
            },

            getDivisionName(divisionId) {
                switch (divisionId) {
                    case 1:
                        return this.$t('divisions.new');

                    case 2:
                        return this.$t('divisions.used');

                    case 3:
                        return this.$t('divisions.service');
                }

                return '';
            },

            onBackToSearchResults() {
                this.state.associateToResultItemId = null;
                this.state.showAdditionalFields = false;

                if (this.state.searchParametersChanged) {
                    this.state.searchParametersChanged = false;
                    this.searchLead();
                }
            },

            onClose(resetSearch = true) {
                this.state.associateToResultItemId = null;
                this.originalEmail = '';

                if (resetSearch) {
                    this.resetSearch();
                }

                this.$emit('close');
            },

            onPopStateHandler() {
                this.replaceRouterHistory = false;
            },
        },

        created() {
            window.addEventListener('popstate', this.onPopStateHandler, false);
            this.$eventBus.$on('show-right-sidebar', this.onShow);

            this.resetSearchedParameters();
        },

        beforeDestroy() {
            window.removeEventListener('popstate', this.onPopStateHandler, false);
            this.$eventBus.$off('show-right-sidebar', this.onShow);
        },
    };
</script>
