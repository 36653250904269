<template>
    <div>
        <activix-banner color="gray" @close="dismiss" v-if="visible">
            <span class="font-semibold">
                {{ $t('announcements.likeVersion') }}
            </span>

            <template #link>
                <a class="md:ml-2" @click="modals.positiveFeedbackOpened = true">👍</a>
                <a class="ml-3" @click="modals.negativeFeedbackOpened = true">👎</a>
            </template>
        </activix-banner>

        <feedback sentiment="negative" :opened.sync="modals.negativeFeedbackOpened" />
        <feedback sentiment="positive" :opened.sync="modals.positiveFeedbackOpened" @approve="onPositiveFeedback" />
        <feedback sentiment="neutral" :opened.sync="modals.neutralFeedbackOpened" @closed="onClosedNeutralFeedback" />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import Feedback from './modals/Feedback.vue';
    import ActivixDate from '../value-objects/ActivixDate.js';
    import { orderByKey } from '../utils/index.js';
    import { useGlobalStore } from '../store/store.js';

    export default {
        components: {
            Feedback,
        },

        data() {
            return {
                nowDate: new ActivixDate('now'),
                releaseDate: new ActivixDate('2019-08-28 15:00'), // UTC
                modals: {
                    negativeFeedbackOpened: false,
                    positiveFeedbackOpened: false,
                    neutralFeedbackOpened: false,
                },
                visible: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            timeUntilRelease() {
                const days = this.releaseDate.diffInDays(this.nowDate);
                const totalHours = this.releaseDate.diffInHours(this.nowDate);
                const totalMinutes = this.releaseDate.diffInMinutes(this.nowDate);

                const hours = totalHours - days * 24;
                const minutes = totalMinutes - totalHours * 60;

                if (days >= 3) {
                    return `
                        ${days} ${this.$tc('delays.day', days)}
                        ${hours} ${this.$tc('delays.hour', hours)}
                    `;
                }

                if (totalHours > 0) {
                    return `
                        ${totalHours} ${this.$tc('delays.hour', totalHours)}
                        ${minutes} ${this.$tc('delays.minute', minutes)}
                    `;
                }

                if (totalMinutes > 0) {
                    return `${totalMinutes} ${this.$tc('delays.minute', totalMinutes)}`;
                }

                return `0 ${this.$tc('delays.minute', 0)}`;
            },

            isReleased() {
                return this.nowDate.isSameOrAfter(this.releaseDate);
            },

            isDisabled() {
                return [false, 'false'].includes(process.env.VUE_APP_ANNOUNCEMENT_BAR);
            },
        },

        methods: {
            openFeedbackModal() {
                this.modals.neutralFeedbackOpened = true;
            },

            onPositiveFeedback() {
                this.$ls.set('feedback:positive', true);
                this.dismiss();
            },

            dismiss() {
                this.close();
                this.modals.positiveFeedbackOpened = false;
                this.saveDismissedState();
            },

            saveDismissedState() {
                let expiration = null;

                if (!this.$ls.get('feedback:positive')) {
                    const now = new ActivixDate('now').addDays(1);
                    const endOfDay = now.endOfDay();

                    expiration = endOfDay.diffInMilliseconds(now);
                }

                this.$ls.set('feedback:dismissed', true, expiration);
            },

            open() {
                const announcementDismissed = this.$ls.get('feedback:dismissed', false);

                if (this.isReleased || this.isDisabled) {
                    return;
                }

                // Make sure dismiss has expiration
                if (announcementDismissed) {
                    this.saveDismissedState();
                    return;
                }

                this.visible = true;
            },

            close() {
                this.visible = false;
            },

            initTimer() {
                this._dateUpdateTimerHandle = setInterval(() => {
                    this.nowDate = new ActivixDate('now');
                }, 60 * 1000);
            },

            onClosedNeutralFeedback() {
                const query = { ...this.$route.query };

                delete query.modal;

                this.$router.replace({
                    ...this.$route,
                    query: orderByKey(query),
                });
            },
        },

        created() {
            const currentVersion = 2;
            const feedbackVersion = this.$ls.get('feedback:version');

            if (feedbackVersion !== currentVersion) {
                this.$ls.remove('feedback:positive');
                this.$ls.remove('feedback:dismissed');
                this.$ls.set('feedback:version', currentVersion);
            }

            this.$eventBus.$on('open-feedback-modal', this.openFeedbackModal);

            this.open();
            this.initTimer();
        },

        mounted() {
            if (this.$route.query.modal == 'feedback') {
                this.$nextTick(() => {
                    this.openFeedbackModal();
                });
            }
        },

        beforeDestroy() {
            if (this._dateUpdateTimerHandle) {
                clearInterval(this._dateUpdateTimerHandle);
                this._dateUpdateTimerHandle = null;
            }
            this.$eventBus.$off('open-feedback-modal', this.openFeedbackModal);

            this.close();
        },
    };
</script>
