<template>
    <activix-modal
        size="lg"
        portal="modal-2"
        :opened="isOpen"
        @close="close"
    >
        <template #header>
            <div class="flex flex-col text-center">
                <h4 class="modal-title" v-text="title" />
            </div>
        </template>
        <template #body>
            <div class="relative w-full max-w-2xl mx-auto overflow-hidden bg-white rounded-lg shadow-lg">
                <div class="flex transition-transform duration-700 ease-in-out" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
                    <img
                        :src="image"
                        class="w-full flex-shrink-0"
                        alt="Carousel image"
                        :key="index"
                        v-for="(image, index) in images"
                    />
                </div>
                <button class="absolute top-1/2 left-2 transform -translate-y-1/2 text-gray-800 px-3 py-1" @click="prev">
                    <icon name="regular/arrow-left-1" class="w-8 h-8 drop-shadow-xl" />
                </button>
                <button class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-800 px-3 py-1" @click="next">
                    <icon name="regular/arrow-right-1" class="w-8 h-8 drop-shadow-xl" />
                </button>
            </div>
        </template>
    </activix-modal>
</template>

<script setup>
    import { ref, defineProps, defineEmits, watch } from 'vue';
    import Icon from '@/components/elements/ActivixIcon.vue';

    const props = defineProps({
        images: {
            type: Array,
            required: true,
        },
        isOpen: {
            type: Boolean,
            required: true,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
    });

    const emit = defineEmits(['close']);
    const currentIndex = ref(0);

    const next = () => {
        currentIndex.value = (currentIndex.value + 1) % props.images.length;
    };

    const prev = () => {
        currentIndex.value = (currentIndex.value - 1 + props.images.length) % props.images.length;
    };

    const close = () => {
        emit('close');
    };

    watch(() => props.isOpen, (isOpen) => {
        if (!isOpen) {
            currentIndex.value = 0;
        }
    });
</script>
