import Vue from 'vue';
import { RecycleScroller } from 'vue-virtual-scroller';

Vue.component('RecycleScroller', RecycleScroller);

const ActivixRecycleScroller = Vue.component('RecycleScroller').extend({
    methods: {
        getScroll() {
            const { $el: el, direction } = this;
            const isVertical = direction === 'vertical';
            let scrollState;

            if (this.pageMode) {
                const bounds = el.getBoundingClientRect();
                const boundsSize = isVertical ? bounds.height : bounds.width;
                const start = -(isVertical ? bounds.top : bounds.left);
                let size = isVertical ? window.innerHeight : window.innerWidth;

                // Note: Removed this part from original function
                // if (start < 0) {
                //     size += start;
                //     start = 0;
                // }

                if (start + size > boundsSize) {
                    size = boundsSize - start;
                }

                scrollState = {
                    start,
                    end: start + size,
                };
            } else if (isVertical) {
                scrollState = {
                    start: el.scrollTop,
                    end: el.scrollTop + el.clientHeight,
                };
            } else {
                scrollState = {
                    start: el.scrollLeft,
                    end: el.scrollLeft + el.clientWidth,
                };
            }

            return scrollState;
        },
    },
    beforeDestroy() {
        const resizeObserver = this.$children.find(c => c.$el.classList.contains('resize-observer'));
        if (!resizeObserver) {
            return;
        }
        resizeObserver.removeResizeHandlers();
        // the lib vue-virtual-scroller uses the vue-resize library
        // the removeResizeHandlers method on it does a
        // delete this._resizeObject.onload after removing the listeners
        // but unfortunately it doesn't remove the referenced function
        // setting it to null does the trick.
        resizeObserver._resizeObject.onload = null;
    },
});

Vue.component('ActivixRecycleScroller', ActivixRecycleScroller);
