<template>
    <div>
        <div class="overscroll-contain h-full shadow-lg">
            <div :style="forModalView ? 'top: 0px' : headerStyle" class="hidden md:flex sticky z-10 overflow-x-hidden items-center pl-3 pr-3 pt-6 pb-3 rounded-t-md text-xs font-bold uppercase bg-white border-b-2 border-grey-200">
                <div
                    :class="getClasses(inventoryUnitColumnParam)"
                    :key="index"
                    v-for="(inventoryUnitColumnParam, index) in inventoryUnitColumnsParams"
                >
                    {{ inventoryUnitColumnParam.title }}
                </div>
            </div>
            <div v-if="inventoryUnits.length">
                <vehicle-list-item
                    :inventory-unit="inventoryUnit"
                    :inventory-filters="inventoryFilters"
                    :category="category"
                    :has-shared-inventory="hasSharedInventory"
                    :index="index"
                    :inventory-unit-columns-params="inventoryUnitColumnsParams"
                    :is-card-expanded="inventoryUnit.id === expandedInventoryUnitId"
                    :is-selected-stock="inventoryUnit.id === selectedInventoryUnitId"
                    :for-modal-view="forModalView"
                    :selected-division="selectedDivision"
                    :search-input="searchInput"
                    :key="inventoryUnit.id"
                    @updateInventory="updateInventory"
                    @expandedInventoryUnit="handleToggleExpanded"
                    @selectVehicle="selectVehicle"
                    v-for="(inventoryUnit, index) in inventoryUnits"
                />
            </div>
            <div v-else>
                <div class="flex flex-col items-center justify-center py-12 gap-3">
                    <icon class="text-6xl text-gray-700" name="regular/car-actions-disable-1" />
                    <p class="mb-0">
                        {{ emptyMessage }}
                    </p>
                </div>
            </div>
            <inventory-vehicle-footer />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { get } from 'lodash-es';
    import { useLayoutStore } from '@/store/modules/layout/store.js';
    import InventoryVehicleFooter from '@/components/inventory/InventoryVehicleFooter.vue';
    import VehicleListItem from './InventoryVehicleListItem.vue';

    export default {
        components: {
            InventoryVehicleFooter,
            VehicleListItem,
        },
        props: {
            searchInput: {
                type: String,
                default: null,
            },
            inventoryUnits: {
                type: Array,
                required: true,
            },
            inventoryFilters: {
                type: Object,
                default: () => {
                },
            },
            expandedVehicleId: {
                type: Number,
                required: false,
            },
            selectedInventoryUnitId: {
                type: Number,
                required: false,
            },
            category: {
                type: String,
                default: 'car',
                validator: value => ['car', 'powersport'].includes(value),
            },
            forModalView: {
                type: Boolean,
                required: false,
            },
            hasSharedInventory: {
                type: Boolean,
                required: false,
            },
            selectedDivision: {
                type: String,
                required: false,
            },
        },
        data() {
            return {
                expandedInventoryUnitId: null,
                selectedStockId: null,
                inventoryUnitColumnsParams: {
                    status: {
                        title: this.$t('inventory.column.status'),
                        classes: {
                            default: 'md:basis-3/20 lg:basis-3/20 xl:basis-2/20 max-w-none md:max-w-[11vw] xl:max-w-[8vw] mr-0.5 md:pl-1 flex w-full pr-4',
                        },
                    },
                    stock: {
                        title: this.$t('inventory.column.stock'),
                        classes: {
                            default: 'md:basis-2/20 lg:basis-2/20 xl:basis-2/20 flex w-full',
                        },
                    },
                    iconColumn: {
                        title: '',
                        classes: {
                            default: 'md:basis-1/20 lg:basis-1/20 xl:basis-1/20 flex w-full',
                        },
                    },
                    year: {
                        title: this.$t('inventory.column.year'),
                        classes: {
                            default: 'md:basis-1/20 lg:basis-1/20 xl:basis-1/20 truncate flex w-full',
                        },
                    },
                    model: {
                        title: this.$t('inventory.column.model'),
                        classes: {
                            default: 'md:basis-3/20 lg:basis-2/20 xl:basis-2/20 truncate flex justify-start w-full pr-4',
                            used: 'md:basis-3/20 lg:basis-2/20 xl:basis-2/20 truncate flex justify-start w-full pr-4',
                        },
                    },
                    trim: {
                        title: this.$t('inventory.column.trim'),
                        classes: {
                            default: 'md:basis-2/20 lg:basis-3/20 xl:basis-3/20 truncate flex justify-start w-full pr-4',
                            used: 'md:basis-2/20 lg:basis-1/20 xl:basis-3/20 truncate flex justify-start w-full pr-4',
                        },
                    },
                    color: {
                        title: this.$t('inventory.column.color'),
                        classes: {
                            default: 'md:pr-0 md:basis-0 lg:basis-2/20 xl:basis-2/20 flex justify-start truncate w-full pr-4',
                            used: 'md:pr-0 md:basis-0 lg:basis-1/20 xl:basis-2/20 flex justify-start truncate w-full pr-4',
                        },
                    },
                    odometer: {
                        title: this.$t('inventory.column.odometer'),
                        classes: {
                            default: 'hidden',
                            used: 'md:basis-2/20 lg:basis-2/20 xl:basis-2/20 truncate w-full',
                        },
                    },
                    location: {
                        title: this.$t('inventory.column.location'),
                        classes: {
                            default: 'hidden',
                        },
                    },
                    daysInInventory: {
                        title: this.$t('inventory.column.daysInInventory'),
                        classes: {
                            default: 'md:pr-0 md:basis-0 lg:basis-1/20 xl:basis-2/20 flex justify-start truncate w-full pr-4',
                            used: 'md:pr-0 md:basis-0 lg:basis-1/20 xl:basis-1/20 flex justify-start truncate w-full pr-4',
                        },
                    },
                    price: {
                        title: this.$t('inventory.column.price'),
                        classes: {
                            default: 'md:basis-2/20 lg:basis-2/20 xl:basis-1/20 truncate flex w-full pr-4',
                        },
                    },
                    leads: {
                        title: this.$t('inventory.column.leads'),
                        classes: {
                            default: 'md:basis-2/20 lg:basis-2/20 xl:basis-2/20 flex md:justify-center w-full',
                            used: 'md:basis-2/20 lg:basis-1/20 xl:basis-1/20 flex md:justify-center w-full',
                        },
                    },
                    action: {
                        title: this.$t('inventory.column.action'),
                        classes: {
                            default: 'flex gap-2 md:basis-4/20 lg:basis-3/20 xl:basis-auto truncate',
                            used: 'flex gap-2 md:basis-4/20 lg:basis-auto xl:basis-auto truncate',
                        },
                    },
                },
            };
        },
        computed: {
            ...mapState(useLayoutStore, ['bodySpacingTop', 'headerHeight']),

            emptyMessage() {
                return this.$t('inventory.emptyInventory');
            },
            headerStyle() {
                const top = this.headerHeight + this.bodySpacingTop;

                return {
                    top: `${top - 1}px`,
                };
            },
        },

        watch: {
            selectedInventoryUnitId: {
                immediate: true,
                handler(selectedInventoryUnitId) {
                    this.selectedStockId = selectedInventoryUnitId;
                },
            },
        },

        methods: {
            getClasses(inventoryUnitColumnParam) {
                return get(inventoryUnitColumnParam.classes, this.selectedDivision, inventoryUnitColumnParam.classes.default);
            },

            handleToggleExpanded(inventoryUnitId) {
                if (this.expandedInventoryUnitId === inventoryUnitId) {
                    this.expandedInventoryUnitId = null;
                    this.$behavior.track('Inventory', { action: 'closeVehicleCard', location: 'inventoryUnitList' });
                } else {
                    this.expandedInventoryUnitId = inventoryUnitId;
                    this.$behavior.track('Inventory', { action: 'expandVehicleCard', location: 'inventoryUnitList' });
                }
            },
            selectVehicle(vehicleId) {
                this.selectedStockId = vehicleId;
                this.$emit('selectVehicle', vehicleId);
            },
            updateInventory(inventoryUnit) {
                this.$emit('updateInventory', inventoryUnit);
            },
        },
    };
</script>
