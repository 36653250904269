export default {
    campaign: 'Campagne',
    createCampaign: 'Créer campagne',
    reviewCampaign: 'Révision de la campagne',
    backToSettings: 'Retour aux paramètres',
    backBtn: 'Retour',
    chooseOption: 'Choisir une option',
    createdSuccessfully: 'Campagne créée avec succès',
    notCreatedSuccessfully: 'La campagne n\'a pu être créée',
    confirmCancelCampaignModalTitle: 'Annuler la campagne',
    confirmCancelCampaignModalContent: 'Êtes-vous sûr de vouloir annuler cette campagne ? \n Les courriels ne seront pas envoyés.',
    confirmCancelCampaignNotification: 'Campagne annulée avec succès',
    confirmDeleteCampaignModalTitle: 'Supprimer la campagne',
    confirmDeleteCampaignModalContent: 'Êtes-vous sûr de vouloir supprimer cette campagne ? \n (cette action est irréversible)',
    confirmDeleteCampaignNotification: 'Campagne supprimer avec succès',
    failedReasons: {
        non_existent_or_unauthorized_user: 'Utilisateur inexistant ou non autorisé',
        lead_no_email: 'Lead sans courriel',
        last_outgoing_email_identical: 'Dernier email sortant identique',
        casl_expired: 'Consentement client expiré',
        do_not_market: 'Lead répertorié comme do not market',
        unsubscribe_all_date: 'Lead désabonné de toutes les communications',
        communicating_user: 'Utilisateur en communication',
        email_communication_disabled: 'Communication par email désactivée',
        phone_communication_disabled: 'Communication par téléphone désactivée',
        sms_communication_disabled: 'Communication par SMS désactivée',
        unknown_reason: 'Raison inconnue',
        invalid_email_data: 'Données email invalides',
        sender_not_found: 'Expéditeur non trouvé',
        sender_no_campaign_access: 'Expéditeur sans accès aux campagnes',
        email_already_added_to_campaign: 'Courriel déjà ajouté à la campagne',
    },
    fetchingError: 'Erreur lors de la récupération des campagnes',
    deleteError: 'Erreur lors de la suppression de la campagne',
    cancelError: 'Erreur lors de l\'annulation de la campagne',
    massMailing: 'Envoi de masse',
    searchPlaceholder: 'Rechercher campagne',
    startingOn: 'Débute le {0} (heure locale)',
    endsOn: 'Termine le {0}',
    endedOn: 'Complété le {0}',
    noCampaignsFound: 'Aucune campagne trouvée',
    filters: {
        createdAt: 'Date de création',
        createdBy: 'Créée par',
        distributionComplete: 'Envois terminés',
        startDate: 'Date de départ',
        endDate: 'Date de fin',
        objective: 'Objectif',
        status: 'Statut',
    },
    trackedStats: {
        createOn: 'Créée le: {0}',
        trackingName: 'Suivi',
        sms: 'SMS',
        mailing: 'Courriel',
        progression: 'Envoyés à {0} des {1} leads ciblés',
        viewButton: 'Voir',
        averageEngagementTime: 'Durée moyenne d\'engagement',
        send: 'Envoyés',
        bounced: 'Rebondis',
        unsubscribed: 'Désabonnés',
        delivered: 'Livrés',
        opened: 'Ouverts',
        replied: 'Répondus',
        clicked: 'Cliqués',
        futureSend: '{0} messages restants à envoyer',
        failedEmail: '{0} messages n\'ont pas été envoyés',
        lastSendDate: 'Le dernier courriel sera envoyé le: {0}',
        deactivatedSend: '{0} messages ne seront pas envoyés',
        deactivatedTooltip: 'Les campagnes annulée ne transmettent aucun message.',
        cancel: 'Annuler',
        delete: 'Supprimer',
    },
    progressBar: {
        openRate: 'Taux d\'ouverture',
        clickThroughRate: 'Taux de clics (CTR)',
        conversionRate: 'Taux de conversion',
        bounceRate: 'Taux de rebond',
        unsubscribeRate: 'Taux de désabonnement',
        spamComplaintRate: 'Taux de plaintes pour spam',
        replyRate: 'Taux de réponse',
        ResponseRateByCommunicationType: 'Taux de réponse par type de communication',
    },
    settings: {
        title: 'Paramètres de Campagne',
        numberLeadsSelected: '{0} leads sélectionnés pour la campagne',
        buildEmail: 'Composer Courriel',
        campaignName: 'Nom de la campagne',
        campaignNameError: 'Doit être inférieur à 50 caractères',
        conversionEvent: 'Objectif de campagne',
        drippingAndDate: 'Gestion des envois et date de début',
        selectDateAndTime: 'Date de départ',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        startDateInfo: 'L\'envoi des courriels commencera à la date et à l\'heure sélectionnées selon le fuseau horaire du concessionnaire.',
        endDateInfo: 'La date de fin sert à spécifier quand les statiques devraient arrêter de compiler. Si vous utilisez l’envoi progressif, la date de fin doit être après le dernier envoi.',
        setType: 'Type d\'envoi',
        oneTimeSend: 'Envoi unique',
        drippingSend: 'Envoi progressif',
        subsequentEmails: 'Les e-mails suivants seront envoyés en même temps.',
        firstDayDispatchCount: 'Nombre initial de destinataires',
        initialTooHigh: 'Le nombre initial  étant plus élévé ou égal au nombre de lead, l\'entièreté sera envoyé en une fois',
        drippingOption: 'Sélectionnez une option',
        subsequentSendOption: 'Définir les envois suivants',
        totalTimeOption: 'Définir la durée totale',
        setFrequency: 'Envoyer tous les',
        days: 'jours',
        drippingDuration: 'Cette campagne prendrait {0} jours pour se terminer',
        numberOfDaysRemaining: 'Envoyer le reste en',
        moreThanOneDay: 'Vous devez mettre au moins un jour ou sélectionner l\'envoi progressif d\'envoi de massif unique\n',
        drippingEmailsByDay: 'Cette campagne enverra {0} messages le premier jour puis {1} par jour pendant {2} jours.',
        mailingWithoutDripping: 'Cette campagne enverra {0} messages',
        mailingWithDateWithoutDripping: 'Cette campagne enverra {0} messages le {1} à {2}',
        reviewBtn: 'Valider',
    },
    review: {
        reviewTitle: 'Détails de la Campagne',
        reviewSelected: '{0} leads sélectionnés',
        filters: 'Filtres appliqués:',
        noFilter: 'Aucun filtre appliqué',
        showAll: 'Afficher tout',
        settingsTitle: 'Paramètres de la campagne',
        nameSet: 'Nom de la campagne',
        statsSet: 'Objectif',
        dateAndTimeRelease: 'Définir la date et l\'heure de diffusion',
        startDate: 'Date de début',
        timeRelease: 'Heure d\'envoi',
        endDate: 'Date de fin',
        drippingFunction: 'Envoi progressif',
        initialRecipient: 'Définir le nombre initial de destinataires',
        count: 'Définir le nombre',
        frequency: 'Répartition restante',
        days: 'jours',
        composerSettings: 'Paramètres du compositeur de campagne',
        nameCompoSet: 'Nom de la catégorie',
        nameTemplate: 'Nom du modèle',
        subjectEn: 'Sujet en Anglais',
        subjectFr: 'Sujet en Français',
        btnLaunch: 'Confirmer le lancement',
        previewLink: 'Aperçu de l\'Email',
        noTemplate: 'Pas de modèles utilisé pour cette campagne',
    },
};
