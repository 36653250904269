<template>
    <div class="flex w-full text-lg">
        <div class="text-left">
            <div>
                {{ $tc('inventory.statistics.moreThan7Days') }}
            </div>
            <div>
                {{ $tc('inventory.statistics.moreThan14Days') }}
            </div>
            <div>
                {{ $tc('inventory.statistics.moreThan30Days') }}
            </div>
            <div>
                {{ $tc('inventory.statistics.moreThan60Days') }}
            </div>
        </div>
        <div class="text-right ml-3">
            <div>
                <span class="font-bold">{{ cards.averageDaysInInventory.moreThan7Days.number }}</span>
            </div>
            <div>
                <span class="font-bold">{{ cards.averageDaysInInventory.moreThan14Days.number }}</span>
            </div>
            <div>
                <span class="font-bold">{{ cards.averageDaysInInventory.moreThan30Days.number }}</span>
            </div>
            <div>
                <span class="font-bold">{{ cards.averageDaysInInventory.moreThan60Days.number }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            cards: {
                type: Object,
                default: () => {},
            },
        },
    };
</script>
