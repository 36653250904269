<template>
    <div class="flex justify-center overflow-hidden h-full items-center" @click="openCarousel">
        <div
            class="relative overflow-hidden border rounded-md cursor-pointer"
            v-if="primaryVehicleImage"
        >
            <img
                alt="Vehicle image"
                class="grow object-contain"
                :src="primaryVehicleImage"
            />
            <div class="px-2 py-1 text-gray-750 flex items-center absolute bottom-0 left-0 w-full backdrop-blur-md bg-white bg-opacity-30 z-10">
                <icon name="regular/expand-2" />
                <span class="ml-1">{{ $tc('inventory.boxes.showImages', vehicleImages.length) }}</span>
            </div>
        </div>
        <activix-svg
            name="illustrations/car-thumbnail"
            class="grow object-contain"
            v-else
        />

        <activix-image-carousel
            :images="vehicleImages"
            :is-open="carouselOpened"
            :title="`${inventoryUnit.make} ${inventoryUnit.model} ${inventoryUnit.year}`"
            @close="carouselOpened = false"
        />
    </div>
</template>

<script>
    import ActivixImageCarousel from '@/components/elements/ActivixImageCarousel.vue';
    import ActivixSvg from '@/components/elements/ActivixSvg.vue';

    export default {
        components: { ActivixSvg, ActivixImageCarousel },

        props: {
            inventoryUnit: {
                type: Object,
                required: true,
            },
            carousel: {
                type: Boolean,
                default: true,
            },
            category: {
                type: String,
                default: 'car',
            },
        },

        data() {
            return {
                carouselOpened: false,
            };
        },

        computed: {
            primaryVehicleImage() {
                if (this.category === 'powersport') {
                    return '/img/moto-wireframe.png';
                }

                return this.vehicleImages[0] ?? null;
            },

            vehicleImages() {
                return this.inventoryUnit.inventory_unit_images
                    ?.map((image) => ({
                        url: image.url,
                        primary: image.primary,
                    }))
                    .sort((a, b) => (b.primary - a.primary))
                    .map((image) => image.url) || [];
            },
        },

        methods: {
            openCarousel() {
                if (!this.carousel || !this.vehicleImages.length) {
                    return;
                }

                this.carouselOpened = true;
            },
        },
    };
</script>
