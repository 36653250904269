<template>
    <div class="relative flex items-center h-full p-6 border-b-3" :class="borderColor">
        <div class="flex flex-col w-full h-full">
            <div class="flex-1 flex justify-between items-center">
                <div class="flex-1 flex items-baseline mr-4 overflow-hidden">
                    <activix-tooltip :content="cardValue.toString()" :only-on-truncate="true">
                        <div class="text-grey-700 text-4xl truncate" v-text="cardValue" />
                    </activix-tooltip>
                    <div class="text-grey-500 text-lg ml-2" v-if="type == 'number' && denominator !== null">
                        (<span class="text-grey-650">{{ formattedPercentage }}</span>)
                    </div>
                    <div class=" text-xl ml-2 truncate" v-if="category">
                        <span class="text-grey-700">{{ formattedCategory }}</span>
                    </div>
                </div>
                <div class="rounded-full p-3 | 2xl:p-4" :class="backgroundColor" v-if="icon">
                    <icon class="text-3xl" :class="textColor" :name="icon" />
                </div>
            </div>
            <div class="flex items-center mt-2 text-grey-600">
                <div class="text-lg font-semibold truncate | 2xl:text-xl" v-text="title" />
                <icon class="ml-2 | 2xl:text-lg" :name="titleIcon" v-if="titleIcon" />
            </div>
        </div>

        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            card: {
                type: Object,
                required: false,
            },
            denominator: {
                type: Number,
                required: false,
            },
            icon: {
                type: String,
                required: false,
            },
            title: {
                type: String,
                required: false,
            },
            titleIcon: {
                type: String,
                required: false,
            },
            type: {
                type: String,
                required: true,
            },
            value: {
                type: [Number, String],
                required: false,
            },
            percentage: {
                type: [Number, String],
                required: false,
            },

            category: {
                type: String,
                required: false,
            },
        },

        computed: {
            formattedPercentage() {
                return `${this.percentage}%`;
            },

            formattedCategory() {
                return `${this.category.toLowerCase()}`;
            },

            cardValue() {
                if (this.value) {
                    return this.value;
                }

                if (this.type == 'time') {
                    let count = this.card.count;

                    if (this.denominator !== null) {
                        count = this.average(this.card.count, this.denominator, true);
                    }

                    return this.timeHms(count);
                }

                if (this.type == 'money') {
                    return this.toMoney(this.card.count);
                }

                return this.card.count;
            },

            color() {
                let returnValue = 'grey';
                const colors = this.card.colors;

                if (!colors) {
                    return returnValue;
                }

                if (Object.keys(colors).length === 1) {
                    return colors[Object.keys(colors)[0]];
                }

                for (const x in colors) {
                    if (!colors.hasOwnProperty(x)) continue;

                    if (this.percentage >= x) {
                        returnValue = colors[x];
                    }
                }

                return returnValue;
            },

            backgroundColor() {
                switch (this.color) {
                    case 'grey':
                        return 'bg-grey-200';
                    case 'red':
                        return 'bg-red-100';
                    case 'orange':
                        return 'bg-orange-100';
                    case 'green':
                        return 'bg-green-100';
                    case 'blue':
                        return 'bg-blue-100';
                }

                return '';
            },

            textColor() {
                switch (this.color) {
                    case 'grey':
                        return 'text-grey-600';
                    case 'red':
                        return 'text-red-500';
                    case 'orange':
                        return 'text-orange-500';
                    case 'green':
                        return 'text-green-500';
                    case 'blue':
                        return 'text-blue-500';
                }

                return '';
            },

            borderColor() {
                switch (this.color) {
                    case 'grey':
                        return 'border-grey-500';
                    case 'red':
                        return 'border-red-500';
                    case 'orange':
                        return 'border-orange-500';
                    case 'green':
                        return 'border-green-500';
                    case 'blue':
                        return 'border-blue-500';
                }

                return '';
            },
        },
    };
</script>
