import { gql } from 'apollo-boost';
import Vue from 'vue';
import { apolloClient } from '@/plugins/vue-apollo.js';
import caching from '@/plugins/caching.js';

export default {
    async fetchCalendarViewsWithCaching() {
        try {
            const calendarViews = await Vue.caching.getCache(caching.CALENDAR_VIEWS_CACHE_KEY);
            const sharedCalendarViews = await Vue.caching.getCache(caching.SHARED_CALENDAR_VIEWS_CACHE_KEY);
            if (calendarViews || sharedCalendarViews) {
                this.calendarViews = calendarViews;

                if (sharedCalendarViews) {
                    this.sharedCalendarViews = sharedCalendarViews;
                }

                setTimeout(async () => {
                    await this.fetchCalendarViews();
                }, 2000);
            } else {
                await this.fetchCalendarViews();
            }
        } catch (e) {
            await this.fetchCalendarViews();
        }
    },

    async fetchCalendarViews() {
        const authUserCalendarViewsResponse = apolloClient.query({
            query: gql`query authUser_calendarViews {
                authUser {
                    id
                    calendar_views {
                        created_at
                        filters
                        id
                        name
                        updated_at
                        user_id
                        shared_to {
                            id
                        }
                    }

                    shared_calendar_views {
                        id
                        user_id
                        name
                        filters
                        updated_at
                        is_shared
                    }
                }
            }`,
        });

        const calendarViewsResponse = (await authUserCalendarViewsResponse).data.authUser;
        this.calendarViews = calendarViewsResponse.calendar_views;
        this.sharedCalendarViews = calendarViewsResponse.shared_calendar_views;

        this.updateCalendarViewCache();
    },

    async addCalendarView(viewData) {
        this.calendarViews.push(viewData);

        this.updateCalendarViewCache();
    },

    async updateCalendarView(viewData) {
        const viewIndex = this.calendarViews.findIndex(v => v.id == viewData.id);

        if (viewIndex !== -1) {
            this.calendarViews.splice(viewIndex, 1, viewData);
        }

        this.updateCalendarViewCache();
    },

    async deleteCalendarView(viewId) {
        const viewIndex = this.calendarViews.findIndex(v => v.id == viewId);

        if (viewIndex !== -1) {
            this.calendarViews.splice(viewIndex, 1);
        }

        this.updateCalendarViewCache();
    },

    updateCalendarViewCache() {
        Vue.caching.setCache(caching.CALENDAR_VIEWS_CACHE_KEY, this.calendarViews);
        Vue.caching.setCache(caching.SHARED_CALENDAR_VIEWS_CACHE_KEY, this.sharedCalendarViews);
    },
};
