<template>
    <div>
        <note-input
            :lead="lead"
            :account="account"
            :class="{ loading: isLoading}"
            :context-note="contextNoteData.note"
            :note-action="noteAction"
            :users="users"
            ref="noteInput"
            @cancel="clearContextNote"
            @submit="onSubmit"
            v-if="!readonly"
        />

        <div
            class="relative flex flex-col grow"
            :class="{ 'max-h-screen-50 overflow-y-auto': !isLoading && normalizedNotes.length }"
            ref="listWrapper"
        >
            <div class="flex justify-end w-full p-2">
                <button
                    class="cursor-pointer"
                    :class="{ 'text-blue-500 font-bold': isNoteFilter }"
                    @click="updateFilter(CommentType.STRING)"
                >
                    {{ $t('clientCard.comment') }}
                </button>
                <button
                    class="ml-2 cursor-pointer"
                    :class="{ 'text-blue-500 font-bold': isAttachmentFilter }"
                    @click="updateFilter(CommentType.ATTACHMENT)"
                >
                    {{ $t('clientCard.files') }}
                </button>
                <button
                    class="ml-2 cursor-pointer"
                    :class="{ 'text-blue-500 font-bold': isCaseFilter }"
                    @click="updateFilter(CommentType.CASE)"
                    v-if="caseEnabled"
                >
                    {{ $t('clientCard.cases') }}
                </button>
                <button
                    class="ml-2 cursor-pointer"
                    :class="{ 'text-blue-500 font-bold': isAudioFilter }"
                    @click="updateFilter(CommentType.AUDIO)"
                >
                    {{ $t('clientCard.audio') }}
                </button>
                <button
                    class=" ml-2 cursor-pointer"
                    :class="{ 'text-blue-500 font-bold': !hasFiltersApplied }"
                    @click="clearFilters"
                >
                    {{ $t('clientCard.all') }}
                </button>
            </div>

            <transition
                enter-active-class="transition duration-150"
                enter-class="opacity-0 -translate-y-2"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition duration-150"
                leave-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-2"
                mode="out-in"
            >
                <note-list
                    :lead="lead"
                    :context-note-data="contextNoteData"
                    :notes="normalizedNotes"
                    :readonly="readonly"
                    :show-list-loading="isLoading"
                    :show-more-items-loading="showMoreItemsLoading"
                    :users="users"
                    @trigger-deleting="triggerDeleting"
                    @trigger-editing="triggerEditing"
                    @trigger-replying="triggerReplying"
                    v-if="normalizedNotes.length || isLoading"
                />

                <empty-state

                    class="pb-6"
                    :description="emptyStateDescription"
                    :show-add="!readonly && !hasFiltersApplied"
                    svg="illustrations/note-blank-2"
                    :title="$t('clientCard.emptyState.noteTitle')"
                    @add-item="$refs.noteInput?.focus()"
                    @clear-filters="clearFilters"
                    v-else
                />
            </transition>
        </div>

        <activix-confirm-modal
            type="error"
            :title="$t('general.areYouSure')"
            :opened.sync="modals.deletion.opened"
            :content="confirmDeletionMessage"
            @approve="onDeleteNote"
            @deny="resetDeleteConfirmationModal"
        />
    </div>
</template>

<script>
    import NoteInput from '@/components/container/lead/notes/NoteInput.vue';
    import NoteList from '@/components/container/lead/notes/NoteList.vue';
    import EmptyState from '@/components/EmptyState.vue';

    import Account from '@/entities/Account.js';
    import Comment from '@/entities/Comment.ts';
    import CommentType from '@/entities/CommentType.js';
    import NoteAction from '@/entities/NoteAction.js';
    import Lead from '@/entities/Lead.js';
    import NotesBoxMixin from '@/mixins/NotesBoxMixin.js';

    export default {
        components: {
            EmptyState,
            NoteInput,
            NoteList,
        },

        mixins: [NotesBoxMixin],

        props: {
            account: {
                type: Account,
                required: true,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        data() {
            return {
                contextNoteData: {
                    action: null,
                    note: null,
                    rootNoteId: null,
                },
                modals: {
                    deletion: {
                        note: null,
                        opened: false,
                        rootNoteId: null,
                    },
                },
                users: [],
                CommentType,
            };
        },

        computed: {
            commentsFilter() {
                return this.filterValues.noteType?.[0] || null;
            },

            isNoteFilter() {
                return this.commentsFilter === CommentType.STRING;
            },

            isAttachmentFilter() {
                return this.commentsFilter === CommentType.ATTACHMENT;
            },

            isCaseFilter() {
                return this.commentsFilter === CommentType.CASE;
            },

            isAudioFilter() {
                return this.commentsFilter === CommentType.AUDIO;
            },

            hasFiltersApplied() {
                return this.commentsFilter !== null;
            },

            caseEnabled() {
                return this.account.isPolestar();
            },

            emptyStateDescription() {
                if (this.hasFiltersApplied) {
                    return this.$t('clientCard.emptyState.noteNoMatchDescription');
                }

                return this.$t('clientCard.emptyState.noteDescription');
            },

            canLoadMoreNotes() {
                return (this.pagination.perPage * this.pagination.currentPage) < this.pagination.totalItemsCount;
            },

            confirmDeletionMessage() {
                const { note } = this.modals.deletion;

                if (!note) {
                    return '';
                }

                const childCount = note.child_count;

                if (childCount === 1) {
                    return this.$t('clientCard.boxes.notes.deleteConfirmationSingleChild');
                }

                if (childCount > 1) {
                    return this.$t('clientCard.boxes.notes.deleteConfirmationMultipleChildren', [childCount]);
                }

                return this.$t('clientCard.noteDeleteMessage');
            },

            contextRootNoteId() {
                return this.contextNoteData.rootNoteId;
            },

            isAtFirstPage() {
                return this.pagination.currentPage === 1;
            },

            isLoading() {
                return !this.initialFetchCompleted || !this.users.length;
            },

            normalizedNotes() {
                return this.notes.map(parentNote => {
                    if (!parentNote.children?.length) {
                        return new Comment({ ...parentNote });
                    }

                    const children = parentNote.children
                        .map(childNote => this.flattenChildNotes(childNote, parentNote))
                        .flat(Infinity)
                        .sort((a, b) => a.created_at.localeCompare(b.created_at));

                    return new Comment({
                        ...parentNote,
                        child_count: children.length,
                        children,
                    });
                });
            },

            noteAction() {
                return this.contextNoteData.action || NoteAction.CREATE;
            },

            showMoreItemsLoading() {
                return !this.isLoading &&
                    (
                        this.canLoadMoreNotes ||
                        (this.$wait.is('fetching.notes') && !this.isAtFirstPage)
                    );
            },
        },

        watch: {
            'account.id': {
                immediate: true,
                async handler() {
                    this.users = await this.account.getUsers([
                        'active',
                        'first_name',
                        'hide_in_user_select',
                        'last_name',
                        'post_id',
                        'role_id',
                    ]);
                },
            },
        },

        methods: {
            updateFilter(typeId) {
                this.applyFilters({ noteType: [typeId] });
            },

            clearContextNote() {
                this.contextNoteData.action = null;
                this.contextNoteData.note = null;
                this.contextNoteData.rootNoteId = null;
            },

            onDeleteNote() {
                const { note, rootNoteId } = this.modals.deletion;

                this.resetDeleteConfirmationModal();

                this.deleteNote({ note, rootNoteId });

                this.clearContextNote();
            },

            flattenChildNotes(note, parentNote) {
                const parentNoteData = {
                    content: parentNote.content,
                    created_at: parentNote.created_at,
                    id: parentNote.id,
                    user_id: parentNote.user_id,
                };

                if (!note.children?.length) {
                    return {
                        ...note,
                        child_count: 0,
                        parent: parentNoteData,
                    };
                }

                const children = note.children.map(childNote => this.flattenChildNotes(childNote, note)).flat(Infinity);

                return [
                    {
                        ...note,
                        child_count: children.length,
                        children: [],
                        parent: parentNoteData,
                    },
                    ...children,
                ];
            },

            infiniteLoadingHandler() {
                if (!this.canLoadMoreNotes || this.$wait.is('fetching.notes')) {
                    return;
                }

                const { clientHeight, scrollHeight, scrollTop } = this.$refs.listWrapper;

                if (scrollTop + clientHeight >= scrollHeight - 100) {
                    this.fetchNextPage();
                }
            },

            initContextNote(note, rootNoteId, action) {
                this.contextNoteData.note = note;
                this.contextNoteData.rootNoteId = rootNoteId;
                this.contextNoteData.action = action;

                this.$refs.noteInput.focus();
            },

            onSubmit(note) {
                if (note.key) {
                    this.addAudioNote({ note, rootNoteId: this.contextRootNoteId });
                } else if (note.id) {
                    this.updateNote({ note, rootNoteId: this.contextRootNoteId });
                } else {
                    this.addNote({ note, rootNoteId: this.contextRootNoteId });
                }

                this.clearContextNote();
            },

            resetDeleteConfirmationModal() {
                this.modals.deletion.note = null;
                this.modals.deletion.rootNoteId = null;
            },

            triggerDeleting({ note, rootNoteId }) {
                this.modals.deletion.note = note;
                this.modals.deletion.rootNoteId = rootNoteId;
                this.modals.deletion.opened = true;
            },

            triggerEditing({ note, rootNoteId }) {
                this.initContextNote(note, rootNoteId, NoteAction.EDIT);
            },

            triggerReplying({ note, rootNoteId }) {
                this.initContextNote(note, rootNoteId, NoteAction.REPLY);
            },
        },

        mounted() {
            this.$refs.listWrapper.addEventListener('scroll', this.infiniteLoadingHandler);

            this.resetPagination();
            this.fetchCurrentPage(true);
        },

        beforeDestroy() {
            this.$refs.listWrapper.removeEventListener('scroll', this.infiniteLoadingHandler);
        },
    };
</script>
