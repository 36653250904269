<template>
    <activix-confirm-modal
        :opened="opened"
        :title="$t('modal.overlappingTaskEventTitle')"
        @approve="approved"
        @deny="deny"
        @close="close"
    >
        <div slot="content">
            <div class="flex flex-col items-center">
                <div class="my-4 space-y-2 divide-y divide-gray-300">
                    <p
                        class="first:pt-0 pt-2"
                        :key="index"
                        v-html="event"
                        v-for="(event, index) in overlappingEventModalContentEvents"
                    />
                </div>
                <div>
                    {{ $t('modal.overlappingTaskEventConfirmation') }}
                </div>
            </div>
        </div>
    </activix-confirm-modal>
</template>

<script>

    import ActivixDate from '@/value-objects/ActivixDate.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: false,
            },
            overlappingEvents: {
                type: Array,
                required: true,
            },
        },

        computed: {
            overlappingEventModalContentEvents() {
                return this.overlappingEvents.map(event => {
                    const startAt = new ActivixDate(event.start_at).toHumanShort();
                    const endAt = new ActivixDate(event.end_at).toHumanShort();
                    return this.$t('modal.overlappingTaskEventList', [event.title, startAt, endAt]);
                });
            },
        },

        methods: {
            approved() {
                this.$emit('approve');
            },

            close() {
                this.$emit('update:opened', false);
                this.$emit('close');
            },

            deny() {
                this.$emit('deny');
            },
        },
    };
</script>
