import BaseModel from '@/entities/BaseModel.ts';
import Suppliable from '@/entities/Suppliable.ts';

export default class Supplier extends BaseModel {
    company: string | null = null;

    display_name: string | null = null;

    exported_on?: string | null = null;

    id: number | null = null;

    import_response?: string | null = null;

    name: string | null = null;

    private _suppliable?: Suppliable;

    private _exported?: boolean = false;

    private _imported?: boolean = false;

    private _related_id: string | null = null;

    private _status?: string | null = null;

    constructor(initialValues: Partial<Supplier> = {}) {
        super();
        this.init(initialValues);
    }

    get pivot(): Suppliable | undefined {
        return this._suppliable;
    }

    set pivot(pivot: Partial<Suppliable>) {
        this._suppliable = new Suppliable(pivot);
    }

    get state(): Suppliable | undefined {
        return this._suppliable;
    }

    set state(state: Partial<Suppliable>) {
        this._suppliable = new Suppliable(state);
    }

    get suppliable(): Suppliable | undefined {
        return this._suppliable;
    }

    get exported(): boolean {
        return this._exported || this.suppliable?.exported || false;
    }

    set exported(exported: boolean) {
        this._exported = exported;
    }

    get imported(): boolean {
        return this._imported || this.suppliable?.imported || false;
    }

    set imported(imported: boolean) {
        this._imported = imported;
    }

    get related_id(): string | null {
        return this._related_id || this.suppliable?.related_id || null;
    }

    set related_id(relatedId: string | null) {
        this._related_id = relatedId;
    }

    get status(): string | null {
        return this._status || this.suppliable?.status || null;
    }

    set status(status: string | null) {
        this._status = status;
    }
}
