import TimeZone from '@/components/timezone/TimeZone.ts';
import { useGlobalStore } from '@/store/store.js';
import Lead from '@/entities/Lead.js';

export const useTimeZoneWarning = () => {
    const canShow = (lead: Lead): boolean => {
        if (!lead?.id) {
            return false;
        }

        const leadTimeZone = lead.account?.timezone;

        if (!leadTimeZone) {
            return true; // we want timezone warning to still render, but in 'danger' mode
        }

        const userTimeZone = useGlobalStore().parentAuthUser.timezone;

        const userTimezoneOffset = TimeZone.offset(userTimeZone);
        const leadTimezoneOffset = TimeZone.offset(leadTimeZone);

        return userTimezoneOffset !== leadTimezoneOffset;
    };

    return {
        canShow,
    };
};
