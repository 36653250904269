<template>
    <activix-modal
        :size="isExternalCall ? 'md' : 'sm'"
        :name="name"
        :portal="portal"
        :loading="loading"
        :loading-overlay="false"
        ref="modal"
        @closed="onClosed"
        @before-open="onBeforeOpen"
    >
        <template slot="header">
            <h4 class="modal-title" v-if="isExternalCall">
                {{ $t('call.contactInfo') }}
            </h4>
            <h4 class="modal-title" v-else>
                {{ callInProgress ? $t('call.callInProgress') : $t('call.generate') }}
            </h4>
        </template>

        <template slot="body">
            <div class="text-center">
                <next-automation-alert :lead="lead" />

                <communicating-alert
                    :lead="lead"
                    :communicating-type="communicatingType"
                    :communicating-user="communicatingUser"
                    :recent-communication="recentCommunication"
                    :most-recent-communication="mostRecentCommunication"
                    @dismiss="dismiss"
                    @close="close"
                />

                <call-lead-external
                    :lead-phone-options="leadPhoneOptions"
                    :communicating="!!communicatingLead || checkingCommunicating"
                    :call-in-progress="callInProgress"
                    :loading="loading"
                    :recording-consent-script="callRecordingConsentScript"
                    v-if="isExternalCall"
                />

                <call-lead-internal
                    :lead-phone-options="leadPhoneOptions"
                    :user-phone-options="userPhoneOptions"
                    :lead-phone.sync="leadPhone"
                    :user-phone.sync="userPhone"
                    :communicating="!!communicatingLead || checkingCommunicating"
                    :call-in-progress="callInProgress"
                    :loading="loading"
                    :lead="lead"
                    :recording-consent-script="callRecordingConsentScript"
                    v-else
                />

                <div v-if="callInProgress">
                    <router-link :to="{ name: 'leads.update', params: { id: lead.id } }" target="_blank">
                        {{ $t('general.goToClientCard') }}
                    </router-link>
                </div>
            </div>
        </template>

        <template slot="footer" v-if="!isExternalCall">
            <div class="flex items-center justify-center">
                <activix-button @click="close">
                    {{ $t('general.cancel') }}
                </activix-button>

                <activix-button
                    type="primary"
                    :disabled="disabled"
                    @click="makeCall"
                >
                    {{ $t('client.call') }}
                </activix-button>

                <div class="absolute right-0 mr-6" v-if="currentUser.block_lead_info && showPhoneCountDown > 0">
                    <button class="link-grey" @click="showPhone = true" v-if="!showPhone">
                        <icon name="regular/phone" class="cursor-pointer" />
                    </button>
                    <span class="text-grey-500" v-else>
                        {{ showPhoneCountDown }}
                    </span>
                </div>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useLeadStore } from '@/store/modules/lead/store.js';
    import { useContextStore } from '@/store/modules/context/store.js';
    import { useGlobalStore } from '@/store/store.js';

    import { showWarning } from '@/utils/toastr.js';

    import CallLeadExternal from '@/components/modals/CallLeadExternal.vue';
    import CallLeadInternal from '@/components/modals/CallLeadInternal.vue';
    import CommunicatingAlert from '@/components/CommunicatingAlert.vue';
    import NextAutomationAlert from '@/components/lead/NextAutomationAlert.vue';

    import Lead from '@/entities/Lead.js';
    import PhoneProvider from '@/entities/PhoneProvider.js';
    import User from '@/entities/User.js';

    export default {
        components: {
            CallLeadExternal,
            CallLeadInternal,
            CommunicatingAlert,
            NextAutomationAlert,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
            portal: {
                type: String,
                default: 'modal-1',
            },
        },

        data() {
            return {
                recentCommunication: null,
                mostRecentCommunication: null,
                checkingCommunicating: false,
                processing: false,
                showPhone: false,
                showPhoneCountDown: 5,
                leadPhone: null,
                userPhone: null,
                callInProgress: false,
                loading: true,
                leadId: null,
                lead: new Lead(),
                userPhones: [],
                currentUser: new User(),
            };
        },

        computed: {
            ...mapState(useGlobalStore, [
                'authUser',
                'parentAuthUser',
                'specificPhoneToCall',
                'taskEventToBeCompleted',
            ]),
            ...mapState(useContextStore, ['account']),
            ...mapState(useLeadStore, ['communicatingLead']),

            isExternalCall() {
                if (PhoneProvider.REQUIRES_EXTENSION.includes(this.account.phone_provider)) {
                    return this.isExtensionDectected;
                }

                return PhoneProvider.EXTERNAL_PROVIDERS.includes(this.account.phone_provider);
            },

            isExtensionDectected() {
                return !!this.$chromeExtensions.length;
            },

            userPhoneOptions() {
                return this.userPhones.map(phone => ({
                    id: phone.id,
                    type: phone.cellphone ? 'cell' : 'work',
                    value: phone.number,
                    label: this.stringifyPhone(phone.number),
                    extension: phone.extension,
                }));
            },

            leadPhoneOptions() {
                return this.lead.getUniquePhones().map(phone => ({
                    id: phone.id,
                    lead_id: phone.lead_id,
                    type: phone.type,
                    value: phone.phone,
                    label: this.stringifyPhone(phone.phone, true),
                    extension: phone.extension,
                }));
            },

            leadPhoneType() {
                return this.leadPhoneOptions.find(phone => phone.id === this.leadPhone)?.type || null;
            },

            callDisabled() {
                return !!(
                    this.lead.unsubscribe_call ||
                    this.lead.unsubscribe_all_date ||
                    this.processing ||
                    !!this.communicatingLead ||
                    this.mostRecentCommunication ||
                    this.callInProgress ||
                    this.loading ||
                    !this.leadPhone ||
                    !this.userPhone
                );
            },

            callRecordingConsentScript() {
                return this.account.call_consent_script || '';
            },

            accountHasSupportedPhoneProvider() {
                return PhoneProvider.INTEGRATED_PROVIDERS.includes(this.account.phone_provider);
            },

            communicatingType() {
                return this.communicatingLead?.communication_method_id;
            },

            communicatingUser() {
                return this.communicatingLead?.communication_user?.id;
            },

            disabled() {
                return this.callDisabled || (!this.accountHasSupportedPhoneProvider && !this.accountHasSupplierPhoneProvider);
            },

            accountHasSupplierPhoneProvider() {
                return PhoneProvider.SUPPLIER_PROVIDERS.includes(this.account.phone_provider);
            },
        },

        watch: {
            specificPhoneToCall() {
                this.setLeadPhoneType();
            },

            showPhone() {
                if (!this.showPhone) {
                    this.showPhoneCountDown = 5;
                } else {
                    this.reduceCountDown();
                }
            },
        },

        methods: {
            ...mapActions(useGlobalStore, [
                'fetchLead',
                'updateLeadAction',
                'taskEventUpdated',
                'appendNewError',
                'setSpecificPhoneToCall',
                'setTaskEventToBeCompleted',
            ]),
            ...mapActions(useLeadStore, ['checkCommunicating']),

            stringifyPhone(phone, block = false) {
                const formattedPhone = this.formatPhone(phone);

                if (block && this.currentUser.block_lead_info && this.showPhoneCountDown > 0) {
                    return `${formattedPhone.slice(0, -4)}XXXX`;
                }

                return formattedPhone;
            },

            reduceCountDown() {
                this.showPhoneCountDown--;

                if (this.showPhoneCountDown > 0) {
                    setTimeout(() => {
                        this.reduceCountDown();
                    }, 1000);
                }
            },

            async makeCall() {
                if (this.callDisabled) {
                    return;
                }

                this.processing = true;

                const payload = {
                    phoneType: this.leadPhoneType,
                    userPhone: this.userPhone,
                };

                try {
                    if (this.accountHasSupplierPhoneProvider) {
                        await this.$axios.post(`v1/ringcentral/ringout/${this.lead.id}`, payload);
                    } else {
                        await this.$axios.post(`v1/voice/outbound/${this.lead.id}`, payload);
                    }

                    // Complete taskEvent if necessary
                    if (this.taskEventToBeCompleted) {
                        this.$axios.post(`v1/reminder/done/${this.taskEventToBeCompleted}`).then(({ data }) => {
                            if (!data.success) {
                                return;
                            }

                            this.taskEventUpdated(data.taskEvent);
                        });
                    }

                    // Show link to client card
                    if (empty(this.$route) || this.$route.name !== 'leads.update') {
                        this.callInProgress = true;
                    } else {
                        this.callInProgress = false;
                        this.$notify.success(this.$t('call.callInProgress'));
                        this.close();
                    }

                    this.close();
                } catch (error) {
                    if (error.response) {
                        switch (error.response.status) {
                            case 403:
                                showWarning(this.$t('call.cantMakeCall'));
                                this.processing = false;
                                return;

                            case 404:
                                showWarning();
                                this.processing = false;
                                return;
                        }
                    }

                    this.appendNewError({
                        code: '0098',
                        display: true,
                        error,
                        payload,
                    });
                }

                this.processing = false;
            },

            checkOldCommunication() {
                if (!this.$refs.modal?.exists) {
                    return;
                }

                this.$axios.get(`v1/leadxpress/check-old-communication/${this.lead.id}`).then(response => {
                    if (empty(response.data.mostRecentCommunication)) {
                        return;
                    }

                    this.mostRecentCommunication = response.data.mostRecentCommunication;
                });
            },

            dismiss() {
                this.mostRecentCommunication = null;
            },

            setLeadPhoneType() {
                if (this.specificPhoneToCall) {
                    this.lead.lead_phones.forEach(leadPhone => {
                        if (this.specificPhoneToCall.id === leadPhone.id) {
                            this.leadPhone = leadPhone.id;
                        }
                    });
                } else if (this.lead.lead_phones.length === 1) {
                    this.leadPhone = this.lead.lead_phones[0].id;
                } else {
                    this.leadPhone = null;
                }
            },

            setUserPhone() {
                const phone = this.userPhones.find(p => p.preferred) || this.userPhones[0];

                this.userPhone = phone?.id;
            },

            async triggerCheckCommunicating() {
                this.checkingCommunicating = true;

                await this.checkCommunicating(this.lead.id);

                this.checkingCommunicating = false;
            },

            close() {
                this.$refs.modal.hide();
            },

            onOpen() {
                this.triggerCheckCommunicating();
                this.checkOldCommunication();
                this.setLeadPhoneType();
                this.setUserPhone();
            },

            onClosed() {
                this.leadId = null;
                this.lead = new Lead();
                this.currentUser = new User();
                this.callInProgress = false;
                this.showPhone = false;
                this.loading = true;
                this.recentCommunication = null;
                this.mostRecentCommunication = null;
                this.checkingCommunicating = false;
                this.setSpecificPhoneToCall(null);

                if (this.taskEventToBeCompleted) {
                    this.setTaskEventToBeCompleted(null);
                }
            },

            async onBeforeOpen(leadId) {
                this.leadId = leadId;

                this.lead = await this.fetchLead(leadId);
                this.currentUser = (await this.parentAuthUser.childUserForAccount(this.lead.account_id)) || this.authUser;
                this.userPhones = await this.currentUser.phones;

                this.onOpen();
                this.loading = false;
            },
        },
    };
</script>
