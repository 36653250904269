import { computed, ComputedRef } from 'vue';

import i18n from '@/plugins/vue-i18n.js';
import { useEventBus } from '@/hooks/useEventBus.ts';
import VueI18n from 'vue-i18n';

interface I18n {
    isFrLocale: ComputedRef<boolean>,
    isEnLocale: ComputedRef<boolean>,
    locale: ComputedRef<string>;
    t(key: VueI18n.Path, values?: VueI18n.Values, localeOverride?: VueI18n.Locale): string;
    tc(key: VueI18n.Path, choice?: VueI18n.Choice, values?: VueI18n.Values): string;
    toggleLocale: () => void;
    n(value: number, key?: VueI18n.Path, locale?: VueI18n.Locale): string;
}

export const useI18n = (): I18n => {
    const { emit } = useEventBus();

    const locale = computed(() => i18n.locale);

    return {
        locale,
        isFrLocale: computed(() => locale.value === 'fr'),
        isEnLocale: computed(() => locale.value === 'en'),
        t: (key: VueI18n.Path, values?: VueI18n.Values, localeOverride?: VueI18n.Locale): string => {
            if (localeOverride) {
                return String(i18n.t(key, localeOverride, values));
            }

            return String(i18n.t(key, values));
        },
        tc: (key: VueI18n.Path, choice?: VueI18n.Choice, values?: VueI18n.Values): string => String(i18n.tc(key, choice, values)),
        toggleLocale: (): void => emit('set-locale', (locale.value === 'fr' ? 'en' : 'fr')),
        n: (value: number, key?: VueI18n.Path, locale?: VueI18n.Locale): string => String(i18n.n(value, key, locale)),
    };
};
