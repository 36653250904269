<template>
    <div class="flex border-1 bg-white justify-between">
        <button-icon-popover icon-name="bold/filter-2" :icon-class-name="numberOfFiltersApplied ? 'text-primary' : ''" class="z-20">
            <template #popoverContent="{ closePopover }">
                <filters
                    class="max-h-128 overflow-y-auto"
                    :filters="availableFilters"
                    :order-by-direction="orderByDirection"
                    :order-by-field="orderByField"
                    :order-by-direction-options="orderByDirectionOptions"
                    :order-by-field-options="orderByFieldOptions"
                    @apply="applyFilters($event, closePopover)"
                    @reset="resetFilters(closePopover)"
                />
            </template>
            <template #after-icon>
                <span class="mr-1" v-text="$t('inventory.filter')" />
                <span
                    class="rounded-full text-center bg-primary px-2"
                    v-text="numberOfFiltersApplied"
                    v-if="numberOfFiltersApplied"
                />
            </template>
        </button-icon-popover>
    </div>
</template>

<script>
    import Filters from '@/components/container/Filters.vue';
    import ButtonIconPopover from '@/components/presentational/ButtonIconPopover.vue';

    export default {
        components: { ButtonIconPopover, Filters },

        props: {
            inventoryFilters: {
                type: Object,
                default: () => {
                },
            },
            orderByDirection: {
                type: String,
                default: '',
            },
            orderByField: {
                type: String,
                default: '',
            },
            numberOfFiltersApplied: {
                type: Number,
                default: null,
            },
        },

        computed: {
            availableFilters() {
                return Object.fromEntries(
                    Object.entries(this.inventoryFilters).filter(([, filter]) => filter.visible),
                );
            },

            availableFiltersForSorting() {
                return Object.fromEntries(
                    Object.entries(this.availableFilters).filter(([, filter]) => filter.sortable),
                );
            },

            orderByFieldOptions() {
                const orderingOptions = [];
                Object.keys(this.availableFiltersForSorting).forEach(key => {
                    orderingOptions.push({ value: key, text: this.availableFiltersForSorting[key].label });
                });
                return orderingOptions;
            },
            orderByDirectionOptions() {
                return [
                    { value: 'asc', text: this.$t('inventory.orderBy.ascending') },
                    { value: 'desc', text: this.$t('inventory.orderBy.descending') },
                ];
            },
        },

        methods: {
            applyFilters({ filters, orderByDirection, orderByField }, closeFilterMenu) {
                closeFilterMenu();
                this.$emit('apply', { orderByDirection, filters, orderByField });
            },

            resetFilters(closeFilterMenu) {
                closeFilterMenu();
                this.$emit('reset');
            },
        },
    };
</script>
