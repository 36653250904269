export default {
    alertTitle: 'Alerte',
    automation: 'Automatisation',
    byUser: 'Par',
    campaign: 'Campagne',
    communicationTitle: 'Communication',
    dealer: 'Concessionnaire',
    eventTitle: 'Événement',
    inTurn: 'Tour de rôle',
    lead: 'Lead',
    markAllAsRead: 'Marquer tous comme lus',
    noAlerts: "Aucune notification d'alerte",
    noCommunications: 'Aucune notification de communication',
    noEvents: "Aucune notification d'événement",
    to: 'à',
    unknown: 'Inconnu',
    viewMore: 'Afficher plus...',
    advisor: 'Conseiller',

    communication: {
        email: 'Nouveau courriel',
        emailRead: 'Votre courriel a été lu',
        newComment: "Quelqu'un vous a laissé un commentaire",
        newCommentAssignedLead: 'Commentaire sur un lead assigné',
        niotextIncoming: 'SMS entrant',
        replyComment: 'Réponse à votre commentaire',
        replyCommentSent: 'Répondu',
    },
    event: {
        appointment: 'Rendez-vous',
        delivery: '@.capitalize:lexicon.delivery.singular',
        other: 'Autre',
        phoneAppointment: 'Rendez-vous Téléphonique',
        testDrive: 'Essai routier',
        virtualAppointment: 'Rendez-vous virtuel',
    },
    general: {
        renewalAlertEquity: 'Équité positive',
        renewalAlertEquityDetails: 'lead en équité positive | leads en équité positive',
        renewalAlertEndLocation: 'Fin de location',
        renewalAlertEndLocationDetails: 'lead en fin de location dans {delay} mois | leads en fin de location dans {delay} mois',
        renewalAlertEndWarranty: 'Fin de garantie',
        renewalAlertEndWarrantyDetails: 'lead en fin de garantie dans {delay} mois | leads en fin de garantie dans {delay} mois',
        renewalAlertExcessMileage: 'Dépassement de l\'odo. sous garantie',
        appointmentCanceled: 'Rendez-vous annulé',
        appointmentNoShow: 'Rendez-vous non présenté',
        appointmentConfirmed: 'Rendez-vous confirmé',
        appointmentStatusUpdated: 'Rendez-vous présenté',
        assignedLeadUpdated: 'Votre client a été modifié',
        assignedLeadVehicleCreated: '{shortUsername} a créé un véhicule {vehicleType}',
        assignedLeadVehicleUpdated: '{shortUsername} a modifié un véhicule {vehicleType}',
        automationCompleted: 'Automatisation complétée',
        automationNotification: 'Notification Automatisé',
        communicationBlockedCommunicating: '{0} non envoyé. Déjà en communication',
        communicationBlockedCantContact: 'Vous n\'avez pas accès à envoyer un {0}',
        declinedLeadDemand: 'Réassignation refusée',
        deliveryStatusUpdated: '@.capitalize:lexicon.delivery.singular complétée',
        eventBadService: 'Mauvais service pour un lead événement',
        eventCanceledAppointment: 'RV annulé pour un lead événement',
        eventConfirmedAppointment: 'RV confirmé pour un lead événement',
        eventDatesUpdated: 'Un événement a été modifié',
        eventLeadToReach: 'Lead événement à rejoindre',
        eventNewAppointment: 'Nouveau RV pour un lead événement',
        eventNewPhoneAppointment: 'Nouveau RV tél. pour un lead événement',
        eventSent: 'Rendez-vous assigné',
        feedbackCalculator: "Retour d'évaluation pour {vehicle}.",
        invite: 'Invitation à un événement',
        leadAddedToSalesJourney: 'Nouveau lead dans le parcours de vente',
        leadAppt: 'Nouveau rendez-vous',
        leadAssigned: 'Un lead vous a été assigné',
        leadAssignedWebOrder: 'Un lead "Commande web" vous a été assigné',
        leadAssignedSent: 'Lead assigné',
        leadInvalid: 'Lead invalide',
        leadLost: 'Lead perdu',
        leadNoDivision: 'Lead sans division',
        leadNoPhone: 'Lead sans téléphone valide',
        leadSeparatedFromSalesJourney: 'Lead séparé d\'un parcours de vente',
        assignLeadDemand: 'Demande de réassignation',
        leadSale: 'Nouvelle @:lexicon.sale.singular',
        leadsAssigned: '{leadCount} leads vous ont été assignés',
        newCommentSent: 'Note',
        newLead: 'Nouveau lead',
        newLeadDuplicate: 'Nouveau lead doublon',
        newLeadWebOrder: 'Nouveau lead "Commande web"',
        newLeadLoyalty: 'Nouveau lead loyauté',
        newLeadLoyaltyDuplicate: 'Doublon lead loyauté',
        newPhoneAppointment: 'Nouveau rendez-vous téléphonique',
        noReplyLead: 'Lead en attente',
        noReplyLeadDuplicate: 'Lead doublon en attente',
        noReplyLeadWebOrder: 'Lead "Commande web" en attente',
        phoneAppointmentCanceled: 'Rendez-vous téléphonique annulé',
        phoneAppointmentConfirmed: 'Rendez-vous téléphonique confirmé',
        replyCommentSent: 'Répondu',
        serviceMyClientsAppointments: 'Rendez-vous service pour un de vos clients',
        serviceMyClientsVehiclePickUp: 'Ramassage prévue pour votre lead service',
        serviceVehiclePickUp: 'Ramassage prévue pour un lead service',
        taskAssigned: 'Une tâche vous a été assignée',
        taskDatesUpdated: 'Une tâche a été modifiée',
        taskStatusUpdated: 'Tâche complétée',
        taskSent: 'Tâche assignée',
        taskDeleted: 'Une tâche a été supprimé',
        taskEventUnassigned: 'Tâche/évènement non assigné',
        timeLimitToAnswer: 'Vous avez {timeLimitToAnswer} pour contacter le lead avant d\'être désassigné',
        unassignedFromLead: 'Désassigné du lead car vous n\'avez pas répondu dans le temps limite',
        returnToMarket: 'Ce lead est de retour sur le marché',
        wantedVehicleCreated: 'Nouveau véhicule recherché ajouté',
        exportSuccessTitle: 'Export de l\'offre à {0}',
        exportQuoteSuccessTitle: 'Export de la soumission à {0}',
        exportFailureTitle: 'Échec de l\'export à {0}',
        exportQuoteFailureTitle: 'Échec de l\'export à {0}',
        exportSuccessText: 'Lead {fullName} exporté avec succès',
        exportFailureText: 'L\'export du lead {fullName} à échoué',

        emailRead: 'Votre courriel a été lu', // @TODO Delete after UI Release
        newComment: "Quelqu'un vous a laissé un commentaire", // @TODO Delete after UI Release
        newCommentAssignedLead: 'Commentaire sur un lead assigné', // @TODO Delete after UI Release
        replyComment: 'Réponse à votre commentaire', // @TODO Delete after UI Release
        lead_vehicle_type_exchange: '@.lower:database.lead_vehicle_type_exchange',
        lead_vehicle_type_wanted: '@.lower:database.lead_vehicle_type_wanted',
    },
    task: {
        call: 'Appel',
        csi: 'CSI',
        email: 'Courriel',
        sms: 'SMS',
    },
};
