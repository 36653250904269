<template>
    <div id="app" class="h-full w-full">
        <transition name="fade" v-if="(showLoading || contextLoading) && !maintenanceMode && !authUserSuspended">
            <div class="z-10 absolute h-screen w-screen flex items-center justify-center" key="spinner">
                <activix-spinner :message="$t('general.loading')" />
            </div>
        </transition>

        <transition appear name="fade">
            <maintenance-mode v-if="maintenanceMode" />
            <suspended-access v-else-if="authUserSuspended" />
            <component
                :is="layout"
                :context-loading="contextLoading"
                :show-loading.sync="showLoading"
                v-else
            />
        </transition>

        <!-- Generic portals -->
        <portal-target name="fullscreen-modal" />
        <portal-target name="modal-0" />
        <portal-target name="modal-1" />
        <portal-target name="modal-2" />
        <portal-target name="modal-3" />
        <portal-target name="modal-4" />

        <!-- Specific portals -->
        <portal-target name="modal-event" />
        <portal-target name="modal-event-sub" />
        <portal-target name="modal-select-picker" v-if="lgLayout" />

        <!-- Notifications -->
        <notifications />

        <!-- Screen Pop -->
        <screen-pop-container />
    </div>
</template>

<script>
    import * as Sentry from '@sentry/vue';

    // Utils
    import { isEmpty } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import { localization } from '@autosync/atx-activix-ui';

    // Plugins
    import SentryClient from './plugins/sentry.js';
    import IntercomClient from './plugins/intercom.js';
    import moment from './plugins/moment.js';

    // Layouts
    import AuthLayout from './layouts/Auth.vue';
    import LandingLayout from './layouts/Landing.vue';
    import DefaultLayout from './layouts/Default.vue';
    import SplashscreenLayout from './layouts/Splashscreen.vue';

    // Components
    import MaintenanceMode from './components/MaintenanceMode.vue';
    import Notifications from './components/Notifications.vue';
    import SuspendedAccess from './components/SuspendedAccess.vue';
    import ScreenPopContainer from './components/ScreenPopContainer.vue';

    // Pinia
    import { useContextStore } from './store/modules/context/store.js';
    import { useGlobalStore } from './store/store.js';

    export default {
        name: 'App',

        components: {
            AuthLayout,
            DefaultLayout,
            LandingLayout,
            MaintenanceMode,
            Notifications,
            SplashscreenLayout,
            SuspendedAccess,
            ScreenPopContainer,
        },

        metaInfo() {
            return {
                meta: [{ name: 'description', content: this.$t('meta.description') }],
                titleTemplate: titleChunk => {
                    return titleChunk || 'Activix CRM';
                },
            };
        },

        data() {
            return {
                maintenanceMode: false,
                loaded: false,
                showLoading: true,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs', 'errors']),
            ...mapState(useContextStore, { contextAccount: 'account' }),

            parentAuthUser() {
                return this.$auth.user();
            },

            isLoggedIn() {
                return this.$auth.check() && !isEmpty(this.parentAuthUser);
            },

            contextLoading() {
                return this.$wait.is([
                    'fetching.contextAccount',
                ]);
            },

            authUserSuspended() {
                if (!this.isLoggedIn) {
                    return false;
                }

                return this.parentAuthUser.suspended || this.authUser.suspended;
            },

            layout() {
                if (this.$route.meta.landing) {
                    return 'landing-layout';
                }

                if (this.$route.meta.splashscreen) {
                    return 'splashscreen-layout';
                }

                if (this.$route.meta.auth == false) {
                    return 'auth-layout';
                }

                return this.isLoggedIn ? 'default-layout' : 'auth-layout';
            },
        },

        watch: {
            errors: {
                deep: true,
                handler(newValue) {
                    if (newValue.length >= 10) {
                        this.sendErrors();
                    }
                },
            },

            parentAuthUser(newUser) {
                const parentAuthUser = isEmpty(newUser) ? null : newUser;

                this.setParentAuthUser(parentAuthUser);

                SentryClient.setAuthUser(parentAuthUser);
                this.$behavior.setAuthUser(parentAuthUser);

                if (!parentAuthUser) {
                    IntercomClient.shutdown();
                }
            },

            async 'parentAuthUser.id'(newUserId, oldUserId) {
                if (newUserId == oldUserId) {
                    return;
                }

                if (newUserId) {
                    await this.init([this.setLocale(), this.fetchConfigs()]);
                }

                this.$broadcasting.userChanged(newUserId, oldUserId);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLocale', 'setParentAuthUser', 'resetErrors', 'fetchConfigs', 'fetchFeaturePreviews']),

            sendErrors() {
                Sentry.withScope(scope => {
                    scope.setExtras({
                        infoOnTheError:
                            'This error is sent when 10 requests errors are logged or every 10 minutes if at least 1 request error was logged since the last reporting',
                        errors: this.errors,
                    });
                    Sentry.captureMessage('Multiple errors occured on requests', 'warning');
                });

                this.resetErrors();
            },

            errorTimer() {
                if (this.errors.length >= 1) {
                    this.sendErrors();
                }

                setTimeout(() => {
                    this.errorTimer();
                }, 10 * 60000);
            },

            cleanup() {
                //
            },

            async setLocale(locale) {
                locale = locale || this.parentAuthUser.locale || this.$i18n.locale;
                this.$i18n.locale = locale;
                this.$axios.defaults.headers.common['X-Locale'] = locale;
                this.$axios.defaults.headers.common['Accept-Language'] = locale;
                this.updateLocale(locale);
                moment.locale(locale);
                localization.use(locale);
                localStorage.setItem('currentLocale', locale);
            },

            async init(requests = []) {
                await Promise.all(requests);

                this.loaded = true;
            },

            onSetLocale(locale) {
                if (this.parentAuthUser.id) {
                    this.$api.users.update(this.parentAuthUser.id, {
                        locale,
                    });
                }

                this.init([this.setLocale(locale), this.fetchConfigs()]);
            },

            onSetMaintenanceMode(maintenanceMode) {
                this.maintenanceMode = maintenanceMode;
            },

            onLogout() {
                if (this.$auth.impersonating()) {
                    this.$auth.unimpersonate();
                } else {
                    this.$auth.logout();
                }
            },
        },

        created() {
            this.init([this.setLocale(), this.$auth.fetch(), this.fetchFeaturePreviews()]);
            this.errorTimer();
            this.cleanup();

            this.$eventBus.$on('set-locale', this.onSetLocale);
            this.$eventBus.$on('set-maintenance-mode', this.onSetMaintenanceMode);
            this.$eventBus.$on('logout', this.onLogout);

            // Summernote fix for multiple modal
            $(document.body, '.note-modal').on('hidden.bs.modal', () => {
                if ($('.modal:visible').length) {
                    document.body.classList.add('modal-open');
                }
            });
        },

        beforeDestroy() {
            this.$eventBus.$off('set-locale', this.onSetLocale);
            this.$eventBus.$off('set-maintenance-mode', this.onSetMaintenanceMode);
            this.$eventBus.$off('logout', this.onLogout);
            this.$openReplay.stop();
        },
    };
</script>
