import Vue from 'vue';
import useFeature, { setupOptimizely } from './optimizely.js';

Vue.use({
    install(VueInstance) {
        if (this.installed) {
            return;
        }

        this.installed = true;

        if (window.optimizelyDatafile) {
            setupOptimizely(window.optimizelyDatafile);
        }

        VueInstance.prototype.$feature = useFeature;
        VueInstance.feature = useFeature;
    },
});
