export default {
    campaign: 'Campaign',
    createCampaign: 'Create campaign',
    reviewCampaign: 'Review Campaign',
    backToSettings: 'Back to settings',
    backBtn: 'Back',
    chooseOption: 'Choose an option',
    createdSuccessfully: 'Campaign created successfully',
    notCreatedSuccessfully: 'Campaign could not be created',
    confirmCancelCampaignModalTitle: 'Cancel Campaign',
    confirmCancelCampaignModalContent: 'Are you sure you want to cancel this campaign ? Emails will not be sent.',
    confirmCancelCampaignNotification: 'Campaign canceled successfully',
    confirmDeleteCampaignModalTitle: 'Delete Campaign',
    confirmDeleteCampaignModalContent: 'Are you sure you want to delete this campaign ? (this action is irreversible)',
    confirmDeleteCampaignNotification: 'Campaign deleted successfully',
    failedReasons: {
        non_existent_or_unauthorized_user: 'Non-existent or unauthorized user',
        lead_no_email: 'Lead with no email',
        last_outgoing_email_identical: 'Last outgoing email identical',
        casl_expired: 'Customer consent expired',
        do_not_market: 'Lead is listed as do not market',
        unsubscribe_all_date: 'Lead is unsubscribed to all communications',
        communicating_user: 'Communicating user',
        email_communication_disabled: 'Email communication disabled',
        phone_communication_disabled: 'Phone communication disabled',
        sms_communication_disabled: 'SMS communication disabled',
        unknown_reason: 'Unknown reason',
        invalid_email_data: 'Invalid email data',
        sender_not_found: 'Sender not found',
        sender_no_campaign_access: 'Sender has no campaign access',
        email_already_added_to_campaign: 'Email already added to campaign',

    },
    fetchingError: 'Error while fetching the campaigns',
    deleteError: 'Error while deleting the campaign',
    cancelError: 'Error while cancel the campaign',
    massMailing: 'Mass mailing',
    searchPlaceholder: 'Search campaign',
    startingOn: 'Starting on {0} (local time)',
    endsOn: 'Ends on {0}',
    endedOn: 'Completed on {0}',
    noCampaignsFound: 'No campaigns found',
    filters: {
        createdAt: 'Creation date',
        createdBy: 'Created by',
        distributionComplete: 'Distribution completed',
        startDate: 'Start date',
        endDate: 'End date',
        objective: 'Objective',
        status: 'Status',
    },
    trackedStats: {
        createOn: 'Created on: {0}',
        trackingName: 'Tracking',
        sms: 'SMS',
        mailing: 'Email',
        progression: 'Sent to {0} of {1} Targeted Leads',
        viewButton: 'View',
        averageEngagementTime: 'Average Engagement Time',
        send: 'Sent',
        bookedAppointment: 'Booked Appointment',
        bounced: 'Bounced',
        unsubscribed: 'Unsubscribed',
        delivered: 'Delivered',
        opened: 'Opened',
        replied: 'Replied',
        clicked: 'Clicked',
        futureSend: '{0} messages left to send',
        failedEmail: '{0} messages could not be sent',
        lastSendDate: 'Last email will be sent on: {0}',
        deactivatedSend: '{0} messages will not be sent',
        deactivatedTooltip: 'Cancelled campaigns do not send messages',
        cancel: 'Cancel',
        delete: 'Delete',
    },
    progressBar: {
        openRate: 'Open Rate',
        clickThroughRate: 'Click-Through Rate (CTR)',
        conversionRate: 'Conversion Rate',
        bounceRate: 'Bounce Rate',
        unsubscribeRate: 'Unsubscribe Rate',
        spamComplaintRate: 'Spam Complaint Rate',
        replyRate: 'Reply Rate',
        responseRateByCommunicationType: 'Response Rate by Communication Type',
    },
    settings: {
        title: 'Campaign Settings',
        numberLeadsSelected: '{0} leads selected for Campaign',
        buildEmail: 'Compose Email',
        campaignName: 'Campaign Name',
        campaignNameError: 'Should be less then 50 character',
        conversionEvent: 'Select an Objective',
        drippingAndDate: 'Dripping Function and Release Date',
        selectDateAndTime: 'Set Date and Time for Release',
        startDate: 'Start Date',
        timeRelease: 'Time for Release',
        endDate: 'End Date',
        startDateInfo: 'The messages will start to be sent at the selected date and time according to the dealer\'s timezone.',
        endDateInfo: 'The end date is used to know when to stop compiling statistics on the campaign. When using the dripping function, the end date must be after the sending of the last message.',
        setType: 'Set Type',
        oneTimeSend: 'One-time Mass Send',
        drippingSend: 'Dripping Function',
        subsequentEmails: 'Subsequent emails will be sent at the same time.',
        firstDayDispatchCount: 'Initial recipient count',
        initialTooHigh: 'The initial number being greater than or equal to the number of leads, the entirety will be sent at once',
        drippingOption: 'Select One',
        subsequentSendOption: 'Set Subsequent Sends',
        totalTimeOption: 'Set Total Time',
        setFrequency: 'Send Every',
        days: 'days',
        drippingDuration: 'This Campaign would take {0} days to complete',
        numberOfDaysRemaining: 'Send the remaining over',
        moreThanOneDay: 'You have to put at least one day or select the One-time Mass Send dripping function',
        drippingEmailsByDay: 'This campaign would send {0} messages on the first day then {1} per day after that for {2} days.',
        mailingWithoutDripping: 'This campaign will send {0} messages.',
        mailingWithDateWithoutDripping: 'This campaign will send {0} messages on {1} at {2}',
        reviewBtn: 'Review',
    },
    review: {
        reviewTitle: 'Review Campaign',
        reviewSelected: '{0} Leads Selected',
        filters: 'Applied Filters:',
        noFilter: 'No filter applied',
        showAll: 'View All',
        settingsTitle: 'Campaign Settings',
        nameSet: 'Campaign Name',
        statsSet: 'Chosen Objective',
        dateAndTimeRelease: 'Set Date and Time for Release',
        startDate: 'Start Date',
        timeRelease: 'Time for Release',
        endDate: 'End Date',
        drippingFunction: 'Dripping Function',
        initialRecipient: 'Set initial recipient count',
        count: 'Set Count',
        frequency: 'Spread Remaining over',
        days: 'days',
        composerSettings: 'Campaign Composer Settings',
        nameCompoSet: 'Category Name',
        nameTemplate: 'Template Name',
        subjectEn: 'Subject in English',
        subjectFr: 'Subject in French',
        btnLaunch: 'Confirm Launch',
        previewLink: 'Preview Email',
        noTemplate: 'No template use for this campaign',
    },
};
