<template>
    <div class="flex w-full">
        <div class="text-left">
            <div class="status-list" :key="status.name" v-for="status in inventoryStatus">
                <span>{{ $tc('inventory.statistics.' + status.name) ?? status.name }}</span>
            </div>
        </div>
        <div class="text-right ml-3">
            <div class="status-list" :key="status.name" v-for="status in inventoryStatus">
                <span class="font-bold">{{ status.count }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            cards: {
                type: Object,
                default: () => {},
            },
        },

        computed: {
            inventoryStatus() {
                const defaultStatusSupplier = this.getDefaultStatusBySupplier;
                const cardsArray = Object.values(this.cards.inStock);
                const defaultNames = ['inStock', 'unavailable', ...defaultStatusSupplier];

                const inventoryStatus = defaultNames.map((name) => {
                    return {
                        name,
                        count: 0,
                    };
                });

                cardsArray.forEach(status => {
                    if (defaultNames.includes(status.name)) {
                        inventoryStatus.find(item => item.name === status.name).count = status.count;
                    } else if (status.count || defaultStatusSupplier.includes(status.name)) {
                        inventoryStatus.push(status);
                    }
                });

                return inventoryStatus;
            },

            getDefaultStatusBySupplier() {
                if (!this.cards.accountSuppliers) {
                    return [];
                }

                const defaultSupplierStatuses = {
                    easydeal_inventory: ['sold'],
                    serti_inventory: ['reserved'],
                    cdk_inventory: ['invoice', 'hold', 'sold'],
                };

                for (const supplier of this.cards.accountSuppliers) {
                    if (defaultSupplierStatuses.hasOwnProperty(supplier.display_name)) {
                        return defaultSupplierStatuses[supplier.display_name];
                    }
                }

                return [];
            },
        },
    };
</script>
