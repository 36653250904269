import axios from '../plugins/axios.js';

export default {
    async getProfile(payload = {}) {
        const response = await axios.get('v1/auth-user', {
            params: payload,
        });

        return response.data.data;
    },

    async getAbilities(payload = {}) {
        const response = await axios.get('v1/auth-user/abilities', {
            params: payload,
        });

        return response.data.data;
    },

    async getFeaturePreviews(payload = {}) {
        const response = await axios.get('v1/auth-user/feature-previews', {
            params: payload,
        });

        return response.data.data;
    },

    async triggerScreenPop() {
        await axios.get('v1/auth-user/screen-pop');
    },

    async getCalendarViews() {
        const response = await axios.get('v1/auth-user/calendar-views');
        if (response === null) {
            return null;
        }

        return response.data;
    },
};
