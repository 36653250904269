export default {
    call: 'Call',
    email: 'Email',
    appointment: 'Appointment',
    delivery: '@.capitalize:lexicon.delivery.singular',
    other: 'Other',
    csi: 'CSI',
    sms: 'SMS',
    phone_appointment: 'Phone appointment',
    virtual_appointment: 'Virtual appointment',
    test_drive: 'Test drive',

    asAction: {
        call: 'call task',
        email: 'email task',
        appointment: 'appointment',
        delivery: '@:lexicon.delivery.singular',
        other: 'event',
        csi: 'CSI task',
        sms: 'SMS task',
        phone_appointment: 'phone appointment',
        virtual_appointment: 'virtual appointment',
        test_drive: 'test drive',
    },
};
