<template>
    <div class="lead-table-footer | sticky bottom-0 bg-white border-t border-grey-200 p-4 rounded-b z-10 | md:px-6 | print:relative">
        <el-pagination
            :layout="paginationLayout"
            :pager-count="5"
            :background="true"
            :current-page="pagination.currentPage"
            :page-size="parseInt(pagination.perPage, 10)"
            :page-sizes="[10, 25, 50, 100, 200]"
            :total="pagination.total"
            @current-change="updateCurrentPage"
            @size-change="updatePerPage"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import { useDashboardStore } from '@/store/modules/dashboard/store.js';
    import { useGlobalStore } from '@/store/store.js';
    import Pagination from '../../mixins/Pagination.js';

    export default {
        mixins: [Pagination],

        computed: {
            ...mapState(useDashboardStore, {
                pagination: 'pagination',
                dashboardType: 'dashboardType',
            }),
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateSessionAction']),
            ...mapActions(useDashboardStore, ['updatePagination']),

            updatePerPage(perPage) {
                this.updatePagination('perPage', perPage);
                this.$eventBus.$emit('update-inventory-pagination', { page: 1, perPage });

                if (perPage <= 50) {
                    this.updateSessionAction('per_page', perPage);
                }
            },

            updateCurrentPage(page) {
                this.updatePagination('currentPage', page);
                this.$eventBus.$emit('update-inventory-pagination', { page });
            },
        },
    };
</script>

<style lang="less">
.el-pagination button.btn-next {
    margin-right: 0px !important;
}
</style>
