export default {
    acura_canada: 'Acura Canada',
    activix: 'Activix CRM',
    api: 'API',
    auto_renewal: 'Automatic @:lexicon.portfolio.singular',
    bmw_canada: 'BMW Canada',
    cdk: 'CDK',
    cloning: 'Cloning',
    ct_wizard: 'CTWizard',
    dealer_vu: 'DealerVu',
    easydeal: 'EasyDeal',
    f_c_a_digital: 'FCA Digital',
    ford_direct: 'FordDirect',
    gm_canada: 'GM Canada',
    honda_canada: 'Honda Canada',
    m_b_c_a_digital: 'MBCA Digital',
    kia_digital: 'Kia Digital',
    manual: 'Manual',
    manual_import: 'Manual import',
    n_c_i_digital: 'NCI Digital',
    niotext: 'NioText',
    pbs: 'PBS',
    phone_system: 'Phone system',
    call_log: 'API phone system',
    porsche_digital: 'Porsche Digital',
    porsche_finder: 'Porsche Finder',
    polaris_digital: 'Polaris Digital',
    harley_davidson_digital: 'Harley Davidson Digital',
    taq_auto: 'Taq Auto',
    maserati_digital: 'Maserati Digital',
    scan: 'Scan Xpress',
    scraper: 'Scraper',
    serti: 'Serti',
    stellantis_digital: 'Stellantis Digital',
    undefined: '-',
    messenger: 'Messenger',
    polestar: 'Polestar',
    mitsubishi: 'Mitsubishi',
    prebooking: 'Automatic pre-booking',
    one_eighty: 'One-Eighty',
    hyundai: 'Hyundai',
    volkswagen: 'Volkswagen',
    autosync: 'AutoSync',
    reynolds: 'Reynolds',
    audi_canada: 'Audi Canada',
};
