import i18n from '@/plugins/vue-i18n.js';
import { format, getTimezoneOffset } from 'date-fns-tz';
import { enUS, frCA } from 'date-fns/locale';
import ActivixDate from '@/value-objects/ActivixDate.js';

const getLocale = () => {
    return i18n.locale === 'en' ? enUS : frCA;
};

const TimeZone = {
    /**
     * Get the standardized name for a given time zone.
     *
     * @example
     * const name = TimeZone.standardizedName('America/Montreal');
     * console.log(name); // "Eastern Standard Time"
     *
     * @params timeZone - The time zone to get the standardized name for. Default is set to {@link ActivixDate.timezone}.
     *
     * @remarks
     * Uses `format` from the `date-fns-tz` package <br>
     * https://www.npmjs.com/package/date-fns-tz#format
     */
    standardizedName: (timeZone: string = ActivixDate.timezone): string => {
        return format(new Date(), 'zzzz', {
            timeZone,
            locale: getLocale(),
        });
    },

    /**
     * Get the offset for a given time zone.
     *
     * @example
     * let offset = TimeZone.offset('America/Montreal');
     * console.log(offset); // "-5"
     *
     * offset = TimeZone.offset('America/St_Johns');
     * console.log(offset); // "-3:30"
     *
     * @params timeZone - The time zone to get the offset for. Default is set to {@link ActivixDate.timezone}.
     *
     * @remarks
     * Uses `getTimezoneOffset` from the `date-fns-tz` package <br>
     * https://www.npmjs.com/package/date-fns-tz#gettimezoneoffset
     */
    offset: (timeZone: string = ActivixDate.timezone): string => {
        const totalOffsetInHours = getTimezoneOffset(timeZone) / 1000 / 60 / 60;

        const sign = totalOffsetInHours < 0 ? '-' : '+';

        const offsetInWholeHours = Math.floor(Math.abs(totalOffsetInHours));
        const offsetRemainderInMins = Math.abs(totalOffsetInHours % 1) * 60;

        return offsetRemainderInMins > 0
            ? `${sign}${offsetInWholeHours}:${offsetRemainderInMins}`
            : `${sign}${offsetInWholeHours}`;
    },
};

export default TimeZone;
