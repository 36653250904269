/* eslint-disable global-require */
import Account from '@/entities/Account.js';
import pinia from './index.js';

let globalStoreInstance;
let contextStoreInstance;

export const globalStore = () => {
    if (!globalStoreInstance) {
        const module = require('@/store/store.js');

        globalStoreInstance = module.useGlobalStore(pinia);
    }

    return globalStoreInstance;
};

export const contextStore = () => {
    if (!contextStoreInstance) {
        const module = require('@/store/modules/context/store.js');

        contextStoreInstance = module.useContextStore(pinia);
    }

    return contextStoreInstance;
};

export function findContextAccount(accountId) {
    if (contextStore().account.id === accountId) {
        return contextStore().account;
    }

    let account = contextStore().account.children.find(a => a.id === accountId) || contextStore().account.parents.find(a => a.id === accountId);
    if (account) {
        return account;
    }

    if (contextStore().group.accounts) {
        account = contextStore().group.accounts.find(a => a.id === accountId);
        if (account) {
            return new Account(account);
        }
    }

    return null;
}
