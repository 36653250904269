<template>
    <activix-modal
        size="xl"
        scrollable
        no-padding
        :opened="opened"
        ref="modal"
        @close="close"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('clientCard.vehicles.stockTitle') }}
            </h4>
        </template>

        <template slot="body">
            <div class="p-6 bg-gray-50">
                <inventory
                    context="leadVehicle"
                    :initial-division="division"
                    :selected-inventory-unit-id="selectedVehicleId"
                    @selectVehicle="selectVehicle"
                />
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import Lead from '@/entities/Lead.js';
    import Inventory from '@/views/Inventory.vue';
    import LeadVehicle from '../../entities/LeadVehicle.ts';

    export default {
        components: {
            Inventory,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            accountId: {
                type: Number,
                required: true,
            },
            vehicle: {
                type: LeadVehicle,
                required: true,
            },
            lead: {
                type: Lead,
                required: true,
            },
            division: {
                type: String,
                default: 'all',
                required: false,
                validator: (value) => ['all', 'new', 'used'].includes(value),
            },
        },

        data() {
            return {
                selectedVehicleId: null,
                stock: [],
                filters: { make: null, modal: null, year: null, stock: null, division: null },
            };
        },

        computed: {
            confirmOrCancelText() {
                return this.vehicle.inventory_unit_id && !this.selectedVehicleId ? this.$t('general.cancelSelection') : this.$t('general.confirmSelection');
            },
        },

        watch: {
            opened(newValue) {
                if (newValue) {
                    this.selectedVehicleId = this.vehicle?.inventory_unit_id;
                    this.initFilters();
                    this.fetchStockVehicles();
                }
            },
        },

        methods: {
            close() {
                this.$emit('update:opened', false);
                this.$emit('update:vehicle', null);
            },

            selectVehicle(selectedVehicle) {
                this.$emit('select', selectedVehicle);
            },

            sort(inventoryVehicles) {
                return inventoryVehicles.sort((vehicle) => {
                    return vehicle.id === this.selectedVehicleId ? -1 : 1;
                });
            },

            async fetchStockVehicles() {
                this.$wait.start('fetching.stock');

                const payload = {
                    account_id: this.accountId,
                    make: this.filters.make,
                    model: this.filters.model,
                    year: this.filters.year,
                    stock: this.filters.stock,
                    division: this.filters.division,
                };

                this.stock = this.sort(await this.$api.stock.index(payload));

                this.$wait.end('fetching.stock');
            },

            initFilters() {
                this.filters.make = this.vehicle?.make ? this.vehicle.make : null;
                this.filters.model = this.vehicle?.model ? this.vehicle.model : null;
                this.filters.year = this.vehicle?.year ? this.vehicle.year : null;
                this.filters.stock = this.vehicle?.stock ? this.vehicle.stock : null;
                this.filters.division = this.vehicle?.division ? this.vehicle.division : null;
            },

            updateFilters(newFilters) {
                this.filters.make = newFilters.make;
                this.filters.model = newFilters.model;
                this.filters.year = newFilters.year;
                this.filters.stock = newFilters.stock;
                this.filters.division = newFilters.division;
                this.fetchStockVehicles();
            },
        },
    };
</script>
