<template>
    <div class="w-full h-full flex flex-col justify-between relative">
        <div
            :class="[
                'overscroll-contain px-6 pb-6',
                collapsed ? 'overflow-y-hidden' : 'overflow-y-auto',
            ]"
        >
            <slide-over-header
                :reset-tooltip="$t('addLead.resetSearch')"
                :collapsed="collapsed"
                :back-button-visible="!!originalLead.id"
                :back-button-tooltip="$t('addLead.returnInitialLead', { lead: originalLead.name })"
                @back="navigateToLead(originalLead.id)"
                @close="onClose"
                @reset="resetLocalFilters"
                @collapse="$emit('update:collapsed', !collapsed)"
            >
                <span class="truncate">
                    {{ $t('addLead.interDealerLeads') }}
                </span>
            </slide-over-header>

            <inter-account-lead-form
                class="mt-8"
                :account-options="childAccounts"
                ref="interAccountLeadForm"
                @search="searchLeads"
                @update-filters="updateFilters"
            />

            <inter-account-leads-result-list
                :leads="filteredLeads"
                :loading="loading"
                @navigate-to-lead="navigateToLead($event)"
            />

            <activix-button
                class="mt-6 py-3 w-full"
                type="white"
                size="medium"
                :loading="loading"
                @click="nextPage"
                v-if="showMoreButton"
            >
                {{ $t('addLead.showMore') }}
            </activix-button>
            <div
                class="text-center mt-6 text-gray-600"
                v-text="$t('addLead.noMoreResults')"
                v-else-if="noMoreResults"
            />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store.js';
    import { useGlobalStore } from '@/store/store.js';
    import { useInterAccountLeadsStore } from '@/store/modules/interAccountLeads/store.js';
    import InterAccountLeadForm from '@/components/inter_account_leads/InterAccountLeadsForm.vue';
    import InterAccountLeadsResultList from '@/components/inter_account_leads/InterAccountLeadsResultList.vue';
    import SlideOverHeader from '@/components/SlideOverHeader.vue';

    export default {
        components: {
            SlideOverHeader,
            InterAccountLeadForm,
            InterAccountLeadsResultList,
        },

        props: {
            collapsed: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                filters: {},
                replaceRouterHistory: false,
                page: 1,
                perPage: 25,
                originalLead: {},
            };
        },

        computed: {
            ...mapState(useInterAccountLeadsStore, [
                'leads',
                'loading',
                'siblingAccounts',
            ]),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['authUser', 'parentAuthUser']),

            filteredLeads() {
                return this.leads.filter(lead => {
                    return lead.account_id !== this.originalLead.accountId;
                });
            },

            showMoreButton() {
                return this.filteredLeads.length >= (this.page * this.perPage) && !!this.filteredLeads;
            },

            noMoreResults() {
                return !this.loading && this.filteredLeads.length && this.page !== 1;
            },

            childAccounts() {
                return this.siblingAccounts
                    .filter(child => child.active && child.id !== this.originalLead.account_id)
                    .sort((a, b) => a.name.localeCompare(b.name));
            },
        },

        watch: {
            '$route.name'() {
                if (this.$route.name !== 'leads.update') {
                    this.onClose();
                }
            },

            'contextLead.id'() {
                if (this.contextLead.customer_id === this.originalLead.customer_id) {
                    this.searchLeads(true);
                }
            },
        },

        methods: {
            ...mapActions(useInterAccountLeadsStore, ['fetchInterAccountLeads']),

            nextPage() {
                this.page++;
                this.searchLeads(false);
            },

            updateFilters(value) {
                this.page = 1;
                this.filters = value;
            },

            setOriginalLead(leadId) {
                if (!this.originalLead.id) {
                    this.originalLead = {
                        id: this.contextLead.id,
                        name: this.contextLead.fullName,
                        account_id: this.contextLead.account_id,
                        customer_id: this.contextLead.customer_id,
                    };
                }

                if (this.originalLead.id === leadId) {
                    this.originalLead = {};
                }
            },

            async navigateToLead(leadId) {
                this.setOriginalLead(leadId);

                const route = {
                    name: 'leads.update',
                    params: { id: leadId },
                };

                this.handleRouterHistory(route);

                await this.$nextTick();
            },

            handleRouterHistory(route, forcePush = false) {
                if (this.replaceRouterHistory && !forcePush) {
                    this.$router.replace(route);
                } else {
                    this.replaceRouterHistory = true;
                    this.$router.push(route);
                }
            },

            resetLocalFilters() {
                this.$refs.interAccountLeadForm.$reset();
                this.resetSearch();
            },

            resetSearch() {
                this.$reset();
                this.$refs.interAccountLeadForm.$reset();
                this.searchLeads();
            },

            async searchLeads(clear = true) {
                const divisionIds = this.filters.division ? this.filters.division.map(division => division.id) : [];
                const accountIds = this.filters.account ? this.filters.account.map(account => account.id) : [];

                try {
                    await this.fetchInterAccountLeads({
                        page: this.page,
                        per_page: this.perPage,
                        division_ids: divisionIds,
                        account_ids: accountIds,
                        lead_id: this.originalLead.id || this.contextLead.id,
                        clear,
                    });
                } catch (error) {
                    this.$notify.error(this.$t('addLead.errorDuringSearch'));
                    throw error;
                }
            },

            onClose() {
                this.$emit('close');
            },

            onPopStateHandler() {
                this.replaceRouterHistory = false;
            },
        },

        created() {
            window.addEventListener('popstate', this.onPopStateHandler, false);
        },

        mounted() {
            if (!this.filteredLeads.length) {
                this.searchLeads();
            }
        },

        beforeDestroy() {
            window.removeEventListener('popstate', this.onPopStateHandler, false);
            if (this.$route.name !== 'leads.update') {
                useInterAccountLeadsStore().leads = [];
                return;
            }

            if (this.originalLead.id && this.contextLead.id !== this.originalLead.id) {
                this.fetchInterAccountLeads({ lead_id: this.contextLead.id });
            }
        },
    };
</script>
