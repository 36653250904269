<template>
    <activix-pills :pills="pills" :value="selectedDivision" @input="onInput" />
</template>

<script>

    import { ActivixPills } from '@autosync/atx-activix-ui';

    export default {
        components: {
            ActivixPills,
        },

        props: {
            selectedDivision: {
                type: String,
                required: true,
            },
        },

        computed: {
            pills() {
                return ['all', 'new', 'used'].map(pill => ({
                    name: pill,
                    label: this.$t(`inventory.division.${pill}`),
                }));
            },
        },

        methods: {
            onInput(val) {
                this.$emit('update-selected-division', val);
            },
        },
    };
</script>
