<template>
    <div>
        <portal to="content-header-left">
            <dashboard-action-icon
                class="w-4 !ml-0"
                :icon="$icons.loading"
                :spinning="isLoading"
                :disabled="isLoading"
                :tooltip="$t('dashboards.refresh')"
                @click="triggerRefresh"
                v-if="!mdLayout"
            />
        </portal>
        <div :class="{ loading: isLoading, 'h-main-view': context == 'dashboard', 'h-screen-80': forModalView }">
            <div v-show="!forModalView">
                <inventory-statistics :account-suppliers="contextAccount.suppliers" :status-options="filtersOptions?.status" ref="inventoryStats" />
            </div>
            <div class="flex justify-between bg-white shadow-lg p-4 rounded">
                <div class="flex justify-start pr-4">
                    <inventory-division-filter
                        class="flex self-center"
                        :selected-division="config.division"
                        @update-selected-division="updateSelectedDivision"
                    />
                </div>
                <activix-input
                    class="w-full max-w-3/5"
                    :placeholder="$t('inventory.searchPlaceholder')"
                    @input="onSearchUpdate"
                    v-model="searchInput"
                >
                    <template #icon>
                        <icon class="text-grey-700" name="regular/search" />
                    </template>
                </activix-input>
                <div class="w-1/6 flex justify-end pl-4">
                    <inventory-filters
                        class="w-32"
                        :inventory-filters="inventoryFilters"
                        :order-by-direction="config.sortBy"
                        :order-by-field="config.orderBy"
                        :number-of-filters-applied="numberOfFiltersApplied"
                        @apply="applyFilters"
                        @reset="resetFilters"
                    />
                </div>
            </div>
            <div class="pt-5">
                <inventory-vehicle-list
                    :inventory-units="inventoryUnits"
                    :inventory-filters="inventoryFilters"
                    :search-input="searchInput"
                    :selected-division="config.division"
                    :for-modal-view="forModalView"
                    :selected-inventory-unit-id="selectedInventoryUnitId"
                    :has-shared-inventory="hasSharedInventory"
                    @selectVehicle="selectVehicle"
                    @updateInventory="updateInventory"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store.js';
    import InventoryVehicleList from '@/components/inventory/InventoryVehicleList.vue';
    import { useDashboardStore } from '@/store/modules/dashboard/store.js';
    import InventoryFilters from '@/components/inventory/InventoryFilters.vue';
    import InventoryDivisionFilter from '@/components/inventory/InventoryDivisionFilter.vue';
    import InventoryStockStatus from '@/entities/InventoryStockStatus.js';
    import LeadVehicleFuel from '@/entities/LeadVehicleFuel.js';
    import LeadVehicleTransmission from '@/entities/LeadVehicleTransmission.js';
    import DashboardActionIcon from '@/components/dashboards/DashboardActionIcon.vue';
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import TrackView from '@/mixins/TrackView.js';
    import InventoryStatistics from '../components/inventory/InventoryVehicleStatistics.vue';

    export default {
        components: { DashboardActionIcon, InventoryDivisionFilter, InventoryFilters, InventoryVehicleList, InventoryStatistics },

        mixins: [TrackView],

        props: {
            context: {
                type: String,
                default: 'dashboard',
                required: false,
            },
            selectedInventoryUnitId: {
                type: Number,
                default: null,
                required: false,
            },
            initialDivision: {
                type: String,
                default: 'all',
                required: false,
                validator: (value) => ['all', 'new', 'used'].includes(value),
            },
        },
        data: () => ({
            config: {
                visibility: ['active'],
                division: 'all',
                filters: {},
                orderBy: 'status',
                perPage: 25,
                sortBy: 'asc',
            },
            inventoryUnits: [],
            searchInput: null,
            page: 1,
            filtersOptions: null,
            hasSharedInventory: false,
        }),

        computed: {
            ...mapState(useContextStore, { contextAccount: 'account' }),
            ...mapState(useDashboardStore, { pagination: 'pagination' }),

            inventoryFilters() {
                return {
                    status: {
                        visible: true,
                        sortable: true,
                        label: this.$t('inventory.column.status'),
                        type: 'select',
                        multiple: true,
                        filterable: true,
                        highlighted: true,
                        value: this.config.filters.status,
                        options: this.options(this.filtersOptions?.status, false, InventoryStockStatus),
                    },
                    make: {
                        visible: true,
                        sortable: true,
                        label: this.$t('inventory.column.make'),
                        type: 'select',
                        multiple: true,
                        highlighted: true,
                        value: this.config.filters.make,
                        options: this.options(this.filtersOptions?.make),
                    },
                    model: {
                        visible: true,
                        sortable: true,
                        label: this.$t('inventory.column.model'),
                        type: 'select',
                        multiple: true,
                        highlighted: true,
                        value: this.config.filters.model,
                        options: this.options(this.filtersOptions?.model),
                    },
                    year: {
                        visible: true,
                        sortable: true,
                        label: this.$t('inventory.column.year'),
                        type: 'select',
                        multiple: true,
                        filterable: true,
                        highlighted: true,
                        value: this.config.filters.year,
                        options: this.options(this.filtersOptions?.year),
                    },
                    color_exterior: {
                        visible: true,
                        sortable: true,
                        label: this.$t('inventory.column.exteriorColor'),
                        type: 'select',
                        multiple: true,
                        filterable: true,
                        highlighted: true,
                        value: this.config.filters.color_exterior,
                        options: this.options(this.filtersOptions?.color_exterior, true),
                    },
                    color_interior: {
                        visible: true,
                        sortable: true,
                        label: this.$t('inventory.column.interiorColor'),
                        type: 'select',
                        multiple: true,
                        filterable: true,
                        highlighted: true,
                        value: this.config.filters.color_interior,
                        options: this.options(this.filtersOptions?.color_interior, true),
                    },
                    price: {
                        visible: true,
                        sortable: true,
                        label: this.$t('inventory.column.price'),
                        type: 'range',
                        mask: 'money',
                        value: [this.config.filters.price_min, this.config.filters.price_max],
                    },
                    mileage: {
                        visible: true,
                        sortable: true,
                        label: this.$t('inventory.column.odometer'),
                        type: 'range',
                        mask: this.contextAccount.country === 'US' ? 'mi' : 'km',
                        value: [this.config.filters.mileage_min, this.config.filters.mileage_max],
                    },
                    location: {
                        visible: true,
                        sortable: false,
                        label: this.$t('inventory.vehicle.location'),
                        type: 'select',
                        multiple: true,
                        filterable: true,
                        highlighted: true,
                        value: this.config.filters.location,
                        options: this.filtersOptions?.integration_name.map((location) => ({ text: location, value: location })),
                    },
                    lot: {
                        visible: this.filtersOptions?.lot.length >= 1,
                        sortable: false,
                        label: this.$t('inventory.vehicle.lot'),
                        type: 'select',
                        multiple: true,
                        filterable: true,
                        highlighted: true,
                        value: this.config.filters.lot,
                        options: this.options(this.filtersOptions?.lot, true),
                    },
                    added_to_inventory_date: {
                        visible: false,
                        sortable: true,
                        label: this.$t('inventory.column.daysInInventory'),
                        type: 'range',
                        mask: 'integer',
                        value: [this.config.filters.added_to_inventory_date_min, this.config.filters.added_to_inventory_date_max],
                    },
                    days_in_inventory: {
                        visible: true,
                        sortable: true,
                        label: this.$t('inventory.column.daysInInventory'),
                        type: 'range',
                        mask: 'integer',
                        value: [this.config.filters.days_in_inventory_min, this.config.filters.days_in_inventory_max],
                    },
                    trim: {
                        visible: true,
                        sortable: true,
                        label: this.$t('inventory.column.trim'),
                        type: 'select',
                        multiple: true,
                        filterable: true,
                        highlighted: true,
                        value: this.config.filters.trim,
                        options: this.options(this.filtersOptions?.trim, true),
                    },
                    concurrence: {
                        visible: true,
                        sortable: true,
                        label: this.$t('inventory.column.leads'),
                        type: 'select',
                        value: this.config.filters.concurrence,
                        options: [
                            { text: this.$t('general.yes'), value: 'yes' },
                            { text: this.$t('general.no'), value: 'no' },
                        ],
                    },
                    certified: {
                        visible: true,
                        sortable: true,
                        label: this.$t('inventory.column.certified'),
                        type: 'select',
                        value: this.config.filters.certified,
                        options: [
                            { text: this.$t('general.yes'), value: 'yes' },
                            { text: this.$t('general.no'), value: 'no' },
                        ],
                    },
                    demo: {
                        visible: true,
                        sortable: true,
                        label: this.$t('inventory.column.demo'),
                        type: 'select',
                        value: this.config.filters.demo,
                        options: [
                            { text: this.$t('general.yes'), value: 'yes' },
                            { text: this.$t('general.no'), value: 'no' },
                        ],
                    },
                    fuel: {
                        visible: true,
                        sortable: false,
                        label: this.$t('inventory.column.fuel'),
                        type: 'select',
                        multiple: true,
                        filterable: true,
                        highlighted: true,
                        value: this.config.filters.fuel,
                        options: this.options(this.filtersOptions?.fuel, true, LeadVehicleFuel),
                    },
                    transmission: {
                        visible: true,
                        sortable: false,
                        label: this.$t('inventory.column.transmission'),
                        type: 'select',
                        multiple: true,
                        filterable: true,
                        highlighted: true,
                        value: this.config.filters.transmission,
                        options: this.options(this.filtersOptions?.transmission, true, LeadVehicleTransmission),
                    },
                    engine: {
                        visible: true,
                        sortable: false,
                        label: this.$t('inventory.column.engine'),
                        type: 'select',
                        multiple: true,
                        filterable: true,
                        highlighted: true,
                        value: this.config.filters.engine,
                        options: this.options(this.filtersOptions?.engine, true),
                    },
                    body_type: {
                        visible: true,
                        sortable: false,
                        label: this.$t('inventory.column.bodyType'),
                        type: 'select',
                        multiple: true,
                        filterable: true,
                        highlighted: true,
                        value: this.config.filters.body_type,
                        options: this.options(this.filtersOptions?.body_type, true),
                    },
                    drivetrain: {
                        visible: true,
                        sortable: false,
                        label: this.$t('inventory.column.drivetrain'),
                        type: 'select',
                        multiple: true,
                        filterable: true,
                        highlighted: true,
                        value: this.config.filters.drivetrain,
                        options: this.options(this.filtersOptions?.drivetrain, true),
                    },
                    visibility: {
                        visible: true,
                        sortable: false,
                        label: this.$t('inventory.column.archived'),
                        type: 'select',
                        multiple: true,
                        placeholder: this.$t('filters.none'),
                        value: this.config.visibility,
                        options: [
                            { text: this.$t('inventory.vehicle.visibility.active'), value: 'active' },
                            { text: this.$t('inventory.vehicle.visibility.archived'), value: 'archived' },
                        ],
                    },
                    vdp_url: {
                        visible: this.filtersOptions?.with_vdp_url_en > 0 || this.filtersOptions?.with_vdp_url_fr > 0,
                        sortable: false,
                        label: this.$t('inventory.vehicle.vdpUrl'),
                        type: 'select',
                        value: this.config.filters.vdp_url,
                        options: [
                            { text: this.$t('general.yes'), value: 'yes' },
                            { text: this.$t('general.no'), value: 'no' },
                        ],
                    },
                };
            },

            numberOfFiltersApplied() {
                return Object.entries(this.config.filters).filter(([key, value]) => {
                    if (key === 'visibility') {
                        return !empty(value) && !(value.includes('active') && value.length === 1);
                    }

                    return !empty(value);
                }).length;
            },

            isLoading() {
                return this.$wait.is('fetching.inventory') || this.$wait.is('fetching.cards') || this.$wait.is('fetching.filters');
            },

            forModalView() {
                return this.context != 'dashboard';
            },
        },

        watch: {
            'contextAccount.id'() {
                this.resetFilters();
                this.fetchFilterOptions();
                this.fetchInventory();
                this.fetchCards();
            },
        },

        methods: {
            ...mapActions(useDashboardStore, [
                'updatePagination',
            ]),

            refreshPagination() {
                this.page = this.pagination.currentPage;
                this.updateConfig('perPage', this.pagination.perPage);

                this.fetchInventory();
            },

            async fetchFilterOptions() {
                try {
                    this.$wait.start('fetching.filters');

                    const response = await this.$api.inventoryUnits.getFilters({
                        accountId: this.contextAccount.id,
                        division: this.config.division,
                    });

                    this.filtersOptions = response.data;
                } catch (error) {
                    this.sendErrorNotification();
                }

                this.$wait.end('fetching.filters');
            },

            applyFilters(event) {
                this.updateConfig('filters', event.filters);
                this.updateConfig('sortBy', event.orderByDirection);
                this.updateConfig('orderBy', event.orderByField);
                this.page = 1;
                this.fullFetch();
                this.$behavior.track('Inventory', { action: 'Filtering', location: 'filtersAdded', filters: event.filter });
            },

            resetFilters() {
                this.updateConfig('sortBy', 'asc');
                this.updateConfig('orderBy', 'status');
                this.updateConfig('filters', {});
                this.config.visibility = ['active'];
                this.fullFetch();
                this.$behavior.track('Inventory', { action: 'resetingFilters', location: 'filters' });
            },
            async updateInventory(inventoryUnit) {
                try {
                    const response = await this.$api.inventoryUnits.update(inventoryUnit.id, {
                        archived: inventoryUnit.archived,
                    });

                    if (response) {
                        this.fullFetch();
                    }
                } catch (error) {
                    this.sendErrorNotification();
                }
            },
            async fetchInventory() {
                try {
                    this.$wait.start('fetching.inventory');
                    const response = await this.$api.inventoryUnits.index({
                        accountId: this.contextAccount.id,
                        searchInput: this.searchInput,
                        page: this.page,
                        perPage: this.config.perPage,
                        orderBy: this.config.orderBy,
                        sortBy: this.config.sortBy,
                        filters: this.formatFilters(),
                        visibility: this.config.visibility,
                        division: this.config.division,
                    });

                    if (response) {
                        this.updatePagination('currentPage', response.meta.current_page);
                        this.updatePagination('perPage', response.meta.per_page);
                        this.updatePagination('from', response.meta.from);
                        this.updatePagination('to', response.meta.to);
                        this.updatePagination('lastPage', response.meta.last_page);
                        this.updatePagination('total', response.meta.total);
                        this.inventoryUnits = response.data;
                        this.hasSharedInventory = response.meta.hasSharedInventory;
                    }
                } catch (error) {
                    this.sendErrorNotification();
                }

                this.$wait.end('fetching.inventory');
            },

            async fetchCards() {
                try {
                    this.$wait.start('fetching.cards');
                    const response = await this.$api.inventoryUnits.getCards({
                        accountId: this.contextAccount.id,
                        searchInput: this.searchInput,
                        filters: this.formatFilters(),
                        division: this.config.division,
                        visibility: this.config.visibility,
                    });

                    if (response && this.$refs.inventoryStats) {
                        const setCards = this.$refs.inventoryStats.setCards;
                        setCards(response.data);
                    }
                } catch (error) {
                    this.sendErrorNotification();
                }

                this.$wait.end('fetching.cards');
            },

            formatFilters() {
                const cleanFilters = {};

                Object.keys(this.config.filters).forEach(key => {
                    if (!empty(this.config.filters[key])) {
                        cleanFilters[key] = this.config.filters[key];

                        if (key.includes('added_to_inventory_date')) {
                            cleanFilters[key] = new ActivixDate('now').subDays(this.config.filters[key]).startOfDay().toDateString();
                        }
                    }
                    if (key.includes('visibility')) {
                        this.updateConfig('visibility', this.config.filters[key]);
                        delete cleanFilters.visibility;
                    }
                });

                return cleanFilters;
            },

            async onSearchUpdate() {
                this.page = 1;
                await this.fetchInventory();
                await this.fetchCards();
                this.$behavior.track('Inventory', { action: 'Searches', location: 'searchBar', searchInput: this.searchInput });
            },

            sendErrorNotification() {
                this.$notify.error(this.$t('inventory.fetchingError'));
            },

            getConfig() {
                const storedConfig = this.$ls.get('inventoryConfig') || this.config;

                if (this.forModalView) {
                    this.config.perPage = storedConfig.perPage;
                } else {
                    this.config = storedConfig;
                }
            },

            updateConfig(key, value) {
                this.config[key] = value;

                if (!this.forModalView) {
                    this.$ls.set('inventoryConfig', this.config);
                } else if (key === 'perPage') {
                    const storedConfig = this.$ls.get('inventoryConfig') || this.config;
                    storedConfig.perPage = this.config.perPage;

                    this.$ls.set('inventoryConfig', storedConfig);
                }
            },

            options(filterOptions, withNone = false, entity = null) {
                const options = [];

                if (withNone) {
                    options.push({ value: 'none', text: this.$t('general.none') });
                }

                if (filterOptions) {
                    if (entity) {
                        options.push(
                            ...filterOptions
                                .map((filterValue) => { return { value: filterValue, text: entity.getTranslation(filterValue) }; })
                                .sort((a, b) => { return a.text.localeCompare(b.text); }),
                        );
                    } else {
                        options.push(...filterOptions.map((filterValue) => { return { value: filterValue, text: filterValue }; }));
                    }
                }

                return options;
            },

            fullFetch() {
                this.fetchInventory();
                this.fetchCards();
            },

            updateSelectedDivision(division) {
                this.updateConfig('division', division);
                this.fetchFilterOptions();
                this.fullFetch();
                this.$behavior.track('Inventory', { action: 'Filtering', location: 'divisionFilter', division });
            },
            triggerRefresh() {
                this.fullFetch();
            },
            selectVehicle(vehicleId) {
                this.$emit('selectVehicle', vehicleId);
                this.$behavior.track('Inventory', { action: 'InventoryUnitSelected', location: 'InventorySelectionModal' });
            },
        },

        beforeMount() {
            this.getConfig();
            if (this.config.division !== this.initialDivision) {
                this.updateConfig('division', this.initialDivision);
                this.$behavior.track('Inventory', {
                    action: 'Filtering',
                    location: 'divisionFilter',
                    division: this.initialDivision,
                });
            }
        },

        async mounted() {
            await this.fetchFilterOptions();
            await this.fetchInventory();
            await this.fetchCards();
            this.$behavior.track('inventory', { action: 'moduleLoaded', location: this.context });
        },
        created() {
            this.onSearchUpdate = debounce(this.onSearchUpdate, 300);
            this.$eventBus.$on('update-inventory-pagination', this.refreshPagination);
        },
        beforeDestroy() {
            this.$eventBus.$off('update-inventory-pagination', this.refreshPagination);
        },
    };
</script>
