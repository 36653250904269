import SupplierId from '@/entities/enums/SupplierId.js';
import Enum from './Enum.js';

export default class CommentType extends Enum {
    static get entries() {
        return {
            all: null,
            string: 1,
            attachment: 2,
            audio: 3,
            case: 4,
            instruction: 5,
            reassign: 6,
        };
    }

    static get ALL() {
        return this.entries.all;
    }

    static get STRING() {
        return this.entries.string;
    }

    static get ATTACHMENT() {
        return this.entries.attachment;
    }

    static get AUDIO() {
        return this.entries.audio;
    }

    static get CASE() {
        return this.entries.case;
    }

    static get INSTRUCTION() {
        return this.entries.instruction;
    }

    static get REASSIGN() {
        return this.entries.reassign;
    }

    static get translationGroup() {
        return 'clientCard.boxes.notes.filters.noteType';
    }

    static exists(property) {
        if (typeof this.entries === 'undefined' || typeof property === 'undefined') {
            return false;
        }

        return Object.values(this.entries).includes(property);
    }

    static selectOptions(account, idName = '', labelName = '', sorted = true) {
        const options = [CommentType.STRING, CommentType.ATTACHMENT, CommentType.AUDIO, CommentType.REASSIGN]
            .map(id => {
                return {
                    [idName || 'id']: id,
                    [labelName || 'label']: this.getTranslation(id),
                };
            });

        if (account.isPolestar()) {
            options.push({
                value: CommentType.CASE,
                text: this.getTranslation(CommentType.CASE),
            });
        }
        if (account.suppliers.some(s => s.id == SupplierId.HYUNDAI)) {
            options.push({
                value: CommentType.INSTRUCTION,
                text: this.getTranslation(CommentType.INSTRUCTION),
            });
        }

        if (sorted) {
            options.sort((a, b) => a[labelName || 'label'].localeCompare(b[labelName || 'label']));
        }

        return options;
    }
}
