var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex grow items-center justify-center"},[_c('div',{staticClass:"sticky",style:(_vm.positionStyle)},[_c('div',{staticClass:"flex grow items-center justify-center",class:{
                'flex-row pb-12': _vm.horizontal,
                'flex-col': !_vm.horizontal,
            }},[_c('activix-svg',{staticClass:"pointer-events-none",class:{
                    'w-48 px-0 ml-0 | md:px-2 md:ml-6': _vm.horizontal,
                    'w-56 px-6': !_vm.horizontal
                },attrs:{"name":_vm.svg}}),_c('div',{staticClass:"flex flex-col gap-6",class:!_vm.horizontal && 'text-center'},[_c('div',[(_vm.title)?_c('div',{staticClass:"text-gray-800 text-2xl font-medium",domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),(_vm.description)?_c('div',{staticClass:"mt-1 text-gray-600 text-lg",domProps:{"textContent":_vm._s(_vm.description)}}):_vm._e()]),(_vm.showAdd)?_c('div',[_c('activix-tooltip',{attrs:{"content":_vm.buttonTooltip}},[_c('div',[_c('activix-button',{attrs:{"disabled":_vm.verifiedSale},on:{"click":function($event){return _vm.$emit('add-item')}}},[_c('div',{staticClass:"text-gray-600",domProps:{"textContent":_vm._s(_vm.$t('general.add'))}})])],1)])],1):(_vm.showClear)?_c('div',[_c('activix-button',{on:{"click":function($event){return _vm.$emit('clear-filters')}}},[_c('div',{staticClass:"text-gray-600",domProps:{"textContent":_vm._s(_vm.$t('filters.clearAllFilters'))}})])],1):_vm._e()])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }