<template>
    <div class="h-full w-full flex items-center justify-between bg-gray-100 px-2">
        <div class="flex">
            <div class="flex items-center justify-end">
                <div class="relative flex items-center h-10 ml-4 w-18" v-if="isDeleted || isRecording">
                    <span class="animate-ping z-0 absolute h-8 w-8 ml-1 rounded-full bg-red-300" />
                    <button class="flex items-center relative h-10 w-10 z-10 justify-center bg-red-500 shadow-md rounded-full | transition duration-200 | hover:bg-red-600" @click="stopRecording">
                        <div class="w-3 h-3 bg-white" />
                    </button>
                </div>
            </div>

            <div class="flex items-center ml-4 w-18" v-if="isRecordingStop">
                <button class="flex items-center justify-center h-10 w-10 bg-blue-500 shadow-md rounded-full | transition duration-200 | hover:bg-blue-600" @click="playPause">
                    <icon :name="iconIsPlaying" class="text-sm text-white" :class="{'-mr-0.5': !isPlaying}" />
                </button>
            </div>
        </div>

        <div class="flex-1 flex justify-center items-center h-full pr-3" ref="waveBox">
            <wave-surfer
                :url="url"
                :duration="duration"
                autoload
                autoplay
                ref="wavesurfer"
                @time="setTime"
                @playing="setPlaying"
                v-if="isRecordingStop"
            />
            <div class="voice-recorder relative flex justify-center items-center" ref="voiceRecorder" v-else />
        </div>

        <div class="flex justify-end pr-4">
            <button
                class="flex items-center justify-center"
                @click="redoClip"
                v-tooltip="$t('clientCard.recordAgain')"
                v-if="isRecordingStop"
            >
                <icon class="text-gray-500 text-lg | transition duration-200 | hover:text-blue-500" name="regular/redo" />
            </button>
            <div class="text-gray-700 text-lg tracking-wide ml-3" v-if="isRecordingStop">
                {{ currentTime }}
            </div>
            <div class="text-gray-700 text-lg tracking-wide" :class="{ 'text-red-500': timeIsCloseToLimit }" v-else>
                {{ currentTimeRecording }}
            </div>
        </div>
    </div>
</template>

<script>
    import { Mp3MediaRecorder } from 'mp3-mediarecorder';
    import Comment from '@/entities/Comment.ts';
    import WaveSurfer from './WaveSurfer.vue';

    // Entity

    // Worker
    const worker = new Worker(new URL('../../workers/mp3/mp3.worker.js', import.meta.url));

    export default {
        components: {
            WaveSurfer,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            comment: {
                type: Comment,
                default: () => new Comment(),
            },
        },

        data() {
            return {
                mediaRecorder: null,
                recorder: null,
                stream: null,
                recordingState: '',
                analyser: '',
                audioContext: '',
                visualElements: [],
                visualValueCount: 45,
                animationFrameId: null,
                isPlaying: false,
                timerRecording: null,
                currentTimeSeconds: 0,
                currentTime: '00:00',
                currentTimeRecording: '00:00',
                currentAudio: {
                    name: 'audioRecord',
                    src: null,
                    duration: 0,
                },
                url: '',
                duration: 0,
            };
        },

        computed: {
            isDeleted() {
                return this.recordingState === '';
            },

            isRecording() {
                return this.recordingState === 'recording';
            },

            isRecordingStop() {
                return this.recordingState === 'stop';
            },

            iconIsPlaying() {
                return this.isPlaying ? 'bold/controls-pause' : 'bold/arrow-button-right-2';
            },

            timeIsCloseToLimit() {
                // 6900 seconds => 1h55
                return this.currentTimeSeconds >= 6900;
            },
        },

        watch: {
            opened(newValue) {
                if (newValue) {
                    this.initAudio();
                } else {
                    if (this.isRecording) {
                        this.stopRecording();
                    }

                    this.destroyAudio();

                    this.$emit('input', null);
                    this.recordingState = '';
                }
            },

            currentTimeSeconds(newValue) {
                if (newValue >= 7200) {
                    this.stopRecording();
                }
            },
        },

        methods: {
            setPlaying(playing) {
                this.isPlaying = playing;
            },

            setTime(time) {
                this.currentTime = time;
            },

            async redoClip() {
                this.recordingState = '';
                await this.$nextTick();

                this.initAudio();
                this.$emit('input', null);
            },

            stopRecording() {
                this.recorder.stop();
                this.$emit('isRecording', false);
                this.stream.getTracks().forEach((track) => {
                    track.stop();
                });
                clearInterval(this.timerRecording);
                this.timerRecording = null;
                this.currentTimeRecording = '00:00';
                this.eraseAudio();
                this.recordingState = 'stop';
            },

            eraseAudio() {
                this.audioContext.close();
                cancelAnimationFrame(this.animationFrameId);
                this.analyser = null;
            },

            initAudio() {
                this.boxWidth = this.$refs.waveBox.getBoundingClientRect().width;
                const visualMainElement = this.$refs.voiceRecorder;

                if (!visualMainElement.hasChildNodes()) {
                    for (let j = 0; j < this.visualValueCount; ++j) {
                        const elm = document.createElement('div');
                        elm.style.opacity = '0';
                        this.setBarWidth(elm);

                        visualMainElement.appendChild(elm);
                    }
                }

                this.visualElements = visualMainElement.childNodes;

                if (navigator?.mediaDevices?.getUserMedia) {
                    navigator.mediaDevices.getUserMedia({ audio: true })
                        .then((stream) => {
                            this.initRecordAudio(stream);
                            this.initContextAudio(stream);
                        })
                        .catch(() => {
                            this.$emit('errorRecording');
                            this.$notify.warning(
                                this.$t('toastr.errorMessages.audioNotAllowed'),
                            );
                        });
                } else {
                    this.$emit('errorRecording');
                    this.$notify.warning(
                        this.$t('toastr.errorMessages.audioNotSupported'),
                    );
                }
            },

            initContextAudio(stream) {
                this.audioContext = new AudioContext();
                this.analyser = this.audioContext.createAnalyser();
                const source = this.audioContext.createMediaStreamSource(stream);
                source.connect(this.analyser);
                this.analyser.smoothingTimeConstant = 0.75;
                this.analyser.fftSize = 32;

                this.initRenderLoop();
                this.recordingState = 'recording';
            },

            initRecordAudio(stream) {
                this.stream = stream;
                this.recorder = new Mp3MediaRecorder(stream, { worker });
                this.recorder.start();
                this.$emit('isRecording', true);
                this.startTimerRecording();

                this.recorder.addEventListener('dataavailable', this.onRecordingReady);
            },

            startTimerRecording() {
                const start = Date.now();
                this.timerRecording = setInterval(() => {
                    const delta = Date.now() - start;
                    const seconds = Math.floor(delta / 1000);
                    const h = Math.floor(seconds / 3600).toString();
                    const m = Math.floor(seconds % 3600 / 60).toString().padStart(2, '0');
                    const s = Math.floor(seconds % 60).toString().padStart(2, '0');

                    this.currentTimeSeconds = seconds;
                    this.currentTimeRecording = h > 0 ? `${h}:${m}:${s}` : `${m}:${s}`;
                }, 1000);
            },

            onRecordingReady(e) {
                if (!this.opened) {
                    return;
                }

                const blobs = [];
                blobs.push(e.data);
                const mp3Blob = new Blob(blobs, { type: 'audio/mpeg' });
                this.currentAudio.blobs = mp3Blob;
                this.currentAudio.src = URL.createObjectURL(mp3Blob);
                this.currentTime = '00:00';

                this.duration = this.currentTimeSeconds;
                this.currentAudio.duration = this.duration;
                this.url = URL.createObjectURL(e.data);

                this.$emit('input', this.currentAudio);
            },

            playPause() {
                if (!this.$refs.wavesurfer) {
                    return;
                }

                this.$refs.wavesurfer.playPause();
            },

            // ** Animations methods **
            initRenderLoop() {
                const frequencyData = new Uint8Array(this.analyser.frequencyBinCount);

                const renderFrame = () => {
                    this.analyser.getByteFrequencyData(frequencyData);
                    this.processFrame(frequencyData);

                    this.animationFrameId = requestAnimationFrame(renderFrame);
                };

                requestAnimationFrame(renderFrame);
            },

            processFrame(data) {
                const dataMap = [14, 13, 13, 12, 11, 10, 9, 8, 7, 7, 6, 6, 5, 5, 4, 4, 3, 3, 2, 2, 1, 1, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 9, 10, 11, 12, 13, 13, 14];
                const values = Object.values(data);

                for (let i = 0; i < this.visualValueCount; ++i) {
                    const value = values[dataMap[i]] / 500;
                    const elmStyles = this.visualElements[i].style;
                    elmStyles.transform = `scaleY( ${value} )`;
                    elmStyles.opacity = Math.max(0.1, value);
                }
            },

            setBarWidth(element) {
                let barWidth = 1;
                const margin = 1;

                barWidth = Math.floor((this.boxWidth - (margin * 2) * this.visualValueCount) / this.visualValueCount);

                if (barWidth > 3) {
                    barWidth = 3;
                } else if (barWidth <= 0) {
                    barWidth = 1;
                }

                element.style.width = `${barWidth}px`;
                element.style.margin = `0 ${margin}px`;
            },

            destroyAudio() {
                if (this.recording) {
                    this.stopRecording();
                }

                this.recorder.removeEventListener('dataavailable', this.onRecordingReady);
                this.recorder = null;

                if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                    this.stream = null;
                }

                if (this.audioContext) {
                    this.audioContext.close();
                    this.audioContext = null;
                }

                if (this.analyser) {
                    this.analyser = null;
                }

                if (this.visualElements) {
                    this.visualElements = [];
                }

                if (this.animationFrameId) {
                    cancelAnimationFrame(this.animationFrameId);
                    this.animationFrameId = null;
                }

                if (this.timerRecording) {
                    clearInterval(this.timerRecording);
                    this.timerRecording = null;
                }
            },
        },
    };
</script>

<style lang="less">
.voice-recorder {
    div {
        display: inline-block;
        border-radius: 20px;
        height: 100px;
        background: black;
    }
}
</style>
