import accounts from './accounts.js';
import accountNotFound from './accountNotFound.js';
import activityReport from './activity-report.js';
import activixUi from './activix-ui.js';
import addLead from './addLead.js';
import attribution from './attribution.js';
import auth from './auth.js';
import automations from './automations.js';
import autopropulsion from './autopropulsion.js';
import calendar from './calendar.js';
import campaign from './campaign.js';
import campaignObjective from './campaignObjective.js';
import campaignStatus from './campaignStatus.js';
import civilities from './civilities.js';
import clientCard from './clientCard.js';
import communicationKinds from './communicationKinds.js';
import communicationMethods from './communicationMethods.js';
import communicationPreferences from './communicationPreferences.js';
import communicationStatuses from './communicationStatuses.js';
import communicationTypes from './communicationTypes.js';
import communications from './communications.js';
import conferenceStatuses from './conferenceStatuses.js';
import consent from './consent.js';
import createdMethods from './createdMethods.js';
import creditScore from './creditScore.js';
import creditScoreChoice from './creditScoreChoice.js';
import dashboards from './dashboards.js';
import dashboardViews from './dashboardViews.js';
import delayDirections from './delayDirections.js';
import delayUnits from './delayUnits.js';
import divisions from './divisions.js';
import elementUi from './elementUi.js';
import emailValidation from './emailValidation.js';
import featurePreview from './featurePreview.js';
import feedback from './feedback.js';
import flows from './flows.js';
import graph from './graph.js';
import groups from './groups.js';
import homepage from './homepage.js';
import history from './history.js';
import imports from './imports.js';
import inturns from './inturns.js';
import inventory from './inventory.js';
import inventoryStockStatus from './inventoryStockStatus.js';
import leadForms from './leadForms.js';
import leadResults from './leadResults.js';
import leadStatus from './leadStatus.js';
import leadTypes from './leadTypes.js';
import leadVehicleCategories from './leadVehicleCategories.js';
import leadVehicleConditions from './leadVehicleConditions.js';
import leadVehicleFrequencies from './leadVehicleFrequencies.js';
import leadVehicleFuels from './leadVehicleFuels.js';
import leadVehicleIntentions from './leadVehicleIntentions.js';
import leadVehicleModalities from './leadVehicleModalities.js';
import leadVehicleStates from './leadVehicleStates.js';
import leadVehicleRvCategories from './leadVehicleRvCategories.js';
import leadVehicleStockStates from './leadVehicleStockStates.js';
import leadVehicleTireTypes from './leadVehicleTireTypes.js';
import leadVehicleTradeTypes from './leadVehicleTradeTypes.js';
import leadVehicleTransmissions from './leadVehicleTransmissions.js';
import lexicon from './lexicon/default.js';
import locales from './locales.js';
import lostSources from './lostSources.js';
import meta from './meta.js';
import navs from './navs.js';
import notFound from './notFound.js';
import notificationCenter from './notificationCenter.js';
import objectives from './objectives.js';
import overview from './overview.js';
import progressStates from './progressStates.js';
import quicktrade from './quicktrade.js';
import recurrenceTypes from './recurrenceTypes.js';
import automationSchedule from './automationSchedule.js';
import refinancedTypes from './refinancedTypes.js';
import schedule from './schedule.js';
import screenPop from './screenPop.js';
import statusPage from './statusPage.js';
import search from './search.js';
import segments from './segments.js';
import sex from './sex.js';
import suppliers from './suppliers.js';
import taskEvents from './taskEvents.js';
import taskEventLocations from './taskEventLocations.js';
import taskEventTypes from './taskEventTypes.js';
import taskEventStatuses from './taskEventStatuses.js';
import taskEventTimelineGroups from './taskEventTimelineGroups.js';
import templates from './templates.js';
import titles from './titles.js';
import unauthorized from './unauthorized.js';
import users from './users.js';
import unsubscribe from './unsubscribe.js';
import vauto from './vauto.js';
import weekdays from './weekdays.js';

export default {
    accounts,
    accountNotFound,
    activityReport,
    activixUi,
    addLead,
    attribution,
    auth,
    automations,
    autopropulsion,
    calendar,
    campaign,
    campaignObjective,
    campaignStatus,
    civilities,
    clientCard,
    communicationKinds,
    communicationMethods,
    communicationPreferences,
    communicationStatuses,
    communicationTypes,
    communications,
    conferenceStatuses,
    consent,
    createdMethods,
    creditScore,
    creditScoreChoice,
    dashboards,
    dashboardViews,
    delayDirections,
    delayUnits,
    divisions,
    emailValidation,
    featurePreview,
    feedback,
    flows,
    graph,
    groups,
    homepage,
    history,
    imports,
    inturns,
    inventory,
    inventoryStockStatus,
    leadForms,
    leadResults,
    leadStatus,
    leadTypes,
    leadVehicleCategories,
    leadVehicleConditions,
    leadVehicleFrequencies,
    leadVehicleFuels,
    leadVehicleIntentions,
    leadVehicleModalities,
    leadVehicleStates,
    leadVehicleRvCategories,
    leadVehicleStockStates,
    leadVehicleTireTypes,
    leadVehicleTradeTypes,
    leadVehicleTransmissions,
    lexicon,
    locales,
    lostSources,
    meta,
    navs,
    notFound,
    notificationCenter,
    objectives,
    overview,
    progressStates,
    quicktrade,
    recurrenceTypes,
    automationSchedule,
    refinancedTypes,
    schedule,
    screenPop,
    statusPage,
    search,
    segments,
    sex,
    suppliers,
    taskEvents,
    taskEventLocations,
    taskEventTypes,
    taskEventStatuses,
    taskEventTimelineGroups,
    templates,
    titles,
    unauthorized,
    unsubscribe,
    users,
    vauto,
    weekdays,
    ...elementUi,
    cdkDrive: {
        success: 'Véhicle envoyé à CDK Drive',
    },
    ...{
        trash: {
            limitAlert: 'Seuls les {limit} derniers leads supprimés sont affichés.',
            showAll: 'Afficher tous les leads supprimés',
            showAvailableOnly: 'Afficher les leads restaurables seulement',
        },
        announcements: {
            dismiss: 'Cacher',
            likeVersion: 'Aimez-vous cette version ?',
        },
        audio: {
            noAudio: 'Aucun audio',
        },
        tradeReport: {
            general: 'général',
            totals: 'Totaux',
            totalsAndAverages: 'Totaux / Moyennes',
            divisionRation: 'ratio division',
            vehicles: 'véhicules',
            evaluatedGeneral: 'évalués',
            evaluatedValue: 'évalués',
            boughtGeneral: 'repris',
            boughtValue: 'repris',
            lostGeneral: 'perdus',
            lostValue: 'perdus',
            new: 'neuf',
            used: 'occasion',
            detailed: 'détaillés',
            detailsGeneral: 'détails',
            detailsValue: 'détails',
            wholesaleGeneral: 'wholesale',
            wholesaleValue: 'wholesale',
            returnGeneral: 'retours',
            returnValue: 'retours',
            recycledGeneral: 'recyclés',
            recycledValue: 'recyclés',
            excludeGeneral: 'exclus',
            excludeValue: 'exclus',
        },
        banner: {
            impersonation: {
                tooltip: 'Vous êtes connecté en tant que {0}. Toute vos actions sont enregistré dans votre propre historique.',
                title: 'Vous êtes connecté en tant que {0}',
                exit: 'Terminer session',
                endingSession: 'Fermeture de la session...',
            },

            nylasTransfer: {
                tooltip: 'Veuillez vous assurer que votre client de messagerie est mis à jour avant le 20 Janvier 2025 pour éviter toute interruption de service.',
                title: 'Votre client de messagerie doit être mis à jour.',
                clickHere: 'Cliquez ici',
            },

        },
        call: {
            generate: 'Générer un appel',
            toNumber: 'Vers quel numéro voulez vous générer un appel?',
            toNumber2: "L'appel sera généré vers le numéro suivant :",
            fromNumber: 'Avec lequel de vos numéros voulez-vous appeler?',
            fromNumber2: "L'appel sera généré à partir du numéro suivant :",
            callInProgress: 'Appel en cours',
            cantMakeCall: "Vous n'êtes pas autorisé à appeler ce lead.",
            callWith: 'Appeler avec',
            home: 'Domicile',
            cell: 'Mobile',
            work: 'Travail',
            contactInfo: 'Information de contact',
            notPreferredPhone: 'Numéro préféré non sélectionné',
        },
        sms: {
            title: 'Conversation Texto',
            notAuthorized: "Vous n'êtes pas autorisé à envoyer un SMS à ce lead.",
        },
        client: {
            addLead: 'Ajouter un lead',
            answered: 'Client répondu',
            associatedLeads: '{0} lead associé | {0} leads associés',
            call: 'Appeler',
            deleteLeads: {
                title: 'Supression de lead | Supression de leads',
                confirmation:
                    'Êtes-vous sûr de vouloir supprimer ce lead? | Êtes-vous sûr de vouloir supprimer {count} leads?',
                failed:
                    "Un lead n'a pas été supprimé, car une erreur est survenue. Veuillez réessayer plus tard. | Certains leads n'ont pas été supprimés, car une erreur est survenue. Veuillez réessayer plus tard.",
                failedCalling:
                    "Un lead n'a pas été supprimé, car un appel est en cours. Veuillez réessayer plus tard. | Certains leads n'ont pas été supprimés, car un appel est en cours. Veuillez réessayer plus tard.",
                failedSingle:
                    "Ce lead n'a pas été supprimé, car une erreur est survenue. Veuillez réessayer plus tard.",
            },
            emailAnswered: 'Courriel répondu',
            emailNotDelivered: 'Courriel non livré',
            incoming_email: 'Courriel entrant',
            leadDeletedSuccessfully: 'Le lead a été supprimé avec succès.',
            leadxpressEmail: 'Courriel Lead Web',
            leadTrashFetchFailed: 'Impossible de récupérer les leads',
            makeCall: 'Appeler',
            pendingReassign: 'Demande de réassignation en attente.<br>Ne compte pas dans vos statistiques.',
            waiting: 'Attente réponse',
            noEmail: 'Aucune adresse courriel valide',
            noMobilePhone: 'Aucun téléphone cellulaire valide',
            noPhone: 'Aucun téléphone valide',
            outgoing_email: 'Courriel sortant',
            outgoing_email_video: 'Courriel vidéo sortant',
            previousEmails: 'Échange de courriels',
            reply: 'Répondre',
            restore: {
                needDeletePermission: "Pour pouvoir restaurer ce lead vous devez avoir l'option de restauration/suppression de lead activée.",
                success: 'Le lead {0} a été restauré',
                cannotRestoreSupplierLead: 'Il est impossible de restaurer les leads liés à {0}',
                restoreFailed: 'La restauration a échoué veuillez réessayer',
            },
            sendEmail: 'Courriel',
            type: 'Type',
            withEmail: 'Avec courriel',
            withPhone: 'Avec tél.',
            withoutEmail: 'Sans courriel',
            withoutPhone: 'Sans tél.',
            withoutEmailAndPhone: 'Sans courriel ni tél.',
            withoutEmailAndMobile: 'Sans courriel ni mobile',
            withMobile: 'Avec mobile',
            withoutMobile: 'Sans mobile',
            wrongEmail: 'Le courriel est invalide',
            wrongPhone: 'Le numéro est invalide',
        },
        commercial: {
            antiThief: 'Anti-vol',
            approved: 'Approuvé | Approuvés',
            averageProfit: 'Profits moyens',
            average: 'Moyenne',
            cash: 'Dépot',
            cashDeal: 'Cash deal',
            clientExclude: 'Client exclu | Clients exclus',
            clientMet: 'Client rencontré | Clients rencontrés',
            clientNotMet: 'Client non-rencontré | Clients non-rencontrés',
            clientRefused: 'Client refusé | Clients refusés',
            delivered: '@.capitalize:lexicon.delivered.singular | @.capitalize:lexicon.delivered.plural',
            endorser: 'Endosseur',
            financing: 'Financement',
            inspected: 'Inspecté',
            institution: 'Institution',
            insurance: 'Assurance',
            meeting: 'Rencontré',
            noNeed: 'Exclus',
            other: 'Autre',
            pending: 'En traitement | En traitement',
            premium: 'Premium',
            prepared: 'Préparé',
            profit: 'Profit',
            progressState: 'État',
            proof: 'Preuve',
            vehicleSelection: 'Véhicule à sélectionner',
            refused: 'Refusé',
            replacement: 'Ass. Rempl',
            sent: 'Envoyé',
            totalPrice: 'Total des services vendus',
            totalPriceCommercialDirector: 'Total des services vendus (Directeur Commercial)',
            totalProfit: 'Profits totaux',
            warranty: 'Garantie',
        },
        date: {
            appointment: 'Rendez-vous',
            appointmentDate: 'Date de RV',
            apptCallDate: 'Date de RV Tél.',
            apptConfirmationDate: 'Date de confirmation RV',
            available: 'Disponibilité',
            availableDate: 'Date véh. ici le',
            beBack: 'B-back',
            beBackDate: 'Date du b-back',
            billOfSaleCreatedAtDate: 'Date de création de la facture de vente',
            billOfSaleSentToCustomerAtDate: 'Date de l\'envoi de la facture de vente',
            billOfSaleSignedByCustomerAtDate: 'Date de la signature de la facture de vente',
            callDate: "Date de l'appel",
            communicationDate: 'Date de communication',
            createdAt: 'Date de création',
            createdDate: 'Date de création',
            creatingPrebookingLeadDate: 'Date de rendez-vous',
            csi: 'CSI',
            csiDate: 'Date du CSI',
            customerDepositReceivedAtDate: 'Date de réception du dépôt client',
            customerReceiptSentAtDate: 'Date d\'envoi du reçu client',
            dateSelected: 'date sélectionnée | dates sélectionnées',
            deliverable: '@.capitalize:lexicon.deliverable.singular',
            deliverableDate: 'Date @.capitalize:lexicon.deliverable.singular',
            delivered: '@.capitalize:lexicon.delivery.singular',
            deliveredDate: 'Date de @:lexicon.delivery.singular',
            deliveryDate: 'Date @:lexicon.deliveryDate.singular',
            discountedDate: 'Escompté',
            paperworkDate: 'Date de dossier',
            discountedFilterDate: "Date d'escompte",
            endContractDate: 'Date fin de contrat',
            invalidDate: 'Date invalide',
            lastPresentedDate: 'Dernière visite',
            lastCommunicationDate: 'Dernière communication',
            modificationDate: 'Date de modification',
            lostDateTooltip: 'Cliquez ici pour filtrer les leads perdus pendant la plage sélectionnée',
            nextPresentedDate: 'Prochaine relance',
            presented: 'Visite',
            presentedDate: 'Date de visite',
            recordedDate: "Date d'enregistrement",
            refinanced: 'Rachat',
            refinancedDate: 'Date du rachat',
            renewal: '@.capitalize:lexicon.portfolio.singular',
            renewalDate: 'Date de @:lexicon.renewal.singular',
            roadTest: 'Essai routier',
            roadTestDate: "Date d'essai routier",
            sale: '@.capitalize:lexicon.sale.singular',
            saleByPhone: '@.capitalize:lexicon.sold.singular par tél.',
            saleByPhoneDate: 'Date de @:lexicon.sale.singular par tél.',
            saleDate: 'Date de @:lexicon.sale.singular',
            sentToCustomerAtDate: 'Date d\'envoi au client',
            soldDate: 'Date de @:lexicon.sale.singular',
            statusUpdatedAt: 'Date de modification du statut',
            takeOver: 'Take Over',
            takeOverDate: 'Date de take over',
            taskDate: 'Date de tâches',
            updatedAt: 'Date de modification',
            endWarrantyDate: 'Date fin de garantie',
            repairDate: 'Date de réparation',
            openWorkOrderDate: 'Ouverture W.O.',
            virtualAppointmentDate: 'Date d\'évènement virtuel',
            workOrderPartialClosureDate: 'Fermeture partielle du W.O.',
            workOrderClosureDate: 'Fermeture du W.O.',
        },
        datepicker: {
            end: 'Date de fin',
            fiscalYearToDate: 'Année à ce jour',
            last30Days: '30 derniers jours',
            last3Month: '3 derniers mois',
            last6Month: '6 derniers mois',
            last12Month: '12 derniers mois',
            last7Days: '7 derniers jours',
            lastWeek: 'Semaine dernière',
            last90Days: '90 derniers jours',
            lastMonth: 'Mois dernier',
            previousYear: 'Année dernière',
            thisYear: 'Cette année',
            sinceBeginning: 'Depuis le début',
            start: 'Date de début',
            thisMonth: 'Ce mois-ci',
            thisWeek: 'Cette semaine',
            tomorrow: 'Demain',
            today: "Aujourd'hui",
            yesterday: 'Hier',
            monthsAgo2: 'Il y a 2 mois',
            monthsAgo3: 'Il y a 3 mois',
            nextMonth: 'Mois prochain',
            next3Month: '3 prochains mois',
            next6Month: '6 prochains mois',
            next12Month: '12 prochains mois',
            next18Month: '18 prochains mois',
            next24Month: '24 prochains mois',
            next36Month: '36 prochains mois',
            olderThan: 'Plus de {months} mois',
            newerThan: 'Moins de {months} mois',
            next7: '7 prochains jours',
            next30: '30 prochains jours',
            next90: '90 prochains jours',
            custom: 'Date personnalisée',
        },
        deliveryTimeframe: {
            fifteen_minute: '15m',
            twenty_minute: '20m',
            twenty_five_minute: '25m',
            thirty_minute: '30m',
            thirty_five_minute: '35m',
            forty_minute: '40m',
            forty_five_minute: '45m',
            fifty_minute: '50m',
            fifty_five_minute: '55m',
            one_hour: '1h',
            one_hour_fifteen_minute: '1h15m',
            one_hour_thirty_minute: '1h30m',
            one_hour_forty_five_minute: '1h45m',
            two_hour: '2h',
            two_hour_fifteen_minute: '2h15m',
            two_hour_thirty_minute: '2h30m',
            two_hour_forty_five_minute: '2h45m',
            three_hour: '3h',
            three_hour_fifteen_minute: '3h15m',
            three_hour_thirty_minute: '3h30m',
            three_hour_forty_five_minute: '3h45m',
            four_hour: '4h',
        },
        error: {
            ajaxq: "Certaines données sont en cours d'enregistrement et seront possiblement perdues. @:general.wishToProceed",
            generic: 'Une erreur est survenue.',
            leadIdEmpty: 'Le lead est dans un format invalide. Veuillez contacter le support.',
            leadNotFound: "Le lead n'a pu être trouvé. Il a probablement été supprimé.",
            needUpdateInLead: 'Pour retourner dans le tour de rôle, vous devez créer ou modifier une fiche client ou demandez à la direction.',
            selectAccountWarning: 'Vous devez sélectionner un compte dans le menu déroulant ci-dessus.',
            unauthorized: 'Accès non autorisé.',
            warning: 'Attention',
            communicationNotFound: "La communication n'a pu être trouvée.",
            noLeadXpressWarning: 'Votre priorité Lead Web est désactivée, les priorités ne seront pas appliquées.',
            noSaleTable: "Le compte sélectionné n'a pas l'option tableau des @:lexicon.sale.plural activé",
            noName: 'Le nom est obligatoire',
            noInTurn: "Le compte sélectionné n'a pas l'option tour de rôle activé",
        },
        followUp: {
            saleIsByPhone: "Le lead sélectionné n'a pas de date de visite, voulez-vous créer une @:lexicon.sale.singular par téléphone ?",
            goToOriginalLead: 'Afficher le lead original',
            emailCount: 'Suivi courriel',
            last: 'Dernier suivi',
            lastDate: 'Date du dernier suivi',
            none: 'Ø suivi',
            noEmail: 'Ø courriel',
            noCall: 'Ø appel',
            noSms: 'Ø SMS',
            noAutomated: 'Ø Automatisé',
            noMessenger: 'Ø Messenger',
            noVideo: 'Ø vidéo',
            phoneCount: 'Suivi téléphonique',
            smsCount: 'Suivi SMS',
            messengerCount: 'Suivi Messenger',
            invalidDate: 'Veuillez sélectionner une date valide',
            invalidDateBefore: "Veuillez sélectionner une date égale ou avant aujourd'hui",
            videoCount: 'Suivi vidéoconférence',
            withCall: 'Appel',
            withEmail: 'Courriel',
            withMessenger: 'Messenger',
            withSms: 'SMS',
            withVideo: 'Vidéo',
            withAutomated: 'Automatisé',
            addSaleByPhone: '@.capitalize:lexicon.sale.singular par tél.',
            vehicleIsSoldInDifferentLeadLink: 'Lead',
            vehicleIsSoldInDifferentLead: 'Le véhicule recherché est déjà indiqué comme vendu dans un autre ',

            todo: {
                appointment: 'Rendez-vous',
                automated: 'Automatisé',
                call: 'Appel',
                csi: 'CSI',
                delivery: '@.capitalize:lexicon.delivery.singular',
                email: 'Courriel',
                late: 'Non complété',
                none: 'Ø tâche',
                other: 'Autre',
                phoneAppointment: 'Rendez-vous tél.',
                sms: 'SMS',
                virtualAppointment: 'Évènement virtuel',
                testDrive: 'Essai routier',
            },
        },
        optOut: {
            email: 'Courriel',
            sms: 'SMS',
            call: 'Tél.',
            dnd: 'NPD.',
            since: 'Depuis le {0}',
            until: "NPD jusqu'au {0}",
            indefinitely: 'NPD indéfiniment',
        },
        general: {
            account: 'Compte',
            accountColumn: '@.capitalize:lexicon.account.singular',
            activated_at: "Date d'activation",
            active: 'Actif',
            activeAccount: 'Comptes actifs',
            add: 'Ajouter',
            advisor: 'Conseiller',
            advisorAgent: 'Conseiller / Agent',
            agent: 'Agent BDC',
            allTeams: 'Toutes équipes',
            appointment: 'RV',
            areYouSure: 'Êtes-vous sûr?',
            assignees: 'Utilisateurs assignés',
            back: 'Retour à la page précédente',
            backHome: "Retour à l'acceuil",
            backToSummary: 'Retour au rapport sommaire des conseillers',
            bdc: 'BDC',
            by: 'Par',
            call: 'Appel | Appels',
            cancel: 'Annuler',
            cancelSelection: 'Supprimer la sélection',
            confirmSelection: 'Confirmer la sélection',
            child: 'Enfant',
            childAccounts: 'Comptes "enfants"',
            city: 'Ville',
            clickHere: 'Cliquez ici',
            client: 'Client | Clients',
            close: 'Fermer',
            commercial: 'F&I',
            continue: 'Continuer',
            copy: 'Copier le jeton',
            copyToClipboard: 'Copier',
            copiedSuccessfully: 'Copié avec succès',
            copiedUnsuccessfully: 'Incapable de copier, veuillez sélectionner manuellement',
            country: 'Pays',
            create: 'Créer',
            createTemplateTooltipDisabled: 'Vous n\'avez pas accès à la création des modèles',
            currentAbr: ' - A',
            currentVehicle: 'Véhicule actuel',
            date: 'Date | Dates',
            dealer: 'Concessionnaire',
            default: 'Défaut',
            defaultTemplateTooltipDisabled: "Vous n'avez pas accès à la modification du modèle par défaut",
            delete: 'Supprimer',
            deleting: 'Suppression en cours...',
            directorTO: 'Directeur T.O.',
            division: 'Division',
            done: 'Terminé',
            download: 'Télécharger',
            drsLink: '{0} Commerce numérique',
            drsLinkClient: 'Lien client',
            drsLinkDealer: 'Lien concessionnaire',
            duplicateTemplateTooltipDisabled: 'Vous n\'avez pas accès à la duplication des modèles',
            edit: 'Modifier',
            editTemplateTooltipDisabled: 'Vous n\'avez pas accès à la modification des modèles',
            email: 'Courriel',
            excludeTerm: 'Préfix ! pour exclure',
            excludeAndMultipleTerms: 'Préfix ! pour exclure. Séparez les entrées avec , pour inclure plusieurs termes',
            favorite: 'Favoris',
            featureDisabled: 'Fonctionnalité indisponible pour le moment',
            followUp: 'Suivi',
            followBy: 'Suivi par',
            goToClientCard: 'Accéder à la fiche client',
            goToLink: 'Accéder au lien',
            groups: 'Groupes',
            howToAssociate: 'Vous pouvez également associer le lead, voici comment!',
            importFile: "Fichier d'import",
            importantInformation: 'Information importante',
            inactive: 'Inactif',
            inactiveAccount: 'Comptes inactifs',
            incoming: 'Entrant',
            insideDates: 'Dans les dates',
            invalidLink: 'Lien invalide',
            lang: 'Langue',
            lead: 'Lead | Leads',
            lessThanAMin: 'il y a < 1 min',
            loading: 'Chargement...',
            loadingMatching: 'Association des colonnes en cours...',
            media: 'Média',
            make: 'Marque',
            model: 'Modèle',
            new: 'Nouveau',
            next: 'Suivant',
            no: 'Non',
            none: 'Aucun',
            note: 'Note | Notes',
            noteDate: 'Date de la note',
            offer: '# Offre',
            ok: 'Ok',
            openLink: 'Ouvrir le lien',
            optOut: 'Opt-out',
            or: 'ou',
            outgoing: 'Sortant',
            outsideDates: 'Hors des dates',
            selectParentAccounts: 'Sélectionnez le compte parent',
            parentAccounts: 'Compte parents',
            phone: 'Téléphone',
            phoneAppointment: 'RV Téléphonique',
            phoneUp: 'Phone-up',
            postalCode: {
                CA: 'Code postal',
                US: 'Code postal',
            },
            previous: 'Précédent',
            price: 'Prix',
            province: {
                CA: '@.capitalize:lexicon.province.CA',
                US: 'État',
            },
            regular: 'Régulier',
            restore: 'Restaurer',
            result: 'Résultat | Résultats',
            save: 'Enregistrer',
            saveAndClose: 'Enregistrer & Fermer',
            saveAndEdit: 'Enregistrer & Modifier',
            saveAndNew: 'Enregistrer & Nouveau',
            saveAndDuplicate: 'Enregistrer & Dupliquer',
            savedSuccessfully: 'Sauvegardé avec succès',
            search: 'Recherche',
            secondaryAdvisor: '@.capitalize:lexicon.secondaryAdvisor.singular',
            serviceAgentShort: 'Agent Serv.',
            services: 'Services',
            skip: 'Reporter',
            state: 'État',
            status: 'Statut',
            stock: '# Stock',
            stockState: 'État de stock',
            submit: 'Soumettre',
            suspended: 'Suspendu',
            team: 'Équipe',
            timezone: 'Fuseau horaire',
            to2: 'à',
            to: 'au',
            total: 'Total',
            unknown: 'Inconnu',
            update: 'Mettre à jour',
            user: 'Utilisateur | Utilisateurs',
            vehicle: 'Véhicule',
            walkIn: '@.capitalize:lexicon.walkIn.singular',
            wantedAbr: ' - R',
            wantedVehicle: 'Véhicule recherché',
            webBoost: 'WebBoost',
            webOrder: 'Commande | Commandes',
            wishToProceed: 'Voulez-vous continuer?',
            with: 'Avec',
            without: 'Sans',
            withoutSources: 'Sans source',
            year: 'Année',
            yes: 'Oui',

            months: {
                january: 'Janvier',
                february: 'Février',
                march: 'Mars',
                april: 'Avril',
                may: 'Mai',
                june: 'Juin',
                july: 'Juillet',
                august: 'Août',
                september: 'Septembre',
                october: 'Octobre',
                november: 'Novembre',
                december: 'Décembre',
            },
        },
        option: {
            showNoModality: 'Afficher les leads sans date de fin de contrat et qui ne sont pas des cash deals',
            hideNoModality: 'Cacher les leads sans date de fin de contrat et qui ne sont pas des cash deals',
            enableFilterUserByDivision: 'Activez pour voir les résultats selon la division du conseiller',
            disableFilterUserByDivision: 'Désactivez pour voir les résultats selon la division du lead',
            enableIncludeAttributions: 'Activez pour inclure les données des leads attribués',
            disableIncludeAttributions: 'Désactivez pour exclure les données des leads attribués',
            enableResultStat: 'Activez pour filtrer selon la date de création ou les dates de résultats (checkbox)',
            disableResultStat: 'Désactivez pour filtrer par type de dates',
            enableEndContractStat:
                'Cliquez pour voir les statistiques selon les dates de fin de contrat et les cash deals',
            disableEndContractStat: 'Cliquez pour voir les statistiques selon les dates de créations uniquement',
            enableDoubleWalkIn: 'Cliquez pour calculer les B-Back 2 fois dans un même mois',
            disableDoubleWalkIn: 'Cliquez pour calculer les B-Back 1 fois dans un même mois',
            enableSavedDate: 'Cliquez pour voir les résultats des véhicules enregistrés',
            disableSavedDate: 'Cliquez pour voir les résultats des véhicules @:lexicon.sold.plural',
            enableShowStats: 'Cliquez pour afficher les statistiques',
            disableShowStats: 'Cliquez pour cacher les statistiques',
            enableCommercialStat:
                'Cliquez pour voir les moyennes basées sur les clients rencontrés moins les clients exclus et refusés',
            disableCommercialStat: 'Cliquez pour voir les moyennes basées sur les clients @:lexicon.delivered.plural',
            generateReport: 'Générer rapport',
            reportBuilder: 'Rapport PDF automatique',
            showAgent: 'Afficher les agents BDC',
            showAdvisor: 'Afficher les conseillers',
            showBdcLead: 'Regrouper par agents BDC',
            showAdvisorLead: 'Regrouper par conseillers',
            showCommercialLead: 'Regrouper par directeur commerciaux',
            showTakeOverDirectorLead: 'Regrouper par directeur take over',
            showAccounts: 'Regrouper par compte',
            showSources: 'Regrouper par source',
            showForms: 'Regrouper par formulaire',
        },
        result: {
            active: 'Actif',
            addAppointment: 'Ajouter un rendez-vous',
            appointment: 'Rendez-vous',
            appointments: 'Rendez-vous',
            appointmentByCall: 'Rdv tél.',
            appointmentByCallLong: 'Rendez-vous téléphonique',
            appt: 'RV | RV',
            apptCall: 'RV téléphonique',
            apptConfirmation: 'Confirmation',
            assigned: 'Lead assigné',
            attempted: 'Tentative | Tentatives',
            automation: 'Automatisation',
            beBack: 'B-back',
            calling: 'En appel',
            canceled: 'Annulé | Annulés',
            canceledByClient: 'Annulé par client',
            canceledByDealer: 'Annulé par concessionnaire',
            canceledByUser: 'Annulé par ',
            cancelationReason: 'Motif d\'annulation',
            canceledNoMoreVehicle: 'Ne possède plus le véhicule',
            client: 'Client',
            confirmed: 'Confirmé | Confirmés',
            conquest: 'Conquête',
            csi: 'CSI',
            dealer: 'Concessionnaire',
            deliverable: '@.capitalize:lexicon.deliverable.singular',
            delivered: '@.capitalize:lexicon.delivered.singular',
            deposit: 'Dépôt',
            destination: 'Destination',
            duplicate: 'Doublon',
            endLcap: 'Fin LCAP',
            endLnette: 'Fin LNETTE',
            endWarranty: 'Fin garantie',
            error: 'Erreur',
            firstAppointment: 'Premier RV',
            happy: 'Positif',
            insideHours: "Int. heures d'affaire",
            insideSchedule: 'Int. horaire conseiller',
            interrupted: 'Interrompu',
            invalid: 'Invalide',
            leadAlreadyHasAppointment: 'Ce lead a déjà un rendez-vous',
            leadAlreadyPresented: 'Ce lead a déjà une visite',
            leadIsService: 'Ce lead est de la division Service',
            leadIsServiceWithConfirmation: 'Ce lead est de la division Service et a un rendez-vous confirmé',
            leadIsSoldbyPhone: 'Ce lead est @:lexicon.sold.singular par téléphone',
            leadIsWalkIn: 'Ce lead est un @.capitalize:lexicon.walkIn.singular',
            leadStatusIs: 'Le statut de ce lead est: {0}',
            longTerm: 'Long terme',
            lost: 'Perdu',
            lostReason: 'Raison perdu',
            lostSource: 'Source perdu',
            invalidReason: 'Raison invalide',
            loyalty: 'Loyauté',
            missed: 'Manqué',
            mr: 'M.',
            ms: 'Mme',
            miss: 'Mlle',
            dr: 'Dr.',
            me: 'Me.',
            modifyPhoneAppointment: 'Modifier rendez-vous tél.',
            neutral: 'Neutre',
            noMoreVehicle: 'Ne possède plus le véhicule',
            noShow: 'No Show',
            notSold: 'Non @:lexicon.sold.singular',
            nowShow: 'No show',
            origin: 'Origine',
            other: 'Autre',
            outsideHours: "Ext. heures d'affaire",
            outsideSchedule: 'Ext. horaire conseiller',
            pending: 'Attente ',
            pendingWb: 'En attente',
            presented: 'Visite',
            presentedColumn: '@.capitalize:lexicon.dashboard_columns.presented',
            promo: 'Promo',
            reached: 'Rejoint',
            reminder: 'Rappel',
            renewalByPhone: '@.capitalize:lexicon.renewed.singular par tel. | @.capitalize:lexicon.renewed.plural par tel.',
            renewed: '@.capitalize:lexicon.renewed.singular',
            revive: 'Récupéré',
            roadTest: 'Essai',
            sale: '@.capitalize:lexicon.sold.singular',
            saleByPhone: '@.capitalize:lexicon.sold.singular par tél. | @.capitalize:lexicon.sold.plural par tél.',
            saleByPhoneIcon: '@.capitalize:lexicon.sold.singular tél.',
            service: 'Service',
            takeOver: 'T.O.',
            takeOverBy: 'T.O. par',
            todo: 'To do',
            twenty_four_hour: '24h',
            twentyFourHr: '24h',
            unhappy: 'Négatif',
            unknown: 'Inconnu',
            verified: 'Vérifié',
            verifiedBy: 'Vérifié par',
            verifiedSale: 'Vente vérifiée',
            waitingSale: 'En att.',
            wow: 'Wow',
        },
        viewSelect: {
            myViews: 'Mes Vues',
            system: 'Système',
            sharedViews: 'Vues Partagées',
            viewOwner: 'Propriétaire',
        },
        selectpicker: {
            all: 'Tous',
            allSelected: 'Tous sélectionnés',
            noneSelected: 'Aucune sélection',
            search: 'Rechercher',
            selected: 'select',
            selectAll: 'Tout sélectionner',
            xSelected: '{0} sélectionnés',
            xSelect: '{0} select',
        },
        toastr: {
            back: 'Retour',
            connectionTimeout: 'Connexion interrompue',
            networkDisconnected: 'Connexion perdue',
            sessionExpired: 'Votre session a expiré.',
            tokenRefreshed: 'Session récupérée.',
            cantImpersonate: 'Erreur lors de la personnification',
            impersonateSuccess: 'Vous êtes maintenant connecté en tant que {0}',
            error: 'Erreur!',
            errorMessage: 'Désolé, une erreur est survenue.',
            errorMessages: {
                sendingEmail: "L'envoi du courriel a échoué",
                updatingLead: 'La mise à jour du lead a échoué',
                niotext: 'Désolé, une erreur est survenue du côté de NioText. Veuillez réessayer.',
                audioNotAllowed: "Veuillez autoriser l'accès à votre microphone pour enregistrer une note audio",
                audioNotSupported: "Votre navigateur ne semble pas supporter l'enregistrement d'audio",
                filesTooBig: 'La taille totale des fichiers dépasse la limite de {maximumSize}',
                invalidData: "Échec de l'envoi de l'email. Les données sont invalides",
                invalidImage: 'Échec de l\'envoi de l\'email. Fichier invalide.',
            },
            errorNetwork: 'Connexion interrompue, veuillez réessayer',
            featurePreviewActivationFailed: "Désolé, une erreur s'est produite lors de l'activation de la fonctionnalité.",
            leadDeleted: 'Lead supprimé par',
            leadNotFound: 'Lead introuvable',
            goToLead: 'Aller à la fiche client',
            nioTextPhoneCantBeDeleted: 'Le numéro de téléphone est utilisé pour NioText',
            phoneMustBeMobile:
                'Le numéro du lead est présent dans NioText, il peut seulement être changé pour un numéro de cellulaire valide',
            noPhoneNioText: 'Vous avez atteint votre limite de conversation SMS séparée avec ce contact.',
            noMobilePhone: "Le lead n'a pas de numéro mobile valide.",
            planYourNextStep: 'Veuillez planifier votre prochaine étape SVP',
            refresh: 'Rafraîchir la page',
            success: 'Succès',
            unauthorized: 'Accès non autorisé',
            warning: 'Avertissement!',
            reconnect: 'Veuillez vous reconnecter.',
            urlCopied: 'Le lien a été copié dans votre presse-papiers',
            prebookingLeadCreated: 'Lead pré-booking et le rendez-vous de service ont été créés',
            exportInProgress: 'Export en cours',
        },
        weekDay: {
            monday: 'Lundi',
            tuesday: 'Mardi',
            wednesday: 'Mercredi',
            thursday: 'Jeudi',
            friday: 'Vendredi',
            saturday: 'Samedi',
            sunday: 'Dimanche',
        },
        delays: {
            before: 'avant',
            day: 'jour | jours',
            hour: 'heure | heures',
            minute: 'minute | minutes',
            infinite: 'Infini',
            month: 'mois | mois',
            sameDay: 'Même jour',
            today: "aujourd'hui",
            week: 'semaine | semaines',
            year: 'an | ans',
            toDo: 'To do',
            threeDay: '3 jours',
        },
        modal: {
            address: 'Adresse',
            noAddress: 'Le lead n\'a pas d\'adresse',
            allNotes: 'Toutes les notes',
            associateLeads: 'Vous allez associer {count} leads ensemble.',
            associateLeadsTitle: 'Association de leads',
            yes: 'Oui',
            virtualAppointment: 'Rendez-vous virtuel',
            virtualAppointmentAdvisorLink: 'Votre lien',
            virtualAppointmentCustomerLink: 'Lien client',
            warrantyChangeAlert: 'Voulez-vous calculer la date de fin de la garantie avec les nouveaux changements? ',
            onlyThisOne: 'Celle-ci seulement',
            modifyAutomatedEvent: 'Modifier un événement automatisé',
            deleteAutomatedEvent: 'Supprimer un événement automatisé',
            modifyAutomatedEventFuture: 'Cet événement et les suivants',
            modifyAutomatedEventCurrent: 'Cet événement',
            modifyAutomatedEventAll: 'Tous les événements',
            modifyAutomatedEventTask: 'Événement automatisé. <br> Cliquez pour consulter l\'automatisation',
            modifyAllLeadsCampaign: 'Vous allez ajouter tous les {count} leads à une campagne.',
            modifyAllLeadsEmail: 'Vous allez envoyer un courriel à tous les {count} leads.',
            modifyAllLeadsEvent: 'Vous allez ajouter tous les {count} leads à un événement.',
            modifyAllLeadsCampaignTitle: 'Création de Campagne',
            modifyAllLeadsEmailTitle: 'Envoi en masse',
            modifyAllLeadsEventTitle: 'Ajout de leads à un événement',
            firstExecutionDate: 'Date du premier envoi',
            nextExecutionDate: "Prochaine date d'éxécution",
            recurrence: 'Récurrence',
            users: 'Utilisateurs',
            startDate: 'Date de début',
            endDate: 'Date de fin',
            update_future_confirm_message:
                'Cette tâche a 1 événement lié, voulez-vous le mettre à jours également? | Cette tâche a {0} événements lié, voulez-vous mettre ceux-ci à jours également?',
            disabledTaskType: 'Cet événement est lié à vos performances et affecte vos statistiques.',
            add: 'Ajouter',
            cancel: 'Annuler',
            later: 'Plus tard',
            confirm: 'Confirmation',
            confirmed: 'Confirmé',
            canceled: 'Annulé',
            icsInvite: 'Rappel d\'agenda',
            servicePrebooking: 'Pré-booking au service',
            leadAssignText: '{0} est déjà assigné sur le lead.',
            leadAssignReason: 'Quelle est la raison de votre demande d\'assignation?',
            leadAssignUser: 'Qui voulez-vous assigner?',
            leadAssignRequest: 'Commentaire :',
            moreInfoToDirection: 'Les directeurs concernés ont été notifiés. Ils pourront accepter ou refuser la demande.',
            completed: 'Complété',
            sendConfirmation: 'Envoyer une confirmation?',
            doNotSendConfirmation: 'Ne pas envoyer de confirmation',
            confirmedTooltip: 'Le rendez-vous a été confirmé par le client',
            canceledTooltip: 'Le rendez-vous a été annulé par le client',
            niotextNeeded: 'Vous devez avoir NioText pour utiliser cette fonctionnalité',
            subscribe: "S'abonner",
            noRecurrence: 'Aucune récurence',
            confirmationDisabled:
                "Le lead doit posséder au moins un téléphone vérifié afin d'utiliser la confirmation SMS",
            account: 'Compte',
            accounts: 'Comptes',
            send: 'Envoyer',
            groups: 'Groupes',
            childAccounts: 'Comptes Actifs',
            parentAccounts: 'Comptes Parents',
            icsTooltip: 'Envoyer un rappel d\'agenda par courriel',
            invalidStartAt: 'La date de début est invalide.',
            invalidEndAt: 'La date de fin est invalide.',
            editEventTitle: 'Modifier un rendez-vous',
            editTaskTitle: 'Modifier une tâche',
            editTaskEventTitle: 'Modifier une tâche ou un rendez-vous',
            add_event2: 'Ajoutez un rendez-vous',
            edit_event: 'Modifiez un rendez-vous',
            add_task: 'Ajoutez une tâche',
            edit_task: 'Modifiez une tâche',
            othersTooltip: 'Méthodes de communication non prises en charge',
            deleteFollowUp:
                'Supprimer {0} est irréversible. Notez que vous pouvez modifier la date à partir de la fiche client.',
            followUpDate: {
                appointment: 'la date de rendez-vous',
                appt_call: 'la date du RV téléphonique',
                appt_confirmation: 'la confirmation de rendez-vous',
                available: 'la date de disponibilité',
                be_back: 'la date de b-back',
                bill_of_sale_created_at: 'la date de facture de vente',
                bill_of_sale_sent_to_customer_at: 'la date d\'envoi de la facture au client',
                bill_of_sale_signed_by_customer_at: 'la date de signature de la facture par le client',
                csi: 'la date CSI',
                customer_deposit_received_at: 'la date de réception du dépôt client',
                customer_receipt_sent_at: 'la date d\'envoi du reçu client',
                deliverable: 'la date où le véhicule sera @.capitalize:lexicon.deliverable.singular',
                delivered: 'la @:lexicon.delivery.singular',
                delivery: 'la date de @:lexicon.delivery.singular',
                paperwork: 'la date de dossier',
                discounted: "l'escompte",
                presented: 'la date de visite',
                presented_other: 'la date de visite autre',
                recorded: "la date d'enregistrement",
                refinanced: 'cette @:lexicon.sale.singular',
                road_test: "la date d'essai routier",
                home_road_test: "la date d'essai routier à domicile",
                sale: 'cette @:lexicon.sale.singular',
                take_over: 'le take over',
            },
            disableUserWarning: {
                additionalAction: 'Actions supplémentaires',
                transferCommunicationsAndLeadNotSold: 'Vous devriez transférer vers un autre utilisateur toutes les leads non @:lexicon.sold.singular .',
                explanationOfProcedure: 'Explication des procédures',
            },
            add_task_event: 'Ajoutez une tâche ou un rendez-vous',
            modalityCashDeal: 'Modalité Cash Deal',
            guestMode: 'Invitation',
            taskReadOnlyTitle: 'Détail de la tâche',
            eventReadOnlyTitle: 'Détail du rendez-vous',
            taskEventReadOnlyTitle: "Détail d'une tâche ou rendez-vous",
            createAppointmentConfirmMessage: 'Créer un rendez-vous basé sur ce rendez-vous téléphonique ?',
            createdBy: 'Créé par',
            createAppointment: 'Créer un Rendez-vous',
            chooseDateRage: 'Veuillez choisir vos dates de début et de fin',
            chooseExportType: "Veuillez choisir le type d'export",
            chooseIncludeSubGroup: 'Inclure le sous-regroupement',
            chooseIncludeSubGroupToolTip: 'Permet de générer votre rapport PDF avec le sous-regroupement qui est actif selon les filtres de la vue actuel.',
            exportType: "Type d'export",
            generate: 'Générer',
            reportLeadsOnly:
                'Les statistiques ne seront pas incluses dans le rapport, il contiendra uniquement les leads.',
            reportStarted: "Vous recevrez un courriel à {0} avec le rapport d'ici quelques minutes.",
            subscriptionCreated: 'Vous recevrez un rapport {1} à l\'adresse {0} à partir du {2} à {3} de cette vue en format {4}.',
            type: 'Type',
            desc: 'Description',
            title: 'Titre',
            start_at: 'Début',
            end_at: 'Fin',
            dealershipTime: 'Heure du concessionnaire',
            dealershipTimeError: 'L\'heure du concessionnaire ne pouvait pas être déterminée. Veuillez vérifier manuellement pour éviter les erreurs.',
            guests: 'Invités',
            errors: 'Erreurs',
            date: 'Date',
            addReminder: 'Ajouter un rappel',
            reminder: 'Rappel',
            call: 'Appel',
            email: 'Courriel',
            oneInformationRequired: "Remplissez l'un des champs suivants",
            other: 'Autre',
            event: 'Rendez-vous',
            phoneAppointment: 'Rendez-vous Tél.',
            testDrive: 'Essai routier',
            delivery: '@.capitalize:lexicon.delivery.singular',
            location: 'Lieu',
            locations: {
                dealership: 'Au concessionnaire',
                customer: 'Chez le client',
                custom: 'Personnalisé',
            },
            vehicles: 'Véhicules',
            priority: 'Priorité',
            low: 'Basse',
            normal: 'Normal',
            high: 'Haute',
            done: 'Suivi complété',
            calendar: 'Calendrier',
            reminders: 'Rappels prédéfinis',
            minutes_before: 'minutes avant',
            hours_before: 'heure avant|heures avant',
            nineoclock_am: '|À 9 heures le jour même',
            nineoclock_pm: 'À 21 heures la veille',
            newTab: 'Nouvel onglet',
            openInNewTab: 'Ouvrir dans un nouvel onglet',
            sms: 'SMS',
            push: 'Mobile',
            email_alert: 'Alerte courriel',
            sms_alert: 'Alerte SMS',
            client: 'Client',
            notification: 'Écran',
            user: 'Conseiller',
            nextStepTitle: 'Veuillez compléter la prochaine étape',
            nextStep: 'Prochaine étape',
            term: 'Terme',
            modality: 'Modalité',
            termAndModality: 'Terme et modalité',
            testDriveCompleted: 'Essai effectué',
            titleAddRenew: 'Ajouter un @:lexicon.portfolio.singular',
            titleAssignAdvisor: "Demande d'assignation",
            endContractRenew: 'Date de fin de contrat',
            // Delete task event
            deleting: 'Suppression',
            delete_confirm_message: 'Êtes-vous sûr de vouloir supprimer ce rendez-vous ou cette tâche?',
            delete: 'Supprimer',
            deleted: 'Supprimé',
            deleted_message: 'Votre rendez-vous a été supprimé avec succès!',
            deleteConfirmMessageMainEvent:
                'Attention! Ce rendez-vous est lié à vos performance et affectera vos statistiques.',

            // Errors
            error_title: 'Le titre ne doit pas être vide',
            error_start_at: 'La date de début ne doit pas être vide',
            error_date: 'La date ne doit pas être vide',
            error_email: 'Les rappels d\'agenda ne peuvent être envoyés qu\'à une adresse courriel valide',
            error_end_at: 'La date de fin ne doit pas être vide',
            error_end_is_before: 'La date de début doit être avant la date de fin',
            error_is_canceled: 'Rappel d\'agenda non disponible, événement annulé, client No show ou pas de propriétaire de tâche',
            noAssociatedEventLead: {
                errorDesc: 'Un lead événement doit être associé à au moins un lead non-événement.',
                errorTitle: 'Modification désactivée',
                actionAssociate: 'Associer',
                actionDismiss: 'Fermer',
            },
            my_calendar: 'Mon calendrier',
            go_lead: 'Accèder au lead',
            save: 'Enregistrer',
            error_lead: 'Vous devez choisir un client',
            add_event: 'Ajoutez une tâche ou un rendez-vous',
            add_comment: 'Ajoutez un commentaire',
            error: {
                reminderDate: 'La date de rappel ne peut être vide',
            },
            error_no_selected_user: 'Vous devez sélectionner un utilisateur à notifier',
            error_no_type: 'Vous devez sélectionner un type de tâche ou rendez-vous',
            error_invalid_date: 'Vous avez une date invalide',
            error_main_event_date_is_after: "La date de {0} ne peux pas être après aujourd'hui",

            sendIcsVirtualAppointment: 'Vous et le client recevrez des courriels de confirmation et de modifications de rendez-vous.',
            task_owner: 'Propriétaire',
            no_lead: 'Aucun lead associé',

            call_client: 'Générer un appel',
            user_phone_choice: 'Avec lequel de vos numéros voulez-vous appeler?',
            call_generated: 'Appel généré',
            call_to: 'Vous recevrez un appel au numéro suivant',

            send_email: 'Envoyer un courriel',
            selectTerm: 'Choisissez le terme',
            agree: 'Accepter',
            associate_email: '* En acceptant, le lead vous sera associé.',
            decline: 'Refuser',
            email_choice: 'À quelle adresse voulez-vous envoyer un courriel?',

            none_selected: 'Aucune sélection',
            selected: 'sélectionnés',
            all_selected: 'Tous sélectionnés',
            select_all: 'Tout sélectionner',
            search: 'Rechercher',
            no_phone: "Le lead n'a aucun téléphone valide",
            no_phone_provider: 'Aucun système téléphonique configuré',
            no_email: "Le lead n'a aucun courriel valide",
            delivered: '@.capitalize:lexicon.delivered.singular',
            presented: 'Visite',
            virtualDone: 'Appel effectué',
            appointmentCallTooltip: 'Rendez-vous téléphonique',
            otherVisitTooltip: 'Autre visite',
            saleByPhoneTooltip: '@.capitalize:lexicon.sale.singular par téléphone',
            deliveredTooltip: 'Client présenté',
            presentedTooltip: 'Voiture @:lexicon.delivered.feminineSingular',
            readOnlyNotAllowed: "Vous n'êtes pas autorisé à modifier cet événement.",
            saleIsNeededForDelivery: 'Vous ne pouvez pas mettre une @:lexicon.delivery.singular pour un lead qui n\'a pas encore été @:lexicon.sold.singular',
            renewalIsNeededForDelivery: 'Vous ne pouvez pas mettre une @:lexicon.delivery.singular pour un lead qui n\'a pas encore été @:lexicon.renewed.singular',
            hasDeliveryTask: 'Il y a déjà une @:lexicon.delivery.singular enregistrée pour ce lead',

            noShowTooltip: 'Client non présenté',
            noShow: 'No show',
            addEventTitle: 'Ajouter un rendez-vous',
            addTaskTitle: 'Ajouter une tâche',
            addTaskEventTitle: 'Ajouter une tâche ou rendez-vous',
            vehicleDamageCostTitle: 'Dommage du véhicule',

            priorities: {
                normal: 'Normale',
                high: 'Haute',
            },
            subscribeToSharedView: 'S\'abonner à la vue partagée',
            subscribeToSharedViewTootlip: 'Vous permet de vous abonner à cette vue partagée',

            updateDashboardViewTitle: 'Sauvegarder cette vue',
            updateDashboardViewContent: 'Voulez-vous sauvegarder cette vue avec les nouveaux filtres sélectionnés ?',

            closingCampaignCreation: 'Toutes les données de la campagne seront perdues',

            overlappingTaskEventTitle: 'Cet événement chevauche un ou plusieurs autres événements',
            overlappingTaskEventList: '{0}<br/>{1} au {2}',
            overlappingTaskEventConfirmation: 'Voulez-vous quand même procéder ?',
        },
        searchLead: {
            chooseALead: 'Choisir un lead',
            chooseLead: 'Choisir ce lead',
            client_name: 'Nom du client',
            clients: 'Client | Clients',
            date: 'Date',
            division: 'Division',
            email: 'Courriel',
            lead_type: 'Type de lead',
            loyalty: 'Loyauté',
            other: 'Autre',
            phone: 'Téléphone',
            renewal: '@.capitalize:lexicon.portfolio.singular',
            tableFooter: 'Affichage de {limit} leads sur {leadCount}',
            title: 'Rechercher un client',
            walk_in: 'Walk-in',
            wanted: 'Recherché',
            exchange: 'Actuel',
        },
        email: {
            title: 'Lead Web',
            title_sent: 'Courriel envoyé',
            response: 'Réponse',
            prefix: 'Préfix',
            prefix_mr: 'M.',
            prefix_ms: 'Mme',
            prefix_none: '- Aucun -',
            response_template: 'Type de réponse',
            response_phrase: 'Phrase',
            content: 'Contenu',
            send: 'Envoyer',
            result: 'Résultat',
            preview_message: 'Aperçu de votre message',
            originalEmail: 'Courriel original',
            no_original_email: 'Aucun courriel original',
            custom_price: 'Prix du véhicule',
            no_custom_price: 'Aucun prix',
            price_here: 'Prix ici',
            vehicle_here: 'Véhicule ici',
            phrase_here: 'Phrase ici',
            link_here: 'Lien ici',
            interested_by_here: 'Intéressé par ici',
            error: 'Erreur',
            is_required: ' est requis',
            incomplete_fr_response: 'Le courriel est exclusivement rédigé en anglais. Souhaitez-vous toujours continuer?',
            incomplete_en_response: 'Le courriel est exclusivement rédigé en français. Souhaitez-vous toujours continuer?',
            success: "Le courriel est en cours d'envoi",
            success_mass_mailing: "Vos courriels sont en cours d'envoi",
            lead_locale: 'Langue du lead',
            unauthorized: "Vous n'êtes pas autorisé à envoyer ce courriel",
            datetime: 'Date & heure',
            datetime_here: 'Date & heure ici',
            other: '- Autre -',
            interested_by: 'Intéressé par',
            subject: 'Sujet',
            body: 'Corps',
            user_not_found_title: 'Courriel Introuvable',
            user_not_found_body: "Bonjour :clientName,<br><br>Nous sommes désolés de vous informer que l'adresse ':userEmail' à laquelle vous avez envoyé le courriel ci-dessous est invalide.<br><br>Veuillez répondre au courriel original directement.<br><br>Merci<br><br>--<br><br>:sentEmail",
            already_assigned_title: 'Déjà répondu',
            already_assigned_text: 'Cette demande a été répondu par :advisorName',
            temp_assigned_title: 'Temporairement réservé',
            temp_assigned_text: 'Cette demande est temporairement réservé à :advisorName',
            temp_assigned_text_2: 'Vous pouvez réessayer dans 5 minutes',
            time_remaining: 'Réservé pour',
            time_elapsed_title: 'Temps écoulé',
            time_elapsed_text: 'Votre temps est écoulé',
            time_elapsed_text_2: "Vous pouvez réessayer en <a onclick='window.history.back();'>cliquant ici</a>",
            forward: 'Ce courriel a été tranféré de',
            custom_email: 'Sur mesure',
            attachment: 'Pièce jointe',
            divisions: 'Divisions',
            vehicle: 'Véhicule',
            vehicle_link: 'Lien du véhicule',
            prefix_mr_fr: 'M.',
            prefix_mr_en: 'Mr',
            prefix_ms_fr: 'Mme',
            prefix_ms_en: 'Ms',
            prefix_none_fr: '- Aucun -',
            prefix_none_en: '- None -',
            error_unknown: "Une erreur inconnue est survenue. Veuillez <a href='https://activixinc.freshdesk.com/fr/support/tickets/new'>contacter le support</a>.",
            leadxpress_unauthorized: "Vous n'êtes pas autorisé à envoyer ce courriel",
            feminine: 'e',
            savedDraft: 'Brouillon enregistré avec succès.',
            on: 'Le',
            wrote: 'a écrit',
        },
        facebook: {
            modal: {
                noReply: '* Pour répondre à la conversation, vous devez utiliser Facebook Messenger.',
            },
            image: 'Image',
        },
        allLead: {
            allFollowedTooltip: 'Communications effectuées dans les dates sélectionnées sur les leads & clients actuellement affichés',
            allFollowedTooltipDates: 'Communications effectuées dans les dates sélectionnées',
            appointmentsTooltip: 'Représente le nombre total de rendez-vous (rendez-vous, essais routiers, livraisons) dans les dates sélectionnées',
            averageContact: 'Contact',
            averageContactTooltip: "Nombre moyen d'échange courriel et d'appels de 60sec ou + entre le lead ou le client et l'utilisateur",
            allLeadsFilteredTooltipOn: 'Inclure tous les clients',
            allLeadsFilteredTooltipOff: 'Exclure les clients @.capitalize:lexicon.portfolio.singular / Loyauté sans activités ou avec une date de fin de contrat dans plus de 3 mois',
            updatedBySupplier: 'Mise à jour',
            averageFollowed: 'Suivi',
            averageFollowedTooltip: 'Nombre de suivi moyen par lead & client',
            clients: 'Client | Clients',
            clientsRenewalLoyalty: '@.capitalize:lexicon.portfolio.singular & loyauté',
            clientsRenewal: '@.capitalize:lexicon.portfolio.singular | @.capitalize:lexicon.portfolio.plural',
            clientsPhoneUp: 'Phone-Up',
            clientsUp: '@.capitalize:lexicon.walkIn.singular & Phone-up',
            clientsWeb: 'Lead web | Leads web',
            clientsWebAndOrder: 'Leads & Commandes Web',
            delivered: '@.capitalize:lexicon.delivered.singular',
            deliveries: 'Véhicule @:lexicon.delivered.singular | Véhicules @:lexicon.delivered.plural',
            deliveriesBack: 'Véhicules @:lexicon.delivered.plural',
            deliveriesPopover: 'Véhicules @:lexicon.delivered.plural sur le nombre de @:lexicon.sale.plural',
            options: {
                enableResultStat:
                    'Activez pour filtrer selon la date de création, fin de contrat ou les dates de résultats (checkbox)',
            },
            PhoneUpOrder: '@.capitalize:lexicon.sale.singular Phone-Up | @.capitalize:lexicon.sale.plural Phone-Up',
            receivedOrder: '@.capitalize:lexicon.sale.singular reçue | @.capitalize:lexicon.sale.plural reçues',
            renewalOrders: ' @.capitalize:lexicon.sale.plural @.capitalize:lexicon.portfolio.singular',
            sales: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            salesBack: '@.capitalize:lexicon.sale.plural totales',
            salesBackDeposit: 'Total des @:lexicon.sale.plural avec dépôt',
            salesPhoneUp: 'Total @:lexicon.sale.plural Phone-Up',
            salesRenewalLoyalty: '@.capitalize:lexicon.sale.singular @:lexicon.portfolio.singular & loyauté | @.capitalize:lexicon.sale.plural @:lexicon.portfolio.singular & loyauté',
            salesRenewalLoyaltyBack: '@.capitalize:lexicon.sale.plural totales @:lexicon.portfolio.singular & loyauté',
            salesUp: '@.capitalize:lexicon.sale.singular up | @.capitalize:lexicon.sale.plural up',
            salesWeb: '@.capitalize:lexicon.sale.singular web | @.capitalize:lexicon.sale.plural web',
            salesWebBack: '@.capitalize:lexicon.sale.plural totales web',
            sourceTestDrive: 'Test drive',
            sourceOther: 'Autre',
            tradeReport: "Rapport d'achat",
            visitWithoutAppointmentTooltip: 'Nombre de visites sans rendez-vous sur le nombre de leads assignés',
            walkinLeads: '@.capitalize:lexicon.walkIn.singular | @.capitalize:lexicon.walkIn.plural',
            walkInOrder: '@.capitalize:lexicon.sale.singular @.capitalize:lexicon.walkIn.abr |  @.capitalize:lexicon.sale.plural @.capitalize:lexicon.walkIn.abr',

            hideNoLeadAccount: 'Cacher les comptes sans leads',
            hideNoLeadAdvisor: 'Cacher les conseillers sans leads',
            showNoLeadAccount: 'Afficher les comptes sans leads',
            showNoLeadAdvisor: 'Afficher les conseillers sans leads',
        },
        loyalty: {
            appointment: 'Rendez-vous | Rendez-vous',
            appointmentCall: 'Rendez-vous téléphoniques',
            appointmentCallTooltipOff: 'Cliquez pour calculer les rendez-vous téléphoniques',
            appointmentCallTooltipOn: 'Cliquez pour calculer les rendez-vous au concessionnaire',
            clients: 'Clients',
            delivered: 'Véhicule @:lexicon.delivered.singular | Véhicules @:lexicon.delivered.plural',
            deliveredCardBack: 'Véhicules @:lexicon.delivered.plural',
            otherVisitTooltipOff: 'Cliquez pour calculer les visites ailleurs',
            otherVisitTooltipOn: 'Cliquez pour calculer les visites au concessionnaire',
            phoneAppointment: 'Rendez-vous téléphoniques',
            phoneAppointmentCardBack: 'Nombre total de rendez-vous',
            phoneAppointmentTooltipOff: 'Cliquez pour calculer les rendez-vous téléphoniques',
            phoneAppointmentTooltipOn: 'Cliquez pour calculer les rendez-vous au concessionnaire',
            presented: 'Visite | Visites',
            sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            saleCardBack: '@.capitalize:lexicon.sale.plural totales',
            visit: 'Visite | Visites',
            visitWithAppointment: 'Avec RV',
            visitWithoutAppointment: 'Sans RV',
        },
        renewal: {
            allFollowedTooltip:
                'Communications effectuées dans les dates sélectionnées sur les clients actuellement affichés',
            appointment: 'Rendez-vous | Rendez-vous',
            assigned: 'Clients',
            averageContactTooltip:
                "Nombre moyen d'échange courriel et d'appels de 60sec ou + entre le client et l'utilisateur",
            averageFollowedTooltip: 'Nombre de suivi moyen par client',
            cash: 'Comptant',
            clients: 'Clients @:lexicon.portfolio.singular',
            delivered: 'Véhicules @:lexicon.delivered.plural',
            disabledEndContractFilterOption: 'La date de fin de contrat doit être la seule option sélectionnée',
            financed: 'Financé',
            location: 'Location | Locations',
            modality: 'Modalités',
            modalityExchange: 'Modalité éch.',
            negativeEquity: 'Équité négative',
            positiveEquity: 'Équité positive',
            presented: 'Visite | Visites',
            renewalType: 'Type de @:lexicon.portfolio.singular',
            saleCardBack: 'Total @:lexicon.renewed.plural',
            sold: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            soldBy: '@.capitalize:lexicon.sold.singular par',
            visitWithAppointment: 'Avec RV',
            visitWithoutAppointment: 'Sans RV',
            visitWithoutAppointmentTooltip: 'Nombre de visites sans rendez-vous sur le nombre de clients assignés',

            hideNoLeadAccount: 'Cacher les comptes sans clients',
            hideNoLeadAdvisor: 'Cacher les conseillers sans clients',
            showNoLeadAccount: 'Afficher les comptes sans clients',
            showNoLeadAdvisor: 'Afficher les conseillers sans clients',
        },
        event: {
            appointment: 'Rendez-vous | Rendez-vous',
            apptCall: 'RV téléphonique | RV téléphoniques',
            apptFilterExcludesCall: '(tél. exclus)',
            apptNoDate: 'Sans date',
            apptNoTime: 'Sans heure',
            leadToReach: 'Conseiller doit appeler',
            lostBack: 'Total perdus',
            potentialAppointment: 'Rendez-vous potentiel | Rendez-vous potentiels',
            presented: 'Visite | Visites',
            reached: 'Rejoint | Rejoints',
            reachedBack: 'Total rejoints',
            saleCardBack: '@.capitalize:lexicon.sale.plural totales',
            uncertainLead: 'Client incertain | Clients incertain',
            visitWithAppointment: 'Avec RV',
            visitWithoutAppointment: 'Sans RV',
        },
        webOrder: {
            allFollowedTooltip:
                'Communications effectuées dans les dates sélectionnées sur les commandes actuellement affichées',
            appointment: 'Rendez-vous | Rendez-vous',
            attempted: 'Tentative | Tentatives',
            attemptedBack: 'de demandes traitées',
            averageContactTooltip:
                "Nombre moyen d'échange courriel et d'appels de 60sec ou + entre le lead et l'utilisateur",
            averageFollowedTooltip: 'Nombre de suivi moyen par commande',
            delivered: 'Véhicule @:lexicon.delivered.singular | Véhicules @:lexicon.delivered.plural',
            deliveredBack: 'Véhicules @:lexicon.delivered.plural',
            deliveryFailed: "Échec lors de l'envoi du courriel",
            firstUpdateAverage: 'Temps moyen première modification',
            leads: 'Commande | Commandes',
            loadingGraphs: 'Génération des graphiques en cours...',
            parent: 'Parent',
            presented: 'Visite | Visites',
            reached: 'Rejoint | Rejoints',
            reachedAverage: 'Temps moyen de première réponse',
            reachedBack: 'des leads rejoints',
            reachedBackShort: 'de rejoints',
            resultTimeInfo: "<strong>Temps de première réponse :</strong><br>Première communication à partir du CRM, non applicable si la première communication est manuelle ou automatisée.<br><br><strong>Temps de première modification :</strong><br>Première modification de la fiche détaillée (tous les champs).<br><br><em>Les temps respectent les heures d'ouverture du concessionnaire.</em>",
            sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            salesOverLeads: ' @.capitalize:lexicon.sale.plural / Commandes',
            salesOverVisits: ' @.capitalize:lexicon.sale.plural / Visites',
            untreated: 'Attente | Attentes',
            untreatedBack: 'des commandes en attentes',
            visitWithAppointment: 'Avec RV',
            visitWithoutAppointment: 'Sans RV',
        },
        leadXpress: {
            allFollowedTooltip:
                'Communications effectuées dans les dates sélectionnées sur les leads actuellement affichés',
            appointment: 'Rendez-vous | Rendez-vous',
            attempted: 'Tentative | Tentatives',
            attemptedBack: 'de demandes traitées',
            averageContactTooltip:
                "Nombre moyen d'échange courriel et d'appels de 60sec ou + entre le lead et l'utilisateur",
            averageFollowedTooltip: 'Nombre de suivi moyen par lead',
            delivered: 'Véhicule @:lexicon.delivered.singular | Véhicules @:lexicon.delivered.plural',
            deliveredBack: 'Véhicules @:lexicon.delivered.plural',
            deliveryFailed: "Échec lors de l'envoi du courriel",
            deposit: 'Dépôts',
            firstUpdateAverage: 'Temps moyen première modification',
            leads: 'Lead | Leads',
            longTerm: 'Long terme',
            lost: 'Perdu | Perdus',
            parent: 'Parent',
            presented: 'Visite | Visites',
            reached: 'Rejoint | Rejoints',
            reachedAverage: 'Temps moyen de première réponse',
            reachedBack: 'des leads rejoints',
            resultTimeInfo:
                "<strong>Temps de première réponse :</strong><br>Première communication à partir du CRM, non applicable si la première communication est manuelle ou automatisée.<br><br><strong>Temps de première modification :</strong><br>Première modification de la fiche détaillée (tous les champs).<br><br><em>Les temps respectent les heures d'ouverture du concessionnaire.</em>",
            sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            salesOverLeads: ' @.capitalize:lexicon.sale.plural / Leads',
            salesOverVisits: ' @.capitalize:lexicon.sale.plural / Visites',
            untreated: 'Attente | Attentes',
            untreatedBack: 'des leads en attentes',
            visitWithAppointment: 'Avec RV',
            visitWithoutAppointment: 'Sans RV',
        },
        webBoost: {
            appointment: 'Rendez-vous | Rendez-vous',
            averageTimeHeader: 'Temps moyen',
            businessHours: "Lead à l'intérieur des heures d'affaires | Leads à l'intérieur des heures d'affaires",
            businessHoursBack: "de leads à l'intérieur des heures d'affaires",
            confirmRevive: "Êtes-vous sûr de vouloir relancer l'appel WebBoost?",
            leads: 'Lead | Leads',
            leadsReached: 'Lead rejoint | Leads rejoints',
            leadsReachedBack: 'Leads rejoints avec WebBoost',
            leadsTreated: 'Lead traité | Leads traités',
            leadsTreatedBack: 'Leads traités avec WebBoost',
            reachedTime: 'Temps moyen',
            reachedTimeBack: 'Temps moyen entre la demande du lead et la premier tentatives',
            revive: 'Relancer',
            reviveWebBoost: "Relancer l'appel WebBoost",
            sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            saleBack: '@.capitalize:lexicon.sale.plural totales',
            saleHeader: '@.capitalize:lexicon.sale.plural',
            treatedHeader: 'Traités',
            validPhone: 'Lead avec un # de téléphone valide | Leads avec un # de téléphone valide',
            validPhoneBack: 'des leads avaient un # de téléphone valide',
        },
        phoneUp: {
            allFollowedTooltip:
                'Communications effectuées dans les dates sélectionnées sur les Phone-up actuellement affichés',
            answered: 'Appel répondu | Appels répondus',
            appointment: 'Rendez-vous | Rendez-vous',
            assigned: 'Appels répondus',
            averageContactTooltip:
                "Nombre moyen d'échange courriel et d'appels de 60sec ou + entre le Phone-up et l'utilisateur",
            averageFollowedTooltip: 'Nombre de suivi moyen par Phone-up',
            callBy: 'Appels par ',
            clientAuto: 'Ajouté automatiquement | Ajoutés automatiquement',
            clientManual: 'Ajouté manuellement | Ajoutés manuellement',
            clients: 'Appel reçu | Appels reçu',
            delivered: 'Véhicules @:lexicon.delivered.plural',
            phoneUp: 'Phone-up',
            presented: 'Visite | Visites',
            sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            saleCardBack: '@.capitalize:lexicon.sale.plural totales',
            salesOverCalls: '@.capitalize:lexicon.sale.plural / Répondus',
            salesOverVisits: ' @.capitalize:lexicon.sale.plural / Visites',
            viaIvr: "Appel provenants de l'IVR | Appels provenants de l'IVR",
            visitWithAppointment: 'Avec RV',
            visitWithoutAppointment: 'Sans RV',
            waitTime: 'Temps moyen',
            waitTimeCardBack: 'Temps moyen entre la réceptionniste et le conseiller',
            visitWithoutAppointmentTooltip: 'Nombre de visites sans rendez-vous sur le nombre de Phone-up assignés',

            hideNoLeadAccount: 'Cacher les comptes sans appels',
            hideNoLeadAdvisor: 'Cacher les conseillers sans appels',
            showNoLeadAccount: 'Afficher les comptes sans appels',
            showNoLeadAdvisor: 'Afficher les conseillers sans appels',
        },
        walkIn: {
            allFollowedTooltip:
                'Communications effectuées dans les dates sélectionnées sur les @:lexicon.walkIn.singular actuellement affichés',
            answered: 'Appels répondus',
            appointment: 'Rendez-vous',
            assigned: '@.capitalize:lexicon.walkIn.singular',
            averageContactTooltip:
                "Nombre moyen d'échange courriel et d'appels de 60sec ou + entre le @:lexicon.walkIn.singular et l'utilisateur",
            averageFollowedTooltip: 'Nombre de suivi moyen par @:lexicon.walkIn.singular',
            beBack: 'B-Back | B-Backs',
            beBackCardBack: "Clients ayant visité le concessionnaire plus d'une fois",
            beBackSaleCardBack: '@.capitalize:lexicon.sale.singular ayant visité le concessionnaire plus d\'une fois | @.capitalize:lexicon.sale.plural ayant visité le concessionnaire plus d\'une fois',
            clients: 'Appels reçu',
            csiPopover: 'CSI sur le nombre de véhicules @:lexicon.delivered.plural',
            delivered: '@.capitalize:lexicon.delivered.singular | @.capitalize:lexicon.delivered.plural',
            deliveredVehicle: 'Véhicule @:lexicon.delivered.singular | Véhicules @:lexicon.delivered.plural',
            deliveredCardBack: 'Véhicules @:lexicon.delivered.plural',
            freshUp: 'Fresh-Up',
            freshUpBack: 'Clients qui ont visité le concessionnaire',
            ofCsi: 'de CSI effectués',
            ofRoadTest: "d'essais routier",
            ofTakeOver: 'de take over',
            ordersUp: '@.capitalize:lexicon.sale.singular Up | @.capitalize:lexicon.sale.plural Up',
            ordersUpDescription: '@.capitalize:lexicon.sale.singular qui ont nécessité une seule visite | @.capitalize:lexicon.sale.plural qui ont nécessité une seule visite ',
            roadTest: 'Essai routier | Essais routier',
            sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
            saleBeBack: '@.capitalize:lexicon.sale.plural B-Back',
            saleBeBackCardBack: "@.capitalize:lexicon.sale.plural provenant de clients ayant visité le concessionnaire plus d'une fois",
            saleCardBack: '@.capitalize:lexicon.sale.plural totales',
            saleFromWalkin: 'Total de @:lexicon.sale.plural du @.capitalize:lexicon.walkIn.singular',
            saleUp: '@.capitalize:lexicon.sale.singular Up | @.capitalize:lexicon.sale.plural Up',
            saleUpCardBack: '@.capitalize:lexicon.sale.plural suite à une seule visite au concessionnaire',
            takeOver: 'Take over',
            totalOrders: 'Total de @:lexicon.sale.singular | Total de @:lexicon.sale.plural',
            totalOrdersBack: 'Total de @:lexicon.sale.singular pour @.capitalize:lexicon.walkIn.singular | Total de @:lexicon.sale.plural pour @.capitalize:lexicon.walkIn.singular',
            walkIn: '@.capitalize:lexicon.walkIn.singular | @.capitalize:lexicon.walkIn.plural',

            hideNoLeadAccount: 'Cacher les comptes sans leads',
            hideNoLeadAdvisor: 'Cacher les conseillers sans leads',
            showNoLeadAccount: 'Afficher les comptes sans leads',
            showNoLeadAdvisor: 'Afficher les conseillers sans leads',
        },
        database: {
            male: 'Homme',
            female: 'Femme',
            fr: 'Français',
            en: 'Anglais',
            mr: 'M.',
            ms: 'Mme',
            mrs: 'Mme',
            miss: 'Mlle',
            dr: 'Dr.',
            me: 'Me.',

            // Male posts
            post_programmer: 'Programmeur',
            post_coach: 'Formateur',
            post_account_manager: 'Directeur de compte',
            post_analyst: 'Analyste',
            post_other: 'Autre',
            post_admin: 'Administrateur',
            post_agent: 'Agent',
            post_director: 'Directeur',

            post_sales_assistant: 'Adjoint aux ventes',
            post_chief_director: 'Directeur général',
            post_delivery_man: 'Livreur',
            post_sales_director: 'Directeur des ventes',
            post_service_director: 'Directeur service',
            post_web_director: 'Directeur web',
            post_assistant_director: 'Directeur adjoint',
            post_owner: 'Propriétaire',
            post_chief_sales_director: 'Directeur général des ventes',
            post_assistant_sales_director: 'Directeur des ventes adjoint',
            post_commercial_director: 'Directeur commercial',
            post_internet_sales_director: 'Directeur des ventes Internet',
            post_commercial_vehicle_director: 'Directeur des véhicules commerciaux',
            post_controller: 'Contrôleur',
            post_technical_advisor: 'Aviseur technique',
            post_advisor: 'Conseiller aux ventes',
            post_internet_seller: 'Conseiller aux @:lexicon.sale.plural Internet',
            post_representative: 'Représentant des ventes',
            post_receptionist: 'Réceptionniste',
            post_product_specialist: 'Spécialiste en produits',
            post_product_advisor: 'Conseiller en produits',
            post_service_agent: 'Agent de service à la clientèle',
            post_internet_specialist: 'Spécialiste des ventes Internet',
            post_product_specialist_2: 'Product Genius',
            post_bdc_agent: 'Agent BDC',
            post_relationist: 'Relationniste',
            post_web_manager: 'Gestionnaire web',
            post_web_specialist: 'Spécialiste web',
            post_web_sales_advisor: 'Conseiller aux ventes web',
            post_internet_specialist_2: 'Spécialiste Internet',
            post_director_bdc: 'Directeur BDC',
            post_commercial: 'Directeur commercial',
            post_director_renewal: 'Directeur renouvellement',
            post_service_advisor: 'Aviseur',

            // Female posts
            post_programmer_w: 'Programmeuse',
            post_coach_w: 'Formatrice',
            post_account_manager_w: 'Directrice de compte',
            post_analyst_w: 'Analyste',
            post_other_w: 'Autre',

            post_sales_assistant_w: 'Adjointe aux ventes',
            post_chief_director_w: 'Directrice générale',
            post_delivery_woman: 'Livreuse',
            post_sales_director_w: 'Directrice des ventes',
            post_service_director_w: 'Directrice service',
            post_web_director_w: 'Directrice web',
            post_assistant_director_w: 'Directrice adjointe',
            post_owner_w: 'Propriétaire',
            post_chief_sales_director_w: 'Directrice générale des ventes',
            post_assistant_sales_director_w: 'Directrice des ventes adjointe',
            post_commercial_director_w: 'Directrice commercial',
            post_internet_sales_director_w: 'Directeur des ventes Internet',
            post_commercial_vehicle_director_w: 'Directeur des véhicules commerciaux',
            post_controller_w: 'Contrôleuse',
            post_technical_advisor_w: 'Aviseure technique',
            post_internet_seller_w: 'Conseillère aux ventes Internet',
            post_representative_w: 'Représentante des ventes',
            post_service_agent_w: 'Agente de service à la clientèle',
            post_product_specialist_w: 'Spécialiste en produits',
            post_product_advisor_w: 'Conseillère en produits',
            post_advisor_w: 'Conseillère aux ventes',
            post_internet_specialist_w: 'Spécialiste des ventes Internet',
            post_product_specialist_2_w: 'Product Genius',
            post_bdc_agent_w: 'Agente BDC',
            post_receptionist_w: 'Réceptionniste',
            post_relationist_w: 'Relationniste',
            post_web_manager_w: 'Gestionnaire web',
            post_web_specialist_w: 'Spécialiste web',
            post_web_sales_advisor_w: 'Conseillère aux ventes web',
            post_internet_specialist_2_w: 'Spécialiste Internet',
            post_director_bdc_w: 'Directrice BDC',
            post_commercial_w: 'Directrice commercial',
            post_director_renewal_w: 'Directrice renouvellement',
            post_service_advisor_w: 'Aviseure',

            role_admin: 'Administrateur',
            role_manager: 'Gestionnaire',
            role_programmer: 'Programmeur',
            role_director: 'Directeur',
            role_agent: 'Agent BDC',
            role_advisor: 'Conseiller',
            role_analyst: 'Analyste',
            role_director_bdc: 'Directeur BDC',
            role_commercial: 'Directeur commercial',
            role_service_advisor: 'Aviseur',
            role_service_agent: 'Agent service',

            role_admin_w: 'Administratrice',
            role_manager_w: 'Gestionnaire',
            role_programmer_w: 'Programmeure',
            role_director_w: 'Directrice',
            role_agent_w: 'Agente BDC',
            role_advisor_w: 'Conseillère',
            role_analyst_w: 'Analyste',
            role_director_bdc_w: 'Directrice BDC',
            role_commercial_w: 'Directrice commerciale',
            role_service_advisor_w: 'Aviseure',
            role_service_agent_w: 'Agente service',

            post_sales_manager: 'Gestionnaire des ventes',
            post_service_manager: 'Gestionnaire de service',
            post_assistant_manager: 'Gestionnaire adjoint',
            post_assistant_sales_manager: 'Gestionnaire des ventes adjoint',
            post_commercial_manager: 'Gestionaire Commercial',
            post_internet_sales_manager: 'Gestionnaire des ventes internet',
            post_commercial_vehicle_manager: 'Gestionnaire commercial de véhicule',
            post_manager_bdc: 'Gestionnaire BDC',
            post_manager: 'Gestionnaire',
            post_manager_renewal: 'Gestionnaire renouvellement',

            post_sales_manager_w: 'Gestionnaire des ventes',
            post_service_manager_w: 'Gestionnaire de service',
            post_assistant_manager_w: 'Gestionnaire adjointe',
            post_assistant_sales_manager_w: 'Gestionnaire des ventes adjointe',
            post_commercial_manager_w: 'Gestionaire commercial',
            post_internet_sales_manager_w: 'Gestionnaire des ventes internet',
            post_commercial_vehicle_manager_w: 'Gestionnaire commercial de véhicule',
            post_manager_bdc_w: 'Gestionnaire BDC',
            post_manager_w: 'Gestionnaire',
            post_manager_renewal_w: 'Gestionnaire renouvellement',

            lead_type_email: 'Lead Web',
            lead_type_phone: 'Téléphone',
            lead_type_walk_in: '@.capitalize:lexicon.walkIn.singular',
            lead_type_loyalty: 'Loyauté',
            lead_type_renewal: '@.capitalize:lexicon.portfolio.singular',
            lead_type_event: 'Événement',
            lead_type_sms: 'SMS',
            lead_type_pre_booking: 'Pré-booking',
            lead_type_dms: 'DMS',
            lead_type_web_order: 'Commande Web',

            lead_vehicle_type_exchange: 'Actuel',
            lead_vehicle_type_wanted: 'Recherché',
        },
        configMailClient: {
            v3: {
                status: 'Statut',
                provider: 'Fournisseur',
                grantStatus: 'Statut de Connection',
                activatedOn: 'Activé le',
                email: 'Courriel',
                valid: 'Valide',
                invalid: 'Invalide',
                404: 'Indisponible',
            },
            activate: 'Activer le client de messagerie',
            reActivate: 'Ré-activer le client de messagerie',
            reAuthenticate: 'Reconnecter le client de messagerie',
            alreadyActive: 'Le client de messagerie est déjà activé',
            deactivate: 'Désactiver le client de messagerie',
            deactivateSuccess: 'Le client de messagerie a été désactivé',
            deactivateFailure: 'Le client de messagerie n\'a pus être désactivé',
            reactivateSuccess: 'Le client de messagerie a été ré-activé',
            error: 'Une erreur est survenue',
            revalidate: 'Revalider vos informations du client de messagerie',
        },
        support: {
            success: 'Votre demande a été envoyé avec succès',
        },
        mailClient: {
            reset: 'Réinitialiser',
            draftDate: 'Date du brouillon',
            activixSupport: 'Support Activix',
            message: 'Message',
            attachments: 'Pièces jointes',
            url: 'URL',
            customerService: 'Service client',
            missingConfig: "Vous devez d'abord configurer votre boîte de réception.",
            importingEmails: 'Nous importons actuellement vos courriels. Veuillez réessayer dans quelques minutes.',
            composerMessage: 'Composer un nouveau message',
            editMessage: 'Modifier le message',
            showLeads: 'Afficher le lead | Afficher les leads',
            removedFromMassMailing:
                "{0} lead a été retiré de la liste d'envoi, car un utilisateur est déjà en train de lui écrire ou la fait dans les 10 dernières minutes. | {0} leads ont été retirés de la liste d'envoi, car un utilisateur est déjà en train de leur écrire ou la fait dans les 10 dernières minutes.",
            removedFromMassMailingDoNotMarket:
                "{0} lead a été retiré de la liste d'envoi, car le lead est répertorié comme do not market. | {0} leads ont été retirés de la liste d'envoi, car plusieurs leads sont répertoriés comme do not market.",
            contactedBy: 'Contacté par {0}',
            mailClientNotConfigured:
                "La boîte de récéption n'est pas configurée. Veuillez la configurer avant d'envoyer un courriel",
            blackListed: " est d'un type de fichier sur la blacklist",
            totalAttachmentExceed: 'La taille totale du fichier joint ne peut pas dépasser',
            emptyBody: 'Description vide',
            emptyBodyQuestion: 'Description est vide, voulez-vous vraiment envoyer ce courriel?',
            emptySubject: 'Sujet vide',
            emptySubjectQuestion: 'Le sujet est vide, voulez-vous vraiment envoyer ce courriel?',
            error: 'Erreur',
            errorSendingMessage: "Erreur lors de l'envoi du message.",
            draftSaved: 'Le brouillon a été sauvegardé correctement.',
            deleteEmailConfirmation: 'Voulez-vous vraiment supprimer ce courriel, il sera perdu définitivement!',
            deleteContactConfirmation: 'Voulez-vous vraiment supprimer ce contact?',
            discardIt: 'Oui, supprimer!',
            saveDraft: 'Voulez-vous sauvegarder le brouillon?',
            saveDraftQuestion: 'Vouslez-vous sauvegarder ce courriel dans vos brouillons?',
            readMail: 'Lire le courriel',
            reply: 'Répondre',
            replyAll: 'Répondre à tous',
            forward: 'Faire suivre',
            from: 'De',
            to: 'À',
            noSubject: 'Aucun sujet',
            delete: 'Supprimer',
            archive: 'Archiver',
            print: 'Imprimer',
            errorArchive: "Erreur au moment d'archiver le courriel.",
            errorDeleting: 'Erreur au moment de suprimer le courriel.',
            emailNotFound: 'Courriel introuvable.',
            compose: 'Composer',
            generalFolder: 'Dossiers généraux',
            inbox: 'Boîte de récéption',
            sent: "Boîte d'envoi",
            drafts: 'Brouillons',
            spam: 'Pourriels',
            trash: 'Corbeille',
            archive2: 'Archives',
            otherFolders: 'Autres dossiers',
            loading: 'Chargement',
            backInbox: 'Retour à la boîte de récéption',
            noEmail: 'Aucun courriel',
            errorConnectingFolderTitle: 'Erreur de connexion au dossier',
            errorConnectingFolder:
                'Assurez-vous que votre connexion IMAP est correcte et que vos dossiers sont choisis correctement.',
            errorMoving: 'Erreur lors du transfert de courriel.',
            errorAttachment: 'Erreur lors de la sauvegarde du fichier joint',
            pressEnterSelect: 'Pressez entrer pour séléctionner',
            selected: 'Sélectionner',
            pressEnterRemove: 'Pressez entrer pour transférer',
            draft: 'Brouillon',
            send: 'Envoyer',
            addAttachment: 'Joindre',
            max: 'Max',
            discard: 'Supprimer',
            editContactInfo: 'Mettre à jour les infos de contact',
            name: 'Nom',
            emailAddress: 'Courriel',
            enterValidEmail: 'Veuillez entrer une adresse valide',
            notPreferredEmail: 'Adresse courriel préférée non sélectionnée',
            close: 'Fermer',
            save: 'Enregistrer',
            subject: 'Sujet',
            cc: 'CC',
            bcc: 'CCI',
            all: 'Tous les messages',
            success: 'Le courriel a été envoyé avec succès',
            emptySubjectTooltip: 'Le courriel doit avoir un sujet',
            emptyRecipientTooltip: 'Vous devez spécifier au moins un destinataire',
            emptyBodyTooltip: 'Le contenu du courriel ne peut pas être vide',
            emptySenderTooltip: "L'expéditeur ne peut pas être vide",
            emptyFallbackSenderTooltip: "L'expéditeur de secours ne peut pas être vide",
            emptyDivisionTooltip: 'La division est requise',
            emptyResponseTemplateTooltip: 'Vous devez sélectionner un modèle de lettre',
            forwarding: 'Transférer',
            enterCcRecipients: 'Ajoutez les destinataires en CC',
            enterBccRecipients: 'Ajoutez les destinataires en CCI',
            enterForwardRecipients: 'Ajoutez les destinataires',
            videoUploading: 'Attendez que la vidéo soit terminée de téléverser',
        },
        saleTable: {
            availableDate: 'Véh. ici le',
            averageValue: 'Obj. moy. / unité',
            carryOverTooltipOff: 'Cliquez pour cacher les Carry Over',
            carryOverTooltipOn: 'Cliquez pour afficher les Carry Over',
            color: 'Couleur',
            commercial: 'F&I',
            creditApproved: 'Approuvés au crédit',
            creditApprovedBack: 'Nombre de clients actuellement approuvés au crédit',
            deliverableDate: 'Véh. @.capitalize:lexicon.deliverable.singular',
            deliveredBack: 'Véhicules @:lexicon.delivered.plural',
            deliveredDate: '@.capitalize:lexicon.delivered.singular',
            deliveries: '@.capitalize:lexicon.delivery.singular | @.capitalize:lexicon.delivery.plural',
            delivery: '@.capitalize:lexicon.delivery.plural',
            deliveryBack: 'Nombre de @:lexicon.sale.singular @:lexicon.delivered.femininePlural au client ou avec une date de @:lexicon.delivery.singular planifiée',
            deliveryDate: '@.capitalize:lexicon.deliveryDate.singular',
            deliveryMonth: 'À @:lexicon.delivery.singular dans le mois',
            deliveryPostponed: 'À @:lexicon.delivery.singular dans le futur (Reporté)',
            displayAllUsersEyeBlueToolTip: "Afficher tous les vendeurs y compris ceux n'ayant aucune @:lexicon.sale.singular et aucun objectif",
            displayUsersWithSaleOrObjectivesEyeToolTip: 'Afficher seulement les vendeurs qui ont des @:lexicon.sale.plural ou des objectifs',
            gas: 'Ravitaillé',
            inStock: 'En stock',
            inStockBack: 'Nombre de @:lexicon.sale.plural en stock',
            inTransit: 'En transit',
            lastMonthSales: '@.capitalize:lexicon.sale.singular du mois précédent',
            locate: 'Locate',
            locates: 'Locates',
            monthStartDayTooltip: 'Designe le jour du debut de vos mois de @:lexicon.sale.plural (Pour les objectifs seulement)',
            new: 'Neuf',
            new_and_used: 'Neuf et occasion',
            newShort: 'N',
            nextStep: 'Prochaine étape: ',
            noAdvisorFistName: 'Aucun',
            noAdvisorLastName: 'Conseiller',
            noDivisionShort: '?',
            notAvailable: 'Non Dispo.',
            objective: 'Objectif',
            objectiveReached: 'Objectif atteint',
            objectiveReachedDeliveries: 'Objectif de @:lexicon.delivery.plural atteint',
            offer: '# Offre',
            order: 'Commande',
            orders: 'Commandes',
            ordersNotDelivered: 'Commandes @.capitalize:lexicon.undelivered.plural de toutes dates',
            progressStateTooltip: "Processus d'approbation",
            recordedDate: 'Enregistré',
            recordedDateTooltipOff: 'Cliquez pour filtrer selon les dates de @:lexicon.sale.singular',
            recordedDateTooltipOn: "Cliquez pour filtrer selon les dates d'enregistrement",
            saleAmmount: 'Objectif',
            saleJournal: 'Tableau des @:lexicon.sale.plural & @:lexicon.delivery.plural',
            stock: 'En stock',
            stockSold: '@.capitalize:lexicon.sold.plural en stock',
            suffix: 'Suffix',
            teamAverage: "% de l'équipe",
            total: 'Totaux',
            totalAvePct: 'Total / Moyenne / %',
            transmission: 'Transmission',
            unknown: 'Inconnu',
            used: 'Occasion',
            usedShort: 'O',
            user: 'Conseiller',
            waitingSale: '@.capitalize:lexicon.sale.singular en att.',
            waitingSaleForce: '@.capitalize:lexicon.sale.singular en att. manuelle',
            waitingSaleNoAssigned: '@.capitalize:lexicon.sale.plural en attentes non assignées',
            waitingSaleNoAssignedShort: 'ø assignées',
            waitingSaleTotal: '@.capitalize:lexicon.sale.singular en att. totales',
            withoutDivision: 'Sans division',
            yourObjective: 'Votre objectif',

            profitAndAverage: {
                tooltip: {
                    byDeliveredDate: 'Cliquez pour utiliser la date de @:lexicon.delivery.singular',
                    byRecordedDate: 'Cliquez pour utiliser la date d\'enregistrement',
                    bySaleDate: 'Cliquez pour utiliser la date de @:lexicon.sale.singular',
                    disabledByRecordedDate: 'Par date d\'enregistrement',
                },
            },

            cards: {
                approved: 'Approuvé | Approuvés',
                carryOver: 'Carry Over | Carry Overs',
                carryOverBack: '@.capitalize:lexicon.sale.plural des mois précédents @:lexicon.delivered.femininePlural ou avec une @:lexicon.delivery.singular prévue dans les dates sélectionnées',
                carryOverNotDelivered: 'Carry Over non @:lexicon.delivered.singular | Carry Overs non @:lexicon.delivered.plural',
                carryOverPlanned: 'Carry Over planifié | Carry Overs planifiés',
                delivered: '@.capitalize:lexicon.delivered.singular | @.capitalize:lexicon.delivered.plural',
                deliveredBack: 'Véhicules @:lexicon.delivered.plural',
                delivery: {
                    inDate: '@.capitalize:lexicon.delivery.singular dans les dates | @.capitalize:lexicon.delivery.plural dans les dates',
                    inFuture: '@.capitalize:lexicon.delivery.singular future | @.capitalize:lexicon.delivery.plural futures',
                },
                lost: 'Perdu | Perdus',
                lostBack: 'Clients perdus ou refusés au crédit',
                lostByClient: 'Perdu / client | Perdus / client',
                lostByDealer: 'Perdu / concessionnaire | Perdus / concessionnaire',
                planned: 'Planifié | Planifiés',
                plannedBack: '@.capitalize:lexicon.delivery.plural planifiées',
                plannedTooltip: '@.capitalize:lexicon.sale.plural dans les dates sélectionnées ayant une @:lexicon.delivery.singular planifiée dans les dates sélectionnées',
                postponed: 'Reporté | Reportés',
                postponedBack: '@.capitalize:lexicon.delivery.plural reportées',
                postponedTooltip: 'Nombre de @:lexicon.delivery.plural reportées après les dates séléctionnées',
                recorded: 'Enregistré | Enregistrés',
                recordedBack: 'Nombre de véhicules enregistrés dans les dates sélectionnées',
                refused: 'Refusé | Refusés',
                sale: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
                saleAndCarryOver: '@.capitalize:lexicon.sale.singular & Carry Over | @.capitalize:lexicon.sale.plural & Carry Overs',
            },
            legendTooltips: {
                approved: '@.capitalize:lexicon.sale.singular approuvée dans les dates sélectionnées, mais non planifiée',
                carryOver: '@.capitalize:lexicon.sold.singular avant les dates sélectionnées, mais @:lexicon.delivered.singular dans les dates sélectionnées',
                carryOverNotDelivered: '@.capitalize:lexicon.sold.singular avant les dates sélectionnées, mais non @:lexicon.delivered.singular',
                carryOverPlanned: '@.capitalize:lexicon.sold.singular avant les dates sélectionnées, mais planifié dans les dates sélectionnées',
                delivered: '@.capitalize:lexicon.sold.singular et @:lexicon.delivered.singular dans les dates sélectionnées',
                discounted: '@.capitalize:lexicon.sale.singular escomptée',
                lost: 'Lead perdu',
                planned: '@.capitalize:lexicon.sold.singular et planifié dans les dates sélectionnées',
                postponed: '@.capitalize:lexicon.sold.singular dans les dates sélectionnées, mais planifié après les dates sélectionnées',
                refused: '@.capitalize:lexicon.sale.singular refusée ou lead perdu',
                sales: '@.capitalize:lexicon.sold.singular dans les dates sélectionnées, mais non planifié',
                waitingSale: '@.capitalize:lexicon.sold.singular avant les dates sélectionnées et sans @:lexicon.delivery.singular prévue',
                waitingSaleForce: '@.capitalize:lexicon.sold.singular avant les dates sélectionnées et sans @:lexicon.delivery.singular prévue - créé manuellement par un utilisateur',
            },
            filters: {
                approved: 'Approuvé',
                carryOver: 'Carry Over',
                carryOverNotDelivered: 'Carry Over non livré',
                delivered: '@:lexicon.delivered.singular',
                deliveryCarryOver: 'Carry Over planifié',
                discounted: 'Escompté',
                lost: 'Perdu',
                manualWaitingSale: '@.capitalize:lexicon.sale.singular en att. manuelle',
                none: 'Aucun',
                planned: 'Planifié',
                postponed: 'Reporté',
                refused: 'Refusé',
                sold: '@.capitalize:lexicon.sold.singular',
                waitingSale: '@.capitalize:lexicon.sale.singular en att.',
            },
            gridReport: {
                carView: 'Voiture',
                divisionView: 'Division',
                nameView: 'Nom',
                selectViewType: 'Choisir un type de vue',
                stockView: '# Stock',
            },
            months: {
                january: 'Janvier',
                february: 'Février',
                march: 'Mars',
                april: 'Avril',
                mai: 'Mai',
                june: 'Juin',
                july: 'Juillet',
                august: 'Août',
                september: 'Septembre',
                october: 'Octobre',
                november: 'Novembre',
                december: 'Décembre',
            },
            steps: {
                available: 'Arrivé du véh.',
                csi: 'CSI',
                deliverable: '@.capitalize:lexicon.deliverable.singular',
                delivered: '@.capitalize:lexicon.delivery.singular',
                delivery: 'Planif. @:lexicon.delivery.singular',
                gas: 'Ravitaillé',
                inspected: 'Inspection',
                prepared: 'Préparation',
                recorded: 'Enregistrement',
            },
            stickers: {
                approved: 'Approuvé',
                carryOver: 'Carry Over',
                delivered: '@.capitalize:lexicon.delivered.singular',
                deliveryCarryOver: 'Carry Over',
                carryOverNotDelivered: 'Carry Over',
                discounted: 'Escompté',
                lost: 'Perdu',
                none: 'Aucun',
                planned: 'Planifié',
                postponed: 'Reporté',
                refused: 'Refusé',
                sold: '@.capitalize:lexicon.sold.singular',
                waitingSale: '@.capitalize:lexicon.sale.singular en att.',
            },
            table: {
                accessory: 'Pneus & accessoires',
                accessoryAverage: 'Moyenne',
                accessoryOnly: 'Accessoires',
                accessoryProfit: 'Profit',
                advisors: 'Conseillers',
                commercial: 'Profits dir. comm.',
                commercialAverage: 'Moyenne',
                commercialProfit: 'Profit',
                delivered: '@.capitalize:lexicon.delivered.plural',
                deliveries: '@.capitalize:lexicon.delivery.plural',
                goals: 'Objectifs',
                inTransit: 'En transit',
                lastMonth: 'null',
                locate: 'Locate',
                objective: 'Objectifs',
                objectiveAverage: 'Moyenne',
                objectiveProfit: 'Profit',
                objectiveSales: '@.capitalize:lexicon.sale.plural',
                order: 'Commandes',
                previousSales: '@.capitalize:lexicon.sale.plural',
                profits: 'Profits',
                saleAverage: 'Moyenne',
                saleProfit: 'Profit',
                sales: '@.capitalize:lexicon.sale.plural',
                sold: '@.capitalize:lexicon.sold.plural',
                stock: 'Stocks',
                tirePercent: 'Pneus',
                total: 'Total',
                user: 'null',
                variation: 'Variation',
                variationTooltip: 'Variation des @:lexicon.sale.plural pour l\'année précédente basée sur les dates sélectionnées.<br><br>Formule:<br><span class="whitespace-nowrap">(Courantes - Préc.) / Préc. * 100</span>',
                vehicles: 'Véhicules',
            },
            tooltips: {
                accessoryAverage: 'Moyenne des profits sur les accessoires par rapport au nombre de @:lexicon.sale.plural',
                accessoryProfit: 'Total des profits entré dans le champ accessoires',
                carryOver: 'Les carry over ne sont pas inclus<br>dans le total des @:lexicon.sale.plural',
                commercialAverage: 'Moyenne des profits de produits du directeur commercial sur le nombre de @:lexicon.sale.plural',
                commercialProfit: 'Total des profits sur les produits du directeur commercial',
                waitingSale: 'Véhicules @:lexicon.sold.plural à une date précédant les dates sélectionnées, non @:lexicon.delivered.plural et sans date de @:lexicon.delivery.singular planifiée',
            },
        },
        multiselect: {
            maximumReached: "Maximum de {0} options sélectionnées. Retirez d'abord une option sélectionnée pour en sélectionner une autre.",
            noResult: 'Aucun résultat...',
            pressEnterRemove: 'Appuyez sur entrer pour désélectionner',
            pressEnterSelect: 'Appuyez sur entrer pour sélectionner',
            selected: 'Sélectionné',
            selectOption: 'Aucune sélection',
        },
        groupManagement: {
            deleteGroup: 'Supprimer le groupe',
            relatedAccounts: 'Comptes associés',
            relatedUsers: 'Utilisateurs associés',
        },
        phoneProvider: {
            addAlreadyBoughtNumber:
                "Numéro déjà acheté, veuillez entrer le numéro ici pour l'ajouter à la base de données",
            areaCode: 'Indicatif régional',
            custom: 'Personnalisé',
            deletePhoneConfirmation:
                'Si vous supprimer ce numéro, il sera impossible de le récupérer. Êtes-vous certains de ne plus en avoir besoin?',
            deletedExternalPhoneConfirmation:
                'Supprimer ce numéro n\'affectera pas le numéro dans le compte de l\'utilisateur. Si l\'utilisateur change son numéro de téléphone il ne sera pas synchronisé non plus.',
            direction: 'Direction',
            division: 'Division',
            deactivatePressToTalk:
                "En désactivant cette option, il est possible que les appels de client tombent sur la boite vocale de l'utilisateur, terminant ainsi la procédure d'appel des clients.",
            deactivateIt: 'Désactiver',
            directionNotApplied: "La direction n'est pas appliquée lorsque le fournisseur téléphonique n'est pas intégré",
            extension: 'Extension',
            ivr: 'IVR',
            montreal: 'Montréal',
            noAdvisor: 'Aucun conseiller',
            noDivision: 'Aucune division',
            noNumberAvailable: 'Aucun numéro disponible',
            noSource: 'Aucune source',
            nearOf: 'Près de',
            postalCode: 'Code postal',
            phone: 'Numéro',
            quebec: 'Québec',
            region: 'Région',
            removeIt: 'Oui, supprimer!',
            source: 'Source',
            successfullyAdded: 'Numéro ajouté avec succès',
            title: 'Acheter un numéro',
            tollFree: 'Tollfree',
            typeOfNumber: 'Type de numéros',
            user: 'Utilisateur',
        },
        responseLeadXpress: {
            addAttachment: 'Joindre',
            alreadyCalled: '{0} a déjà appelé le client {1}.',
            alreadyCalledLink: '{userName} a déjà appelé un <a target="_blank" href="{link}">client</a> à ce numéro {date}.',
            alreadyEmailedLink: '{userName} a déjà envoyé un courriel à un <a target="_blank" href="{link}">client</a> à cette adresse {date}.',
            alreadySentEmail: '{0} a déjà envoyé un courriel au client {1}.',
            attachment: 'Fichiers',
            bcc: 'CCI',
            category: 'Catégorie',
            calling: "{0} est déjà en train d'appeler le client, veuillez réessayer plus tard.",
            cc: 'CC',
            civility: 'Civilité',
            division: 'Division',
            forwardTo: 'Transférer à',
            ignore: 'Ignorer',
            ignoreTooltip: 'Masque ce message et vous permet de communiquer avec le client',
            language: 'Langue',
            model: 'Modèle',
            myself: 'moi',
            nextAutomationJob: 'Un courriel automatisé sera envoyé le {0}.',
            notify: 'Notifier',
            notifyTooltip: 'Ceci assignera le lead à {0} et lui enverra une notification',
            originalCommunication: 'Courriel original',
            originalCommunicationTooltip: 'Inclure le courriel original du client dans votre courriel',
            addLastEmailBody: 'Courriel précédent',
            addLastEmailBodyTooltip: 'Inclure le courriel précédent dans votre courriel',
            relativeFields: 'Rôles',
            selectedRecipient: '{count} destinataire séléctionné | {count} destinataires séléctionnés',
            sender: 'Expéditeur',
            senderBackup: 'Expéditeur de secours',
            sendingEmail: '{0} est déjà en train de composer un courriel, veuillez réessayer plus tard.',
            sendingSms: '{0} est déjà en train de communiquer via SMS, veuillez réessayer plus tard.',
            insertVehicle: 'Insérer un véhicule',
            viewDetails: 'Voir les détails',
            signatureWillBeUpdatedOnSend: "La signature sera mise à jour selon l'expéditeur sélectionné lors de l'envoi.",
            specificUser: 'Utilisateur spécifique',
            leadBelongToSomeoneElse: 'Ce client est assigné à {0}. Êtes-vous sûr de vouloir communiquer avec ce client?',
            subject: 'Sujet',
            template: 'Modèle',
            to: 'Destinataire',
            wantedVehicleLink: 'Lien du véhicule',
            wantedVehicleDrsLinkDealer: 'Lien DRS concessionnaire',
            wantedVehicleDrsLinkClient: 'Lien DRS client',
            leadRelatedUser: {
                user: 'Conseiller',
                bdcUser: 'Agent BDC',
                serviceAdvisorUser: 'Aviseur',
                serviceAgentUser: 'Agent de service',
                commercial: 'Directeur commercial',
                custom: 'Sur mesure',
            },
            imapIsDisabledAlert: 'Votre IMAP dans le CRM est inactif. Les nouveaux e-mails n\'apparaîtront pas dans CRM et les e-mails envoyés par CRM proviendront d\'un e-mail CRM interne. Veuillez réactiver votre IMAP pour une intégration transparente de la messagerie :',
            imapIsDisabledDocumentationLink: 'Voir la documentation',
            dismiss: 'Ignorer',
            easyLinks: {
                tradeIn: 'Soummetre un échange',
                deposit: 'Faire un dépôt',
                creditApplication: 'Soummetre une demande de crédit',
                appointment: 'Prendre un rendez-vous',
            },
        },
        massActions: {
            caslConsent: 'Consentement manuel',
            title: 'Actions en masse',
            massEdit: 'Mettre à jour',
            massDelete: 'Supprimer',
            massMailing: 'Envoyer courriel',
            campaign: 'Créer une campagne',
            selectField: 'Sélectionnez un champ à mettre à jour',
            field: 'Champ',
            form: 'Formulaire',
            numberOfLeadsOverRange: 'Vous devez sélectionner 5000 leads et moins pour créer une campagne',
            numberOfLeadsUnderRange: 'Vous devez sélectionner plusieurs leads pour créer une campagne',
            newValue: 'Nouvelle valeur',
            division: 'Division',
            advisor: 'Conseiller',
            bdcAgent: 'Agent BDC',
            commercial: 'Directeur Commercial',
            optOut: 'Opt-out',
            event: 'Envoyer vers événement',
            serviceAdvisor: 'Aviseur technique',
            serviceAgent: 'Agent service',
            status: 'Statut',
            result: 'Résultat',
            source: 'Source',
            civility: 'Civilité',
            code: 'Code',
            language: 'Langue',
            location: 'Localisation',
            noNotification: 'Aucune notification ne sera envoyée',
            unselectAllLeads: 'Effacer la sélection',
            wantedVehicleModel: 'Modèle - R',
            wantedVehicleMake: 'Marque - R',
            wantedVehicleTrim: 'Version - R',
            wantedVehicleFrequency: 'Fréquence - R',
            wantedVehicleModality: 'Modalité - R',
            wantedVehicleResidual: 'Résiduel - R',
            wantedVehicleTerm: 'Terme - R',
            wantedVehicleRate: 'Taux - R',
            wantedVehicleTransmission: 'Transmission - R',
            wantedVehicleFuel: 'Carburant - R',
            wantedVehicleStockState: 'État de stock - R',
            wantedVehiclePayment: 'Paiement - R',
            wantedVehicleTaxedPayment: 'Paiement avec taxes - R',
            exchangeVehicleInServiceDate: 'Mise en service - A',
            exchangeVehicleFrequency: 'Fréquence - A',
            exchangeVehicleModality: 'Modalité - A',
            exchangeVehicleResidual: 'Résiduel - A',
            exchangeVehicleTerm: 'Terme - A',
            exchangeVehicleRate: 'Taux - A',
            exchangeVehicleTransmission: 'Transmission - A',
            exchangeVehicleFuel: 'Carburant - A',
            exchangeVehicleIntention: 'Intention - A',
            exchangeVehicleCondition: 'Condition - A',
            exchangeVehicleValue: 'Valeur - A',
            exchangeVehicleStockState: 'État de stock - A',
            exchangeVehiclePayment: 'Paiement - A',
            exchangeVehicleTaxedPayment: 'Paiement avec taxes - A',
            warningDelete: 'Suppression en cours...',
            warningAssociate: 'Association en cours...',
            warningUpdate: 'Mise à jour en cours...',
            warningMerge: 'Fusion en cours...',
            group: {
                lead: 'Lead',
                wantedVehicle: 'Véhicule recherché',
                exchangeVehicle: 'Véhicule actuel',
                custom: 'Champ personnalisé',
            },
        },
        options: {
            massMailing: 'Envoi de masse',
            bcc: 'CCI',
            cc: 'CC',
            assigned: 'Assigné',
            defaultCc: 'CC par default',
            defaultBcc: 'CCI par default',
            emailCc: 'CC emails',
            emailBcc: 'CCI emails',
            defaultFowarding: 'Destinataire',
        },
        leadFilter: {
            advisor: 'Conseiller',
            secondaryAdvisor: '@.capitalize:lexicon.secondaryAdvisor.singular',
            division: 'Division',
            user: 'Conseiller',
            leadForm: 'Formulaire',
            wantedVehicleModel: 'Modèle - R',
            wantedVehicleMake: 'Marque - R',
            exchangeVehicleMake: 'Marque - A',
            exchangeVehicleModel: 'Modèle - A',
            source: 'Source',
            provider: 'Fournisseur',
            bdcUser: 'BDC',
            commercial: 'Directeur commercial',
            nbClient: 'Leads',
            takeOver: 'Take over',
            serviceAgent: 'Agent service',
            serviceAdvisor: 'Aviseur technique',
            account: 'Compte',
            noAdvisor: 'Sans conseiller',
            noCampaign: 'Sans campagne',
            noReferrer: 'Sans référent',
            noLeadForm: 'Pas de formulaire',
            noWantedVehicleMake: 'Pas de Marque - R',
            noWantedVehicleModel: 'Pas de Modèle - R',
            noExchangeVehicleMake: 'Pas de Marque - A',
            noExchangeVehicleModel: 'Pas de Modéle - A',
            noDivision: 'Pas de Division',
            noEndContractDate: 'Aucune date de fin de contact',
            noSecondaryAdvisor: 'Sans @:lexicon.secondaryAdvisor.singular',
            noServiceAdvisor: 'Aucun aviseur technique',
            noSource: 'Pas de Source',
            noApprovedSource: 'Pas de Source Approuvée',
            noProvider: 'Pas de Fournisseur',
            noBdcUser: 'Sans BDC',
            noCommercial: 'Sans Dir. Comm.',
            noTakeOverDirector: 'Sans Dir T.O.',
            noServiceAgent: 'Sans Agent service',
            endContractDate: 'Fin de Contrat',
            dayOfWeek: 'Jour de la semaine',
            weekOfYear: 'Semaine de l\'année',
            month: 'Mois',
            year: 'Année',
            campaign: 'Campagne',
            referrer: 'Référent',
        },
        service: {
            cards: {
                appointment: 'Rendez-vous | Rendez-vous',
                average: 'en moyenne',
                clients: 'Client | Clients',
                clientsPhoneUp: 'Client Phone-up | Clients Phone-up',
                clientsPrebooking: 'Client Pré-booking | Clients Pré-booking',
                clientsWeb: 'Client Web | Clients Web',
                confirmation: 'Confirmé | Confirmés',
                maintenance: 'Entretien vendu | Entretiens vendus',
                ofConfirmation: 'de confirmations',
                overSale: 'Service + Vendu',
                overSaleCardBack: 'Clients avec services supplémentaires @:lexicon.sold.plural',
                presentedCard: 'Visite | Visites',
                total: 'Total',
                visitWithAppointment: 'Avec RV',
                visitWithoutAppointment: 'Sans RV',
            },

            columns: {
                advisorAgent: 'Aviseur / Agent service',
                date: 'Date',
                creationDate: 'Création',
                lastPresentedDate: 'Dernière visite',
                odometerLastVisit: 'KM dern. visite',
                nextPresentedDate: 'Prochaine relance',
                segment: 'Segment',
                appt: 'RV',
                confirmation: 'Confirmés',
                presented: 'Visite',
                maintenance: 'Entretien',
                overSale: 'Service +',
                totalPrice: 'Total $',
                totalMinutes: 'Total hr',
                listening: 'Audio',
                comment: 'Notes',
                code: 'Code',
                storage: 'Entreposage',
                averageSpending: 'Visite $',
                serviceIntervalKm: 'Intervalle KM',
                serviceMonthlyKm: 'KM mensuel',
            },

            categories: {
                alignment: 'Align.',
                body: 'Carrosserie',
                brakes: 'Freins',
                maintenance: 'Entretien',
                motor: 'Moteur',
                others: 'Autres',
                parts: 'Pièces',
                tires: 'Pneus',
                transmission: 'Transmiss.',
                wash: 'Lavage',
                problem: 'Problème',
                cleaning: 'Lavage',
                all: 'Tous',
            },

            tooltip: {
                product_checkbox_disabled: 'Vous devez utiliser la fiche client afin de modifier les services vendus.',
            },

            actions: {
                enableResultStats:
                    'Cliquez pour voir les statistiques selon les dates de création et/ou de résultats (rv, visite)',
            },

            client: ' Numéro du client',
            appt: 'RV',
            // presented: 'Visite',
            folowing: 'Suivi',
            agent: 'Agent(e)',
            appt_date: 'Date RV',
            call_date: "Date de l'appel",
            // total: 'Totaux',
            call_by_agent: 'Appels par agent',
            call_by_account: 'Appels par compte',
            calls: 'Appels',
            transfer_time: 'Temps de transfert',
            transfer_time_agent: "Temps moyen entre la réceptionniste et l'agent(e)",
            transfer_time_card: 'Temps moyen du transfert entre la réceptionniste et le conseiller',
            appt_sub: 'de rendez-vous obtenu',
            lead_appt_card: 'Nombre de rendez-vous obtenu sur le nombre de demandes',
            call_answered: 'Appels répondus',
            sertiImportedTooltip: 'Valeur importé par Serti',

            hideNoLeadAccount: 'Cacher les comptes sans lead',
            hideNoLeadAdvisor: 'Cacher les aviseurs sans lead',
            showNoLeadAccount: 'Afficher les comptes sans lead',
            showNoLeadAdvisor: 'Afficher les aviseurs sans lead',
        },
        emailLead: {
            cantSendEmail: "Vous n'êtes pas autorisé à envoyer un courriel à ce lead. | Vous n'êtes pas autorisé à envoyer un courriel à ces leads.",
            cantSendSameEmail: 'Vous avez déjà envoyer un courriel identique à ce lead dans les dernières 24 heures.',
            invalidEmail: "{lead} n'a pas une address courriel valide. | Les leads n'ont pas des adresses courriels valides.",
            unsubscribeRequired: "Vous devez avoir le lien de désabonnement lors d'une envoi multiple.",
        },
        importation: {
            ruleAlert: {
                title: 'Attention',
                text: 'Êtes-vous sûr de vouloir fusionner les leads? Cette action est irréversible',
                yes: 'Oui',
                no: 'Non',
            },
        },
        export: {
            add: 'Ajouter',
            addPhone: 'Ajouter un téléphone',
            addName: 'Ajouter un nom',
            lucyPushNoPhoneText: 'Le client doit obligatoirement avoir un numéro de téléphone ou une adresse courriel',
            lucyPushNoPhoneTitle: 'Numéro de téléphone ou adresse courriel obligatoire',
            lucyPushNoNameText: 'Le client doit obligatoirement avoir un nom',
            lucyPushNoNameTitle: 'Nom obligatoire',
            honda: {
                needModelTrimAlert: 'Pour envoyer le lead vers Honda Canada, vous devez choisir un modèle valide.',
                needTrimAlert: 'Pour envoyer le lead vers Honda Canada, vous devez choisir une version valide.',
            },
        },
        progress_state: {
            approved: 'Approuvé',
            cash: 'Dépot',
            cash_deal: 'Cash deal',
            endorser: 'Endosseur',
            proof: 'Preuve',
            vehicle_selection: 'Véhicule à sélectionner',
            refused: 'Refusé',
            sent: 'Envoyé',
        },
        filters: {
            all: 'Tous',
            apply: 'Appliquer',
            clearAllFilters: 'Retirer tous les filtres',
            filters: 'Filtres',
            none: 'Aucun',
            reset: 'Réinitialiser',
            showAll: 'Tout afficher',

            vehicle_frequency: {
                one_payment: 'Paiement unique',
                weekly: 'Hebdomadaire',
                bi_monthly: 'Bimensuel',
                two_weeks: '2 semaines',
                monthly: 'Mensuel',
            },
            appointment: {
                phoneAppointment: 'RV',
                phoneAppointmentCompleted: 'RV Complété',
                canceled: 'Annulé',
                confirmed: 'Confirmé',
                noDate: 'RV sans date',
                noShow: 'No show',
                notConfirmed: 'Non confirmé',
                noTime: 'RV sans heure',
                toReach: 'Conseiller doit appeler',
                uncertain: 'Client incertain',
                yesWithoutStatus: 'Oui (sans statut)',
                notCompleted: 'Non complété',
                completed: 'Complété',
                tomorrow: 'RV demain',
            },
            lead_type: {
                email: 'Lead Web',
                phone: 'Phone-up',
                walk_in: '@.capitalize:lexicon.walkIn.singular',
                loyalty: 'Loyauté',
                renewal: '@.capitalize:lexicon.portfolio.singular',
                event: 'Événement',
                pre_booking: 'Pré-booking',
                sms: 'SMS',
                dms: 'DMS',
                web_order: 'Commande Web',
            },
            presented: {
                presented: '@.capitalize:lexicon.presented.singular',
                presentedOther: '@.capitalize:lexicon.presentedOther.singular',
            },
            roadTest: {
                roadTest: 'Essai Routier',
                homeRoadTest: 'Essai Routier à domicile',
            },
            media: {
                audio: 'Audio',
                video: 'Vidéo',
                noAudioNote: 'Aucune note audio',
                noFileAttached: 'Aucune pièce-jointe',
                audioNote: 'Note audio',
                fileAttached: 'Pièce-jointes',
            },
        },
        sorting: {
            title: 'Tri',

            newestFirst: 'Plus récents en premier',
            oldestFirst: 'Plus anciens en premier',
        },
        duplicates: {
            action: 'Action',
            associatedLeads: '{0} lead associé | {0} leads associés',
            associatedSuccessfully: 'Les leads ont été associés avec succès.',
            boolean: {
                false: 'Non',
                null: 'Non',
                true: 'Oui',
            },
            clientName: 'Nom du client',
            delivered: '@.capitalize:lexicon.delivered.singular',
            division: 'Division',
            email: 'Courriel',
            endDate: 'Date de fin',
            groupes: 'Groupes',
            leadDeletedSuccessfully: 'Les leads ont été supprimés avec succès.',
            leadType: 'Type de client',
            matchBy: 'Comparaison par',
            mergedSuccessfully: 'Les leads ont été fusionnés avec succès.',
            name: 'Nom',
            noResult: 'Aucun résultat trouvé',
            offerNumber: '# Offre',
            orderNumber: '# Commande',
            phone: 'Téléphone',
            results: 'Résultats',
            search: 'Recherche',
            source: 'Source',
            startDate: 'Date de début',
            dateType: 'Type de date',
            dateTypeTooltip: 'Sélectionnez tous les types de dates à considérer dans la recherche',
        },
        confidentialityAgreement: {
            termsAndConditionsConfirmation:
                "J'ai lu et accepte la Politique de confidentialité et les Conditions d'utilisation",
            iAgree: "J'accepte",
        },
    },
};
