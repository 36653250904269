<template>
    <activix-popover placement="top" v-if="showWidget">
        <template #trigger>
            <div class="flex text-sm text-gray-800 | transition cursor-pointer hover:text-gray-600">
                <icon name="regular/email-action-reply" />
            </div>
        </template>

        <div class="flex flex-col max-w-xs p-3 space-y-1.5 rounded-lg overflow-hidden">
            <div class="flex justify-between gap-2 items-center">
                <span class="font-semibold" v-text="parentNoteOwnerName" />
                <span class="text-sm text-gray-600" v-text="parentNoteDateTime" />
            </div>

            <p class="mb-0 line-clamp-2 text-sm" v-text="note.parent.content" />
        </div>
    </activix-popover>
</template>

<script>
    import { ActivixPopover } from '@autosync/atx-activix-ui';

    import Comment from '@/entities/Comment.ts';

    export default {
        components: { ActivixPopover },

        props: {
            depth: {
                type: Number,
                required: true,
            },
            note: {
                type: Comment,
                required: true,
            },
        },

        data: () => ({
            parentNoteOwnerName: '',
        }),

        computed: {
            parentNoteDateTime() {
                const createdAt = as_locale(this.note.parent.created_at, 'created_at');

                if (createdAt.isSameDay(now())) {
                    return createdAt.fromNow()[0].toUpperCase() + createdAt.fromNow().slice(1);
                }

                return createdAt.humanShort(true);
            },

            showWidget() {
                return this.depth >= 1 && this.note.parent.id;
            },
        },

        watch: {
            'note.parent.userId': {
                immediate: true,
                async handler() {
                    this.parentNoteOwnerName = (await this.note.parent.getUser(['first_name', 'last_name'])).fullName;
                },
            },
        },
    };
</script>
