<template>
    <div class="flex w-full text-lg">
        <div class="text-left">
            <div>
                {{ $tc('inventory.new') }}
            </div>
            <div>
                {{ $tc('inventory.used') }}
            </div>
            <div v-if="cards.totalVehicles.unknown.count">
                {{ $tc('inventory.statistics.unknown') }}
            </div>
            <div>
                {{ $tc('inventory.statistics.totalVehicles') }}
            </div>
        </div>
        <div class="text-right ml-3">
            <div>
                <span class="font-bold">{{ cards.totalVehicles.new.count }} </span>
                <span class="text-grey-6500">({{ cards.totalVehicles.demo.count }} {{ $tc('inventory.demo') }})</span>
            </div>
            <div>
                <span class="font-bold">{{ cards.totalVehicles.used.count }} </span>
                <span class="text-grey-6500">({{ cards.totalVehicles.certified.count }} {{ $tc('inventory.vehicle.certified') }})</span>
            </div>
            <div>
                <span class="font-bold" v-if="cards.totalVehicles.unknown.count">{{ cards.totalVehicles.unknown.count }}</span>
            </div>
            <div>
                <span class="font-bold">{{ cards.totalVehicles.number.count }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            cards: {
                type: Object,
                default: () => {},
            },
        },
    };
</script>
