export default {
    a: 'un',
    added: 'a ajouté',
    addedNumber: 'a ajouté un numéro de téléphone',
    addedEmail: 'a ajouté un courriel',
    addedCustomable: 'a ajouté une valeur à',
    advisor: 'Conseiller',
    bdc: 'BDC Agent',
    by: 'par',
    case: 'case',
    assignationDemand: 'a fait une demande réassignation pour être ',
    deleted: 'a supprimé ce lead',
    deletedNumber: 'a supprimé le numéro',
    deletedEmail: 'a supprimé le courriel',
    aFeminine: 'une',
    email: 'courriel',
    eventLeadCreated: 'à créé ce lead événement à partir de',
    exportedToDeskingTool: 'a envoyé le lead vers le Desking tool',
    exportedToDms: 'a envoyé le lead vers le DMS',
    followingAutomationSettings: "suivant les paramètres de l'automatisation",
    for: 'pour',
    from: 'de',
    manualAssociation: 'a créé ce lead à partir de',
    merged: 'a fusionné le lead',
    new: 'Nouveau',
    newFeminine: 'Nouvelle',
    newVowel: 'Nouvel',
    noHistory: 'Aucun historique',
    of: 'du',
    ofFeminine: 'de la',
    phoneNumber: 'numéro de téléphone',
    renewalCycle: 'a créé un lead de type @:lexicon.portfolio.singular associé à ce lead',
    the: 'le',
    to: 'à',
    toThisLead: 'à ce lead',
    toThisSalesJourney: 'à ce parcours de vente',
    updated: 'a modifié',
    associated: 'a associé ce lead avec',
    leadExportedToSupplier: 'a envoyé le lead vers',
    leadValue: 'Lead',
    leadPhoneValue: '{0} Téléphone',
    leadEmailValue: '{0} Courriel',
    commentValue: 'Commentaire',
    reminderValue: 'Alerte',
    restored: 'a restauré ce lead',
    exchangeVehicleValue: 'Véhicule actuel',
    vehicleExportedToSupplier: 'a envoyé le véhicule vers',
    wantedVehicleValue: 'Véhicule recherché',
    type: 'le type',

    actions: {
        added: 'a ajouté',
        forced: 'a forcé',
        removed: 'a supprimé',
        restored: 'a restauré',
        sent: 'a envoyé',
        updated: 'a modifié',
        withdraw: 'a retirer',
    },

    models: {
        comment: 'Commentaire',
        communication: 'Communication',
        exchangeVehicle: 'Véhicule actuel',
        lead: 'Lead',
        leadEmail: 'Courriel',
        leadPhone: 'Téléphone',
        taskEvent: 'Tâche / rendez-vous',
        wantedVehicle: 'Véhicule recherché',
        flow: 'Procédure',
        flowUser: 'Utilisateur',
    },

    prefix: {
        a: 'un',
        aFeminine: 'une',
        of: 'du',
        ofFeminine: 'de la',
        this: 'ce',
        thisFeminine: 'cette',
        to: 'au',
        toFeminine: 'à la',
    },

    communication: {
        entityName: 'Communication',
        field: {
            status: 'Résultat',
            description: 'Description',
            user: 'Utilisateur',
            url: 'le Lien',
            duration_call: "Durée de l'appel",
        },
        value: {
            answered: 'Rejoint',
            attempted: 'Tentative',
            calling: 'En cours',
            delivery_failed: 'Échec',
            error: 'Erreur',
            interrupted: 'Interrompu',
            invalid: 'Invalide',
            pending: 'En attente',
            unanswered: 'Non-répondu',
        },
    },

    lead: {
        nextPresentedDate: 'Prochaine relance',
        storage: 'Entreposage',
        ratingComment: 'Commentaire évaluation',
        rating: 'Évaluation',
        code: 'Code',
        scraperDescription: 'Contenu du courriel original',

        // Personal info
        address1: 'Adresse',
        address2: 'Adresse 2',
        birthDate: 'Date de naissance',
        city: 'Ville',
        country: 'Pays',
        firstName: 'Prénom',
        lastName: 'Nom',
        locale: 'Langue',
        longTerm: 'Long terme',
        postalCode: {
            CA: 'Code postal',
            US: 'Code postal',
        },
        province: 'Province',
        secondContact: '2ème contact',
        sex: 'Genre',
        civility: 'Civilité',
        secondContactCivility: 'Civilité deuxième contact',

        // Global info
        bdcUser: 'Agent BDC',
        commercial: 'Dir. commercial',
        deliveredBy: 'Livreur',
        firstUpdateTime: 'Temps de première modification',
        responseTime: 'Temps de réponse',
        result: 'Résultat',
        secondaryUser: '@.capitalize:lexicon.secondaryAdvisor.singular',
        takeOverDirector: 'Take Over par',
        unsubscribeCall: 'Désabonné appel',
        unsubscribeEmail: 'Désabonné courriel',
        unsubscribeSms: 'Désabonné SMS',
        user: 'Conseiller',
        technician: 'Technicien',

        // General info
        agent: 'Agent',
        budget: 'Budget',
        callDate: "Date de l'appel",
        calledCount: "Nombre d'appel",
        campaign: 'Événement',
        clientNumber: '# de client',
        comment: 'Commentaire',
        communicationPreference: 'Préférence de communication',
        custom10: 'Custom 10',
        custom1: 'Custom 1',
        custom2: 'Custom 2',
        custom3: 'Custom 3',
        custom4: 'Custom 4',
        custom5: 'Custom 5',
        custom6: 'Custom 6',
        custom7: 'Custom 7',
        custom8: 'Custom 8',
        custom9: 'Custom 9',
        division: 'Division',
        event: 'Événement',
        form: 'Formulaire',
        importedCampaign: 'Campagne',
        importSource: 'Source',
        keyword: '@:clientCard.keyword',
        leadForm: 'Formulaire',
        leadType: 'Type de client',
        lostReason: 'Raison perdu',
        navigationHistory: '@:clientCard.navigationHistory',
        source: 'Source',
        referrer: '@:clientCard.referrer',
        searchTerm: '@:clientCard.searchTerm',
        sector: 'Secteur',
        segment: 'Segment',
        serviceAdvisor: 'Aviseur technique',
        serviceAgent: 'Agent service',
        status: 'Statut',
        timeFrame: 'Délai d\'intention d\'achat',

        // Performance
        appointment: 'Rendez-vous',
        appointmentDate: 'Date de RV',
        apptCall: 'Rendez-vous tél.',
        apptCallDate: 'Date de RV tél.',
        beBack: 'B-back',
        beBackDate: 'Date du b-back',
        billOfSaleCreatedAt: 'Date de création de facture de vente',
        billOfSaleSentToCustomerAt: 'Date d\'envoi de facture de vente au client',
        billOfSaleSignedByCustomerAt: 'Date de signature de facture de vente par le client',
        csi: 'CSI',
        csiDate: 'Date du CSI',
        customerDepositReceivedAt: 'Date de réception du dépôt du client',
        customerReceiptSentAt: 'Date d\'envoi de reçu au client',
        delivered: '@.capitalize:lexicon.delivered.singular',
        deliveredDate: 'Date de @.capitalize:lexicon.delivery.singular',
        discountedDate: 'Escompté',
        deposit: 'Dépôt',
        doNotMarket: 'Do not market',
        homePresented: 'Autre visite',
        homePresentedDate: 'Autre date de visite',
        lostSource: 'Annulé par',
        presented: 'Visite',
        presentedDate: 'Date de visite',
        presentedOther: 'Autre visite',
        presentedOtherDate: 'Autre date de visite',
        refinancedAt: 'Date de refinancement',
        roadTest: 'Essai routier',
        roadTestDate: "Date d'essai routier",
        homeRoadTestDate: "Date d'essai routier à domicile",
        sale: '@.capitalize:lexicon.sale.singular',
        saleByPhone: '@.capitalize:lexicon.sale.singular par tél.',
        saleDate: 'Date de @:lexicon.sale.singular',
        takeOver: 'Take Over',
        takeOverDate: 'Date de take over',
        walkAround: 'Walk around',
        writeUp: 'Quotation',

        // Process
        approved: 'Approuvé',
        available: 'Véh. ici le',
        availableDate: 'Véh. ici le',
        deliverable: '@.capitalize:lexicon.deliverable.singular',
        deliverableDate: '@.capitalize:lexicon.deliverable.singular le',
        delivery: '@.capitalize:lexicon.delivery.singular',
        deliveryDate: '@.capitalize:lexicon.deliveryDate.singular',
        paperwork: 'Dossier',
        paperworkDate: 'Date de dossier',
        gas: 'Ravitaillé',
        inspected: 'Inspecté',
        leadBank: 'Institution',
        prepared: 'Préparé',
        preparedWorkOrder: 'P.W.O.',
        progressState: 'État',
        endServiceDate: 'Fin de service prévu',
        promisedDate: 'Heure promise',
        invoiced: 'Facturé',
        plannedPickUpDate: 'Récupération prévue',
        waitingSale: '@.capitalize:lexicon.sale.singular en attente',
        statusUpdatedAt: 'Statut mis à jour à',
        importedBank: 'Institution',

        // Commercial
        commercialMeeting: 'Rencontré',
        commercialNoNeed: 'Exclus',
        productTotal: 'Total directeur commercial',

        account: 'Compte',
        exported: 'Exporté',

        apptConfirmation: 'Confirmation RV',
        apptConfirmationDate: 'Date confirmation RV',
        apptValidated: 'Rendez-vous validé',
        createdAt: 'Créer le',
        deletedAt: 'Supprimé à',
        deletedBy: 'Supprimé par',
        endContractDate: 'Date fin de contrat',
        evaluated: 'Déjà évalué',
        freezeFinancialData: 'Bloquer les données financières',
        lastPresentedDate: 'Dernière visite',
        reachedClient: 'Client rejoint',
        refinanced: 'Rachat',
        refinancedDate: 'Date du rachat',
        refinancedType: 'Type du rachat',
        renewal: '@.capitalize:lexicon.portfolio.singular',
        serviceCleaningCar: 'Lavage',
        serviceIntervalKm: 'Intervalle KM ',
        serviceMonthlyKm: 'KM mensuel',
        updatedAt: 'Mis à jour à',
        qualification: 'Qualification',
        lastDialogsStatus: 'Dernier statut',
        workOrder: 'W.O. #',
        plannedRecoveryDate: 'Récupération prévue',
        repairOrder: 'Repair order #',
        repairDate: 'Date de réparation',
        openWorkOrderDate: 'Ouverture W.O.',
        recall: 'Rappel',
        endWarranty: 'Fin garantie',
        endLcap: 'Fin LCAP',
        endLnette: 'Fin LNETTE',
        nowShow: 'No show',
        loyalty: 'Loyauté',
        nonConsumerInitiated: 'Non généré par le consommateur',
        prepaid: 'Prépayé',
        business: 'Affaires',
        businessName: "Nom d'entreprise",
        odometerLastVisit: 'KM dern. visite',
        twentyFourHour: '24h',
        subAccount: 'Compte concierge',
        workOrderClosureDate: '@:clientCard.workOrderClosureDate',
        workOrderPartialClosureDate: '@:clientCard.workOrderPartialClosureDate',

        customerIntelligenceUrl: "Url de l'intelligence client",
        customerIntelligenceSummary: "Sommaire de l'intelligence client",
    },
    customer: {
        caslConsentConfirmedTypeManual: 'a forcé un consentement manuel',
        caslConsentTypeManual: 'un consentement manuel',
        caslConsentConfirmedTypeManualRemoved: 'a retiré le consentement manuel',
        caslConsentConfirmationSentAt: 'a envoyé une demande de consentement',
        caslConsentConfirmationConsentRequest: 'une demande de consentement',
    },
    leadPhone: {
        blocked: 'Bloqué',
        deletedAt: 'Supprimé à',
        extension: 'Extension',
        mobile: 'Mobile',
        phone: 'Numéro',
        validated: 'Validé',
        typeString: 'le type de téléphone',
        type: {
            cell: 'cellulaire',
            home: 'maison',
            work: 'travail',
        },
    },
    leadEmail: {
        deletedAt: 'Supprimé à',
        email: 'Adresse',
        typeString: 'le type de courriel',
        type: {
            home: 'personnel',
            work: 'travail',
        },
    },
    comment: {
        content: 'Contenu',
        deletedAt: 'Supprimé à',
        private: 'privé',
        lead: 'Lead',
        user: 'Utilisateur',
    },
    reminder: {
        date: 'Date',
    },
    taskEvent: {
        address: 'Adresse',
        deletedAt: 'Supprimé le',
        description: 'Description',
        endAt: 'Fin',
        noShow: 'No show',
        confirmed: 'Confirmé',
        canceled: 'Annulé',
        location: 'Lieu',
        priority: 'Priorité',
        startAt: 'Début',
        status: 'Terminé / Visite / @.capitalize:lexicon.delivered.singular',
        taskEventType: 'Type',
        title: 'Titre',
        user: 'Propriétaire',
        confirmationReply: 'Réponse de confirmation',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        deleted: 'a supprimé une',
        value: 'Tâche / rendez-vous',
        sendIcs: 'Rappel d\'agenda',
        userVirtualEventLink: 'Lien évènement virtuel conseiller',
        customerVirtualEventLink: 'Lien évènement virtuel client',
    },
    vehicle: {
        accessories: 'Accessoires',
        accidentedDamageCost: 'Coûts degat',
        actualValue: 'Valeur réelle',
        allowedMileage: 'KM alloué',
        balance: 'Solde',
        bank: 'Institution',
        baseWarranty: 'Garantie de base',
        bodyType: 'Carrosserie',
        budgetMax: 'Budget max.',
        budgetMin: 'Budget min.',
        calculatedEquity: 'Équité',
        calculatorAppraiser: 'Évaluateur',
        calculator_value: 'Estimé',
        calculatorValue: 'Estimé',
        category: 'Catégorie',
        categoryRv: 'Catégorie VR',
        certified: 'Certifié',
        colorExterior: 'Couleur',
        colorInterior: 'Couleur Int.',
        comment: 'Commentaires',
        condition: 'Condition',
        createdAt: 'Ajouté',
        createdBy: 'Créé par',
        deletedAt: 'Supprimé à',
        originalCreatedAt: 'Date de création originale',
        depositAmount: 'Montant du dépôt',
        depositReceivedAt: 'Date de réception du dépôt',
        documentation: 'Documentation',
        drivingWheels: 'Roues motrices',
        endContractDate: 'Fin de contrat',
        endWarrantyDate: 'Fin de garantie',
        endWarrantyMileage: 'Fin de garantie KM',
        endExtendedWarrantyDate: 'Fin de garantie prolongée',
        endExtendedWarrantyMileage: 'Fin de garantie prolongée KM',
        engine: 'Moteur',
        estimated: 'Estimé',
        extendedWarranty: 'Garantie prolongée',
        frequency: 'Fréquence',
        fuel: 'Carburant',
        importedBank: 'Institution',
        initialCash: 'Comptant initial',
        intention: 'Intention',
        inServiceDate: 'Mise en service',
        length: 'Longueur',
        lengthMax: 'Longueur max.',
        lengthMin: 'Longueur min.',
        licensePlate: 'Numéro de plaque',
        link: 'Lien',
        make: 'Marque',
        makeWarranty: 'Type de garantie',
        mechanical: 'Produit méc.',
        mileage: 'Odomètre',
        mileageExcess: 'Odo. excédentaire estimé',
        modality: 'Modalité',
        model: 'Modèle',
        offer: '# Offre',
        option: 'Options',
        orderNumber: '# Commande',
        payment: 'Paiement',
        paymentWithTax: 'Paiement avec taxes',
        preparation: 'Préparation',
        price: 'Prix',
        primaryVehicle: 'Véhicule principal',
        profit: 'Profit',
        purchaseDate: "Date d'achat",
        rate: 'Taux',
        recall: 'Rappel',
        recordedDate: 'Enregistré le',
        related: 'DMS',
        requested: '$ Souhaité',
        residual: 'Résiduel',
        securityDeposit: 'Dépôt sécurité',
        service: 'Service',
        sleeping: 'Couchage',
        sold: 'Véhicule @:lexicon.sold.singular',
        soldBy: '@.capitalize:lexicon.sold.singular par',
        soldDate: 'Date de @:lexicon.sale.singular',
        stock: '# Stock',
        stockState: 'Inventaire',
        suffix: 'Suffix',
        term: 'Terme',
        tire: 'Pneus vendu',
        tireType: 'Type Pneus',
        tradeNotes: 'Notes',
        tradeType: 'Achat',
        transmission: 'Transmission',
        trim: 'Version',
        updatedAt: 'Modifié',
        updatedBy: 'Mis à jour par',
        url: 'url',
        value: 'Valeur',
        vehicleState: 'État du véhicule',
        verified: 'Vérifié',
        verifiedBy: 'Vérifié',
        vin: 'NIV',
        vinDecoded: 'NIV décodé',
        warranty: 'Garantie',
        weight: 'Poids',
        year: 'Année',
        yearMax: 'Année max.',
        yearMin: 'Année min.',
        drsLinkClient: 'Lien client',
        drsLinkDealer: 'Lien concessionnaire',
    },

    automation: {
        autoCloseLead: 'Le lead pourrait être fermé',
    },

    attribution: {
        modalTitle: '{leadType} ajouté au parcours de vente par {userName} le {date}',
        noChanges: "Il n'y a aucune information additionnelle ajoutée au parcours de vente",
        initialContext: "Cet événement était initialement l'événement primaire, les changements ne sont pas disponible",

        added: 'ajouté',
        details: 'Détails',
        updated: 'modifié',

        communicationCreation: {
            email: '@:history.new',
            phone: '@:history.newVowel',
            sms: '@:history.new',
            messenger: '@:history.new',
            video: '@:history.newVowel',
        },

        emailCreation: '@:history.new courriel {type}',
        emailType: {
            home: 'personnel',
            work: 'professionnel',
        },

        phoneCreation: '@:history.new téléphone {type}',
        phoneType: {
            cell: 'cellulaire',
            home: 'personnel',
            work: 'professionnel',
        },

        taskEventCreation: {
            call: '@:history.newFeminine',
            email: '@:history.newFeminine',
            appointment: '@:history.new',
            delivery: '@:history.newFeminine',
            other: '@:history.newVowel',
            csi: '@:history.newFeminine',
            sms: '@:history.newFeminine',
            phone_appointment: '@:history.new',
            virtual_appointment: '@:history.new',
            test_drive: '@:history.newVowel',
        },
    },

    flowUser: {
        order: 'priorité {priority}, position {order}',
    },

    flow: {
        notifyPrecedentPriorities: 'Notifier les priorités précédentes',
        roundRobin: 'Round Robin',
    },
};
