<template>
    <div class="flex flex-wrap -mx-3" v-if="visible">
        <!-- Selected Account -->
        <div class="pb-6 px-3 w-full | xl:w-1/2" v-if="accountOptions.length && !state.addingAssociatedLead">
            <label>
                {{ $t('clientCard.account') }}
                <span class="form-star" v-if="accountIsRequired">*</span>
            </label>
            <activix-select
                white
                label="name"
                placeholder="..."
                :class="accountIsRequired && showAdditionalFields ? additionalFieldsClass : ''"
                :filterable="true"
                :options="accountOptions"
                @change="debounce"
                v-model="newLead.childAccountId"
            >
                <el-option
                    :label="childAccount.name"
                    :value="childAccount.id"
                    :key="childAccount.id"
                    v-for="childAccount in accountOptions"
                />
            </activix-select>
        </div>

        <!-- Civility and Client Name -->
        <div class="pb-6 px-3 w-full" :class="{ 'xl:w-1/2': accountOptions.length && !state.addingAssociatedLead }">
            <label>
                {{ $t('clientCard.name') }}
                <span class="form-star" v-if="clientNameIsRequired">*</span>
            </label>
            <div class="flex shadow">
                <activix-select
                    class="attached-r"
                    white
                    label="label"
                    identifier="value"
                    placeholder="..."
                    :autosize="true"
                    :options="civilities"
                    v-model="newLead.civility"
                >
                    <el-option
                        :label="civility.label"
                        :value="civility.value"
                        :key="civility.value"
                        v-for="civility in civilities"
                    />
                </activix-select>
                <activix-input
                    white
                    class="flex-1"
                    input-class="attached-l"
                    :has-error="clientNameIsRequired && showAdditionalFields"
                    @keydown.enter="$emit('search')"
                    @input="debounce"
                    v-model="newLead.name"
                />
            </div>
        </div>

        <!-- Business Name -->
        <div class="pb-6 px-3 w-full">
            <label>
                {{ $t('clientCard.businessName') }}
                <span class="form-star" v-if="businessNameIsRequired">*</span>
            </label>
            <div class="flex shadow">
                <activix-input
                    white
                    class="flex-1"
                    input-class="attached-l"
                    :has-error="businessNameIsRequired && showAdditionalFields"
                    @keydown.enter="$emit('search')"
                    @input="debounce"
                    v-model="newLead.businessName"
                />
            </div>
        </div>
        <div class="pb-6 px-3 w-full">
            <label>
                {{ $t('clientCard.primaryContact') }}
            </label>
            <activix-select
                white
                class="w-full"
                label="name"
                :filterable="true"
                :options="contactTypes"
                v-model="newLead.contactType"
            >
                <el-option
                    :label="type.label"
                    :value="type.value"
                    :key="type.value"
                    v-for="type in contactTypes"
                />
            </activix-select>
        </div>
        <!-- Phone -->
        <div class="pb-6 px-3 w-full | xl:w-1/2">
            <label>
                {{ $t('clientCard.phone') }}
                <span class="form-star" v-if="phoneIsRequired">*</span>
            </label>
            <activix-masked-input
                mask-type="phone"
                white
                :class="phoneIsRequired && showAdditionalFields ? additionalFieldsClass : ''"
                @keydown.enter="$emit('search')"
                @input="debounce"
                v-model="newLead.phone"
            >
                <template #icon>
                    <icon class="text-gray-700" name="regular/house-chimney-2" />
                </template>
            </activix-masked-input>
        </div>

        <!-- Email -->
        <div class="pb-6 px-3 w-full | xl:w-1/2">
            <div class="flex items-center space-x-2 mb-2">
                <label class="mb-0">
                    {{ $t('clientCard.email') }}
                    <span class="form-star" v-if="emailIsRequired">*</span>
                </label>
                <transition
                    enter-active-class="transition duration-300"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition duration-300"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <email-validation
                        :email="email"
                        ref="emailValidation"
                        @update="updateLeadEmail"
                        v-if="isValidEmail && !isFocusingEmail"
                    />
                </transition>
            </div>
            <activix-masked-input
                mask-type="email"
                white
                :class="emailIsRequired && showAdditionalFields ? additionalFieldsClass : ''"
                @keydown.enter="$emit('search')"
                @input="debounce"
                @focus="isFocusingEmail = true"
                @blur="verifyEmail"
                v-model="newLead.email"
            >
                <template #icon>
                    <icon class="text-gray-700" :name="$icons.email" />
                </template>
            </activix-masked-input>
        </div>

        <div class="pb-6 px-3 w-full text-center">
            <div class="cursor-pointer w-auto inline-block" @click="$emit('toggle-additional-fields')">
                <div class="flex items-center link-gray text-sm uppercase">
                    {{ $t('addLead.additionalFields') }}
                    <icon class="ml-2 text-xs" name="regular/arrow-up-1" v-if="showAdditionalFields" />
                    <icon class="ml-2 text-xs" name="regular/arrow-down-1" v-else />
                </div>
            </div>
        </div>

        <template v-if="showAdditionalFields">
            <!-- Client Type -->
            <div class="pb-6 px-3 w-full | xl:w-1/2">
                <label>
                    {{ $t('client.type') }}
                    <span class="form-star" v-if="leadTypeIsRequired">*</span>
                </label>
                <activix-select
                    white
                    placeholder="..."
                    label="name"
                    :has-error="leadTypeIsRequired && showAdditionalFields"
                    :options="leadTypes"
                    v-model="newLead.leadType"
                >
                    <el-option
                        :label="leadType.name"
                        :value="leadType.id"
                        :key="leadType.id"
                        v-for="leadType in leadTypes"
                    />
                </activix-select>
            </div>

            <!-- Division -->
            <div class="pb-6 px-3 w-full | xl:w-1/2">
                <label>
                    {{ $t('clientCard.division') }}
                    <span class="form-star" v-if="divisionIsRequired">*</span>
                </label>
                <activix-select
                    white
                    placeholder="..."
                    label="name"
                    :has-error="divisionIsRequired && showAdditionalFields"
                    :options="divisions"
                    v-model="newLead.division"
                >
                    <el-option
                        :label="division.name"
                        :value="division.id"
                        :key="division.id"
                        v-for="division in divisions"
                    />
                </activix-select>
            </div>

            <!-- Source -->
            <div class="pb-6 px-3 w-full | xl:w-1/2" v-if="showSource">
                <label>
                    {{ $t('clientCard.source') }}
                    <span class="form-star" v-if="sourceIsRequired">*</span>
                </label>
                <activix-select
                    white
                    label="name"
                    placeholder="..."
                    :class="sourceIsRequired ? additionalFieldsClass : ''"
                    :filterable="true"
                    :disabled="!!accountOptions.length && !newLead.childAccountId"
                    :options="sources"
                    v-model="newLead.source"
                >
                    <el-option
                        :class="{ 'font-semibold': source.lead_type_id && source.lead_type_id == newLead.leadType }"
                        :label="source.name"
                        :value="source.id"
                        :key="source.id"
                        v-for="source in sources"
                    />
                </activix-select>
            </div>

            <!-- Advisor -->
            <div class="pb-6 px-3 w-full | xl:w-1/2">
                <label>
                    {{ $t('clientCard.advisor') }}
                </label>
                <activix-select
                    white
                    label="fullName"
                    placeholder="..."
                    :clearable="true"
                    :filterable="true"
                    :disabled="!!accountOptions.length && !newLead.childAccountId"
                    :options="activeUserOptions"
                    v-model="newLead.advisor"
                >
                    <el-option
                        :label="activeUser.fullName"
                        :value="activeUser.id"
                        :key="activeUser.id"
                        v-for="activeUser in activeUserOptions"
                    />
                </activix-select>
            </div>

            <!-- Presented Date -->
            <div class="pb-6 px-3 w-full | xl:w-1/2" v-if="isWalkIn">
                <activix-tooltip :content="!dateIsValid(newLead.presentedDate) ? $t('followUp.invalidDate') : ''">
                    <label>
                        {{ $t('clientCard.presentedDate') }}
                        <span class="form-star" v-if="presentedDateIsRequired">*</span>
                    </label>
                </activix-tooltip>
                <date-time-picker
                    white
                    :class="presentedDateIsRequired ? additionalFieldsClass : ''"
                    :end-date="maxDate"
                    v-model="newLead.presentedDate"
                />
            </div>

            <!-- Call date -->
            <div class="pb-6 px-3 w-full | xl:w-1/2" v-if="isPhone">
                <activix-tooltip :content="!dateIsValid(newLead.callDate) ? $t('followUp.invalidDate') : ''">
                    <label>
                        {{ $t('clientCard.callDate') }}
                        <span class="form-star" v-if="callDateIsRequired">*</span>
                    </label>
                </activix-tooltip>
                <date-time-picker
                    white
                    :class="callDateIsRequired ? additionalFieldsClass : ''"
                    v-model="newLead.callDate"
                />
            </div>

            <!-- End contract date -->
            <div class="pb-6 px-3 w-full | xl:w-1/2" v-if="isRenewal">
                <activix-tooltip
                    :content="!dateIsValid(newLead.endContractDate, true) ? $t('clientCard.invalidDate') : ''"
                >
                    <label>
                        {{ $t('clientCard.endContractDateExchange') }}
                        <span class="form-star" v-if="endContractDateIsRequired">*</span>
                    </label>
                </activix-tooltip>
                <date-time-picker
                    white
                    date-only
                    :class="endContractDateIsRequired ? additionalFieldsClass : ''"
                    v-model="newLead.endContractDate"
                />
            </div>

            <!-- BDC Agent -->
            <div class="pb-6 px-3 w-full | xl:w-1/2" v-if="showBdcAgent">
                <label>
                    {{ $t('clientCard.bdcAgent') }}
                </label>
                <activix-select
                    white
                    label="fullName"
                    placeholder="..."
                    :clearable="true"
                    :filterable="true"
                    :disabled="!!accountOptions.length && !newLead.childAccountId"
                    :options="activeUserOptions"
                    v-model="newLead.bdc"
                >
                    <el-option
                        :label="activeUser.fullName"
                        :value="activeUser.id"
                        :key="activeUser.id"
                        v-for="activeUser in activeUserOptions"
                    />
                </activix-select>
            </div>

            <!-- Service Advisor -->
            <div class="pb-6 px-3 w-full | xl:w-1/2" v-if="isService">
                <label>
                    {{ $t('clientCard.serviceAdvisor') }}
                </label>
                <activix-select
                    white
                    label="fullName"
                    placeholder="..."
                    :clearable="true"
                    :filterable="true"
                    :disabled="!!accountOptions.length && !newLead.childAccountId"
                    :options="activeUserOptions"
                    v-model="newLead.serviceAdvisor"
                >
                    <el-option
                        :label="activeUser.fullName"
                        :value="activeUser.id"
                        :key="activeUser.id"
                        v-for="activeUser in activeUserOptions"
                    />
                </activix-select>
            </div>

            <!-- Service Agent -->
            <div class="pb-6 px-3 w-full | xl:w-1/2" v-if="isService">
                <label>
                    {{ $t('clientCard.serviceAgent') }}
                </label>
                <activix-select
                    white
                    label="fullName"
                    placeholder="..."
                    :clearable="true"
                    :filterable="true"
                    :disabled="!!accountOptions.length && !newLead.childAccountId"
                    :options="activeUserOptions"
                    v-model="newLead.serviceAgent"
                >
                    <el-option
                        :label="activeUser.fullName"
                        :value="activeUser.id"
                        :key="activeUser.id"
                        v-for="activeUser in activeUserOptions"
                    />
                </activix-select>
            </div>
        </template>
    </div>
</template>

<script>
    import { cloneDeep, camelCase, debounce } from 'lodash-es';
    import LeadEmail from '@/entities/LeadEmail.js';

    import { mapState } from 'pinia';
    import AddLead from '../../mixins/AddLead.js';

    // Components
    import DateTimePicker from '../inputs/DateTimePicker.vue';
    import EmailValidation from '../lead/EmailValidation.vue';

    // Entities
    import Civility from '../../entities/Civility.js';
    import DashboardType from '../../entities/DashboardType.js';
    import Division from '../../entities/Division.js';
    import LeadType from '../../entities/LeadType.js';
    import Account from '../../entities/Account.js';
    import ContactType from '../../entities/ContactType.js';

    // Value Objects
    import ActivixDate from '../../value-objects/ActivixDate.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    const getDefaultLead = () => ({
        advisor: null,
        bdc: null,
        callDate: new ActivixDate('now').toString(),
        civility: null,
        businessName: '',
        contactType: ContactType.MAIN,
        name: '',
        division: null,
        email: '',
        endContractDate: new ActivixDate('now').addMonths(12).toString(),
        presentedDate: new ActivixDate('now').toString(),
        leadType: null,
        phone: '',
        childAccountId: null,
        serviceAdvisor: null,
        serviceAgent: null,
        source: null,
        sourceName: '',
    });

    export default {
        components: {
            DateTimePicker,
            EmailValidation,
        },

        mixins: [AddLead],

        props: {
            addingAssociatedLead: {
                type: Boolean,
                default: false,
            },
            relatedLeadId: {
                type: Number,
                default: null,
            },
            state: {
                type: Object,
                default: () => {},
            },
            account: {
                type: Account,
            },
            accountOptions: {
                type: Array,
                default: () => [],
            },
            previouslySelectedAccountId: {
                type: Number,
                default: null,
            },
            visible: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                searching: false,
                newLead: cloneDeep(getDefaultLead()),
                maxDate: now().endOfDay().toString(),
                defaultValues: {},
                additionalFieldsClass: 'border border-red-400',
                oldEmail: '',
                email: new LeadEmail(),
                isFocusingEmail: false,
                activeUsers: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs', 'parentAuthUser']),
            ...mapState(useDashboardStore, ['dashboardType']),
            ...mapState(useContextStore, { contextAccount: 'account' }),

            showAdditionalFields() {
                return this.state.showAdditionalFields || this.state.addingAssociatedLead;
            },

            isPhone() {
                return this.newLead.leadType == LeadType.PHONE;
            },

            activeUserOptions() {
                return this.activeUsers.map(user => ({
                    id: user.id,
                    fullName: user.fullName,
                }));
            },

            civilities() {
                return Civility.uniqueOptions('value', 'label');
            },

            contactTypes() {
                return [
                    { label: this.$t('clientCard.primaryContactOptions.main'), value: ContactType.MAIN },
                    { label: this.$t('clientCard.primaryContactOptions.business'), value: ContactType.BUSINESS },
                ];
            },

            showDivisionService() {
                if ([LeadType.LOYALTY, LeadType.RENEWAL, LeadType.WEB_ORDER].includes(this.newLead.leadType)) {
                    return false;
                }

                if (
                    !this.authUser.hasDivisionAccess(Division.SERVICE) &&
                    !this.authUser.hasAccessTo('dashboards.service')
                ) {
                    return false;
                }

                return true;
            },

            showDivisionNew() {
                return (
                    this.authUser.hasDivisionAccess(Division.NEW) &&
                    ![LeadType.PRE_BOOKING, LeadType.DMS].includes(this.newLead.leadType)
                );
            },

            showDivisionUsed() {
                return (
                    this.authUser.hasDivisionAccess(Division.USED) &&
                    ![LeadType.PRE_BOOKING, LeadType.DMS].includes(this.newLead.leadType)
                );
            },

            emptySearchParameters() {
                return (
                    (!this.newLead.name || this.newLead.name.length < 3) && !this.newLead.email && !this.newLead.phone
                );
            },

            isValidDivision() {
                return !!this.newLead.division;
            },

            isValidSource() {
                return !!this.newLead.source;
            },

            divisions() {
                return this.configs.divisions
                    .filter(division => {
                        if (division.id == Division.SERVICE) {
                            return this.showDivisionService;
                        }

                        if (division.id == Division.NEW) {
                            return this.showDivisionNew;
                        }

                        if (division.id == Division.USED) {
                            return this.showDivisionUsed;
                        }

                        return false;
                    })
                    .map(division => {
                        return {
                            ...division,
                            name: this.$t(`divisions.${division.name}`),
                        };
                    })
                    .sort((a, b) => a.name.localeCompare(b.name));
            },

            leadTypes() {
                return this.configs.leadTypes
                    .filter(leadType => {
                        return this.leadTypeIds.includes(leadType.id);
                    })
                    .map(a => {
                        const b = cloneDeep(a);
                        b.name = this.$t(`leadTypes.abbreviations.${camelCase(b.name)}`);

                        return b;
                    })
                    .sort((a, b) => a.name.localeCompare(b.name));
            },

            leadTypeIds() {
                if (this.authUser.isAdmin()) {
                    return this.configs.leadTypes
                        .filter(leadType => ![LeadType.EVENT, LeadType.DMS].includes(leadType.id))
                        .map(leadType => leadType.id);
                }

                const leadTypeIds = [];

                if (this.authUser.hasLoyalty()) {
                    leadTypeIds.push(LeadType.LOYALTY);
                }

                if (this.authUser.hasRenewal()) {
                    leadTypeIds.push(LeadType.RENEWAL);
                }

                if (
                    this.selectedAccount.service &&
                    (this.authUser.hasDivisionAccess(Division.SERVICE) || this.authUser.hasService())
                ) {
                    leadTypeIds.push(LeadType.PRE_BOOKING);
                }

                if (this.selectedAccount.hasNioText() && this.authUser.hasNioText()) {
                    leadTypeIds.push(LeadType.SMS);
                }

                if (this.selectedAccount.hasOption('walk_in') && this.authUser.hasWalkIn()) {
                    leadTypeIds.push(LeadType.WALK_IN);
                }

                if (this.authUser.hasPhoneUp()) {
                    leadTypeIds.push(LeadType.PHONE);
                }

                if (this.authUser.hasLeadXpress() || this.authUser.hasWebBoost()) {
                    leadTypeIds.push(LeadType.EMAIL);
                }

                if (this.authUser.hasWebOrder()) {
                    leadTypeIds.push(LeadType.WEB_ORDER);
                }

                return leadTypeIds;
            },

            selectedAccountAuthUser() {
                return this.parentAuthUser.selfOrChildUserForAccount(this.selectedAccount.id);
            },

            mandatoryPhone() {
                if ([LeadType.PHONE, LeadType.SMS].includes(this.newLead.leadType) && !this.isValidPhone) {
                    return true;
                }

                if (!this.isValidPhone && !this.isValidEmail) {
                    return this.selectedAccount.mandatory_coordinate;
                }

                return false;
            },

            mandatoryEmail() {
                if (
                    ![LeadType.PHONE, LeadType.SMS].includes(this.newLead.leadType) &&
                    !this.isValidPhone &&
                    !this.isValidEmail
                ) {
                    return this.selectedAccount.mandatory_coordinate;
                }

                return false;
            },

            defaultLeadType() {
                if (this.defaultValues.addingAssociatedLead) {
                    return null;
                }

                let leadType = null;

                if (DashboardType.exists(this.defaultValues.type)) {
                    leadType = this.defaultValues.type;
                }

                if ([DashboardType.ALL, DashboardType.WALK_IN].includes(this.dashboardType)) {
                    leadType = LeadType.WALK_IN;
                }

                if ([DashboardType.LEAD_XPRESS, DashboardType.WEB_BOOST].includes(this.dashboardType)) {
                    leadType = LeadType.EMAIL;
                }

                if (this.dashboardType == DashboardType.WEB_ORDER) {
                    leadType = LeadType.WEB_ORDER;
                }

                if (this.dashboardType == DashboardType.PHONE_UP) {
                    leadType = LeadType.PHONE;
                }

                if (this.dashboardType == DashboardType.LOYALTY) {
                    leadType = LeadType.LOYALTY;
                }

                if (this.dashboardType == DashboardType.RENEWAL) {
                    leadType = LeadType.RENEWAL;
                }

                if (this.dashboardType == DashboardType.SERVICE) {
                    leadType = LeadType.PRE_BOOKING;
                }

                if (this.leadTypeIds.includes(leadType)) {
                    return leadType;
                }

                return null;
            },

            defaultCivility() {
                if (Civility.exists(this.defaultValues.civility)) {
                    return this.defaultValues.civility;
                }

                return null;
            },

            defaultDivision() {
                if (empty(this.authUser.divisions)) {
                    return null;
                }

                const associatedLeadDivision = this.defaultValues.division;

                const userCanCreateServiceLead =
                    this.authUser.hasDivisionAccess(Division.SERVICE) ||
                    this.authUser.hasAccessTo('dashboards.service');

                const setNewLeadToService =
                    associatedLeadDivision == Division.SERVICE || this.dashboardType == DashboardType.SERVICE;

                if (userCanCreateServiceLead && setNewLeadToService) {
                    return Division.SERVICE;
                }

                if (this.authUser.divisions.length === 1) {
                    return this.authUser.divisions[0];
                }

                if (this.authUser.hasDivision(associatedLeadDivision)) {
                    return associatedLeadDivision;
                }

                return null;
            },

            defaultAdvisor() {
                const accountAuthUserId = this.selectedAccountAuthUser?.isAdvisor() || this.selectedAccountAuthUser?.isDirector() ? this.selectedAccountAuthUser.id : null;
                const advisor =
                    this.activeUserOptions.find(
                        user => user.id == (this.defaultValues.advisor || accountAuthUserId),
                    ) || {};

                return advisor.id || null;
            },

            defaultBdcAgent() {
                const accountAuthUserId = this.selectedAccountAuthUser?.isBdc() || this.selectedAccountAuthUser?.isBdcDirector() ? this.selectedAccountAuthUser.id : null;
                const bdcAgent =
                    this.activeUserOptions.find(
                        user => user.id == (this.defaultValues.bdc || accountAuthUserId),
                    ) || {};

                return bdcAgent.id || null;
            },

            defaultServiceAdvisor() {
                const accountAuthUserId = this.selectedAccountAuthUser?.isServiceAdvisor() ? this.selectedAccountAuthUser.id : null;
                const serviceAdvisor =
                    this.activeUserOptions.find(
                        user => user.id == (this.defaultValues.serviceAdvisor || accountAuthUserId),
                    ) || {};
                return serviceAdvisor.id || null;
            },

            defaultServiceAgent() {
                const accountAuthUserId = this.selectedAccountAuthUser?.isServiceAgent() ? this.selectedAccountAuthUser.id : null;
                const serviceAgent =
                    this.activeUserOptions.find(
                        user => user.id == (this.defaultValues.serviceAgent || accountAuthUserId),
                    ) || {};
                return serviceAgent.id || null;
            },

            defaultSource() {
                const provider = this.sources.find(source => source.id == this.defaultValues.source) || {};

                return provider.id || null;
            },

            accountIsRequired() {
                return !this.newLead.childAccountId && !this.state.addingAssociatedLead;
            },

            clientNameIsRequired() {
                return this.newLead.contactType == ContactType.MAIN && !this.isValidClientName;
            },

            businessNameIsRequired() {
                return this.newLead.contactType == ContactType.BUSINESS && !this.isValidBusinessName;
            },

            leadTypeIsRequired() {
                return !this.isValidLeadType;
            },

            phoneIsRequired() {
                return this.mandatoryPhone && !this.isValidPhone;
            },

            emailIsRequired() {
                return this.mandatoryEmail && !this.isValidEmail;
            },

            divisionIsRequired() {
                return !this.isValidDivision && this.divisions.length >= 2;
            },

            sourceIsRequired() {
                return this.mandatorySource && !this.isValidSource && this.sources.length;
            },

            presentedDateIsRequired() {
                return !this.dateIsValid(this.newLead.presentedDate);
            },

            callDateIsRequired() {
                return !this.dateIsValid(this.newLead.callDate);
            },

            endContractDateIsRequired() {
                return !this.dateIsValid(this.newLead.endContractDate, true);
            },
        },

        watch: {
            newLead: {
                deep: true,
                handler() {
                    if (this.newLead.source) {
                        const previousSource = this.sources.find(source => source.id == this.newLead.source);
                        this.newLead.sourceName = previousSource ? previousSource.name : '';
                        this.newLead.source = previousSource ? previousSource.id : null;
                    }

                    this.$emit('update', this.newLead);
                },
            },

            'selectedAccount.id': {
                immediate: true,
                async handler() {
                    this.activeUsers = await this.selectedAccount.getActiveUsers(['first_name', 'last_name']);
                },
            },

            defaultAdvisor(newValue) {
                this.newLead.advisor = newValue;
            },

            defaultBdcAgent(newValue) {
                this.newLead.bdc = newValue;
            },

            dashboardType() {
                if (this.emptySearchParameters) {
                    this.newLead.leadType = this.defaultLeadType;
                }
            },

            'newLead.leadType'(leadType) {
                if (leadType == LeadType.PHONE) {
                    this.newLead.callDate = new ActivixDate('now').toString();
                } else {
                    this.newLead.callDate = '';
                }

                if (leadType == LeadType.WALK_IN) {
                    this.newLead.presentedDate = new ActivixDate('now').toString();
                } else {
                    this.newLead.presentedDate = '';
                }

                if (
                    [LeadType.PRE_BOOKING, LeadType.DMS].includes(leadType) &&
                    this.authUser.hasDivisionAccess(Division.SERVICE)
                ) {
                    this.newLead.division = Division.SERVICE;
                }

                if (
                    !this.divisions
                        .map(type => {
                            return type.id;
                        })
                        .includes(this.newLead.division)
                ) {
                    this.newLead.division = null;
                }
            },

            'newLead.division'(division) {
                if (division == Division.SERVICE) {
                    this.newLead.serviceAdvisor = this.defaultServiceAdvisor;
                    this.newLead.serviceAgent = this.defaultServiceAgent;
                } else {
                    this.newLead.serviceAdvisor = null;
                    this.newLead.serviceAgent = null;
                }
            },

            account(newValue, oldValue) {
                if (this.searching || !this.searching || !newValue.id || newValue.id == oldValue.id) {
                    return;
                }

                this.$nextTick(() => {
                    this.reset();
                });
            },
        },

        methods: {
            verifyEmail() {
                if (!this.isValidEmail) {
                    return;
                }

                this.isFocusingEmail = false;

                if (this.oldEmail === this.newLead.email) {
                    return;
                }

                this.oldEmail = this.newLead.email;

                this.$nextTick(() => {
                    if (this.$refs.emailValidation) {
                        this.email.email = this.newLead.email;
                        this.$refs.emailValidation.fetchEmailValidation();
                    }
                });
            },

            updateLeadEmail(validatedEmail) {
                this.email.validated_email = validatedEmail;
            },

            dateIsValid(date, after = false) {
                if (!locale_dt(date).isValid()) {
                    return false;
                }

                return after ? locale_dt(date).isFuture() : !locale_dt(date).isFuture();
            },

            setLeadDataFromSearch(searchObject) {
                const indexes = ['email', 'phone', 'name'];

                indexes.forEach(index => {
                    if (searchObject.key == index) {
                        this.newLead[index] = searchObject.searchValue;
                    } else {
                        this.newLead[index] = '';
                    }
                });
            },

            parseSearchedValue(searchValue) {
                let key = '';

                if (searchValue.indexOf('@') !== -1) {
                    key = 'email';
                } else if (this.isPhoneNumber(searchValue)) {
                    searchValue = this.formatPhoneNumber(searchValue);
                    key = 'phone';
                } else {
                    key = 'name';
                }

                this.setLeadDataFromSearch({ key, searchValue });
            },

            onOpenLeadCreate(defaultValues = {}, searchValue = '') {
                this.defaultValues = defaultValues;
                this.newLead.division = this.defaultDivision;

                this.$emit('reset-account-options');

                if (searchValue) {
                    this.parseSearchedValue(searchValue);
                } else {
                    if (defaultValues.addingAssociatedLead) {
                        this.setLeadFromDefaultValues();
                    }

                    this.$emit('update-state', {
                        addingAssociatedLead: defaultValues.addingAssociatedLead || false,
                    });

                    if (defaultValues.relatedLeadId) {
                        this.$emit('update:relatedLeadId', defaultValues.relatedLeadId);
                    }
                }

                this.$eventBus.$emit('show-right-sidebar', 'addLead', defaultValues.inventoryUnit);
                this.debounce();
            },

            isPhoneNumber(phone) {
                return this.formatPhoneNumber(phone, true).length == 12;
            },

            setLeadFromDefaultValues() {
                const defaultLead = getDefaultLead();

                this.newLead = {
                    ...cloneDeep(defaultLead),
                    name: this.defaultValues.name || defaultLead.name,
                    email: this.contextAccount?.disable_manual_duplicate ? defaultLead.email : this.defaultValues.email || defaultLead.email,
                    phone: this.formatPhoneNumber(this.defaultValues.phone || defaultLead.phone, true),
                    civility: this.defaultCivility,
                    advisor: this.defaultAdvisor,
                    businessName: this.defaultValues.businessName,
                    contactType: this.defaultValues.business ? ContactType.BUSINESS : ContactType.MAIN,
                    bdc: this.defaultBdcAgent,
                    division: this.defaultDivision,
                    leadType: this.defaultLeadType,
                    source: this.defaultSource,
                    sourceName: this.defaultSource
                        ? this.sources.find(source => source.id == this.defaultSource).name
                        : '',
                    serviceAdvisor: this.defaultServiceAdvisor,
                    serviceAgent: this.defaultServiceAgent,
                };

                this.newLead.childAccountId = this.previouslySelectedAccountId;
            },

            setLeadPresentedDateToNow() {
                this.newLead = {
                    ...this.newLead,
                    presentedDate: new ActivixDate('now').toString(),
                };
            },

            reset() {
                this.defaultValues = {};
                this.setLeadFromDefaultValues();
            },

            debounceSearch() {
                this.$emit('search');
            },

            debounce() {
                this.debounceSearch();
                this.verifyEmail();
            },
        },

        created() {
            this.debounceSearch = debounce(this.debounceSearch, 500);
            this.verifyEmail = debounce(this.verifyEmail, 1000);

            this.$eventBus.$on('open-lead-create', this.onOpenLeadCreate);
            this.$eventBus.$on('reset-new-lead-object', this.reset);
            this.$eventBus.$on('reset-walk-in-presented-date', this.setLeadPresentedDateToNow);
        },

        mounted() {
            this.reset();
        },

        beforeDestroy() {
            this.$eventBus.$off('open-lead-create', this.onOpenLeadCreate);
            this.$eventBus.$off('reset-new-lead-object', this.reset);
            this.$eventBus.$off('reset-walk-in-presented-date', this.setLeadPresentedDateToNow);
        },
    };
</script>
