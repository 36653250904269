<template>
    <div>
        <span class="flex items-center" v-if="isEmpty">
            <icon class="text-sm mr-1" :class="processStateIcon" name="bold/check-circle-1" />
            <span>{{ processState }}</span>
        </span>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import Lead from '../../entities/Lead.js';
    import SupplierId from '../../entities/enums/SupplierId.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        data() {
            return {
                processPerformanceState: {
                    presented_date: 'text-gray-600',
                    presented_other_date: 'text-gray-600',
                    road_test_date: 'text-gray-600',
                    home_road_test_date: 'text-gray-600',
                    take_over_date: 'text-orange-500',
                    twenty_four_hour: 'text-orange-400',
                    deposit: 'text-blue-600',
                    sale_date: 'text-blue-600',
                    delivery_date: 'text-blue-600',
                    approved: 'text-blue-600',
                    prepared: 'text-blue-600',
                    gas: 'text-blue-600',
                    inspected: 'text-orange-400',
                    delivered_date: 'text-green-500',
                    csi_date: 'text-purple-500',
                },
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            soldLabel() {
                return this.contextAccount.hasSupplier(SupplierId.POLESTAR) ? this.$tc('clientCard.order') : this.$t('clientCard.sold');
            },

            isEmpty() {
                return !this.lead.process_state || this.lead.process_state !== 'none';
            },

            processStateIcon() {
                if (this.lead.process_state === 'none') {
                    return 'text-gray-600';
                }

                return this.processPerformanceState[this.lead.process_state];
            },

            processState() {
                switch (this.lead.process_state) {
                    case 'delivered_date': return this.$t('clientCard.delivered');
                    case 'delivery_date': return this.$t('clientCard.deliveryDate');
                    case 'sale_date': return this.soldLabel;
                    case 'take_over_date': return this.$t('clientCard.takeOver');
                    case 'road_test_date': return this.$tc('clientCard.roadTest');
                    case 'presented_date': return this.$t('clientCard.presented');
                    case 'csi_date': return this.$t('clientCard.csi');
                }

                return this.$t(`clientCard.${this.lead.process_state}`);
            },
        },
    };
</script>
