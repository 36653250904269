<template>
    <div class="list-none last:rounded-b-lg">
        <div
            :class="[
                'flex flex-col md:flex-row gap-6 md:gap-0 justify-start items-center overflow-x-hidden pl-3 pr-3 py-6 md:p-4  text-sm',
                'cursor-pointer hover:bg-gray-100',
                {
                    'bg-slate-100': isArchived,
                    'bg-white': !isArchived,
                    'border-b border-grey-200' : !isCardExpanded,
                    '!border-2 border-blue-300': isSelectedStock
                },
            ]"
            @click="expandedInventoryUnitCard"
        >
            <div :class="getClasses(inventoryUnitColumnsParams.status)">
                <span class="flex items-center w-1/3 md:hidden" v-text="inventoryUnitColumnsParams.status.title" />
                <activix-tooltip :content="formatTooltip(inventoryUnitDmsStatusTooltip)">
                    <div class="flex flex-col rounded-full items-center min-w-24">
                        <span
                            :class="['px-4 uppercase text-center font-semibold text-xs truncate m-auto md:m-0', stockStatusColor, archivedStatusClass, {
                                'rounded-t-full': isArchived,
                                'rounded-full py-2': !isArchived,
                            }]"
                            v-text="stockStatusText"
                        />
                        <span
                            :class="['px-4 rounded-b-full uppercase text-center font-semibold text-xs truncate text-white bg-grey-600 m-auto md:m-0', archivedStatusClass]"
                            v-text="$t('inventory.vehicle.visibility.archived')"
                            v-if="isArchived"
                        />
                    </div>
                </activix-tooltip>
            </div>

            <div :class="getClasses(inventoryUnitColumnsParams.stock)">
                <span class="flex items-center w-1/3 md:hidden" v-text="inventoryUnitColumnsParams.stock.title" />
                <activix-tooltip :content="formatTooltip(inventoryUnitStockAndDivision)">
                    <samp
                        class="text-sm px-2 py-1 border-2 border-grey-300 bg-grey-100 rounded-md m-auto md:m-0 md:max-w-[10vw] lg:max-w-[8vw] truncate"
                        v-html="highlight(inventoryUnitStockAndDivision)"
                    />
                </activix-tooltip>
            </div>
            <div :class="getClasses(inventoryUnitColumnsParams.iconColumn)">
                <icon
                    name="regular/car-parking-home"
                    class="flex w-8 h-8"
                    :class="{ 'hidden': !hasSharedInventory || inventoryUnit.account_id !== contextAccount.id }"
                />
            </div>
            <div
                :class="[getClasses(inventoryUnitColumnsParams.year), inventoryUnit.year ? '' : 'italic text-gray-500']"
            >
                <span class="flex items-center w-1/3 md:hidden" v-text="inventoryUnitColumnsParams.year.title" />
                <span class="m-auto md:m-0" v-html="highlight(inventoryUnit.year) || $t('inventory.year')" />
            </div>

            <div
                :class="[getClasses(inventoryUnitColumnsParams.model), inventoryUnit.model ? '': 'italic text-gray-500']"
            >
                <span class="flex items-center w-1/3 md:hidden" v-text="inventoryUnitColumnsParams.model.title" />
                <activix-tooltip :content="formatTooltip(inventoryUnitModel)">
                    <span class="m-auto md:m-0" v-html="highlight(inventoryUnitModel)" />
                </activix-tooltip>
            </div>
            <div
                :class="[getClasses(inventoryUnitColumnsParams.trim), inventoryUnit.trim ? '': 'italic text-gray-500']"
            >
                <span class="flex items-center w-1/3 md:hidden" v-text="inventoryUnitColumnsParams.trim.title" />
                <activix-tooltip :content="formatTooltip(inventoryUnitTrim)">
                    <span class="m-auto md:m-0" v-html="highlight(inventoryUnitTrim)" />
                </activix-tooltip>
            </div>

            <div
                :class="getClasses(inventoryUnitColumnsParams.color)"
            >
                <span class="flex items-center w-1/3 md:hidden" v-text="inventoryUnitColumnsParams.color.title" />
                <activix-tooltip :content="formatTooltip(inventoryUnitColors)">
                    <span
                        class="m-auto md:m-0"
                        :class="inventoryUnit.color_exterior ? 'truncate': 'italic text-gray-500'"
                        v-html="highlight(inventoryUnitColors)"
                    />
                </activix-tooltip>
            </div>

            <div
                :class="[getClasses(inventoryUnitColumnsParams.odometer), inventoryUnit.mileage ? '': 'italic text-gray-500']"
                v-show="selectedDivision === 'used'"
            >
                <span class="flex items-center w-1/3 md:hidden" v-text="inventoryUnitColumnsParams.odometer?.title" />
                <span class="m-auto md:m-0" v-text="inventoryUnitMileage" />
            </div>

            <div
                :class="getClasses(inventoryUnitColumnsParams.daysInInventory)"
            >
                <span class="flex items-center w-1/3 md:hidden" v-text="inventoryUnitColumnsParams.daysInInventory.title" />
                <activix-tooltip :content="formatTooltip(inventoryUnitDaysInInventory)">
                    <span class="m-auto md:m-0 truncate" v-html="inventoryUnitDaysInInventory" />
                </activix-tooltip>
            </div>

            <div
                :class="[getClasses(inventoryUnitColumnsParams.price), inventoryUnit.price ? '': 'italic text-gray-500']"
            >
                <span class="flex items-center w-1/3 md:hidden" v-text="inventoryUnitColumnsParams.price.title" />
                <span class="m-auto md:m-0" v-text="inventoryUnitPrice" />
            </div>

            <div :class="getClasses(inventoryUnitColumnsParams.leads)">
                <span class="flex items-center w-1/3 md:hidden" v-text="inventoryUnitColumnsParams.leads.title" />
                <activix-tooltip :content="numberOfLeadsTooltip">
                    <span class="flex items-center m-auto md:m-0">
                        <span v-text="numberOfLeads" />
                    </span>
                </activix-tooltip>
            </div>

            <div :class="getClasses(inventoryUnitColumnsParams.action)">
                <activix-button
                    type="primary"
                    class="text-white"
                    :disabled="isArchived"
                    @click="createLeadInventoryUnit(inventoryUnit, $event)"
                    v-show="!forModalView"
                >
                    <span v-text="inventoryUnitCreateLead" />
                </activix-button>
                <activix-button
                    type="primary"
                    :disabled="isSelectedStock"
                    :active="isSelectedStock"
                    @click="selectVehicle(inventoryUnit, $event)"
                    v-show="forModalView"
                >
                    <span v-text="inventoryUnitAddVehicleToLead" />
                </activix-button>
                <activix-popover
                    placement="bottom-end"
                    trigger="click"
                    class="flex w-full h-full"
                    class-name="bg-white rounded-md shadow-lg border border-gray-200 z-20"
                    ref="popover"
                >
                    <template #trigger>
                        <button
                            class="relative flex items-center justify-center border rounded-sm text-gray-700 hover:bg-gray-300 bg-white py-3 px-1 lg:px-2"
                            @click="openPopover($event)"
                        >
                            <icon name="bold/navigation-menu-vertical" class="text-lg" />
                        </button>
                    </template>
                    <div class="flex flex-col">
                        <button class="bg-white text-gray-700 hover:bg-gray-300 p-2" @click="archivedInventoryUnit(inventoryUnit, $event)" v-if="!isArchived">
                            <div class="flex gap-2 items-center px-3 py-2">
                                <icon name="regular/archive-folder" class="text-lg" />
                                <span v-text="$t('inventory.column.toArchived')" />
                            </div>
                        </button>
                        <button class="bg-white text-gray-700 hover:bg-gray-300 p-2" @click="unarchivedInventoryUnit(inventoryUnit, $event)" v-if="isArchived">
                            <div class="flex gap-2 items-center px-3 py-2">
                                <icon name="regular/archive-folder" class="text-lg" />
                                <span v-text="$t('inventory.column.toUnarchived')" />
                            </div>
                        </button>
                    </div>
                    <slot name="popoverContent" :close-popover="closePopover" />
                </activix-popover>
            </div>
        </div>

        <div
            :class="['flex flex-col p-6 bg-white', { 'border-b-2 border-grey-200' : isCardExpanded }]"
            v-if="isCardExpanded"
        >
            <inventory-vehicle-card
                category="car"
                :inventory-unit="inventoryUnit"
                :inventory-filters="inventoryFilters"
                :for-modal-view="forModalView"
                :inventory-colors="inventoryUnitColors"
            />
        </div>
    </div>
</template>

<script>
    import { get } from 'lodash-es';
    import InventoryStockStatus from '@/entities/InventoryStockStatus.js';
    import InventoryVehicleCard from '@/components/inventory/InventoryVehicleCard.vue';
    import { capitalizeFirst } from '@/utils/string.js';
    import { toCurrency } from '@/utils/numbers.js';
    import { ActivixPopover } from '@autosync/atx-activix-ui';
    import { mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store.js';

    export default {
        components: {
            ActivixPopover,
            InventoryVehicleCard,
        },
        props: {
            searchInput: {
                type: String,
                default: null,
            },
            inventoryUnit: {
                type: Object,
                required: true,
            },
            inventoryFilters: {
                type: Object,
                default: () => {
                },
            },
            isCardExpanded: {
                type: Boolean,
                required: true,
            },
            isSelectedStock: {
                type: Boolean,
                required: false,
            },
            inventoryUnitColumnsParams: {
                type: Object,
                required: true,
            },
            selectedDivision: {
                type: String,
                required: false,
            },
            inventoryUnitId: {
                type: Number,
                required: false,
            },
            forModalView: {
                type: Boolean,
                required: false,
            },
            hasSharedInventory: {
                type: Boolean,
                required: false,
            },
        },
        data() {
            return {
                expanded: false,
                isSelect: false,
            };
        },
        computed: {
            ...mapState(useContextStore, { contextAccount: 'account' }),

            inventoryUnitTrim() {
                return this.inventoryUnit.trim !== 'none' ? this.inventoryUnit.trim : '---';
            },
            inventoryUnitColors() {
                const exteriorColor = this.inventoryUnit.color_exterior != 'none'
                    ? `${capitalizeFirst(this.inventoryUnit.color_exterior)}`
                    : 'N/A';

                const interiorColor = this.inventoryUnit.color_interior != 'none'
                    ? ` • ${capitalizeFirst(this.inventoryUnit.color_interior)}`
                    : ' • N/A';

                return `${exteriorColor}${interiorColor}`;
            },
            inventoryUnitDaysInInventory() {
                if (this.inventoryUnit.days_in_inventory != null) {
                    return this.$tc('inventory.day', this.inventoryUnit.days_in_inventory, [this.inventoryUnit.days_in_inventory]);
                }
                return '---';
            },
            inventoryUnitModel() {
                return this.inventoryUnit.model || '---';
            },
            inventoryUnitDmsStatus() {
                return this.inventoryUnit.dms_status === 'empty_status'
                    ? this.$t('inventory.emptyStatus')
                    : this.inventoryUnit.dms_status;
            },
            inventoryUnitDmsStatusTooltip() {
                return `${this.$t('inventory.column.dmsStatus')}: ${this.inventoryUnitDmsStatus}`;
            },
            inventoryUnitMileage() {
                return this.odometer;
            },
            inventoryUnitPrice() {
                return this.inventoryUnit.price ? `${toCurrency(this.inventoryUnit.price)}` : '---';
            },
            stockStatusColor() {
                const statusColor = {
                    in_stock: 'text-white bg-green-500',
                    available: 'text-white bg-green-500',
                    unavailable: 'text-white bg-red-500',
                    order: 'text-white bg-blue-500',
                    reserved: 'text-white bg-blue-500',
                    locate: 'text-white bg-orange-500',
                }[this.inventoryUnit.status];
                if (statusColor && this.inventoryUnit.stock) {
                    return statusColor;
                }
                return 'text-gray-600 bg-gray-300';
            },
            stockStatusText() {
                const stock = InventoryStockStatus.getTranslation(this.inventoryUnit.status);

                return stock ?? this.$t('inventory.stockStatusTBD');
            },
            odometer() {
                const unit = this.inventoryUnit.odometer_unit || 'km';
                if (!this.inventoryUnit.mileage) {
                    return `--- ${unit}`;
                }
                return this.$n(this.inventoryUnit.mileage, unit);
            },
            integrationName() {
                return this.inventoryUnit.integration_name;
            },
            expandLabel() {
                if (this.expanded) {
                    return this.$t('inventory.boxes.clickToCollapse');
                }
                return this.$t('inventory.boxes.clickToExpand');
            },
            numberOfLeadsTooltip() {
                if (this.inventoryUnit.concurrent_opportunities.length >= 1) {
                    return this.$tc('inventory.selectedInOtherLeads', this.inventoryUnit.concurrent_opportunities.length, [this.inventoryUnit.concurrent_opportunities.length]);
                }
                return '---';
            },
            numberOfLeads() {
                return this.inventoryUnit.concurrent_opportunities.length || '---';
            },
            inventoryUnitCreateLead() {
                return this.$t('inventory.createLead');
            },
            inventoryUnitAddVehicleToLead() {
                if (this.isSelectedStock) {
                    return this.$t('inventory.selected');
                }
                return this.$t('inventory.select');
            },
            inventoryUnitStockAndDivision() {
                if (this.inventoryUnit.division != null) {
                    return `#${this.inventoryUnit.stock} • ${this.$t(`inventory.vehicle.division.${this.inventoryUnit.division}`)}`;
                }
                return `#${this.inventoryUnit.stock}`;
            },
            isArchived() {
                return this.inventoryUnit.archived;
            },
            archivedStatusClass() {
                if (this.isArchived) {
                    return 'py-1 flex items-center justify-center w-full';
                }
                return '';
            },
        },
        methods: {
            getClasses(inventoryUnitColumnParam) {
                return get(inventoryUnitColumnParam.classes, this.selectedDivision, inventoryUnitColumnParam.classes.default);
            },

            formatTooltip(content) {
                if (content === '---') {
                    return '';
                }

                return content;
            },

            highlight(content) {
                if (!content) {
                    return content;
                }

                if (!this.searchInput) {
                    return content;
                }

                const contentString = content.toString();
                const searchInputString = this.searchInput.replace(/\s+/g, '|');

                return this.highlightWords(contentString, searchInputString);
            },

            highlightWords(content, words) {
                return `${content}`.replace(new RegExp(words, 'gi'), match => {
                    return `<span class="bg-yellow-300">${match}</span>`;
                });
            },

            createLeadInventoryUnit(inventoryUnit, event) {
                if (event) {
                    event.stopPropagation();
                }

                this.$eventBus.$emit('open-lead-create', {
                    inventoryUnit: this.inventoryUnit,
                });
                this.$behavior.track('Inventory', { action: 'Click', location: 'createLeadVehicleList' });
            },

            expandedInventoryUnitCard() {
                this.$emit('expandedInventoryUnit', this.inventoryUnit.id);
            },
            selectVehicle(vehicleId, event) {
                this.isSelect = true;
                if (event) {
                    event.stopPropagation();
                }
                if (this.selectedVehicleId != vehicleId) {
                    this.$emit('selectVehicle', vehicleId);
                } else {
                    this.$emit('selectVehicle', null);
                }
            },
            openPopover(event) {
                if (event) {
                    event.stopPropagation();
                }
            },
            archivedInventoryUnit(inventoryUnit, event) {
                if (event) {
                    event.stopPropagation();
                }

                this.$emit('updateInventory', {
                    id: inventoryUnit.id,
                    archived: true,
                });
                this.closePopover();
            },
            unarchivedInventoryUnit(inventoryUnit, event) {
                if (event) {
                    event.stopPropagation();
                }

                this.$emit('updateInventory', {
                    id: inventoryUnit.id,
                    archived: false,
                });
                this.closePopover();
            },
            closePopover() {
                this.$refs.popover.close();
            },
        },
    };
</script>
