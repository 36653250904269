<template>
    <activix-modal
        :name="name"
        no-padding
        @closed="onClosed"
        @before-open="onBeforeOpen"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $tc('general.note', 2) }}
            </h4>
        </template>
        <template slot="body">
            <comment
                class="flex flex-col"
                :lead="lead"
                :account="account"
                v-if="lead.id"
            />
        </template>
    </activix-modal>
</template>

<script>
    import Account from '@/entities/Account.js';
    import Lead from '@/entities/Lead.js';
    import NotesBoxMixin from '@/mixins/NotesBoxMixin.js';
    import { findContextAccount } from '@/pinia/storeHelper.js';
    import Comment from '../comment/Comment.vue';

    export default {
        name: 'CommentModal',

        components: {
            Comment,
        },

        mixins: [NotesBoxMixin],

        props: {
            name: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                lead: new Lead(),
            };
        },

        computed: {
            account() {
                return this.lead.id ? findContextAccount(this.lead.account_id) : new Account();
            },
        },

        methods: {
            onClosed() {
                this.lead = new Lead();
                this.initialFetchCompleted = false;
            },

            async onBeforeOpen(lead) {
                this.lead = lead;
            },
        },
    };
</script>
