<template>
    <activix-select
        trigger-padding-class-name="!text-lg py-2 px-3 border-none"
        filterable
        invisible
        :same-width="false"
        :width-class-name="!mdLayout ? 'min-w-80 max-w-128' : 'w-full'"
        :height-class-name="!mdLayout ? 'min-h-60 max-h-128' : 'h-main-view'"
        :options="groupAccounts"
        :placeholder="$t('multiselect.selectOption')"
        v-model="groupAccountId"
    />
</template>

<script>
    import { get, cloneDeep, isEmpty, debounce } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import { ActivixSelect } from '@autosync/atx-activix-ui';

    // Pinia
    import { useContextStore } from '../store/modules/context/store.js';
    import { useUserCardStore } from '../store/modules/userCard/store.js';
    import { useGlobalStore } from '../store/store.js';

    export default {
        components: {
            ActivixSelect,
        },

        props: {
            activeAccounts: {
                type: Array,
                default: () => [],
            },
            childAccounts: {
                type: Array,
                default: () => [],
            },
            childUsers: {
                type: Array,
                default: () => [],
            },
            groups: {
                type: Array,
                default: () => [],
            },
            inactiveAccounts: {
                type: Array,
                default: () => [],
            },
            parentAccounts: {
                type: Array,
                default: () => [],
            },
            parentUser: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                groupAccountId: '',
            };
        },

        computed: {
            ...mapState(useUserCardStore, {
                currentEditedUser: 'currentForm',
            }),
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
                contextGroup: 'group',
            }),

            showGroups() {
                return !isEmpty(this.groups) && get(this.$route.meta, 'accountSelect.showGroups', false);
            },

            showActiveAccounts() {
                return !isEmpty(this.activeAccounts) && get(this.$route.meta, 'accountSelect.showActiveAccounts', true);
            },

            showInactiveAccounts() {
                return (
                    !isEmpty(this.inactiveAccounts) && get(this.$route.meta, 'accountSelect.showInactiveAccounts', true)
                );
            },

            showParentAccounts() {
                return !isEmpty(this.parentAccounts) && get(this.$route.meta, 'accountSelect.showParentAccounts', true);
            },

            showChildAccounts() {
                return !isEmpty(this.childAccounts);
            },

            groupAccounts() {
                const groupAccounts = [];

                if (this.showGroups) {
                    groupAccounts.push({
                        label: this.$t('general.groups'),
                        options: this.groups.map(group => {
                            return {
                                label: group.name,
                                value: `G${group.id}`,
                            };
                        }),
                    });
                }

                if (this.showParentAccounts) {
                    groupAccounts.push({
                        label: this.$t('general.parentAccounts'),
                        options: this.parentAccounts.map(account => {
                            return {
                                label: account.name,
                                value: account.id.toString(),
                            };
                        }),
                    });
                }

                if (this.showChildAccounts) {
                    groupAccounts.push({
                        label: this.$t('general.childAccounts'),
                        options: this.childAccounts.map(account => {
                            return {
                                label: account.name,
                                value: account.id.toString(),
                            };
                        }),
                    });
                }

                if (this.showActiveAccounts) {
                    groupAccounts.push({
                        label: this.$t('general.activeAccount'),
                        options: this.activeAccounts.map(account => {
                            return {
                                label: account.name,
                                value: account.id.toString(),
                            };
                        }),
                    });
                }

                if (this.showInactiveAccounts) {
                    groupAccounts.push({
                        label: this.$t('general.inactiveAccount'),
                        options: this.inactiveAccounts.map(account => {
                            return {
                                label: account.name,
                                value: account.id.toString(),
                            };
                        }),
                    });
                }

                return groupAccounts;
            },
        },

        watch: {
            'contextGroup.id': {
                immediate: true,
                handler(groupId) {
                    if (groupId) {
                        this.groupAccountId = `G${groupId}`;
                    }
                },
            },

            'contextAccount.id': {
                immediate: true,
                handler(newContextAccountId, oldContextAccountId) {
                    if (newContextAccountId != oldContextAccountId) {
                        this.groupAccountId = String(newContextAccountId);
                    }
                },
            },

            groupAccountId: {
                immediate: true,
                async handler(newValue, oldValue) {
                    this.debounceAccountAction(newValue, oldValue);
                },
            },
        },

        methods: {
            ...mapActions(useContextStore, [
                'setContextAccountAction',
                'setContextGroupAction',
                'setContextLeadIdAction',
                'setContextUserAction',
            ]),

            affectDefaultSelection() {
                if (
                    !this.contextGroup.id &&
                    !this.contextAccount.id &&
                    !this.groupAccountId &&
                    this.parentAccounts.length > 0 &&
                    (this.authUser.isBdc() || this.authUser.isBdcDirector() || this.authUser.isCommercialDirector())
                ) {
                    if (this.$route.name !== 'leads.update') {
                        this.groupAccountId = this.parentAccounts[0].id;
                    }
                }
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.affectDefaultSelection();
            });
        },

        beforeCreate() {
            this.debounceAccountAction = debounce(async (newValue, oldValue) => {
                if (newValue == oldValue || !newValue) {
                    return;
                }

                const isGroup = String(newValue).indexOf('G') !== -1;
                const value = String(newValue).replace('G', '');

                if (isGroup) {
                    this.setContextGroupAction(value);
                } else if (this.$route.name === 'users.update') {
                    await this.setContextAccountAction(value);

                    // Find the appropriate userId
                    let parentUser = cloneDeep(this.parentUser);

                    if (isEmpty(parentUser) && !isEmpty(this.currentEditedUser)) {
                        parentUser = this.currentEditedUser;
                    }

                    if (!parentUser.id) {
                        return;
                    }

                    const user = [parentUser].concat(this.childUsers).find(user => {
                        if (user.account) {
                            return user.account.id == newValue;
                        }

                        return user.account_id == newValue;
                    });

                    this.$router.push({
                        name: 'users.update',
                        params: {
                            id: !isEmpty(user) ? user.id : this.authUser.id,
                        },
                        query: this.$route ? this.$route.query : {},
                    });
                } else if (this.$route.name === 'accounts.update') {
                    this.$router.push({
                        name: 'accounts.update',
                        params: {
                            id: value,
                        },
                        query: this.$route ? this.$route.query : {},
                    });
                } else {
                    if (this.contextLead.id != null && this.contextLead.account_id != value) {
                        await this.setContextLeadIdAction();
                    }

                    await this.setContextAccountAction(value);

                    this.setContextGroupAction();
                    this.setContextUserAction();
                }
            }, 1500);
        },
    };
</script>
