/* eslint-disable camelcase */
import { camelCase } from 'lodash-es';
import i18n from '../plugins/vue-i18n.js';
import Model from './Model.js';
import NotificationTypeIcon from './NotificationTypeIcon.js';
import NotificationType from './NotificationType.js';
import ActivixDate from '../value-objects/ActivixDate.js';
import NotificationGroup from './NotificationGroup.js';

export default class Notification extends Model {
    static get defaults() {
        return {
            options: {},
        };
    }

    /**
     * Getters
     */

    get type() {
        return camelCase(this.options.sub_type || this.options.type);
    }

    get leadName() {
        return this.options.from || this.options.lead_name || i18n.t('notificationCenter.unknown');
    }

    get title() {
        if (this.type == 'processUpdated') {
            return this.options.custom_message;
        }

        if (['leadAssigned', 'newLead', 'noReplyLead'].includes(this.type) && this.options.is_web_order) {
            return i18n.t(
                `notificationCenter.${this.category}.${this.type}WebOrder`,
                this.options.translationParams || [],
            );
        }

        if (this.type === 'assignedLeadUpdated' && this.options?.action === 'wantedVehicleCreated') {
            return i18n.t(
                `notificationCenter.${this.category}.wantedVehicleCreated`,
                this.options.translationParams || [],
            );
        }

        if (this.options.subject) {
            return this.options.subject;
        }

        const translationParams = this.options.translationParams || [];

        if (this.options.translations) {
            Object.keys(this.options.translations).forEach(key => {
                translationParams[key] = i18n.t(this.options.translations[key]);
            });
        }

        return i18n.t(`notificationCenter.${this.category}.${this.type}`, translationParams);
    }

    get date() {
        if (!this.options.date) {
            return '';
        }

        return new ActivixDate(this.options.date.date || this.options.date).toHumanShort(false);
    }

    get iconName() {
        return NotificationTypeIcon.entries[this.type];
    }

    get tooltip() {
        if (this.type == 'automationNotification') {
            return i18n.t('notificationCenter.general.automationNotification');
        }
        return this.title;
    }

    get iconColor() {
        const greenNotifications = [
            'email',
            'emailRead',
            'newComment',
            'niotextIncoming',
            'eventConfirmedAppointment',
            'appointmentConfirmed',
            'phoneAppointmentConfirmed',
        ];
        const redNotifications = [
            'leadLost',
            'leadDeleted',
            'eventCanceledAppointment',
            'eventBadService',
            'appointmentCanceled',
            'phoneAppointmentCanceled',
        ];
        const orangeNotifications = [
            'noReplyLead',
            'leadInvalid',
            'assignedLeadUpdated',
            'assignedLeadVehicleCreated',
            'assignedLeadVehicleUpdated',
            'appointmentNoShow',
        ];
        const blueNotifications = ['processUpdated'];

        if (this.category != NotificationType.TASK && greenNotifications.includes(this.type)) {
            return 'text-green-500';
        }

        if (redNotifications.includes(this.type)) {
            return 'text-red-500';
        }

        if (orangeNotifications.includes(this.type)) {
            return 'text-orange-500';
        }

        if (blueNotifications.includes(this.type)) {
            return 'text-blue-500';
        }

        return 'text-grey-600';
    }

    get leadUrl() {
        if (!this.options.lead_id) {
            return '';
        }

        return {
            name: 'leads.update',
            params: { id: this.options.lead_id },
            query: { open: this.options.comm_id, lead_event_id: this.options.lead_event_id },
        };
    }

    get group() {
        switch (this.category) {
            case NotificationType.COMMUNICATION:
                return NotificationGroup.COMMUNICATIONS;

            case NotificationType.GENERAL:
                return NotificationGroup.ALERTS;

            case NotificationType.EVENT:
            case NotificationType.TASK:
            case NotificationType.OTHER:
                return NotificationGroup.EVENTS;

            default:
                return '';
        }
    }

    /**
     * Setters
     */

    set type(type) {
        this.category = camelCase(type);
    }

    /**
     * Specifications
     */

    isSeen() {
        return this.seen_at;
    }
}
