import Comment from '@/entities/Comment.ts';
import CommentType from '@/entities/CommentType.js';

export default {
    data() {
        return {
            clearContext: false,
            filterValues: {},
            initialFetchCompleted: false,
            notes: [],
            pagination: {
                currentPage: 1,
                perPage: 25,
                totalItemsCount: 0,
            },
        };
    },

    computed: {
        legacyNoteTypeFilter() {
            let filterTypeId = this.$ls.get('commentsFilter', null);

            if (CommentType.exists(filterTypeId)) {
                return filterTypeId;
            }

            switch (filterTypeId) {
                case 'all': filterTypeId = CommentType.ALL; break;
                case 'attachment': filterTypeId = CommentType.ATTACHMENT; break;
                case 'audio': filterTypeId = CommentType.AUDIO; break;
                case 'comment': filterTypeId = CommentType.STRING; break;
            }

            return filterTypeId;
        },
    },

    methods: {
        applyFilters(filters) {
            if (Number.isInteger(filters.noteType)) {
                filters.noteType = [filters.noteType];
            }

            this.$set(this, 'filterValues', {
                ...filters,
                noteType: filters.noteType || [],
                related: filters.related === undefined ? false : filters.related,
            });

            this.persistFilters();
            this.initialFetchCompleted = false;
            this.resetPagination();
            this.fetchCurrentPage(true);
        },

        clearFilters() {
            this.applyFilters({});
        },

        async fetchCurrentPage(replaceItems = false) {
            await this.fetchPage(this.pagination.currentPage, replaceItems);
        },

        async fetchFirstPage(replaceItems = false) {
            await this.fetchPage(1, replaceItems);
        },

        async fetchNextPage(replaceItems = false) {
            await this.fetchPage(++this.pagination.currentPage, replaceItems);
        },

        getNoteIndexForItsContext(noteId, rootNoteId) {
            const rootNoteIndex = this.notes.findIndex(note => note.id === rootNoteId);

            if (rootNoteIndex === -1) {
                return {};
            }

            if (noteId === rootNoteId) {
                return {
                    context: this.notes,
                    index: rootNoteIndex,
                };
            }

            const parentNote = this.getParentNote(noteId, this.notes[rootNoteIndex]);

            if (!parentNote) {
                return {};
            }

            const index = parentNote.children.findIndex(childNote => childNote.id === noteId);

            if (index === -1) {
                return {};
            }

            return {
                context: parentNote.children,
                index,
            };
        },

        getNotePage(noteId) {
            const noteIndex = this.notes.findIndex(note => note.id === noteId);

            if (noteIndex === -1) {
                return null;
            }

            const notePosition = noteIndex + 1;

            return Math.ceil(notePosition / this.pagination.perPage);
        },

        getParentNote(noteId, ancestorNote) {
            if (!ancestorNote.children.length) {
                return null;
            }

            const isParent = ancestorNote.children.some(childNote => childNote.id === noteId);

            if (isParent) {
                return ancestorNote;
            }

            for (const childNote of ancestorNote.children) {
                const parentNote = this.getParentNote(noteId, childNote);

                if (parentNote) {
                    return parentNote;
                }
            }

            return null;
        },

        initFilters() {
            const savedFilters = this.$ls.get(this.filtersStorageKey, null);

            if (savedFilters) {
                this.applyFilters(savedFilters);
            } else if (this.legacyNoteTypeFilter) {
                this.applyFilters({ noteType: [this.legacyNoteTypeFilter] });
            } else {
                this.applyFilters({});
            }
        },

        mergeFetchedNotes(fetchedNotes, replaceItems = false) {
            if (!this.notes.length || replaceItems) {
                this.notes = fetchedNotes;

                return;
            }

            fetchedNotes.forEach(fetchedNote => {
                const existingNoteIndex = this.notes.findIndex(existingNote => existingNote.id === fetchedNote.id);

                if (existingNoteIndex !== -1) {
                    this.notes.splice(existingNoteIndex, 1, fetchedNote);

                    return;
                }

                const previousNoteIndex = this.notes.findIndex(previousNote => previousNote.created_at < fetchedNote.created_at);

                if (previousNoteIndex === 0) {
                    this.notes.splice(0, 0, fetchedNote);

                    return;
                }

                if (previousNoteIndex === -1) {
                    this.notes.push(fetchedNote);

                    return;
                }

                this.notes.splice(previousNoteIndex - 1, 0, fetchedNote);
            });
        },

        async onNoteAdded(rootNoteId) {
            if (rootNoteId) {
                await this.reloadNotePage(rootNoteId);
            } else {
                await this.fetchFirstPage();
            }

            this.$wait.end('creating.note');
        },

        persistFilters() {
            this.$ls.set(this.filtersStorageKey, this.filterValues);
        },

        async refreshItems() {
            this.resetPagination();
            await this.fetchCurrentPage(true);
        },

        async reloadNotePage(noteId) {
            const notePage = this.getNotePage(noteId);

            if (!notePage) {
                return;
            }

            await this.fetchPage(notePage);
        },

        removeNoteFromList(noteId, rootNoteId) {
            const { context, index } = this.getNoteIndexForItsContext(noteId, rootNoteId);

            if (!context) {
                return;
            }

            context.splice(index, 1);
        },

        resetPagination() {
            this.pagination.currentPage = 1;
            this.pagination.totalItemsCount = 0;
        },

        triggerClearContext() {
            this.clearContext = true;
        },

        updateNoteFromList(noteId, rootNoteId, data) {
            const { context, index } = this.getNoteIndexForItsContext(noteId, rootNoteId);

            if (!context) {
                return;
            }

            context.splice(index, 1, new Comment({
                ...context[index],
                ...data,
            }));
        },

        async addAudioNote({ note, rootNoteId }) {
            this.$wait.start('creating.note');

            try {
                await this.$api.comments.storeAudioNote({
                    ...note,
                    leadId: this.lead.id,
                });

                this.onNoteAdded(rootNoteId);
            } catch (e) {
                this.$wait.end('creating.note');
            }
        },

        async addNote({ note, rootNoteId }) {
            this.$wait.start('creating.note');

            note.lead_id = this.lead.id;

            try {
                await this.$api.comments.store({
                    comment: note,
                    file: note.file,
                });

                if (note.alert_users.length > 0) {
                    this.$behavior.track('Notes', { action: 'mention', users: note.alert_users });
                }

                this.onNoteAdded(rootNoteId);
            } catch (e) {
                this.$wait.end('creating.note');
            }
        },

        async deleteNote({ note, rootNoteId }) {
            if (!note) {
                return;
            }

            const waitKey = `deleting.note[${note.id}]`;

            if (this.$wait.is(waitKey)) {
                return;
            }

            this.$wait.start(waitKey);

            try {
                await this.$api.comments.destroy(note.id);

                this.removeNoteFromList(note.id, rootNoteId);
            } catch (error) {
                if (error.response && error.response.status !== 404) {
                    this.appendNewError({
                        code: '0085',
                        display: true,
                        error,
                        payload: {
                            comment_id: note.id,
                        },
                    });
                }

                throw error;
            } finally {
                this.$wait.end(waitKey);
            }
        },

        async fetchPage(page, replaceItems = false) {
            if (!this.lead.id) {
                return;
            }

            this.$wait.start('fetching.notes');

            const skip = (page - 1) * this.pagination.perPage;

            const response = await this.$axios.get(`v1/comments/${this.lead.id}`, {
                params: {
                    skip,
                    take: this.pagination.perPage,
                    filter: this.filterValues.noteType?.[0],
                    related: this.filterValues.related,
                },
            });

            const fetchedNotes = Object.values(response.data.comments);

            this.mergeFetchedNotes(fetchedNotes, replaceItems);

            this.pagination.totalItemsCount = response.data.total;

            this.$wait.end('fetching.notes');

            this.initialFetchCompleted = true;
        },

        async updateNote({ note, rootNoteId }) {
            if (!note) {
                return;
            }

            this.$wait.start('updating.note');

            const updatedNoteData = await this.$api.comments.update(note.id, note);

            this.updateNoteFromList(note.id, rootNoteId, updatedNoteData);

            this.$wait.end('updating.note');
        },
    },

    created() {
        this.$wait.start('fetching.notes');
    },

    mounted() {
        this.$eventBus.$on('update-comment', this.fetchFirstPage);

        this.initFilters();
    },

    beforeDestroy() {
        this.$eventBus.$off('update-comment', this.fetchFirstPage);
    },
};
