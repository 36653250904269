<template>
    <component :is="svgFile" v-if="name" />
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                required: true,
            },
        },

        computed: {
            svgFile() {
                if (!this.name) {
                    return '';
                }

                // We could also use "svg/[request]" to have seperate chunks for each SVG.
                return () => import(/* webpackMode: "lazy-once", webpackChunkName: "svg" */ `@/assets/svg/${this.name}.svg`);
            },
        },
    };
</script>
