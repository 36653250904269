<template>
    <div :class="{ 'note-sticky-toobar': stickyToolbar }">
        <textarea class="form-control" :value="value" ref="textarea" />
    </div>
</template>

<script>
    import Account from '@/entities/Account.js';
    import { mapState } from 'pinia';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'ActivixSummernote',

        props: {
            height: {
                type: [Number, String],
                default: null,
            },
            minHeight: {
                type: Number,
                default: null,
            },
            maxHeight: {
                type: Number,
                default: null,
            },
            value: {
                type: String,
                default: '',
            },
            isLast: {
                type: Boolean,
                default: false,
            },
            stickyToolbar: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['parentAuthUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
        },

        watch: {
            '$i18n.locale'() {
                this.destroy();
                this.init();
            },

            value(newValue) {
                const currentValue = $(this.$refs.textarea).summernote('code');

                if (newValue != currentValue) {
                    $(this.$refs.textarea).summernote('code', newValue);
                }
            },
        },

        methods: {
            init() {
                const toolbar = [
                    ['type', ['style']],
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['font', ['strikethrough', 'superscript', 'subscript']],
                    ['fontsize', ['fontsize']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph', 'height']],
                    ['fontname', ['fontname']],
                    ['insert', ['picture', 'link']],
                ];

                const activixAccounts = [Account.DEMO, Account.ACTIVIX, Account.ACTIVIX_INTERNAL];
                const accountIsActivixAccount = activixAccounts.some(activixAccount => {
                    return this.contextAccount.id === activixAccount || this.contextAccount.parents.some(parent => parent.id === activixAccount);
                });

                if (this.parentAuthUser.isAdmin() || this.$can('other.wysiwyg:codeview') || accountIsActivixAccount) {
                    toolbar.push(['view', ['codeview']]);
                }

                $(this.$refs.textarea).summernote({
                    dialogsInBody: true,
                    dialogsFade: true,
                    height: this.height,
                    minHeight: this.minHeight,
                    maxHeight: this.maxHeight,
                    lang: this.$i18n.locale == 'fr' ? 'fr-FR' : 'en-US',
                    toolbar,
                    popover: {
                        image: [
                            ['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']],
                            ['float', ['floatLeft', 'floatRight', 'floatNone']],
                            ['custom', ['imageAttributes']],
                            ['remove', ['removeMedia']],
                        ],
                    },
                    removeFormat: true, // Doesn't seem to be a real option...
                    callbacks: {
                        onChange: this.onChange,
                        onImageUpload: this.uploadImage,
                    },
                });

                $(this.$refs.textarea).summernote('removeModule', 'autoLink');

                if (this.isLast) {
                    document.querySelector('.note-editor').classList.add('rounded-b');
                }
            },

            destroy() {
                $(this.$refs.textarea).summernote('destroy');
            },

            onChange(value) {
                this.$emit('input', value);
            },

            insertImage(url) {
                $(this.$refs.textarea).summernote('insertImage', url);
            },

            insertText(text) {
                $(this.$refs.textarea).summernote('insertText', text);
            },

            insertNode(node) {
                $(this.$refs.textarea).summernote('insertNode', node);
            },

            pasteHTML(html) {
                $(this.$refs.textarea).summernote('pasteHTML', html);
            },

            disable() {
                $(this.$refs.textarea).summernote('disable');
            },

            enable() {
                $(this.$refs.textarea).summernote('enable');
            },

            async uploadImage(files) {
                const payload = new FormData();
                payload.append('image', files[0]);

                $(this.$refs.textarea).summernote('disable');

                try {
                    const response = await this.$axios.post('v1/auth-user/images', payload);

                    this.insertImage(response.data.data);
                    $(this.$refs.textarea).summernote('enable');
                } catch (error) {
                    this.$notify.warning(this.$t('templates.errorUploading'));
                    $(this.$refs.textarea).summernote('enable');

                    throw error;
                }
            },
        },

        created() {
            this.$eventBus.$on('summernote-paste-html', this.pasteHTML);
        },

        mounted() {
            this.$nextTick(() => {
                this.init();
            });
        },

        beforeDestroy() {
            this.$eventBus.$off('summernote-paste-html', this.pasteHTML);
            this.destroy();
        },
    };
</script>
