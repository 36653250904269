<template>
    <div>
        <div
            class="flex justify-between p-3"
            :class="isDanger ? 'border-2 border-red-200 bg-red-100' : 'bg-blue-100'"
        >
            <div class="flex flex-col">
                <span :class="isDanger ? 'font-bold' : 'text-gray-700'">
                    {{ $t('modal.dealershipTime') }}
                </span>
                <span class="text-gray-700" v-if="isDanger">
                    {{ $t('modal.dealershipTimeError') }}
                </span>
                <span class="font-bold" v-if="!isDanger">
                    {{ formattedDuration }}
                </span>
                <span class="text-gray-700" v-if="!isDanger">
                    {{ formattedTimezone }}
                </span>
            </div>
            <div class="flex items-center">
                <icon class="mr-1" :name="iconName" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import TimeZone from '@/components/timezone/TimeZone.ts';

    export interface TimeZoneDifferenceWarningProps {
        startTime: string;
        endTime?: string;
        timeZone?: string;
        city?: string;
    }

    const props = withDefaults(defineProps<TimeZoneDifferenceWarningProps>(), {
        endTime: '',
        city: '',
    });

    const isDanger = computed<boolean>(() => !props.timeZone);

    const iconName = computed<string>(() => (isDanger.value ? 'regular/alert-triangle' : 'regular/network-clock'));

    const formattedDuration = computed<string>(() => {
        if (isDanger.value) {
            return '';
        }

        const startAt = new ActivixDate(props.startTime, 'dateTime', props.timeZone);

        if (!props.endTime) {
            return startAt.toHumanTime();
        }

        const endAt = new ActivixDate(props.endTime, 'dateTime', props.timeZone);
        const sameDay = startAt.isSameDay(endAt);

        let formattedTimeDuration = sameDay ? startAt.toHumanTime() : startAt.toHumanShort();

        if (endAt.isAfter(startAt)) {
            formattedTimeDuration += sameDay
                ? ` - ${endAt.toHumanTime()}`
                : ` - ${endAt.toHumanShort()}`;
        }

        return formattedTimeDuration;
    });

    const formattedTimezone = computed<string>(() => {
        if (isDanger.value) {
            return '';
        }

        const timeZoneStandardizedName = TimeZone.standardizedName(props.timeZone);
        const timeZoneOffset = TimeZone.offset(props.timeZone);

        return props.city
            ? `${timeZoneStandardizedName}, ${props.city} (UTC${timeZoneOffset})`
            : `${timeZoneStandardizedName} (UTC${timeZoneOffset})`;
    });
</script>
