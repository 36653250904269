import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';

import AuthUser from '@/entities/AuthUser.js';
import { isSm } from '../utils/layout.js';

import accounts from './accounts.js';
import auth from './auth.js';
import automations from './automations.js';
import campaign from './campaign.js';
import calendar from './calendar.js';
import consent from './consent.js';
import dashboards from './dashboards.js';
import duplicates from './duplicates.js';
import flow from './flow.js';
import groups from './groups.js';
import homePage from './homePage.js';
import imports from './imports.js';
import inventory from './inventory.js';
import analytics from './analytics.js';
import inTurn from './inTurn.js';
import leads from './leads.js';
import leadTrash from './leadTrash.js';
import redirects from './redirects.js';
import schedule from './schedule.js';
import search from './search.js';
import suppliers from './suppliers.js';
import templates from './templates.js';
import users from './users.js';
import unsubscribe from './unsubscribe.js';

import NotFound from '../views/NotFound.vue';
import Unauthorized from '../views/Unauthorized.vue';
import Forbidden from '../views/Forbidden.vue';

if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
}

Vue.use(Router);
Vue.use(Meta);

const routes = [
    ...redirects,
    {
        path: '/unauthorized',
        name: 'unauthorized',
        component: Unauthorized,
        meta: {
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            layout: {
                contentHeader: false,
            },
        },
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        component: Forbidden,
        props: route => ({ error: route.query.error }),
        meta: {
            splashscreen: true,
        },
    },
    ...accounts,
    ...auth,
    ...automations,
    ...campaign,
    ...calendar,
    ...consent,
    ...dashboards,
    ...duplicates,
    ...flow,
    ...groups,
    ...homePage,
    ...imports,
    ...inventory,
    ...analytics,
    ...inTurn,
    ...leads,
    ...schedule,
    ...search,
    ...suppliers,
    ...templates,
    ...users,
    ...unsubscribe,
    ...leadTrash,
    {
        path: '*',
        name: 'notFound',
        component: NotFound,
        meta: {
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            layout: {
                contentHeader: false,
            },
        },
    },
];

if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_SHOW_SANDBOX === 'true') {
    routes.push(
        {
            name: 'sandbox',
            path: '/sandbox',
            component: () => import(/* webpackChunkName: "sandbox" */ '../sandbox/Sandbox.vue'),
            meta: {
                landing: true,
                header: {
                    search: true,
                },
            },
        },
        {
            path: '/sandbox/:version',
            component: () => import(/* webpackChunkName: "sandbox" */ '../sandbox/Version.vue'),
            meta: {
                landing: true,
                header: {
                    search: true,
                },
            },
        },
    );
}

const routesPhoneDisabled = [
    'templates.',
    'automations.',
    'schedule',
    'flow.',
    'duplicates',
    'imports.',
    'users.',
    'accounts.',
    'groups.',
];

// Prevent "NavigationDuplicated" console errors
['push', 'replace'].forEach(method => {
    const originalMethod = Router.prototype[method];
    Router.prototype[method] = function m(location, onResolve, onReject) {
        if (onResolve || onReject) {
            return originalMethod.call(this, location, onResolve, onReject);
        }

        return originalMethod.call(this, location).catch((error) => {
            if (Router.isNavigationFailure(error)) {
                return error;
            }

            return Promise.reject(error);
        });
    };
});

const router = new Router({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.name == from.name) {
            return null;
        }

        if (savedPosition) {
            return savedPosition;
        }

        return { x: 0, y: 0 };
    },
});

router.onReady(() => {
    document.addEventListener('click', e => {
        const route = e.target.getAttribute('data-vue-router');

        if (!route) {
            return;
        }

        router.push(route);
    });
});

// Remove hash from route
router.beforeEach(async (to, from, next) => {
    if (to.path === '/auth/callback') {
        Vue.router.currentRoute.meta.auth = false;
        Vue.auth.handleAuthentication();
        next(false);
        return;
    }

    if (to.path === '/auth/mfa-callback') {
        Vue.mfaAuth.handleAuthentication();
        next(false);
        return;
    }

    if (to.query.ref) {
        Vue.ls.set('referer', to.query.ref);
    }

    if (isSm && to.name && routesPhoneDisabled.some(v => to.name.indexOf(v) >= 0)) {
        next('/');
        return;
    }

    if (to.hash) {
        next({ ...to, hash: '' });
        return;
    }

    if (window.reloadNextPage && to.name && from.name && to.name != from.name) {
        window.location = to.fullPath;
        return;
    }

    if (to.path === '/') {
        try {
            const user = await Vue.auth.waitForUser();
            let defaultPath = 'dashboards.allLead';

            if (user.default_dashboard && new AuthUser(user).hasAccessTo(user.default_dashboard)) {
                defaultPath = user.default_dashboard;
            }

            const link = Vue.router.resolve({ name: defaultPath });

            if (link.resolved.matched.length > 0) {
                next(link.href);
            } else {
                next({ name: 'dashboards.allLead' });
            }

            return;
        } catch (e) {
            if (Vue.router?.currentRoute?.name === 'forbidden') {
                next();
            } else {
                next({ name: 'dashboards.allLead' });
            }

            return;
        }
    }

    next();
});

Vue.router = router;

export default router;
