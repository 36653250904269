export default {
    additionalFields: 'Champs Additionnels',
    addToSalesJourney: 'Ajouter au parcours de vente',
    addToSalesJourneyDisabledByDivisionTooltip: 'Impossible d\'ajouter à un parcours de vente d\'une autre division',
    associate: 'Associer',
    associateLeadNotActiveModalText: 'Le lead que vous avez sélectionné est {0}<br>Êtes-vous sûr de vouloir continuer?',
    associateToSelectedLead: 'Associer au lead sélectionné',
    backToSearchResults: 'Retour aux résultats',
    businessNameLength: 'Le nom d\'entreprise doit avoir au moins 3 caractères',
    businessNameRequired: 'Le nom d\'entreprise est requis',
    clientNameLength: 'Le nom du lead doit avoir au moins 3 caractères',
    clientNameRequired: 'Le nom du lead est requis',
    close: 'Fermer',
    collapse: 'Réduire',
    completableFieldsTooltip: 'Les informations suivantes seront ajoutées au lead sélectionné.',
    complete: 'Compléter',
    confirmLeadAssociation: 'Voulez-vous vraiment créer un lead associé?',
    confirmLeadCreation: 'Voulez-vous vraiment créer un nouveau lead?',
    confirmLeadCreationOverAddingVisit: 'Voulez-vous vraiment créer un lead au lieu d\'ajouter une visite à un lead existant (procédure suggérée)?',
    confirmLeadCreationOverSelectingExisting: 'Voulez-vous vraiment créer un lead au lieu de sélectionner un lead existant?',
    confirmLeadEdit: 'Voulez-vous modifier ce lead?',
    continue: 'Procéder',
    copyClientsInformations: 'Copier les informations du client',
    copyWantedToExchangeVehicle: 'Copier le véhicule recherché vers l\'actuel',
    createAssociatedLead: 'Créer lead associé',
    createNewLead: 'Créer un nouveau lead',
    createdAt: 'Créé le',
    disableManualDuplicate: "Un lead ne peut pas être ajouté lorsqu'un lead avec le même courriel existe déjà.",
    divisionRequired: 'La division est requise',
    emailFound: 'Courriel trouvé',
    emailOrPhoneRequired: 'Un courriel ou un numéro de téléphone doit être spécifié',
    emailRequired: 'L\'adresse courriel est requise',
    error: 'Une erreur s\'est produite. Veuillez réessayer.',
    errorDuringAddEventToSalesJourney: "Une erreur s'est produite durant l'ajout de l'évènement au parcours de vente. Veuillez réessayer.",
    errorDuringAssociation: 'Une erreur s\'est produite durant l\'association. Veuillez réessayer.',
    errorDuringSearch: 'Une erreur s\'est produite durant la recherche. Veuillez recommencer.',
    expand: 'Agrandir',
    fieldDisabled: 'Ce champ ne peut pas être modifié durant la création d\'un lead associé',
    interDealerLeads: 'Leads Interconcessionnaire',
    interDealerLeadsTooltip: 'Seulement les leads reliés des autres concessionnaires dans votre groupe sont visibles.',
    leadAssociation: 'Création de lead associé',
    leadCreation: 'Création de lead',
    leadTypeRequried: 'Le type de lead est requis',
    mustSelectAccount: 'Un compte doit être sélectionné',
    nameMatch: 'Nom exact trouvé',
    nameNotMatched: 'Nom différent que recherché',
    namePartiallyMatched: 'Correspondance partielle du nom recherché',
    nextStep: 'Prochaine étape',
    noAdvisor: 'Aucun conseiller',
    noDivision: 'Aucune division',
    noEmailFound: 'Pas de match de courriel',
    noEmailSearched: 'Pas de courriel recherché',
    noEmailsInLead: 'Ce lead n\'a pas de courriels',
    noInterDealerLeads: 'Aucun lead interconcessionnaire',
    noMoreResults: 'Aucun autre résultat.',
    noNameSearched: 'Pas de nom recherché',
    noPhoneFound: 'Pas de match de numéro de téléphone',
    noPhoneSearched: 'Pas de numéro recherché',
    noPhonesInLead: 'Ce lead n\'a pas de numéros de téléphones',
    noResults: 'Aucun résultat',
    noVehicle: 'Aucun véhicule',
    originRequired: 'La source du lead est requise',
    originalEmail: 'Courriel original',
    phoneFound: 'Numéro trouvé',
    phoneRequired: 'Le numéro de téléphone est requis',
    resetSearch: 'Réinitialiser',
    resetSearchDisabled: 'Cette option n\'est pas disponible pendant l\'ajout d\'un lead associé',
    results: 'Résultats',
    returnInitialLead: 'Retour au lead initial ({lead})',
    select: 'Choisir',
    selectLeadForAssociation: 'Sélectionner ce lead pour l\'association',
    selectLeadNotActiveModalText: 'Le lead que vous avez sélectionné est {0}<br>Veuillez sélectionner un lead actif.',
    showMore: 'Voir plus',
    validCallDateRequired: 'Une date d\'appel est requise',
    validEndContractDateRequired: 'Une date de fin de contrat est requise',
    validPresentedDateRequired: 'Une date de visite est requise',
    vehicle: 'Véhicule',
};
