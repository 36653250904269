export default {
    email: 'Email',
    phone: 'Phone',
    sms: 'SMS',
    messenger: 'Messenger',
    video: 'Video',

    asAction: {
        email: '{type} email',
        phone: '{type} call',
        sms: '{type} SMS',
        messenger: '{type} facebook message',
        video: '{type} video call',
    },
};
