<template>
    <div class="flex grow justify-center items-center" v-if="showListLoading">
        <activix-spinner class="sticky bottom-6 my-6" :style="{ top: `${containerOffsetHeight}px` }" />
    </div>

    <div v-else>
        <note-list-item
            :context-note-data="contextNoteData"
            :depth="depth"
            :lead="lead"
            :last-in-group="index === lastNoteIndex"
            :note="note"
            :note-type-filter="noteTypeFilter"
            :readonly="readonly"
            :root-note-id="rootNoteId || note.id"
            :users="users"
            :key="note.id"
            @trigger-deleting="$emit('trigger-deleting', $event)"
            @trigger-editing="$emit('trigger-editing', $event)"
            @trigger-replying="$emit('trigger-replying', $event)"
            v-for="(note, index) in notes"
        />

        <div class="flex justify-center border-t p-3" v-if="showMoreItemsLoading">
            <activix-spinner :size="22" :line-size="2" />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useLayoutStore } from '@/store/modules/layout/store.js';

    import NoteListItem from '@/components/container/lead/notes/NoteListItem.vue';
    import Lead from '@/entities/Lead.js';

    export default {
        components: {
            NoteListItem,
        },

        props: {
            contextNoteData: {
                type: Object,
                required: true,
            },
            depth: {
                type: Number,
                default: 0,
            },
            noteTypeFilter: {
                type: Array,
                default: () => [],
            },
            notes: {
                type: Array,
                required: true,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            rootNoteId: {
                type: Number,
                default: null,
            },
            showListLoading: {
                type: Boolean,
                default: false,
            },
            showMoreItemsLoading: {
                type: Boolean,
                default: false,
            },
            users: {
                type: Array,
                required: true,
            },
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useLayoutStore, ['bodySpacingTop', 'headerHeight']),

            containerOffsetHeight() {
                return (this.headerHeight + this.bodySpacingTop + 20);
            },

            lastNoteIndex() {
                return this.notes.length - 1;
            },
        },
    };
</script>
