<template>
    <div class="flex flex-wrap lg:-mx-3">
        <card
            class="px-3 w-full | xs:w-1/3"
            :class="inventoryCardClass"
            :icon="card.icon"
            :card="card.card"
            :denominator="card.denominator"
            :title="card.title"
            :category="card.category"
            :key="card.name"
            @click="$behavior.track('inventory', { action: 'Click', location: 'inventoryStatisticCardClicked'})"
            v-for="card in activeCards"
        >
            <template #description v-if="card.componentDescription">
                <component :is="card.componentDescription" :cards="cards" />
            </template>
            <template #description v-else-if="card.description">
                <div class="flex justify-center text-lg">
                    <div class="text-xl">
                        {{ card.description }}
                    </div>
                </div>
            </template>
            <template #popover v-if="card.informationTooltip">
                <div class="text-center" v-text="card.informationTooltip" />
            </template>
        </card>
    </div>

    <!-- Fix circleCI commit -->
</template>

<script>
    import Card from '@/components/dashboards/cards/Card.vue';

    // Mixins
    import { camelCase, round } from 'lodash-es';
    import $behavior from '@/plugins/mixpanel.js';
    import InventoryCards from '../../mixins/InventoryCards.js';
    import DashboardCaching from '../../mixins/DashboardCaching.js';
    import { toMoney } from '../../utils/index.js';

    export default {
        components: { Card },

        mixins: [InventoryCards, DashboardCaching],

        props: {
            statusOptions: {
                type: Array,
                default: () => [
                    'inStock',
                    'unavailable',
                ],
            },
            accountSuppliers: {
                type: Array,
                default: () => {
                },
            },
        },

        data() {
            return {
                cards: {
                    totalVehicles: {
                        number: {
                            count: null,
                            colors: {
                                0: 'blue',
                            },
                        },
                        new: {
                            count: null,
                        },
                        demo: {
                            count: null,
                        },
                        used: {
                            count: null,
                        },
                        certified: {
                            count: null,
                        },
                        unknown: {
                            count: null,
                        },
                    },
                    averagePrice: {
                        number: {
                            count: null,
                            colors: {
                                0: 'blue',
                            },
                        },
                        lowestPrice: {
                            price: null,
                        },
                        highestPrice: {
                            price: null,
                        },
                        totalValue: {
                            price: null,
                        },
                    },
                    averageDaysInInventory: {
                        number: {
                            count: null,
                            colors: {
                                0: 'blue',
                            },
                        },
                        moreThan7Days: {
                            number: null,
                        },
                        moreThan14Days: {
                            number: null,
                        },
                        moreThan30Days: {
                            number: null,
                        },
                        moreThan60Days: {
                            number: null,
                        },
                    },
                    inStock: {
                        number: {
                            count: null,
                            colors: {
                                0: 'blue',
                            },
                            name: 'inStock',
                        },
                    },
                },
            };
        },

        computed: {
            $behavior() {
                return $behavior;
            },
            inventoryCards() {
                return {
                    totalVehicles: {
                        visible: true,
                    },
                    averagePrice: {
                        visible: true,
                    },
                    averageDaysInInventory: {
                        visible: false,
                    },
                    inStock: {
                        visible: true,
                    },
                    newVehicles: {
                        visible: true,
                    },
                };
            },
        },

        methods: {
            getMoneyValue($value) {
                return $value === null ? 'N/A' : toMoney($value ?? 0);
            },

            setCards(results) {
                this.cards.totalVehicles.number.count = results.count;
                this.cards.totalVehicles.used.count = results.used;
                this.cards.totalVehicles.certified.count = results.certified;
                this.cards.totalVehicles.demo.count = results.demo;
                this.cards.totalVehicles.new.count = results.new;
                this.cards.totalVehicles.unknown.count = results.unknown;

                this.cards.averagePrice.number.count = this.getMoneyValue(results.averagePrice);
                this.cards.averagePrice.lowestPrice.price = this.getMoneyValue(results.lowestPrice);
                this.cards.averagePrice.highestPrice.price = this.getMoneyValue(results.highestPrice);

                if (this.getMoneyValue(results.averagePrice) === 'N/A') {
                    this.cards.averagePrice.totalValue.price = 'N/A';
                } else {
                    this.cards.averagePrice.totalValue.price = this.getMoneyValue(results.totalPrice);
                }

                this.cards.averageDaysInInventory.number.count = round(results.averageDaysInventory);
                this.cards.averageDaysInInventory.moreThan7Days.number = results.inventoryAgeRange.moreThan7Days;
                this.cards.averageDaysInInventory.moreThan14Days.number = results.inventoryAgeRange.moreThan14Days;
                this.cards.averageDaysInInventory.moreThan30Days.number = results.inventoryAgeRange.moreThan30Days;
                this.cards.averageDaysInInventory.moreThan60Days.number = results.inventoryAgeRange.moreThan60Days;

                this.cards.inStock.number.count = results.by_status.inStock ?? 0;
                this.cards.accountSuppliers = this.accountSuppliers;

                Object.entries(this.statusOptions).forEach(([, value]) => {
                    const statusResult = results.by_status[camelCase(value)];
                    this.cards.inStock[camelCase(value)] = {
                        count: statusResult ?? 0,
                        name: camelCase(value),
                    };
                });

                this.$nextTick(() => {
                    this.setCaching('cards', this.cards);
                });
            },
        },
    };
</script>
