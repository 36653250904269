import { isEmpty } from 'lodash-es';
import { contextStore } from '@/pinia/storeHelper.js';
import Supplier from '@/entities/Supplier.ts';
import SupplierId from '@/entities/enums/SupplierId.js';
import TradeInVehicle from '@/entities/TradeInVehicle.ts';
import WantedVehicle from '@/entities/WantedVehicle.ts';
import { LeadEvent } from '@/entities/types/LeadEventType.ts';
import { VehicleTradeType } from '@/entities/types/VehicleTradeType.ts';
import { VehicleValue } from '@/entities/types/VehicleType.ts';
import DrsQuoteInformation from '@/entities/DrsQuoteInformation.ts';
import DrsTradeInInformation from '@/entities/DrsTradeInInformation.ts';
import BaseModel from '@/entities/BaseModel.ts';
import i18n from '../plugins/vue-i18n.js';
import ActivixDate from '../value-objects/ActivixDate.js';
import Lead from './Lead.js';
import LeadVehicleFilter from './LeadVehicleFilter.js';
import LeadVehicleModality from './LeadVehicleModality.js';
import LeadVehicleType from './LeadVehicleType.js';

export default class LeadVehicle extends BaseModel {
    id: number | null = null;

    accessories: number | null = null;

    accidented_damage_cost: number | null = null;

    actual_value: number | null = null;

    allowed_mileage: number | null = null;

    apply_cbb_value: boolean = true;

    asl_make_id: string | null = null;

    asl_model_id: string | null = null;

    asl_spec_id: string | null = null;

    autosync_id: string | null = null;

    autosync_last_updated_at: string | null = null;

    balance: number | null = null;

    bank_id: number | null = null;

    body_type: string | null = null;

    budget_max: string | null = null;

    budget_min: string | null = null;

    calculator_appraiser: string | null = null;

    calculated_equity: number | null = null;

    calculator_value: string | null = null;

    category: string | null = null;

    category_rv: string | null = null;

    certified: boolean | null = null;

    client_number: string | null = null;

    color_exterior: string | null = null;

    color_interior: string | null = null;

    comment: string | null = null;

    condition: string | null = null;

    created_at: string | null = null;

    custom_fields: any[] = [];

    customables?: any[];

    deposit_amount: number | null = null;

    deposit_received_at: string | null = null;

    documentation: string | null = null;

    driving_wheels: string | null = null;

    drs_appointment_link: string | null = null;

    drs_credit_application_link: string | null = null;

    drs_deposit_link: string | null = null;

    drs_link_client: string | null = null;

    drs_link_dealer: string | null = null;

    drs_trade_in_link: string | null = null;

    end_contract_date: string | null = null;

    end_extended_warranty_date: string | null = null;

    end_extended_warranty_mileage: number | null = null;

    end_warranty_date: string | null = null;

    end_warranty_mileage: number | null = null;

    engine: string | null = null;

    exported_to_serti: boolean | null = null;

    extended_warranty: string | null = null;

    frequency: string | null = null;

    fuel: string | null = null;

    funding: string | null = null;

    imported_bank: string | null = null;

    in_service_date: string | null = null;

    initial_cash: string | null = null;

    intention: string | null = null;

    inventory_unit: any = null;

    inventory_unit_id: number | null = null;

    lead_events: LeadEvent[] = [];

    lead_id: number | null = null;

    length_max: string | null = null;

    length_min: string | null = null;

    license_plate: string | null = null;

    link: string | null = null;

    make: string | null = null;

    make_model_year_slug: string | null = null;

    make_warranty_id: number | null = null;

    mileage: string | null = null;

    mileage_excess: string | null = null;

    modality: string | null = null;

    model: string | null = null;

    odometer_unit: string | null = null;

    offer: string | null = null;

    option: string | null = null;

    order_number: string | null = null;

    payment: number | null = null;

    payment_with_tax: number | null = null;

    preparation: number | null = null;

    price: number | null = null;

    primary_vehicle: boolean = false;

    profit: number | null = null;

    purchase_date: string | null = null;

    rate: number | null = null;

    recall: string | null = null;

    recorded_date: string | null = null;

    related_id: string | null = null;

    requested: number | null = null;

    residual: number | null = null;

    security_deposit: number | null = null;

    service: boolean = false;

    sleeping: number | null = null;

    sold: boolean = false;

    sold_at: string | null = null;

    sold_by: string | null = null;

    stock: string | null = null;

    stock_state: string | null = null;

    term: number | null = null;

    tire: boolean = false;

    tire_type: string[] = [];

    trade_notes: string | null = null;

    trade_type: VehicleTradeType | null = null;

    transmission: string | null = null;

    trim: string | null = null;

    type: string | null = null;

    updated_at: string | null = null;

    url: string | null = null;

    value: number | null = null;

    vauto_value: string | null = null;

    vehicle_state: string | null = null;

    vehicle_value: VehicleValue | null = null;

    verified_by_id: number | null = null;

    vin: string | null = null;

    vin_decoded: boolean | null = null;

    warranty: string | null = null;

    weight: string | null = null;

    year: string | null = null;

    year_max: number | null = null;

    year_min: number | null = null;

    private _lead: Lead = new Lead();

    private _suppliers: Supplier[] = [];

    constructor(initialValues: any = {}) {
        super();
        this.init(initialValues);
        this.initDrsAdditionalInformation(this.drs_additional_information);
    }

    initDrsAdditionalInformation(drsAdditionalInformation: any) {
        const drsAdditionalInformationNormalized = drsAdditionalInformation || {};

        switch (this.type) {
            case LeadVehicleType.WANTED:
                this._drs_additional_information = new DrsQuoteInformation(drsAdditionalInformationNormalized);
                break;
            case LeadVehicleType.EXCHANGE:
                this._drs_additional_information = new DrsTradeInInformation(drsAdditionalInformationNormalized);
                break;
            default:
                this._drs_additional_information = drsAdditionalInformationNormalized;
        }
    }

    static get exportableSuppliers(): number[] {
        return [SupplierId.VAUTO, SupplierId.AUTOPROPULSION, SupplierId.QUICKTRADE];
    }

    set lead(lead: Lead) {
        this._lead = new Lead(lead);
    }

    get lead(): Lead {
        return this._lead;
    }

    set suppliers(suppliers: Partial<Supplier>[]) {
        this._suppliers = suppliers.map(supplier => new Supplier(supplier));
    }

    get suppliers(): Supplier[] {
        return this._suppliers;
    }

    set drs_additional_information(drsAdditionalInformation: any) {
        this.initDrsAdditionalInformation(drsAdditionalInformation);
    }

    get drs_additional_information(): DrsTradeInInformation | DrsQuoteInformation {
        return this._drs_additional_information;
    }

    get exportSupplier(): Supplier | null {
        const suppliers = this.suppliers.filter(suppliable => {
            return suppliable.id && LeadVehicle.exportableSuppliers.includes(suppliable.id);
        });

        return suppliers?.[0] || null;
    }

    get isExported(): boolean {
        return this.exportSupplier?.exported || false;
    }

    get isImported(): boolean {
        return this.exportSupplier?.imported || false;
    }

    get soldInDifferentLead(): number | null {
        if (this.type == 'wanted' &&
            this.inventory_unit &&
            this.inventory_unit.sold_in_vehicles
        ) {
            return this.inventory_unit.sold_in_vehicles.find((vehicle: any) => {
                return vehicle.lead_id !== this.lead_id;
            })?.id;
        }

        return null;
    }

    get supplierWithRelatedId(): Supplier | undefined {
        return this.suppliers.find(supplier => supplier.related_id !== null);
    }

    isExchange(): this is TradeInVehicle {
        return this.type === LeadVehicleType.EXCHANGE;
    }

    isWanted(): this is WantedVehicle {
        return this.type === LeadVehicleType.WANTED;
    }

    get isCashDeal(): boolean {
        return this.modality === LeadVehicleModality.CASH;
    }

    get isPolestarDeposited(): boolean {
        return this.custom_fields.some(customField => customField?.fixed_field === 'custom_field_order_deposit' && customField?.pivot?.value?.[0] === true);
    }

    get isPolestarOrder(): boolean {
        return this.suppliers.some(supplier => supplier?.suppliable?.additional_information?.type === LeadVehicleFilter.ORDER);
    }

    get isPolestarTestDrive(): boolean {
        return this.suppliers.some(supplier => supplier?.suppliable?.additional_information?.type === LeadVehicleFilter.TEST_DRIVE);
    }

    get isPolestarTestDriveAYL(): boolean {
        if (!this.isPolestarTestDrive) {
            return false;
        }

        const testDriveSubTypeCustomField = this.custom_fields.find(customField => customField?.fixed_field === 'custom_field_sub_type');
        const subTypeValue = testDriveSubTypeCustomField?.pivot?.value?.[0]?.toLowerCase();

        return subTypeValue === 'drive out';
    }

    get isPolestarTestDriveTDM(): boolean {
        if (!this.isPolestarTestDrive) {
            return false;
        }

        const testDriveSubTypeCustomField = this.custom_fields.find(customField => customField?.fixed_field === 'custom_field_sub_type');
        const subTypeValue = testDriveSubTypeCustomField?.pivot?.value?.[0]?.toLowerCase();

        return subTypeValue === 'event';
    }

    get isTRS(): boolean {
        return this.suppliers.some(supplier => supplier.id === SupplierId.AUTOSYNC);
    }

    get isVerified(): boolean {
        return !!this.verified_by_id;
    }

    get totalPrice(): number {
        const price = parseFloat(this.price?.toString() || '') || 0;
        const preparation = parseFloat(this.preparation?.toString() || '') || 0;
        const accessories = parseFloat(this.accessories?.toString() || '') || 0;
        const documentation = parseFloat(this.documentation || '') || 0;

        return price + preparation + accessories + documentation;
    }

    get eligibleForRenewal(): boolean {
        return (
            new ActivixDate(this.end_contract_date).isEmpty() &&
            this.modality !== LeadVehicleModality.CASH &&
            !this.term
        );
    }

    get hasLeadEvents(): boolean {
        return this.lead_events.some(leadEvent => !leadEvent.is_primary_lead_event);
    }

    static validType(type: string): boolean {
        return LeadVehicleType.exists(type);
    }

    static isConsideredEmpty(vehicle: any, ignoreFields: string[] = []): boolean {
        ignoreFields = [
            ...ignoreFields,
            'id',
            'lead_id',
            'type',
            'created_at',
            'created_by',
            'updated_at',
            'updated_by',
            'deleted_at',
            'deleted_by',
        ];

        for (const [key, value] of Object.entries(vehicle)) {
            if (!ignoreFields.includes(key) && !isEmpty(value) && value) {
                return false;
            }
        }

        return true;
    }

    static calculateVehicleValue(vehicle: LeadVehicle): number {
        if (isEmpty(vehicle.vehicle_value) || !vehicle.apply_cbb_value) {
            return vehicle.value ? parseFloat(vehicle.value?.toString() || '') : 0;
        }

        let condition = vehicle.condition || contextStore().account.renewal_alert_options.equity_base;

        if (condition == 'extra_clean') {
            condition = 'xclean';
        }

        const base = vehicle.vehicle_value[`base_${condition}`];
        const mileage = vehicle.vehicle_value[`mileage_${condition}`];

        return parseFloat(base) + parseFloat(mileage);
    }

    static calculateMonthsRemaining(endContractDate: string, displayMonths = false): string | number {
        const endOfContractDate: ActivixDate = new ActivixDate(endContractDate);

        const diffInMonths = endOfContractDate.diffInMonths(new ActivixDate('now'));
        const monthRemaining = diffInMonths > 0 ? diffInMonths : 0;

        if (displayMonths) {
            return `${monthRemaining} ${i18n.t('clientCard.vehicles.months')}`;
        }

        return monthRemaining;
    }

    paymentRemaining(): number {
        if (!this.end_contract_date || !this.frequency) {
            return 0;
        }

        const endContractDate: ActivixDate = new ActivixDate(this.end_contract_date);

        let payment = 0;

        if (this.modality == LeadVehicleModality.FINANCING) {
            payment += 1;
        }

        switch (this.frequency) {
            case 'two_weeks':
                payment += Math.floor(endContractDate.diffInWeeks(new ActivixDate('now')) / 2);
                break;

            case 'weekly':
                payment += endContractDate.diffInWeeks(new ActivixDate('now'));
                break;

            case 'bi_monthly':
                payment += Math.floor(endContractDate.diffInDays(new ActivixDate('now')) / 15.2);
                break;

            case 'monthly':
                payment += endContractDate.diffInMonths(new ActivixDate('now'));
                break;

            case 'one_payment':
                payment = 0;
                break;
        }

        return payment;
    }

    calculateVehicleValue(): number {
        return LeadVehicle.calculateVehicleValue(this);
    }

    getStatus(account: any, lead: any): string | null {
        if (this.isExchange()) {
            return this.getExchangeStatus();
        }

        return this.getWantedStatus(account, lead);
    }

    getExchangeStatus(): string | null {
        return null;
    }

    getWantedStatus(account: any, lead: any): string | null {
        if (!this.sold) {
            if (account.isPolestar() && this.isPolestarTestDrive) {
                return 'polestarTestDrive';
            }

            return null;
        }

        if (lead.delivered_date) {
            return 'delivered';
        }

        if (
            lead.isOfType(5) &&
            lead.refinanced_date
        ) {
            return 'refinanced';
        }

        if (
            lead.isOfType(5) &&
            (lead.sale_date || lead.sale_by_phone)
        ) {
            return 'renewed';
        }

        if (
            lead.isNotOfType(5) &&
            (lead.sale_date || lead.sale_by_phone)
        ) {
            return 'sold';
        }

        return null;
    }

    isConsideredEmpty(ignoreFields: string[] = []): boolean {
        return LeadVehicle.isConsideredEmpty(this, ignoreFields);
    }

    toString(): string {
        return [(this.make || '').trim(), (this.model || '').trim(), String((this.year || '')).trim()].filter(v => v).join(' ');
    }

    static hasField(field: string, vehicleType: string, account: any, user: any = null): boolean {
        switch (vehicleType) {
            case LeadVehicleType.EXCHANGE:
                return this.hasExchangeField(field, account, user);
            case LeadVehicleType.WANTED:
                return this.hasWantedField(field, account);
            default:
                return false;
        }
    }

    static hasExchangeField(field: string, account: any, user: any = null): boolean {
        if (!account.hasClientCardField(field, LeadVehicleType.EXCHANGE)) {
            switch (field) {
                case 'end_contract_date':
                case 'modality':
                    return account.auto_renewal;

                default:
                    return false;
            }
        }

        switch (field) {
            case 'accidented_damage_cost':
            case 'actual_value':
            case 'tire_type':
            case 'trade_notes':
            case 'trade_type':
                return account.trade_report && (!user || user.trade_report || user.isAdmin());

            case 'end_warranty_date':
                return account.service || account.equity_alert;

            case 'extended_warranty':
            case 'license_plate':
            case 'purchase_date':
            case 'recall':
                return account.service;

            default:
                return true;
        }
    }

    static hasWantedField(field: string, account: any): boolean {
        if (!account.hasClientCardField(field, LeadVehicleType.WANTED)) {
            switch (field) {
                case 'end_contract_date':
                case 'modality':
                    return account.auto_renewal;

                case 'verified_by_id':
                    return account.verified_sale;

                default:
                    return false;
            }
        }

        return true;
    }
}
