export default {
    email: 'Courriel',
    phone: 'Téléphone',
    sms: 'SMS',
    messenger: 'Messenger',
    video: 'Vidéo',

    asAction: {
        email: 'courriel {type}',
        phone: 'appel {type}',
        sms: 'texto {type}',
        messenger: 'message facebook {type}',
        video: 'appel vidéo {type}',
    },
};
