import Vue from 'vue';
import Notifications from 'vue-notification';

Vue.use(Notifications, {
    name: 'notifications',
    componentName: 'vue-notifications',
});

const notify = {
    trigger(type, params) {
        if (typeof params === 'string') {
            params = { title: '', text: params };
        }

        if (['warning', 'error'].includes(type)) {
            params.ignoreDuplicates = true;
        }

        if (typeof params === 'object') {
            const customParams = {
                type,
                data: {},
            };

            switch (type) {
                case 'success':
                    customParams.data.icon = 'bold/check-1';
                    break;
                case 'warning':
                    customParams.data.icon = 'bold/alert-triangle';
                    break;
                case 'error':
                    customParams.data.icon = 'bold/close';
                    break;
                case 'info':
                    customParams.data.icon = 'regular/information-circle';
                    break;
                default:
                    break;
            }

            Vue.notifications({ ...customParams, ...params });
        }
    },
    info(params) {
        this.trigger('info', params);
    },
    success(params) {
        this.trigger('success', params);
    },
    error(params) {
        this.trigger('error', params);
    },
    warning(params) {
        this.trigger('warning', params);
    },
    clean() {
        Vue.notifications({ clean: true });
    },
    close(id) {
        Vue.notifications.close(id);
    },
};

Vue.use({
    install(VueInstance) {
        if (this.installed) {
            return;
        }

        this.installed = true;

        VueInstance.prototype.$notify = notify;
        VueInstance.notify = notify;
    },
});

export default notify;
