<template>
    <div class="flex flex-col gap-y-6 rounded-b-lg bg-white w-96 divide-y divide-gray-200 z-20">
        <div class="flex flex-col px-6 pt-6" v-if="orderByDirection">
            <activix-label class="block" :label="$t('sorting.title')" />

            <div class="flex">
                <activix-select
                    class="flex-1 w-1/2 mr-2"
                    label-key="text"
                    value-key="value"
                    :options="orderByFieldOptions"
                    v-model="selectedOrderByField"
                    v-show="orderByFieldOptions?.length"
                />
                <activix-select
                    class="flex-1"
                    :class="{ 'w-1/2': orderByFieldOptions?.length }"
                    label-key="text"
                    value-key="value"
                    :options="internalOrderingOptions"
                    v-model="selectedOrderBy"
                />
            </div>
        </div>

        <div class="flex flex-col gap-y-6 px-6 pt-6">
            <div
                :key="name"
                v-for="(filter, name) in availableFilters"
            >
                <activix-label class="block" :label="filter.label" />

                <activix-tooltip :content="filter.tooltip">
                    <activix-select
                        class="flex-1"
                        label-key="text"
                        value-key="value"
                        :filterable="filter.filterable || false"
                        :multiple="filter.multiple || false"
                        :highlighted="filter.highlighted || false"
                        :nullable="filter.nullable === undefined ? true : filter.nullable"
                        :nullable-option-label="$t('filters.all')"
                        :options="getSelectOptions(filter)"
                        :placeholder="filter.placeholder || $t('filters.all')"
                        :select-all="filter.selectAll"
                        :value="getValue(name, filter)"
                        @input="updateSelectedFilter(name, $event)"
                        v-if="['select', 'bool'].includes(filter.type)"
                    />

                    <div class="flex" v-else-if="filter.type === 'range'">
                        <activix-input
                            class="flex-auto w-44"
                            :mask="filter.mask"
                            :value="selectedFilters[getRangeName(name, 'min')]"
                            :highlighted="filter.highlighted || false"
                            @input="updateRangeFilter(getRangeName(name, 'min'), $event)"
                        />
                        <p class="flex-auto w-9 text-center">
                            _
                        </p>
                        <activix-input
                            class="flex-auto w-44"
                            :mask="filter.mask"
                            :value="selectedFilters[getRangeName(name, 'max')]"
                            :highlighted="filter.highlighted || false"
                            @input="updateRangeFilter(getRangeName(name, 'max'), $event)"
                        />
                    </div>

                    <activix-input
                        :value="selectedFilters[name]"
                        @input="updateSelectedFilter(name, $event)"
                        v-else-if="filter.type === 'text'"
                    />
                    <activix-checkbox
                        class-name="flex gap-2 items-start"
                        :value="selectedFilters[name]"
                        @input="updateSelectedFilter(name, $event)"
                        v-else-if="filter.type === 'checkbox'"
                    />
                    <div
                        class="flex"
                        v-else-if="filter.type ==='date-range'"
                    >
                        <date-time-picker
                            :date-only="filter.dateOnly || true"
                            :start-date="filter.minDate"
                            :end-date="filter.maxDate"
                            :show-icon="false"
                            :highlighted="filter.highlighted || false"
                            :value="selectedFilters[getRangeName(name, 'min')]"
                            @input="updateRangeFilter(getRangeName(name, 'min'), $event)"
                        />
                        <p class="flex-auto w-9 text-center">
                            _
                        </p>
                        <date-time-picker
                            :date-only="filter.dateOnly || true"
                            :start-date="filter.minDate"
                            :end-date="filter.maxDate"
                            :show-icon="false"
                            :highlighted="filter.highlighted || false"
                            :value="selectedFilters[getRangeName(name, 'max')]"
                            @input="updateRangeFilter(getRangeName(name, 'max'), $event)"
                        />
                    </div>
                </activix-tooltip>
            </div>
        </div>
        <div class="sticky bottom-0">
            <div class="flex justify-center gap-x-3 p-6 bg-gray-50">
                <activix-button @click="reset">
                    {{ $t('filters.reset') }}
                </activix-button>
                <activix-button color="blue" @click="apply">
                    {{ $t('filters.apply') }}
                </activix-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ActivixButton, ActivixInput, ActivixSelect } from '@autosync/atx-activix-ui';

    import ActivixLabel from '@/components/elements/ActivixLabel.vue';
    import ActivixCheckbox from '@/components/elements/ActivixCheckbox.vue';
    import ActivixDate from '@/value-objects/ActivixDate.js';

    import DateTimePicker from '@/components/inputs/DateTimePicker.vue';

    export default {
        components: {
            ActivixButton,
            ActivixInput,
            ActivixLabel,
            ActivixSelect,
            ActivixCheckbox,
            DateTimePicker,
        },

        props: {
            filters: {
                type: Object,
                required: true,
            },
            orderByDirection: {
                type: String,
                default: null,
            },
            orderByDirectionOptions: {
                type: Array,
                default: () => [],
            },
            orderByField: {
                type: String,
                default: null,
            },
            orderByFieldOptions: {
                type: Array,
                default: () => [],
            },
        },

        data: () => ({
            selectedDate: new ActivixDate('now').dateTime,
            selectedFilters: {},
            selectedOrderBy: null,
            selectedOrderByField: null,
        }),

        computed: {
            availableFilters() {
                return Object.fromEntries(
                    Object.entries(this.filters)
                        .filter(([, filter]) => !filter.invalid),
                );
            },

            internalOrderingOptions() {
                if (this.orderByDirectionOptions.length) {
                    return this.orderByDirectionOptions;
                }

                return [
                    {
                        value: 'newestFirst',
                        text: this.$t('sorting.newestFirst'),
                    },
                    {
                        value: 'oldestFirst',
                        text: this.$t('sorting.oldestFirst'),
                    },
                ].sort((a, b) => a.text.localeCompare(b.text));
            },
        },

        watch: {
            availableFilters: {
                immediate: true,
                async handler() {
                    await this.$nextTick();

                    this.setInitialValue();
                },
            },
            orderByDirection: {
                immediate: true,
                handler(newValue) {
                    this.selectedOrderBy = newValue;
                },
            },
            orderByField: {
                immediate: true,
                handler(newValue) {
                    this.selectedOrderByField = newValue;
                },
            },
        },

        methods: {
            apply() {
                const normalizedSelectedFilters = Object.fromEntries(
                    Object.entries(this.selectedFilters)
                        .map(([name, selectedValue]) => {
                            const type = this.availableFilters[name]?.type;
                            const value = this.normalizeFilterValueOut({ type, value: selectedValue });

                            return [name, value];
                        }),
                );

                this.$emit('apply', {
                    filters: normalizedSelectedFilters,
                    orderByDirection: this.selectedOrderBy,
                    orderByField: this.selectedOrderByField,
                });
            },

            eraseFilters() {
                this.selectedFilters = Object.entries(this.availableFilters)
                    .map(([name]) => [name, undefined]);
            },

            getSelectOptions(filter) {
                if (this.isFilterTypeBoolean(filter)) {
                    return [
                        {
                            value: 'yes',
                            text: this.$t('general.yes'),
                        },
                        {
                            value: 'no',
                            text: this.$t('general.no'),
                        },
                    ];
                }

                return filter.options || [];
            },

            getValue(name, filter) {
                const value = this.selectedFilters[name];

                if (this.isFilterTypeSelectMultiple(filter)) {
                    return value || [];
                }

                return value;
            },

            getNormalizeFilterValue(filter) {
                if (this.isFilterTypeBoolean(filter)) {
                    return this.normalizeBoolValueIn(filter.value);
                }

                return filter.value;
            },

            getRangeName(field, position) {
                return `${field}_${position}`;
            },

            isFilterTypeBoolean(filter) {
                return filter.type === 'bool';
            },

            isFilterTypeRange(filter) {
                return filter.type === 'range' || filter.type === 'date-range';
            },

            isFilterTypeSelectMultiple(filter) {
                return filter.type === 'select' && filter.multiple;
            },

            normalizeBoolValueIn(value) {
                if (value === true) {
                    return 'yes';
                }

                if (value === false) {
                    return 'no';
                }

                return null;
            },

            normalizeBoolValueOut(value) {
                if (value === 'yes') {
                    return true;
                }

                if (value === 'no') {
                    return false;
                }

                return null;
            },

            normalizeFilterValueOut(filter) {
                if (this.isFilterTypeBoolean(filter)) {
                    return this.normalizeBoolValueOut(filter.value);
                }

                return filter.value;
            },

            reset() {
                this.eraseFilters();

                this.$emit('reset');
            },

            setInitialValue() {
                this.selectedFilters = Object.entries(this.availableFilters)
                    .map(([name, filter]) => {
                        if (this.isFilterTypeRange(filter) && filter.value) {
                            return {
                                [`${name}_min`]: filter?.value[0],
                                [`${name}_max`]: filter?.value[1],
                            };
                        }
                        return { [name]: this.getNormalizeFilterValue(filter) };
                    })
                    .reduce((result, filter) => {
                        for (const name in filter) {
                            if (filter.hasOwnProperty(name)) {
                                result[name] = filter[name];
                            }
                        }

                        return result;
                    });
            },

            updateRangeFilter(filterName, filterValue) {
                this.$set(this.selectedFilters, filterName, filterValue);
            },

            updateSelectedFilter(filterName, filterValue) {
                this.selectedFilters[filterName] = filterValue;

                this.$emit('filter-selected', {
                    filterName,
                    filterValue,
                });
            },
        },
    };
</script>
