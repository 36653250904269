<template>
    <figure class="shrink-0 flex justify-center items-center p-1.5 rounded-full shadow-md bg-white h-12 w-12">
        <activix-avatar
            class="object-cover w-full h-full rounded-full border border-gray-200 text-gray-700 bg-gray-200"
            :username="user.fullName"
            :size="32"
            :src="user.hasProfilePicture() ? user.profile_picture : ''"
        />
    </figure>
</template>

<script>
    import User from '@/entities/User.js';

    export default {
        props: {
            user: {
                type: User,
                required: true,
            },
        },
    };
</script>
