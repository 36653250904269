import { camelCase, get, upperFirst } from 'lodash-es';

const components = {};
const requireComponent = require.context('../components/inventory/cards', true, /\.(js|vue)$/i);

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = upperFirst(
        camelCase(
            fileName
                .split('/')
                .pop()
                .replace(/\.\w+$/, ''),
        ),
    );

    components[componentName] = componentConfig.default || componentConfig;
});

export default {
    components: {
        ...components,
    },

    computed: {
        inventoryCardsConfig() {
            return {
                totalVehicles: {
                    valid: true,
                    title: this.$tc('inventory.statistics.totalVehicles'),
                    card: get(this.cards, 'totalVehicles.number'),
                    icon: this.$icons.delivery,
                    componentDescription: 'TotalVehiclesBigDescription',
                    category: this.$tc('inventory.vehicles'),
                },
                averagePrice: {
                    valid: true,
                    title: this.$tc('inventory.statistics.averagePrice'),
                    card: get(this.cards, 'averagePrice.number'),
                    icon: this.$icons.sale,
                    componentDescription: 'AveragePriceBigDescription',
                },
                averageDaysInInventory: {
                    valid: true,
                    title: this.$tc('inventory.statistics.averageDaysInInventory'),
                    card: get(this.cards, 'averageDaysInInventory.number'),
                    icon: this.$icons.timeClockCircle,
                    componentDescription: 'AverageDaysInInventoryBigDescription',
                    category: this.$tc('inventory.statistics.days'),
                },
                inStock: {
                    valid: true,
                    title: this.$tc('inventory.statistics.inStock'),
                    card: get(this.cards, 'inStock.number'),
                    icon: this.$icons.inStock,
                    componentDescription: 'InStockBigDescription',
                    category: this.$tc('inventory.vehicles'),
                },
            };
        },

        activeCards() {
            return Object.keys(this.inventoryCards)
                .map(name => {
                    return {
                        name,
                        ...this.inventoryCards[name],
                        ...this.inventoryCardsConfig[name],
                    };
                })
                .filter(card => {
                    return card.valid && card.visible;
                });
        },

        inventoryCardClass() {
            const countCard = this.activeCards.length;
            return `xl:w-1/${countCard}`;
        },
    },
};
