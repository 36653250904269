import Account from '../../../entities/Account.js';
import Group from '../../../entities/Group.js';
import User from '../../../entities/User.js';

export default {
    account: new Account(),
    accountId: null,
    divisionId: null,
    group: new Group(),
    groupId: null,
    leadId: null,
    teamId: null,
    user: new User(),
    userId: null,
};
