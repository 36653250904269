export default {
    getAllCalendarViews() {
        return this.calendarViews.concat(this.sharedCalendarViews);
    },

    getCalendarView() {
        return (viewId) => {
            return this.getAllCalendarViews.find(view => {
                return view.id == viewId;
            });
        };
    },
};
